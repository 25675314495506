import { put } from 'redux-saga/effects';
import { takeEvery } from 'typed-redux-saga';

import type { JustificationWrapper } from '@legalplace/bankroot-api/dto';
import type { PayloadAction } from '@reduxjs/toolkit';

import {
  setJustificationTransactionsCount,
  startFetchActiveCompanyPcaBalance,
  startFetchActiveCompanyVatBalance,
} from '../actions';

function* handleSetNonJustifiedTransactionsCount({
  payload: { nonCategorizedTransactionsCount },
}: PayloadAction<
  Pick<
    JustificationWrapper,
    | 'nonCategorizedTransactionsCount'
    | 'nonJustifiedTransactionsCount'
    | 'transactionsMissingProofCount'
  >
>): Generator {
  if (nonCategorizedTransactionsCount === 0) {
    yield put(startFetchActiveCompanyPcaBalance());
    yield put(startFetchActiveCompanyVatBalance());
  }
}

function* setNonJustifiedTransactionsCountSaga(): Generator {
  yield takeEvery(
    setJustificationTransactionsCount,
    handleSetNonJustifiedTransactionsCount
  );
}

export default setNonJustifiedTransactionsCountSaga;
