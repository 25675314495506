import type {
  GetCompanyAccountingImpactsResponseDto,
  GetCompanyBalanceHistoryResponseDto,
  GetCompanyCashFlowResponseDto,
  GetCompanyFinancialResultsResponseDto,
  GetCompanyMainExpensesResponseDto,
  GetCompanyTransactionTypeBreakdownDto,
  GetWalletCashflowResponseDto,
} from '@legalplace/bankroot-api/modules/company/dto';
import type { CashFlowWalletGroupBy } from '@legalplace/shared';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../services/api/api';

export const companyFinancesApi = createApi({
  reducerPath: 'companyFinancesApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['companyFinances'],
  endpoints: (build) => ({
    getMainExpenses: build.query<
      GetCompanyMainExpensesResponseDto,
      { companyId: string; startDate: string; endDate: string }
    >({
      query: ({ companyId, startDate, endDate }) => ({
        url: `/company/${companyId}/main-expenses?startDate=${startDate}&endDate=${endDate}`,
      }),
      providesTags: ['companyFinances'],
    }),
    getFinancialPerformanceByMonth: build.query<
      GetCompanyFinancialResultsResponseDto[],
      { companyId: string; startDate: string; endDate: string }
    >({
      query: ({ companyId, startDate, endDate }) => ({
        url: `/company/${companyId}/financial-results?startDate=${startDate}&endDate=${endDate}`,
      }),
      providesTags: ['companyFinances'],
    }),
    getFinancialPerformanceByAccountingPeriod: build.query<
      GetCompanyAccountingImpactsResponseDto,
      { companyId: string; startDate: string; endDate: string }
    >({
      query: ({ companyId, startDate, endDate }) => ({
        url: `/company/${companyId}/accounting-impacts?startDate=${startDate}&endDate=${endDate}`,
      }),
      providesTags: ['companyFinances'],
    }),
    getBalanceHistory: build.query<
      GetCompanyBalanceHistoryResponseDto,
      { companyId: string; startDate: string; endDate: string }
    >({
      query: ({ companyId, startDate, endDate }) => ({
        url: `/company/${companyId}/balance-history?startDate=${startDate}&endDate=${endDate}`,
      }),
      providesTags: ['companyFinances'],
    }),
    getCashFlow: build.query<
      GetCompanyCashFlowResponseDto[],
      { companyId: string; startDate: string; endDate: string }
    >({
      query: ({ companyId, startDate, endDate }) => ({
        url: `/company/${companyId}/cash-flow?startDate=${startDate}&endDate=${endDate}`,
      }),
      providesTags: ['companyFinances'],
    }),
    getWalletCashFlow: build.query<
      GetWalletCashflowResponseDto,
      {
        companyId: string;
        startDate: string;
        endDate: string;
        groupByMethod: CashFlowWalletGroupBy;
      }
    >({
      query: ({ companyId, startDate, endDate, groupByMethod }) => ({
        url: `/company/${companyId}/wallet-cash-flow?startDate=${startDate}&endDate=${endDate}&groupBy=${groupByMethod}`,
      }),
      providesTags: ['companyFinances'],
    }),
    getTransactionTypeBreakdown: build.query<
      GetCompanyTransactionTypeBreakdownDto,
      { companyId: string; startDate: string; endDate: string }
    >({
      query: ({ companyId, startDate, endDate }) => ({
        url: `/company/${companyId}/transaction-type-breakdown?startDate=${startDate}&endDate=${endDate}`,
      }),
      providesTags: ['companyFinances'],
    }),
  }),
});

export const {
  useGetMainExpensesQuery,
  useGetFinancialPerformanceByMonthQuery,
  useGetFinancialPerformanceByAccountingPeriodQuery,
  useGetBalanceHistoryQuery,
  useGetCashFlowQuery,
  useGetWalletCashFlowQuery,
  useGetTransactionTypeBreakdownQuery,
} = companyFinancesApi;
