import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import type { TFunction } from 'react-i18next';

import {
  BankTertiaryButton,
  CCA3,
  Dropdown,
  FlexContainerBasic,
  FlexContainerBasicColumn,
  FULL_SELECT_COUNTRY_OPTIONS,
  POST_CODE_INPUT_MASK_REGEX,
  Typography,
} from '@legalplace/storybook';

import { useAppSelector } from '../../../../hooks/store';
import { skipCardOrderRequest } from '../../../../services/api/onboardingFlow.api';
import { isLpDomiAddress } from '../../../../services/utils';
import { selectActiveCompany } from '../../../../store/companies/selectors';
import { InputWrapper } from '../../../common/forms/inputWrapper/InputWrapper';
import { Input } from '../../../common/forms/styledInput/Input';

import { PrimaryButton } from '../../../UI/PrimaryButton.style';
import { OnboardingForm } from '../CredentialsCreation/CredentialsCreation.style';
import { CardDeliveryButtonsContainer } from './CardDelivery.style';

export function CardDeliveryForm({
  t,
  setIsEditing,
  deliveryAddressFormState,
  setDeliveryAddressFormState,
  notifySuccess,
}: {
  t: TFunction;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  deliveryAddressFormState: Record<string, string | number | boolean>;
  setDeliveryAddressFormState: Dispatch<
    SetStateAction<Record<string, string | number | boolean>>
  >;
  notifySuccess: () => void;
}): JSX.Element {
  const activeCompany = useAppSelector(selectActiveCompany);

  const [errorMessage, setErrorMessage] = useState<{
    address?: string;
    zipCode?: string;
    city?: string;
  }>({});

  const validateAddressClickHandler = async (): Promise<void> => {
    const newErrors: Record<string, string> = {};

    if (
      isLpDomiAddress({
        address1: deliveryAddressFormState.address1,
        zipCode: deliveryAddressFormState.zipCode,
      })
    ) {
      newErrors.address = t('address_error_message');
    }

    if (!deliveryAddressFormState.address1) {
      newErrors.address = t('empty_field_error');
    }

    if (!deliveryAddressFormState.zipCode) {
      newErrors.zipCode = t('empty_field_error');
    }

    if (!deliveryAddressFormState.city) {
      newErrors.city = t('empty_field_error');
    }

    if (!deliveryAddressFormState.country) {
      newErrors.country = t('empty_field_error');
    }

    if (
      deliveryAddressFormState.zipCode &&
      (deliveryAddressFormState.zipCode as string).length < 3
    ) {
      newErrors.zipCode = t('zip_code_length_error_message');
    }

    if (
      deliveryAddressFormState.zipCode &&
      deliveryAddressFormState.country === CCA3.FRA &&
      !POST_CODE_INPUT_MASK_REGEX.test(
        deliveryAddressFormState.zipCode as string
      )
    ) {
      newErrors.zipCode = t('french_zip_code_error_message');
    }

    setErrorMessage(newErrors);
    if (Object.values(newErrors).length) {
      return;
    }

    setIsEditing(false);
  };

  const skipCardStep = async (): Promise<void> => {
    try {
      await skipCardOrderRequest();
      notifySuccess();
    } catch (error: unknown) {
      console.error(
        `[skipCardDeliveryStep] - Error trying to skip card delivery step for company id : ${activeCompany?.id}`,
        error
      );
    }
  };

  return (
    <>
      <OnboardingForm onSubmit={validateAddressClickHandler}>
        <FlexContainerBasicColumn>
          <InputWrapper
            name="addressField"
            label={t('address_field')}
            hasError={!!errorMessage.address}
            errorMessage={errorMessage.address}
          >
            <FlexContainerBasicColumn gap={{ default: '1rem' }}>
              <Input
                hasError={!!errorMessage.address}
                placeholderColor="grey300"
                name="addressField"
                type="text"
                placeholder={t('address_placeholder')}
                value={deliveryAddressFormState.address1?.toString()}
                onChange={(e) =>
                  setDeliveryAddressFormState((prev) => ({
                    ...prev,
                    address1: e.target.value,
                  }))
                }
                maxLength={38}
              />
              <Input
                hasError={!!errorMessage.address}
                placeholderColor="grey300"
                name="addressField"
                type="text"
                placeholder={t('address_placeholder_2')}
                value={deliveryAddressFormState.address2?.toString()}
                onChange={(e) =>
                  setDeliveryAddressFormState((prev) => ({
                    ...prev,
                    address2: e.target.value ?? '',
                  }))
                }
                maxLength={38}
              />
            </FlexContainerBasicColumn>
          </InputWrapper>
          <FlexContainerBasic gap={{ default: '1.6rem' }}>
            <InputWrapper
              name="zipCodeField"
              label={t('zip_code_field')}
              hasError={!!errorMessage.zipCode}
              errorMessage={errorMessage.zipCode}
            >
              <Input
                hasError={!!errorMessage.zipCode}
                placeholderColor="grey300"
                name="zipCodeField"
                type="text"
                placeholder={t('zip_code_placeholder')}
                value={deliveryAddressFormState.zipCode?.toString()}
                onChange={(e) =>
                  setDeliveryAddressFormState((prev) => ({
                    ...prev,
                    zipCode: e.target.value,
                  }))
                }
                maxLength={10}
              />
            </InputWrapper>
            <InputWrapper
              name="cityField"
              label={t('city_field')}
              hasError={!!errorMessage.city}
              errorMessage={errorMessage.city}
            >
              <Input
                hasError={!!errorMessage.city}
                placeholderColor="grey300"
                name="cityField"
                type="text"
                placeholder={t('city_placeholder')}
                value={deliveryAddressFormState.city?.toString()}
                onChange={(e) =>
                  setDeliveryAddressFormState((prev) => ({
                    ...prev,
                    city: e.target.value,
                  }))
                }
              />
            </InputWrapper>
          </FlexContainerBasic>
          <FlexContainerBasic>
            <InputWrapper name="countryField" label={t('country_field')}>
              <Dropdown
                hasSearch
                placeholder={t('country_placeholder')}
                placeholderColor="grey300"
                name="countryField"
                options={FULL_SELECT_COUNTRY_OPTIONS}
                defaultOption={FULL_SELECT_COUNTRY_OPTIONS.find(
                  (option) => option.value === deliveryAddressFormState.country
                )}
                onChange={(e) =>
                  setDeliveryAddressFormState((prev) => ({
                    ...prev,
                    country: e?.value as CCA3,
                  }))
                }
              />
            </InputWrapper>
          </FlexContainerBasic>
        </FlexContainerBasicColumn>
      </OnboardingForm>
      <CardDeliveryButtonsContainer>
        <BankTertiaryButton onClick={skipCardStep} width="fit-content">
          <Typography tag="p3" color="grey700" fontWeight="500">
            {t('skip_button_label')}
          </Typography>
        </BankTertiaryButton>
        <PrimaryButton
          onClick={validateAddressClickHandler}
          width={{ default: '100%', medium: 'fit-content' }}
        >
          {t('confirm_address_button_label')}
        </PrimaryButton>
      </CardDeliveryButtonsContainer>
    </>
  );
}
