import { useTranslation } from 'react-i18next';

import {
  FlexContainerBasicAlignCenter,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { AccountingTrialEndContainer } from './AccountingTrialEndBanner.style';

export function AccountingTrialEndBanner(): JSX.Element | null {
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'accounting_trial_end_banner',
  });

  return (
    <AccountingTrialEndContainer>
      <Typography
        tag="p2"
        color="white"
        spacing={{ default: '0.8rem 0' }}
        fontWeight="600"
      >
        {t('title')}
      </Typography>
      <FlexContainerBasicAlignCenter gap={{ default: '0.4rem' }}>
        <StreamLineIcon
          icon={StreamLineIconList.arrowright}
          fillColor="white"
          height="1.5rem"
          width="1.6rem"
        />
        <Typography tag="p3" color="white" spacing={{ default: '0.8rem 0' }}>
          {t('subtitle')}
        </Typography>
      </FlexContainerBasicAlignCenter>
    </AccountingTrialEndContainer>
  );
}
