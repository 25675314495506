import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Capacitor } from '@capacitor/core';
import { BankIconLoader, Typography } from '@legalplace/storybook';

import { ampli } from '../../../../ampli/api';
import { Status } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/store';
import { getPopup } from '../../../../services/api/popup.api';
import { PopupType } from '../../../../services/api/webPopup.api';
import { showToastError } from '../../../../services/notifications/toast';
import { startSilentFetchAccounts } from '../../../../store/accounts/actions';
import {
  selectAccountStatus,
  selectPendingAccounts,
} from '../../../../store/accounts/selectors';

import { BankSynchronizationAccountSelection } from './BankSynchronizationAccountSelection';
import { BankSynchronizationError } from './BankSynchronizationError';
import { BankSynchronizationSelector } from './BankSynchronizationSelector';

import { OnboardingStepBodyContainer } from '../../OnboardingV2Steps.style';
import { BankSynchronizationContainer } from './BankSynchronization.style';

export function BankSynchronization({
  notifySuccess,
}: {
  notifySuccess: () => void;
}): JSX.Element {
  const { t } = useTranslation('onboarding', {
    keyPrefix: 'onboarding_v2.bank_synchronization',
  });
  const dispatch = useAppDispatch();
  const QONTO_SYNCHRO_URL = import.meta.env.VITE_APP_QONTO_OAUTH_URL;
  const [isSynchronizing, setIsSynchronizing] = useState(false);
  const [hasError, setHasError] = useState(false);
  const accountFetchesCounterRef = useRef(0);
  const shouldStopPollingRef = useRef(true);

  const pendingAccounts = useAppSelector(selectPendingAccounts);
  const accountLoadingStatus = useAppSelector(selectAccountStatus);

  useEffect(() => {
    if (pendingAccounts.length) {
      shouldStopPollingRef.current = true;
    }
  }, [pendingAccounts.length]);

  const getPendingAccounts = useCallback(async (): Promise<void> => {
    try {
      if (pendingAccounts.length) {
        shouldStopPollingRef.current = true;
        return;
      }
      dispatch(startSilentFetchAccounts());
    } catch (error: unknown) {
      console.error(
        `[getPendingAccounts] - Error fetching pending accounts`,
        error
      );
      setHasError(true);
    }
  }, [dispatch, pendingAccounts.length]);

  const startSynchronization = useCallback(
    (url: string, isAmpliNeeded?: boolean) => {
      const bankPopup = getPopup();
      bankPopup.openPopup({ width: 1000, height: 700 });
      setIsSynchronizing(true);
      bankPopup.redirectAndListenForCallback(
        url,
        async () => {
          shouldStopPollingRef.current = false;
          setHasError(false);
          await getPendingAccounts();
          setTimeout(() => {
            setIsSynchronizing(false);
          }, 2000);
        },
        async () => {
          shouldStopPollingRef.current = true;
          if (!Capacitor.isNativePlatform()) {
            showToastError(t('bad_item_sync_message'));
            setHasError(true);
          }
          await getPendingAccounts();
          setTimeout(() => {
            setIsSynchronizing(false);
          }, 2000);
        },
        PopupType.Bridge
      );
      if (isAmpliNeeded) {
        ampli.bankBridgeSyncTabOpened({
          api_used: url.includes(QONTO_SYNCHRO_URL) ? 'Qonto' : 'Bridge',
          source: 'Onboarding',
        });
      }
    },
    [QONTO_SYNCHRO_URL, getPendingAccounts, t]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        !shouldStopPollingRef.current &&
        accountFetchesCounterRef.current < 10
      ) {
        getPendingAccounts();
        accountFetchesCounterRef.current += 1;
      } else {
        clearInterval(intervalId);
      }
    }, 3_000);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    dispatch,
    getPendingAccounts,
    isSynchronizing,
    pendingAccounts.length,
    shouldStopPollingRef,
  ]);

  if (hasError) {
    return <BankSynchronizationError />;
  }

  if (
    accountLoadingStatus === Status.LOADING ||
    accountLoadingStatus === Status.IDLE
  ) {
    return <BankIconLoader />;
  }

  if (pendingAccounts.length) {
    return (
      <OnboardingStepBodyContainer internalspacing={{ default: '2.4rem' }}>
        <BankSynchronizationAccountSelection notifySuccess={notifySuccess} />
      </OnboardingStepBodyContainer>
    );
  }

  if (!isSynchronizing && !hasError) {
    return (
      <BankSynchronizationSelector
        startSynchronization={startSynchronization}
      />
    );
  }

  return (
    <BankSynchronizationContainer>
      <BankIconLoader />
      <Typography
        tag="p3"
        lineHeight={{ default: '2rem' }}
        color="greytainted700"
        textAlign="center"
      >
        {t('loading_message')}
      </Typography>
    </BankSynchronizationContainer>
  );
}
