export const accounting = {
  comment: {
    save: 'Enregistrer',
    saved: 'Enregistré',
    saving: 'Enregistrement',
    comment: 'Commentaire',
  },
  category: {
    refund_toggle_message: "Il s'agit d'un remboursement",
    category_search_bar_placeholder: 'Rechercher une catégorie',
  },
};
