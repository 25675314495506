import type { FindAccountDto } from '@legalplace/bankroot-api/modules/account/dto';
import { FAKE_BRIDGE_ACCOUNT_ID } from '@legalplace/shared';

export const getInitialNewAccountsToSynchronize = (
  pendingAccounts: FindAccountDto[]
): Record<string, boolean> =>
  pendingAccounts.reduce(
    (acc, account) => ({
      ...acc,
      [account.id]: true,
    }),
    {}
  );

export const getNewAccountsToSynchronizeValues = (
  newAccountsToSynchronize: Record<string, boolean>
): string[] =>
  Object.keys(newAccountsToSynchronize).filter(
    (accountId) =>
      newAccountsToSynchronize[accountId] &&
      accountId !== FAKE_BRIDGE_ACCOUNT_ID
  );
