import type { AxiosResponse } from 'axios';

import type {
  CompanyUserDto,
  CompanyUserRightsDto,
  FindCompanyDto,
  GetCompanyAccountingImpactsResponseDto,
  GetCompanyBalanceHistoryResponseDto,
  GetCompanyCashFlowResponseDto,
  GetCompanyFinancialResultsResponseDto,
  GetCompanyMainExpensesResponseDto,
  GetReferralDto,
  GetVatBalanceDto,
  UpdateCompanySetupStatusResponseDto,
} from '@legalplace/bankroot-api/modules/company/dto';
import type {
  GetCompanyTransactionTypeBreakdownDto,
  GetWalletCashflowResponseDto,
} from '@legalplace/bankroot-api/modules/company/dto/companyFinances';
import type { ConsentUrlDto } from '@legalplace/bankroot-api/modules/consent/dto';
import type {
  AccountingUpsell,
  CashFlowWalletGroupBy,
  CompanySetupStatus,
  UserCompanyStatus,
} from '@legalplace/shared';

import { api } from './api';

export const getCompaniesRequest = (): Promise<
  AxiosResponse<FindCompanyDto[]>
> => api.get(`/company`);

export const getMainExpensesRequest = (
  companyId: string,
  startDate: Date,
  endDate: Date
): Promise<AxiosResponse<GetCompanyMainExpensesResponseDto>> =>
  api.get(
    `/company/${companyId}/main-expenses?startDate=${startDate}&endDate=${endDate}`
  );

export const getFinancialPerformanceByMonthRequest = (
  companyId: string,
  startDate: Date,
  endDate: Date
): Promise<AxiosResponse<GetCompanyFinancialResultsResponseDto[]>> =>
  api.get(
    `/company/${companyId}/financial-results?startDate=${startDate}&endDate=${endDate}`
  );

export const getFinancialPerformanceByAccountingPeriodRequest = (
  companyId: string,
  startDate: Date,
  endDate: Date
): Promise<AxiosResponse<GetCompanyAccountingImpactsResponseDto>> =>
  api.get(
    `/company/${companyId}/accounting-impacts?startDate=${startDate}&endDate=${endDate}`
  );

export const getBalanceHistoryRequest = (
  companyId: string,
  startDate: Date,
  endDate: Date
): Promise<AxiosResponse<GetCompanyBalanceHistoryResponseDto>> =>
  api.get(
    `/company/${companyId}/balance-history?startDate=${startDate}&endDate=${endDate}`
  );

export const getCashFlowRequest = (
  companyId: string,
  startDate: Date,
  endDate: Date
): Promise<AxiosResponse<GetCompanyCashFlowResponseDto[]>> =>
  api.get(
    `/company/${companyId}/cash-flow?startDate=${startDate}&endDate=${endDate}`
  );

export const getWalletCashFlowRequest = (
  companyId: string,
  startDate: Date,
  endDate: Date,
  groupByMethod: CashFlowWalletGroupBy
): Promise<AxiosResponse<GetWalletCashflowResponseDto>> =>
  api.get(
    `/company/${companyId}/wallet-cash-flow?startDate=${startDate}&endDate=${endDate}&groupBy=${groupByMethod}`
  );

export const getCompanyVatBalanceRequest = (
  companyId: string
): Promise<AxiosResponse<GetVatBalanceDto>> =>
  api.get(`/company/${companyId}/vat-balance`);

export const patchCompanySetupStatusRequest = (
  companyId: string,
  oldStatus?: CompanySetupStatus
): Promise<AxiosResponse<UpdateCompanySetupStatusResponseDto>> =>
  api.patch(`/company/${companyId}/setup-status/next?oldStatus=${oldStatus}`);

export const createAccountingTrialRequest = (
  companyId: string,
  upsell: AccountingUpsell
): Promise<AxiosResponse<void>> =>
  api.post(`/company/${companyId}/accounting-trial`, { upsell });

export const getCompanyUserRequest = (
  companyId: string
): Promise<AxiosResponse<CompanyUserDto[]>> =>
  api.get(`/company/${companyId}/user`);

export const getCompanyUserRightsRequest = (
  companyId: string
): Promise<AxiosResponse<CompanyUserRightsDto>> =>
  api.get(`/company/${companyId}/user/rights`);

export const patchCompanyUserRequest = (
  companyId: string,
  userId: string,
  status: UserCompanyStatus
): Promise<AxiosResponse<ConsentUrlDto | null>> =>
  api.patch(`/company/${companyId}/user/${userId}`, { status });

export const resendUserInvitation = (
  userIdToResendInvite: string,
  companyId: string
): Promise<AxiosResponse<void>> =>
  api.post(`/company/${companyId}/user/${userIdToResendInvite}`);

export const deleteUserInvitation = (
  userIdToCancelInvite: string,
  companyId: string
): Promise<AxiosResponse<void>> =>
  api.delete(`/company/${companyId}/user/${userIdToCancelInvite}`);

export const getTransactionTypeBreakdown = (
  companyId: string,
  startDate: Date,
  endDate: Date
): Promise<AxiosResponse<GetCompanyTransactionTypeBreakdownDto>> =>
  api.get(
    `/company/${companyId}/transaction-type-breakdown?startDate=${startDate}&endDate=${endDate}`
  );

export const getCompanyReferral = (
  companyId: string
): Promise<AxiosResponse<GetReferralDto>> =>
  api.get(`/company/${companyId}/referral`);
