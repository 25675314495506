import { FULL_SELECT_COUNTRY_OPTIONS } from '@legalplace/storybook';

import { AddCardStep } from '../../constants/card.constants';

const COUNTRY_TRANSLATIONS = FULL_SELECT_COUNTRY_OPTIONS.reduce(
  (acc, { value, label }) => {
    acc[`country_${value}`] = label;
    return acc;
  },
  {} as Record<string, string>
);

export const cards = {
  home: {
    page_title: 'Cartes',
    new_card_button: 'Nouvelle carte',
  },
  card_details: {
    status_label: {
      ToCreate: 'À commander',
      Processing: 'Fabrication en cours',
      ToActivate: 'À activer',
      Canceled: 'Bloquée définitivement',
      Canceling: 'Bloquée définitivement',
      Activated: 'Active',
      Suspended: 'Désactivée temporairement',
    },
    back_button: 'Retour',
    subtitle: {
      ToCreate: 'Commandez une carte en quelques clics !',
      Processing: 'La carte sera envoyée prochainement.',
      ToActivate:
        "La carte a été envoyée. Dès réception, vous pourrez l'activer ici.",
      Canceled: 'Bloquée définitivement',
      Canceling: 'Bloquée définitivement',
    },
    processing: {
      activate_card: 'Activer la carte...',
    },
    to_create: {
      create_card: 'Commander la carte...',
    },
    activated: {
      type: 'Type',
      physical_card: 'Carte physique',
      expiry: 'Expiration',
      display_pin: 'Afficher le code PIN',
      disable_card: 'Désactiver la carte...',
      withdrawal_spending: {
        rolling_window: 'Dont retraits',
        spent_amount: 'retirés',
      },
      spending: {
        rolling_window: 'Toutes les dépenses',
        spent_amount: 'dépensés',
      },
      spending_title: 'Dépenses sur les 30 derniers jours :',
      online_payment: 'Paiement en ligne',
      atm_withdrawal: 'Retraits dans les distributeurs',
      foreign_currency: 'Paiements hors Euros (€)',
      save_button: 'Enregistrer',
      card_options_updated: 'Préférences de carte enregistrées !',
      suspend_card_flow: {
        modal_title: 'Désactiver la carte',
        confirm_suspension_message:
          'En cas de perte, vous pouvez désactiver la carte par sécurité. Vous pourrez ensuite la réactiver ou la bloquer définitivement si nécessaire.',
        close_modal_label: 'Annuler',
        confirm_suspension_label: 'Désactiver',
        success_text_message_line1:
          'Dès que vous aurez retrouvé la carte, vous pourrez la réactiver immédiatement.',
        success_text_message_line2:
          "Si vous savez déjà que la carte est perdue, volée, ou qu'elle ne fonctionne plus, bloquez-la définitivement pour en commander une nouvelle.",
        keep_looking_label: 'Je cherche encore',
        permanently_cancel_card_label: 'Bloquer',
        success_suspend_title: 'La carte est désactivée temporairement !',
      },
    },
    to_activate: {
      button_label: 'Activer la carte...',
      modal_title: 'Activer la carte',
      identifier_instructions:
        "Renseignez l'identifiant qui se situe au dos de la carte :",
      form_button_label: 'Activer',
      input_label: 'Identifier',
      failed_consent_title: 'Activation annulée',
      failed_consent_message: 'Vous avez annulé l’activation de cette carte.',
      modal_cancellation_button: 'Fermer',
      close_modal_button_label: 'Fermer',
      success_consent_title: 'La carte est désormais active !',
      success_consent_message_line_1:
        'Vous pouvez payer en ligne et en magasin (sans contact inclus).',
      success_consent_message_line_2:
        'Apple Pay et Google Pay sont également disponibles : ajoutez simplement la carte avec votre application habituelle.',
      invalid_identifier_error_message:
        "Il ne s'agit pas de l'identifiant attendu.",
      display_pin: 'Afficher le code PIN',
    },
    suspended: {
      type: 'Type',
      physical_card: 'Carte physique',
      expiry: 'Expiration',
      permanently_cancel_card: 'Bloquer définitivement...',
      reactivate_card: 'Réactiver la carte...',
      rolling_window: '30 jours glissants',
      remaining_amount: 'restants',
      online_payment: 'Paiement en ligne',
      atm_withdrawal: 'Retraits dans les distributeurs',
      foreign_currency: 'Paiements hors Euros (€)',
      save_button: 'Enregistrer',
      cancel_card_flow: {
        modal_title: 'Bloquer définitivement la carte',
        reason_label: 'Motif du blocage',
        cancel_card_button_label: 'Bloquer',
        back_button: 'Annuler',
        warning_permanent_action_label:
          'Je comprends que cette action est irréversible.',
        reason_form_label: {
          lost: 'Carte perdue, impossible de la retrouver',
          stolen: 'Carte volée',
          defected: 'Carte défectueuse',
          blocked: 'Carte devenue inutile',
        },
        success_title: 'La carte est bloquée définitivement !',
        success_message:
          'Vous pouvez dès à présent en commander une autre pour la remplacer.',
        new_card_button: 'Commander une carte',
      },
      reactivate_card_flow: {
        modal_title: 'Réactiver la carte',
        confirm_reactivation_message:
          'Carte retrouvée ? Réactivez-la immédiatement pour pouvoir l’utiliser à nouveau.',
        close_modal_label: 'Annuler',
        confirm_reactivation_label: 'Réactiver',
        close_modal_button_label: 'Fermer',
        failed_consent_title: 'Réactivation annulée',
        failed_consent_message:
          'Vous avez annulé la réactivation de cette carte. Elle reste désactivée temporairement.',
        success_consent_title: 'La carte est de nouveau active !',
        success_text_message:
          'Vous l’avez compris : en cas de doute, n’hésitez pas à la désactiver temporairement.',
        card_locked_label: 'Votre carte est bloquée',
        card_locked_details_text: `Votre carte vient d'être bloquée suite à 3 erreurs consécutives durant la saisie de votre code PIN. Veuillez suivre la procédure suivante pour la débloquer.`,
        help_button_label: 'Suivre la procédure',
      },
    },
    canceled: {
      type: 'Type',
      physical_card: 'Carte physique',
      cancelation_date: 'Désactivation',
    },
    info_table: {
      carrier: 'Transporteur',
      not_available: 'Bientôt disponible',
      tracking_number: 'Numéro de suivi',
      delivery_address: 'Adresse',
      ...COUNTRY_TRANSLATIONS,
    },
  },
  card_list: {
    status_label_ToCreate: 'À commander',
    status_label_Processing: 'Fabrication en cours',
    status_label_ToActivate: 'À activer',
    status_label_Canceled: 'Bloquée définitivement',
  },
  add_card_form: {
    modal_title: 'Commander une carte',
    steps: {
      [AddCardStep.DELIVERY_ADDRESS]: 'Livraison',
      [AddCardStep.RECAP_FORM]: 'Récapitulatif',
    },
    recap_modal_message: 'Merci de vérifier les informations ci-dessous :',
    delivery_form_title: 'Adresse de livraison',
    address_1_label: 'Numéro et nom de rue',
    address_2_label: "Complément d'adresse (optionnel)",
    zip_code_label: 'Code postal',
    city_label: 'Ville',
    country_label: 'Pays',
    footer_message: 'La carte sera envoyée par lettre recommandée.',
    button_label: 'Continuer',
    card_owner_label: 'Propriétaire de la carte',
    ...COUNTRY_TRANSLATIONS,
    edit_button_label: 'Modifier',
    confirm_button_label: 'Commander',
    cancel_button_label: 'Annuler',
    close_modal_button_label: 'Fermer',
    success_consent_title: 'Carte commandée !',
    success_consent_message_line1: 'Elle sera très vite envoyée.',
    success_consent_message_line2:
      "Une fois receptionnée, il suffira de l'activer ici et elle sera utilisable immédiatement.",
    failed_consent_title: 'Commande annulée',
    failed_consent_message: 'Vous avez annulé la commande de cette carte.',
    address_error_message:
      "Renseignez une adresse de réception qui n'est pas celle de votre domiciliation.",
  },
  activate_card_form: {
    modal_title: 'Activer la carte',
    identifier_instructions:
      "Renseignez l'identifiant qui se situe au dos de la carte :",
    button_label: 'Activer',
  },
};
