import { quoteSlice } from './reducer';

export const {
  failQuotesRequest,
  startFetchQuotesRequest,
  fetchQuotesSuccess,
  resetQuote,
  setSelectedQuoteId,
  startFetchQuoteStatsRequest,
  fetchQuoteStatsError,
  updateQuoteStatusById,
  fetchQuoteStatsSuccess,
  setQuoteModalOpeningSource,
} = quoteSlice.actions;
