import { ReceivedDirectDebitMandateStatus } from '@legalplace/bankroot-api/generated/api-graphql';
import type {
  AccountMandateDto,
  AddAccountMandateDto,
} from '@legalplace/bankroot-api/modules/account/dto';
import type { ConsentUrlDto } from '@legalplace/bankroot-api/modules/consent/dto';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../services/api/api';

export const mandatesApi = createApi({
  reducerPath: 'mandatesApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['Mandates'],
  endpoints: (build) => ({
    getMandates: build.query<AccountMandateDto[], string>({
      query: (accountId) => ({ url: `/account/${accountId}/mandate` }),
      providesTags: ['Mandates'],
    }),
    suspendMandate: build.mutation<
      void,
      { accountId: string; swanMandateId: string }
    >({
      query: ({ accountId, swanMandateId }) => ({
        url: `/account/${accountId}/mandate`,
        method: 'PATCH',
        body: {
          swanMandateId,
          newStatus: ReceivedDirectDebitMandateStatus.Suspended,
        },
      }),
      invalidatesTags: ['Mandates'],
    }),
    enableMandate: build.mutation<
      ConsentUrlDto,
      { accountId: string; swanMandateId: string }
    >({
      query: ({ accountId, swanMandateId }) => ({
        url: `/account/${accountId}/mandate`,
        method: 'PATCH',
        body: {
          swanMandateId,
          newStatus: ReceivedDirectDebitMandateStatus.Enabled,
        },
      }),
      invalidatesTags: ['Mandates'],
    }),
    addMandate: build.mutation<
      ConsentUrlDto,
      { accountId: string; addAccountMandateDto: AddAccountMandateDto }
    >({
      query: ({ accountId, addAccountMandateDto }) => ({
        url: `/account/${accountId}/mandate`,
        method: 'POST',
        body: addAccountMandateDto,
      }),
      invalidatesTags: ['Mandates'],
    }),
  }),
});

export const {
  useAddMandateMutation,
  useEnableMandateMutation,
  useGetMandatesQuery,
  useSuspendMandateMutation,
} = mandatesApi;
