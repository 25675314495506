import { flexBaseAlignCenter, styled } from '@legalplace/storybook';

export const ToggleContainer = styled.div`
  ${flexBaseAlignCenter};
  width: fit-content;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.backgroundColors.grey100};
  padding: ${({ theme }) => theme.spacing.xxxxxsmall};
  border-radius: ${({ theme }) => theme.borders.radiusxLg};
  white-space: nowrap;
`;

export const ToggleBackground = styled.div<{
  isToggleChecked?: boolean;
}>`
  background-color: ${({ theme, isToggleChecked }) =>
    isToggleChecked
      ? theme.backgroundColors.primary500
      : theme.backgroundColors.grey100};
  border-radius: ${({ theme }) => theme.borders.radiusxLg};
`;
