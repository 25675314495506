export const englishTranslations = {
  title: '1st translation',
  credit_transfer: {
    transaction_dashboard: {
      transaction_date: 'Date',
      transaction_type: 'Type',
      transaction_amount: 'Amount',
    },
    execute_credit_transfer: {
      transaction_label: 'Label (optional)',
    },
  },
};
