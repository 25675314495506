import type { AcademySectionDto } from '@legalplace/bankroot-api/modules/academy/dto';

export function getNextUncompletedSectionIndex(
  sections: AcademySectionDto[],
  openedSectionIndex: number
): number {
  return sections.findIndex(
    (section, index) =>
      index !== openedSectionIndex &&
      section.articles.some((article) => !article.isRead)
  );
}

export function isCurrentOpenedSectionDone(
  sections: AcademySectionDto[],
  openedSectionIndex: number
): boolean {
  return sections[openedSectionIndex].articles.every(
    (article) => article.isRead
  );
}

export function computeProgress(section: AcademySectionDto): number {
  const total = section.articles.length;
  const read = section.articles.filter((article) => article.isRead).length;
  return read / total;
}
