export const menu = {
  page_title: 'Menu',
  transactions: 'Transactions',
  cards: 'Cartes',
  credit_transfers: 'Virements',
  direct_debits: 'Prélèvements',
  expenses: 'Notes de frais',
  accounting: 'Comptabilité',
  invoices: 'Factures',
  deadlines: 'Échéances',
  navigation_menu_connected_as: 'Connecté en tant que',
  logout_button: 'Déconnexion',
  help_button: 'Aide',
  settings_button: 'Paramètres',
  help_button_label: 'Aide',
  menu_button_label: 'Menu',
  dashboard: 'Tableau de bord',
  my_accountant: 'Mon comptable',
  accounting_group_label: 'Comptabilité',
  bank_group_label: 'Compte pro',
  referral_label: 'Parrainez vos proches',
  invoice_group_label: 'Facturation',
  client: 'Clients',
  prestation: 'Prestations',
  quote: 'Devis',
  upsell_compta: 'Compte pro',
  upsell_compta_tag: 'Découvrir',
  accounting_to_bank_upsell: 'Compte pro',
  accounting_to_bank_upsell_tag: 'Gratuit',
  banking_to_accounting_upsell: 'Comptabilité',
  banking_to_accounting_upsell_tag: 'Essayer',
  onboarding_stepper: {
    finalize_registration_button_label: 'Finalisez votre inscription',
    steps_left_subtitle: "et on s'occupe de votre compta !",
  },
};
