import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TaxCredentialsStatus } from '@legalplace/shared';
import {
  FlexAlignCenterJustifySpaceBetween,
  FlexContainerBasicColumn,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { SettingsSection } from '../../constants';
import { ClosableTaxCredentialStatuses } from '../../constants/taxCredentials.constants';
import { useAppSelector } from '../../hooks/store';
import { selectActiveCompany } from '../../store/companies/selectors';
import {
  useGetTaxesCredentialsQuery,
  useSkipTaxesCredentialsMutation,
} from '../../store/taxesCredentials/reducer';
import { ActivationCodeModal } from '../ActivationCodeModal/ActivationCodeModal';

import { PrimaryButton } from '../UI/PrimaryButton.style';
import {
  UpdateTaxCredentialsBannerCloseButton,
  UpdateTaxCredentialsBannerContainer,
} from './UpdateTaxCredentialsBanner.style';

export function UpdateTaxCredentialsBanner(): JSX.Element {
  const { t } = useTranslation('update_tax_credentials_banner');
  const navigate = useNavigate();

  const activeCompany = useAppSelector(selectActiveCompany);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [skipTaxesCredentials, { isLoading }] =
    useSkipTaxesCredentialsMutation();
  const { data = { taxCredentialStatus: TaxCredentialsStatus.Valid } } =
    useGetTaxesCredentialsQuery(activeCompany?.id ?? '', {
      skip: !activeCompany?.id,
    });
  const { taxCredentialStatus } = data;
  const shouldDisplayCloseButton =
    ClosableTaxCredentialStatuses.includes(taxCredentialStatus);

  const handleButtonClick = useCallback((): void => {
    if (!activeCompany?.id) {
      return;
    }
    if (taxCredentialStatus === TaxCredentialsStatus.NotSubscribedToVat) {
      window.open(`/Adherer_au_service_IPG.pdf`);
      return;
    }
    if (taxCredentialStatus === TaxCredentialsStatus.UnInitializedCredentials) {
      window.open(`/Création_de_votre_compte_impots.gouv.pdf`);
      return;
    }
    navigate(
      `/${activeCompany?.id}/parametres?section=${SettingsSection.TAX_CREDENTIALS}`
    );
  }, [activeCompany?.id, navigate, taxCredentialStatus]);

  const handleCloseButtonClick = async (): Promise<void> => {
    if (!activeCompany?.id) {
      return;
    }
    await skipTaxesCredentials(activeCompany.id);
  };

  return (
    <UpdateTaxCredentialsBannerContainer>
      <FlexContainerBasicColumn gap={{ default: '0.8rem' }}>
        <FlexAlignCenterJustifySpaceBetween>
          <Typography tag="p2" color="white" fontWeight="600">
            {t(`${taxCredentialStatus}.title`)}
          </Typography>
          {shouldDisplayCloseButton && (
            <UpdateTaxCredentialsBannerCloseButton
              onClick={handleCloseButtonClick}
              isLoading={isLoading}
            >
              <StreamLineIcon
                icon={StreamLineIconList.close}
                fillColor="white"
              />
            </UpdateTaxCredentialsBannerCloseButton>
          )}
        </FlexAlignCenterJustifySpaceBetween>
        <Typography tag="p3" color="white" spacing={{ default: '0.8rem 0' }}>
          {t(`${taxCredentialStatus}.message`)}
        </Typography>
        <PrimaryButton
          width={{ default: 'fit-content' }}
          onClick={handleButtonClick}
        >
          {t(`${taxCredentialStatus}.button_label`)}
        </PrimaryButton>
      </FlexContainerBasicColumn>

      <ActivationCodeModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </UpdateTaxCredentialsBannerContainer>
  );
}
