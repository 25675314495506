import type { CompanyUserDto } from '@legalplace/bankroot-api/modules/company/dto';
import { CARD_ACTIVE_STATUSES, UserCompanyStatus } from '@legalplace/shared';
import { createSelector } from '@reduxjs/toolkit';

import { Status } from '../../constants';
import type { RootState } from '../../store';

import type { UserState } from './type';

export const selectLoggedInState = (state: RootState): Status =>
  state.user.loggedInState;

export const selectUserInformations = (
  state: RootState
): UserState['userInformation'] => state.user.userInformation;

export const selectCompanyUsers = (state: RootState): CompanyUserDto[] =>
  state.user.companyUsers.allIds.map((id) => state.user.companyUsers.byId[id]);

export const selectCompanyUsersFetchStatus = (state: RootState): Status =>
  state.user.companyUsers.status;

export const selectActiveCompanyUsersWithActiveCardNumber = createSelector(
  (state: RootState) => selectCompanyUsers(state),
  (state: RootState) => selectCompanyUsersFetchStatus(state),
  (state: RootState) => state.cards.allIds,
  (state: RootState) => state.cards.byId,
  (state: RootState) => state.cards.status,
  (
    companyUsers,
    companyUserStatus,
    allCardIds,
    cardById,
    cardStatus
  ): (CompanyUserDto & { activeCardNumber: number })[] => {
    if (companyUserStatus === Status.LOADING || cardStatus === Status.LOADING) {
      return [];
    }
    const activeCardByUserId: Record<string, number> = {};
    allCardIds.forEach((cardId) => {
      const card = cardById[cardId];
      if (CARD_ACTIVE_STATUSES.includes(card.status)) {
        activeCardByUserId[card.associatedUserId] =
          activeCardByUserId[card.associatedUserId] + 1 || 1;
      }
    });

    const activeUsers = companyUsers.filter(
      (companyUser) => companyUser.status === UserCompanyStatus.ACTIVE
    );

    return activeUsers.map((companyUser) => ({
      ...companyUser,
      activeCardNumber: activeCardByUserId[companyUser.userId] || 0,
    }));
  }
);
