import type { AxiosResponse } from 'axios';
import { call, put, select, takeEvery } from 'typed-redux-saga';

import type { PageDto } from '@legalplace/bankroot-api/modules/pagination/dto';
import type { GetTransactionDto } from '@legalplace/bankroot-api/modules/transaction/dto';
import type { PayloadAction } from '@reduxjs/toolkit';

import { getActiveFiltersFromQueryParams } from '../../../components/transactionDashboard/TransactionDashboard.helper';
import type { IActiveFilters, IFilters } from '../../../interfaces';
import {
  getPcaTransactionsRequest,
  getTransactionsRequest,
} from '../../../services/api/transaction.api';
import { selectAreAccountsLoading } from '../../accounts/selectors';
import { selectActiveCompanyId } from '../../companies/selectors';
import {
  fetchTransactionsFailed,
  fetchTransactionSuccess,
  startFetchTransactions,
  updateTransaction,
} from '../actions';
import { selectFilterParameters } from '../selector';
import type { PaginationOptions } from '../types';

function* handleFetchTransactions(
  action: PayloadAction<
    Partial<PaginationOptions> & {
      additionalTransaction?: GetTransactionDto;
    }
  >
): Generator {
  let activeCompanyId = '';
  let response: AxiosResponse<PageDto<GetTransactionDto>, unknown>;
  try {
    const accountsAreLoading = yield select(selectAreAccountsLoading);
    if (accountsAreLoading) {
      throw new Error('Transaction error');
    }
    activeCompanyId = (yield select(selectActiveCompanyId)) as string;
    const { defaultFilters, activeFilters, categorizableItemId } =
      (yield select(selectFilterParameters)) as {
        activeFilters: IActiveFilters;
        defaultFilters: IFilters;
        categorizableItemId?: string;
      };
    const filters = {
      ...defaultFilters,
      ...getActiveFiltersFromQueryParams(
        activeFilters,
        new URLSearchParams(window.location.search)
      ),
    };
    const { page = 1, take = 10, additionalTransaction } = action.payload;
    if (categorizableItemId) {
      response = yield* call(
        getPcaTransactionsRequest,
        activeCompanyId,
        filters,
        {
          page,
          take,
        },
        categorizableItemId
      );
    } else {
      response = yield* call(getTransactionsRequest, activeCompanyId, filters, {
        page,
        take,
      });
    }
    const { data: paginatedTransactions } = response;
    yield put(fetchTransactionSuccess(paginatedTransactions));

    if (additionalTransaction) {
      yield put(updateTransaction(additionalTransaction));
    }
  } catch (error: unknown) {
    console.error(
      `[handleFetchTransactions] - Error trying to fetch transactions for company ${activeCompanyId}`,
      error
    );
    yield put(fetchTransactionsFailed());
  }
}

function* fetchTransactionsSaga(): Generator {
  yield takeEvery(startFetchTransactions, handleFetchTransactions);
}

export default fetchTransactionsSaga;
