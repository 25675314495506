import { BillingDocumentType } from '@legalplace/documents/index';
import { BillingQuoteStatus } from '@legalplace/shared';

export const billingQuote = {
  title_page: 'Devis',
  start_billing_document_button: 'Nouveau devis',
  duplicating_quote: 'Duplication en cours',
  unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
  copy_of: 'Copie de {{quoteTitle}}',
  quote_stats: {
    amount_waiting_label: 'En attente',
    amount_accepted_label: 'Acceptés',
    amount_billed_label: 'Dont facturés',
  },
  quote_dashboard: {
    title: 'Filtres {{currentActiveFilterCount}}',
    footer_text: '{{count}} devis',
    quote_dashboard_filter_result_text: '{{quoteCount}} devis',
    searchbar_placeholder: 'Nom, client, montant...',
    reset_button: 'Réinitialiser',
    quote_search_button: 'Recherche',
    no_item: 'Aucune devis effectué pour le moment',
    no_item_subtitle: "Tous vos devis s'afficheront ici.",
    no_filtered_item: 'Aucun résultat avec les critères sélectionnés',
    no_filtered_item_subtitle: 'Essayez de réajuster votre recherche.',
    quote_dashboard_Draft: 'Brouillon',
    [BillingQuoteStatus.CreationPending]: 'Brouillon',
    [BillingQuoteStatus.OnHold]: 'En attente',
    [BillingQuoteStatus.Accepted]: 'Accepté',
    [BillingQuoteStatus.Invoiced]: 'Facturé',
    [BillingQuoteStatus.Refused]: 'Refusé',
    date_filter_button: 'Date',
    billing_quote_status_filter_button: 'Statut',
    billing_quote_status_reset_action_button: 'Réinitialiser',
    billing_quote_status_filter_action_button: 'Appliquer',
    date_from: 'Date de début',
    date_to: 'Date de fin',
    date_reset_action_button: 'Réinitialiser',
    date_filter_action_button: 'Appliquer',
    date_filter_title: 'Date',
    billingQuoteStatuses_filter_title: 'Statut',
    apply_filters_button: 'Appliquer',
    reset_all_button: 'Réinitialiser',
    mobile_filter_button: 'Filtrer',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    no_quote_file_to_download:
      "Le devis n'est pas encore disponible, réessayez dans quelques secondes.",
    today_date_row: "Aujourd'hui",
    yesterday_date_row: 'Hier',
  },
  sidebar: {
    header: {
      document_name: 'Devis',
      [`status_label_${BillingQuoteStatus.CreationPending}`]: 'Brouillon',
      [`status_label_${BillingQuoteStatus.OnHold}`]: 'En attente',
      [`status_label_${BillingQuoteStatus.Accepted}`]: 'Accepté',
      [`status_label_${BillingQuoteStatus.Invoiced}`]: 'Facturé',
      [`status_label_${BillingQuoteStatus.Refused}`]: 'Refusé',
      issue_date: "Date d'émission : {{issueDate}}",
      due_date: "Date d'échéance : {{dueDate}}",
      amount_tax_value: 'TTC',
    },
    actions: {
      mark_as_accepted_button: 'Marquer comme accepté',
      mark_as_refused_button: 'Marquer comme refusé',
      generate_invoice_button: 'Générer ma facture',
      modify_quote_button: 'Modifier',
      modify_to_on_hold_quote_button: 'Modifier (En Attente)',
      duplicate_quote_button: 'Dupliquer',
      delete_quote_button: 'Supprimer',
      quote_to_invoice_error: 'Action impossible, veuillez compléter le devis.',
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      delete_quote_success: 'Devis supprimé avec succès.',
      delete_modal: {
        modal_title: 'Supprimer le devis',
        modal_content_title: 'Supprimer le devis n°{{billNumber}}',
        modal_content_text:
          'Supprimer un devis est irréversible, souhaitez-vous continuer ?',
        confirm_button_label: 'Oui, supprimer',
        cancel_button_label: 'Annuler',
        delete_quote_success: 'Le devis a été supprimé !',
        unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      },
    },
    files: {
      download_started: 'Téléchargement démarré !',
      modal_title: 'Devis - {{fileName}}',
      download_button: 'Télécharger',
      quote_generation_error: 'Devis en cours de génération...',
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    },
  },
  modal: {
    title: 'Nouveau devis',
    parameter_button: 'Paramètres',
    close_button: 'Fermer',
    details_tab_title: 'Détails',
    entity_search_placeholder: 'Rechercher un client enregistré',
    entity_search_action_button: 'Créer un nouveau client',
    details_title_label: 'Client',
    missing_client_error_message: 'Veuillez sélectionner ou ajouter un client',
    parameters_modal: {
      vat_number_input_label: 'N°TVA Intracommunautaire',
      allowed_types: 'PNG, JPG, JPEG',
      upload_file: 'Ajouter votre logo',
      confirm_delete: 'Oui, supprimer',
      cancel_delete: 'Annuler',
      download_started: 'Téléchargement démarré !',
      modal_title: 'Paramètres',
      numbering_title_label: 'Numérotation',
      invoice_serial_input_label: 'Préfixe de la facture',
      next_invoice_number_input_label: 'Prochain numéro de facture',
      quote_serial_input_label: 'Préfixe du devis',
      next_quote_number_input_label: 'Prochain numéro de devis',
      contact_details_title_label: 'Votre entreprise',
      email_input_label: 'E-mail',
      phone_number_input_label: 'Numéro de téléphone',
      client_email_form: 'E-mail (optionnel)',
      address_1_label: 'Numéro et nom de rue',
      address_2_label: "Complément d'adresse (optionnel)",
      zip_code_label: 'Code postal',
      city_label: 'Ville',
      country_label: 'Pays',
      save_button: 'Appliquer',
      cancel_button: 'Annuler',
      save_billing_success_text: 'Paramètres enregistrés !',
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      next_invoice_number_info_text:
        'Votre prochaine facture portera le numéro {{ nextBillNumber }}.',
      next_quote_number_info_text:
        'Votre prochain devis portera le numéro {{ nextBillNumber }}.',
    },
    document_title: {
      title_label: 'Nom du devis',
      title_placeholder: 'Indiquer un nom pour votre devis',
    },
    document_details: {
      quote_file_name: 'Devis.pdf',
      due_date_error:
        "La date de fin de validité ne peut pas être antérieure à la date d'émission.",
      missing_due_date_error:
        'Veuillez sélectionner la date de fin de validité',
      due_date_label: 'Date de fin de validité',
      emission_date_label: "Date d'émission",
      command_number_form: 'Numéro du bon de commande (optionnel)',
      conditions_label: 'Conditions',
      command_number_text_area:
        'Conditions de paiement et mentions légales (optionnel)',
      save_quote_button_text: 'Enregistrer mon devis',
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      submit_error_message: 'Impossible de créer le devis.',
      download_started: 'Téléchargement démarré !',
      additional_info_label: 'Informations complémentaires',
      addtional_info_text_area: 'Précisions à ajouter au devis',
      save_additional_info_toggle_label:
        'Enregistrer comme commentaire récurrent',
      quote_saved: 'Devis enregistré !',
    },
  },
  quote_to_invoice: {
    modal_title: 'Devis accepté',
    modal_content:
      'Vous n’avez plus qu’à générer votre facture a partir de ce devis.',
    generate_invoice_from_quote_button: 'Générer la facture',
    modify_invoice_created_from_quote_button: 'Modifier',
    generation_validation_subtitle:
      'Si vous continuez, la facture ne sera plus modifiable.',
    generation_validation_content: 'Souhaitez-vous générer la facture ?',
    generation_validation_confirmation_button: 'Oui, continuer',
    cancel_validation_button: 'Annuler',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    missing_billing_client:
      "SIREN du client absent, veuillez compléter les informations dans la page 'Clients'",
    missing_billing_address:
      "Adresse du client incomplète, veuillez compléter les informations dans la page 'Clients'",
  },
  pdf: {
    [`${BillingDocumentType.Quote}_title`]: 'Devis',
    bill_number_label: 'Numéro de devis :',
    emission_date_label: "Date d'émission :",
    due_date_label: 'Date de fin de validité :',
    purchase_order_label: 'Bon de commande :',
    header_billing_lists: 'Description',
    billing_list_quantity: 'Qté',
    billing_list_unit_price: 'Prix unitaire HT',
    billing_list_tva_percentage: 'TVA %',
    billing_list_tva_euro: 'TVA €',
    billing_list_total: 'TOTAL TTC',
    billing_total_wt: 'TOTAL HT : ',
    billing_total_tva: 'TOTAL TVA : ',
    billing_total: 'TOTAL TTC : ',
    footer_note_capital: 'au capital de',
    footer_note_siren: 'SIREN N°',
    footer_note_vat: 'N°TVA Intracommunautaire',
    footer_note_no_vat_string: 'TVA non applicable, article 293 B du CGI',
    additional_info_label: 'Informations complémentaires',
    footer_note_0_vat_non_ue_string:
      'Autoliquidation de TVA, article 196 de la Directive 2006/112/CE',
    footer_note_0_vat_ue_goods_string:
      'Exonération de TVA, article 262 ter I du code général des impôts',
    footer_note_0_vat_ue_services_string:
      'Autoliquidation de TVA, article 196 de la Directive 2006/112/CE',
  },
};
