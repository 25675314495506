import { call, put, select, takeEvery } from 'typed-redux-saga';

import type { BalanceType } from '@legalplace/shared';
import type { PayloadAction } from '@reduxjs/toolkit';

import { getCategoriesRequest } from '../../../services/api/categories.api';
import {
  fetchCategoriesFailed,
  fetchCategoriesSuccess,
  startFetchCategories,
} from '../actions';
import { selectCachedCategories } from '../selectors';

function* handleFetchCategories({
  payload,
}: PayloadAction<{
  balanceType: BalanceType;
  isTabletDesktop: boolean;
}>): Generator {
  const { balanceType, isTabletDesktop } = payload;
  try {
    const cachedCategories = yield select(selectCachedCategories, balanceType);
    const hasCachedCategories =
      Array.isArray(cachedCategories) && cachedCategories.length;

    if (hasCachedCategories) {
      yield put(
        fetchCategoriesSuccess({
          categories: cachedCategories,
          isTabletDesktop,
          balanceType,
          isFromCache: true,
        })
      );
      return;
    }

    const response = yield* call(getCategoriesRequest, balanceType);
    const { data: categories } = response;
    yield put(
      fetchCategoriesSuccess({
        categories,
        isTabletDesktop,
        balanceType,
        isFromCache: false,
      })
    );
  } catch (error: unknown) {
    console.error(
      `[handleFetchCategories] - Error trying to fetch categories`,
      error
    );
    yield put(fetchCategoriesFailed());
  }
}

function* fetchCategoriesSaga(): Generator {
  yield takeEvery(startFetchCategories, handleFetchCategories);
}

export default fetchCategoriesSaga;
