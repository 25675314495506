import { VatValueOptions } from '../../constants/vat.constants';

export const partnerCurrentAccount = {
  pca_balance: "Compte courant de l'associé",
  debtor_message: 'Cet associé est débiteur.',
  balance_unavailable: 'Indisponible',
  home: {
    debtor_message: 'Cet associé est débiteur.',
    back_button: 'Retour',
    pca_balance: "Compte courant de l'associé",
    pca_balance_positive: "Dû à l'associé",
    pca_balance_negative: "Dû à l'entreprise",
    last_pca_balance_registration:
      'Solde au {{lastRegisteredBalanceDate}} : {{lastRegisteredBalance}}',
    add_expense: 'Ajouter une note de frais',
    show_transactions: 'Afficher les transactions',
    warning_transactions_to_justify_one:
      'Attention, les montants peuvent être incorrects car il vous reste 1 transaction à justifier.',
    warning_transactions_to_justify_other:
      'Attention, les montants peuvent être incorrects car il vous reste {{count}} transactions à justifier.',
  },
  transaction_dashboard: {
    title: 'Filtres {{currentActiveFilterCount}}',
    date_filter_title: 'Date',
    transactionType_filter_title: 'Type',
    proofStatus_filter_title: 'Justificatif',
    operationsStatus_filter_title: 'Catégorisation',
    reset_all_button: 'Réinitialiser tout',
    apply_filters_button: 'Appliquer',
    credit: 'Crédit',
    debit: 'Débit',
    transaction_date: 'Date',
    transaction_type: 'Type',
    transaction_amount: 'Montant',
    footer_text_zero: '0 transaction',
    footer_text_one: '1 transaction',
    footer_text_other: '{{count}} transactions',
    transaction_dashboard_filter_result_text:
      '{{transactionCount}} transactions',
    transaction_dashboard_get_next_page_button: 'Voir plus',
    transaction_toolbar_export_button: 'Exporter',
    searchbar_placeholder: 'Libellé, montant...',
    transaction_dashboard_pending: 'En cours',
    operations_status_filter_button: 'Catégorisation',
    proof_status_filter_action_button: 'Appliquer',
    proof_status_reset_action_button: 'Réinitialiser',
    missing_proof: 'Justificatifs obligatoires manquants',
    uploaded_proof: 'Justificatifs déposés',
    not_categorized: 'Non catégorisées',
    search_category_placeholder: 'Rechercher une catégorie',
    search_category_empty_state:
      'Aucune catégorie ne correspond à votre recherche.',
    manually_categorized: 'Catégorisation manuelle',
    ai_categorized: 'Catégorisation par IA',
    accounts_filter_button: 'Comptes',
    accounts_filter_action_button: 'Appliquer',
    accounts_reset_action_button: 'Réinitialiser',
    proof_status: {
      Missing: 'Manquants',
      Uploaded: 'Déposés',
    },
    operations_status: {
      NotCategorized: 'Transactions non catégorisées',
    },
    not_justified_label: 'À justifier',
    transaction_type_filter_button: 'Type',
    transaction_type_filter_action_button: 'Appliquer',
    transaction_type_reset_action_button: 'Réinitialiser',
    transaction_type_card: 'Paiement par carte',
    transaction_type_withdrawal: 'Retrait au distributeur',
    transaction_type_payment: 'Virement',
    transaction_type_levy: 'Prélèvement',
    transaction_type_expense: 'Note de frais',
    credit_debit_filter_button: 'Crédit / Débit',
    credit_debit_filter_action_button: 'Appliquer',
    credit_debit_reset_action_button: 'Réinitialiser',
    credit_debit_null_choice: 'Indifférent',
    date_filter_button: 'Date',
    date_filter_action_button: 'Appliquer',
    date_reset_action_button: 'Réinitialiser',
    mobile_filter_button: 'Filtrer',
    mobile_filter_action_button: 'Filtrer',
    mobile_reset_action_button: 'Réinitialiser',
    mobile_filter_drawer_title: 'Filtrer',
    reset_button: 'Réinitialiser',
    from: 'Du',
    to: 'au',
    transaction_search_button: 'Recherche',
    no_item: 'Aucune transaction pour le moment',
    no_item_subtitle:
      "Toutes les transactions du compte courant d'associé s'afficheront ici",
    no_filtered_item: 'Aucun résultat avec les critères sélectionnés',
    no_filtered_item_subtitle: 'Essayez de réajuster votre recherche.',
    export_success: 'Téléchargement démarré !',
    unknown_error: 'Une erreur est survenue, veuillez réessayer.',
    transaction_type_debit: 'Débit',
    transaction_type_credit: 'Crédit',
    transactionAmount_filter_title: 'Montant',
    amount_from: 'Entre',
    amount_to: 'Et',
    date_from: 'Date de début',
    date_to: 'Date de fin',
    transaction_types: {
      Carte: 'Paiement par carte',
      CarteMagasin: 'Paiement par carte (en magasin)',
      CarteEnLigne: 'Paiement par carte (sur internet)',
      RetraitDistributeur: 'Retrait au distributeur',
      Virement: 'Virement',
      VirementInstantané: 'Virement instantané',
      Prélèvement: 'Prélèvement',
      NoteDeFrais: {
        purchase: 'Note de frais (achats divers)',
        mileage_allowance: 'Note de frais (indemnité {{distance}} km)',
      },
    },
    download_started: 'Téléchargement démarré !',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    multiple_categories: 'Catégories multiples',
    to_categorize: 'À catégoriser',
    vat_dropdown: {
      [VatValueOptions.VAT_0_FRA]: '0 % - France',
      [VatValueOptions.VAT_0_UE]: '0 % - UE',
      [VatValueOptions.VAT_0_OTHER]: '0 % - Hors UE',
      [VatValueOptions.VAT_210]: '2,1 %',
      [VatValueOptions.VAT_550]: '5,5 %',
      [VatValueOptions.VAT_850]: '8,5 %',
      [VatValueOptions.VAT_1000]: '10 %',
      [VatValueOptions.VAT_2000]: '20 %',
      [VatValueOptions.VAT_UNDEFINED]: 'TVA',
    },
    file_preview_title: 'Justificatif - {{fileName}}',
    today_date_row: 'Aujourd’hui',
    yesterday_date_row: 'Hier',
    accounting_ongoing: 'Préparation du bilan',
    accounting_closed: 'Exercice clôturé',
    transaction_not_categorized: 'Non catégorisée',
  },
};
