import {
  Button,
  FlexContainerBasicColumn,
  styled,
} from '@legalplace/storybook';

export const AcademyModalLearnMoreContainer = styled(FlexContainerBasicColumn)`
  background-color: ${({ theme }) => theme.backgroundColors.primary50};
  border-radius: ${({ theme }) => theme.borders.radiusMd};
  border: 1px solid ${({ theme }) => theme.backgroundColors.primary100};
  padding: ${({ theme }) => theme.spacing.medium};
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.xxsmall};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
  }
`;

export const ArticlesButton = styled(Button)`
  background-color: ${({ theme }) => theme.backgroundColors.primary500};
  border-radius: ${({ theme }) => theme.borders.radiusSm};
  color: ${({ theme }) => theme.typographyColors.white};
  padding: ${({ theme }) => `${theme.spacing.xxsmall} ${theme.spacing.xsmall}`};
  width: fit-content;
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
`;

export const MyAccountantButton = styled(Button)`
  background-color: ${({ theme }) => theme.backgroundColors.primary500};
  border-radius: ${({ theme }) => theme.borders.radiusSm};
  color: ${({ theme }) => theme.typographyColors.white};
  padding: ${({ theme }) => `${theme.spacing.xxsmall} ${theme.spacing.xsmall}`};
  width: fit-content;
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};

  :disabled {
    border: 1px solid ${({ theme }) => theme.backgroundColors.grey200};
    background-color: ${({ theme }) => theme.backgroundColors.white};
    color: ${({ theme }) => theme.typographyColors.primary900};
  }
`;
