import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AccountingOnboardingSource,
  OnboardingCallInterlocutor,
  OnboardingStep,
} from '@legalplace/shared';
import { DrawerV2, Progress } from '@legalplace/storybook';

import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { setIsOnboardingDrawerOpen } from '../../store/onboardingSteps/actions';
import {
  selectAccountingOnboardingInformation,
  selectOnboardingSteps,
} from '../../store/onboardingSteps/selector';

export function OnboardingV2Drawer({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose?: () => void;
  children: JSX.Element;
}): JSX.Element | null {
  const { t } = useTranslation('onboarding', {
    keyPrefix: 'onboarding_v2',
  });
  const dispatch = useAppDispatch();
  const { progressPercentage, currentStep } = useAppSelector(
    selectOnboardingSteps
  );
  const onboardingInformation = useAppSelector(
    selectAccountingOnboardingInformation
  );

  useEffect(() => {
    dispatch(setIsOnboardingDrawerOpen(isOpen));
  }, [dispatch, isOpen]);

  if (!progressPercentage || !currentStep) {
    return null;
  }
  const { step: currentOnboardingStep } = currentStep;

  const onboardingCallInterlocutor =
    onboardingInformation?.source === AccountingOnboardingSource.CreaPlusCompta
      ? OnboardingCallInterlocutor.Accountant
      : OnboardingCallInterlocutor.Advisor;

  return (
    <DrawerV2
      isOpen={isOpen}
      onClose={onClose}
      onBackdropClick={onClose}
      isDrawerFullHeight
      title={
        currentOnboardingStep === OnboardingStep.OnboardingCall
          ? t(`steps.${currentOnboardingStep}`, {
              callInterlocutor: t(
                `call_interlocutor.${onboardingCallInterlocutor}`
              ),
            })
          : t(`steps.${currentOnboardingStep}`)
      }
      bodyInternalSpacing={{
        default: 'unset',
      }}
    >
      {isOpen && (
        <>
          <Progress
            value={progressPercentage}
            color="pink200"
            progressBarHeight={{ default: '0.2rem' }}
          />
          {children}
        </>
      )}
    </DrawerV2>
  );
}
