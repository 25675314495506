/* eslint-disable react/jsx-props-no-spreading */
import {
  type DetailedHTMLProps,
  type InputHTMLAttributes,
  type MouseEventHandler,
  useCallback,
  useRef,
} from 'react';

import type {
  StreamLineIconList,
  ThemeColorsType,
} from '@legalplace/storybook';

import { InputContainer, InputIcon } from '../inputWrapper/InputWrapper.style';

export function Input({
  hasError,
  placeholderColor,
  className,
  shouldGrow,
  disabled,
  actionButton,
  preIcon,
  color,
  ...props
}: DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  hasError: boolean;
  placeholderColor?: keyof ThemeColorsType;
  color?: keyof ThemeColorsType;
  className?: string;
  shouldGrow?: boolean;
  preIcon?: StreamLineIconList;
  actionButton?: {
    icon: StreamLineIconList;
    onClick: () => void;
  };
}): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  const onContainerClick: MouseEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (inputRef.current && !disabled) {
        inputRef.current.focus();
      }
      if (props.onClick) {
        props.onClick(e);
      }
    },
    [disabled, props]
  );

  return (
    <InputContainer
      hasError={hasError}
      placeholderColor={placeholderColor}
      onClick={onContainerClick}
      className={className}
      shouldGrow={shouldGrow}
      disabled={disabled}
      color={color}
    >
      {preIcon && (
        <InputIcon icon={preIcon} width="2rem" fillColor="primary900" />
      )}
      <input ref={inputRef} {...props} disabled={disabled} />
    </InputContainer>
  );
}
