export const NO_REDIRECT_TO_LOGIN_URI = [
  'connexion',
  'close',
  'mobile/close',
  'auth',
  'consent',
  'mot-de-passe',
  'information-migration',
  'comptabilite-inscription',
  'confirmation-onboarding',
  'invalider-requete',
];
