import type { GetOperationDto } from '@legalplace/bankroot-api/modules/operation/dto';
import type { PageMetaDto } from '@legalplace/bankroot-api/modules/pagination/dto';
import type { GetTransactionDto } from '@legalplace/bankroot-api/modules/transaction/dto';

import { Status } from '../../constants';
import type { IActiveFilters, IFilters } from '../../interfaces';
import type { RootState } from '../../store';

export const selectTransactionsById = (
  state: RootState
): Record<string, GetTransactionDto> => state.transactions.byId;

export const selectTransactionAllId = (state: RootState): string[] =>
  state.transactions.allIds;

export const selectIsTransactionLoading = (state: RootState): boolean =>
  state.transactions.status === Status.LOADING;

export const selectIsNextPageTransactionLoading = (state: RootState): boolean =>
  state.transactions.pageStatus === Status.LOADING;

export const selectLastPaginationState = (
  state: RootState
): PageMetaDto | undefined => state.transactions.lastPaginationResult;

export const selectCurrentOperation = (
  state: RootState
): GetOperationDto | undefined => state.transactions.currentOperation;

export const selectEditingExpenseId = (state: RootState): string | undefined =>
  state.transactions.editingExpenseId;

export const selectDuplicatingExpenseId = (
  state: RootState
): string | undefined => state.transactions.duplicatingExpenseId;

export const selectFilterParameters = (
  state: RootState
): {
  activeFilters: IActiveFilters;
  defaultFilters: IFilters;
  categorizableItemId?: string;
} => state.transactions.filterParameters;
