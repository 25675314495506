import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ConnectBridgeItemDtoWithAction } from '@legalplace/bankroot-api/bridge/dto';
import type { FindAccountDto } from '@legalplace/bankroot-api/modules/account/dto';
import {
  ConnectBridgeItemAction,
  FAKE_BRIDGE_ACCOUNT_ID,
} from '@legalplace/shared';
import type { IButton } from '@legalplace/storybook';
import {
  BankIconLoader,
  LoadingManager,
  ModalDrawerV2,
  ModalVariants,
  Typography,
} from '@legalplace/storybook';

import { useAppDispatch, useAppSelector } from '../../hooks/store';
import {
  getBridgeItemSolveUrlRequest,
  getBridgeItemWithoutAccountsSolveUrlRequest,
} from '../../services/api/bridgeItem.api';
import { startFetchAccounts } from '../../store/accounts/actions';
import { selectActiveCompanyId } from '../../store/companies/selectors';
import type { ICustomModalProps } from '../UI/UI.interface';

export function AccountResyncModal({
  isOpen,
  closeHandler,
  accountStatusDescription,
  currentBridgeItemId,
  currentAccountId,
  currentExternalAuthId,
}: ICustomModalProps & {
  currentBridgeItemId?: string;
  currentExternalAuthId?: string;
  currentAccountId: string;
} & Pick<FindAccountDto, 'accountStatusDescription'>): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('accounts', {
    keyPrefix: 'modal.resync_account',
  });
  const [solveConnectionUrl, setSolveConnectionUrl] = useState<
    string | undefined
  >('');
  const [isAccountSolveRequestLoading, setIsAccountSolveRequestLoading] =
    useState(false);
  const activeCompanyId = useAppSelector(selectActiveCompanyId);

  const solveBridgeItemConnection = useCallback(
    async (bridgeItemId: string, accountId: string): Promise<void> => {
      // reset solve bridge item connection url
      setSolveConnectionUrl('');
      setIsAccountSolveRequestLoading(true);
      try {
        // We create fake accounts to display bridge items requiring user action and without accounts.
        // In that case what we want to resync is the bridge item itself and not an account belonging to it.
        const isFakeBridgeAccount = accountId === FAKE_BRIDGE_ACCOUNT_ID;
        let connectBridgeItemDtoWithAction: ConnectBridgeItemDtoWithAction;
        if (isFakeBridgeAccount) {
          ({ data: connectBridgeItemDtoWithAction } =
            await getBridgeItemWithoutAccountsSolveUrlRequest(bridgeItemId));
        } else {
          ({ data: connectBridgeItemDtoWithAction } =
            await getBridgeItemSolveUrlRequest(bridgeItemId, accountId));
        }
        if (
          connectBridgeItemDtoWithAction?.action ===
          ConnectBridgeItemAction.RELOAD_ACCOUNTS
        ) {
          dispatch(startFetchAccounts());
          closeHandler();
          return;
        }

        const { redirectUrl } = connectBridgeItemDtoWithAction || {};

        if (redirectUrl) {
          setSolveConnectionUrl(redirectUrl);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsAccountSolveRequestLoading(false);
      }
    },
    [
      setSolveConnectionUrl,
      setIsAccountSolveRequestLoading,
      dispatch,
      closeHandler,
    ]
  );

  useEffect(() => {
    if (currentExternalAuthId || !currentBridgeItemId || !currentAccountId) {
      return;
    }
    solveBridgeItemConnection(currentBridgeItemId, currentAccountId);
  }, [
    currentBridgeItemId,
    currentAccountId,
    solveBridgeItemConnection,
    currentExternalAuthId,
  ]);

  useEffect(() => {
    const state = {
      companyId: activeCompanyId,
      externalAuthId: currentExternalAuthId,
    };
    const base64State = Buffer.from(JSON.stringify(state)).toString('base64');
    setSolveConnectionUrl(
      `${import.meta.env.VITE_APP_QONTO_OAUTH_URL}&state=${base64State}`
    );
  }, [currentExternalAuthId, activeCompanyId]);

  return (
    <ModalDrawerV2
      isOpen={isOpen}
      onClose={closeHandler}
      onBackdropClick={closeHandler}
      title={t('title')}
      {...(!isAccountSolveRequestLoading &&
        solveConnectionUrl && {
          modalVariant: ModalVariants.ACTION,
          primaryButtonProps: {
            forwardedAs: 'a',
            href: solveConnectionUrl,
          } as IButton,
          secondaryAction: closeHandler,
          buttonLabels: {
            primary: t('buttons.resync_btn_text'),
            secondary: t('buttons.close_btn_text'),
          },
        })}
    >
      <LoadingManager
        isLoading={isAccountSolveRequestLoading}
        customLoadingComponent={<BankIconLoader />}
      >
        <Typography tag="p" color="primary900">
          {accountStatusDescription || t('default_message')}
        </Typography>
      </LoadingManager>
    </ModalDrawerV2>
  );
}
