import { flexBase, flexBaseColumn, styled } from '@legalplace/storybook';

import { CardImage } from '../../../CardDetails/CardDetails.style';
import { addStyleInDesktop } from '../../../UI/Responsive.style';

export const CardDeliveryInformationsContainer = styled.div`
  ${flexBaseColumn};
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

export const CardDeliveryCardAndInformationsContainer = styled.div`
  ${flexBaseColumn};
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.medium};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
  }
`;

export const CardDeliveryButtonsContainer = styled.div`
  ${flexBase};
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.medium};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column-reverse;
  }
`;

export const CardImageDelivery = styled(CardImage)`
  ${({ theme }) => addStyleInDesktop(theme, `width:40%; `)}
`;
