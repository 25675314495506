export default {
  menu: {
    title: 'Lancez votre activité',
    subtitle: "Découvrez les règles comptables et économisez de l'argent !",
  },
  modal: {
    title: 'Lancez votre activité',
    learn_more: {
      title: 'Vous souhaitez en savoir plus ?',
      description:
        'Découvrez plus de 150 articles et vidéos pour gérer au mieux votre activité !',
      articles_button: 'Découvrir nos contenus',
      my_accountant_button: 'Contacter mon comptable',
      tooltip:
        'Finalisez votre inscription pour avoir accès à votre comptable.',
    },
    footer: {
      do_not_show_again: 'Ne plus afficher',
      confirm_do_not_show: 'Oui, ne plus afficher',
      cancel: 'Annuler',
    },
    end_message: {
      title: `Vous avez complété l'académie 🎉`,
      subtitle: `D'autres articles et conseils sont disponibles.`,
      description:
        'Vous pouvez retrouver plus de 150 articles et vidéos pour gérer au mieux votre activité dans notre blog dédié.',
      articles_button: 'Découvrir nos contenus',
    },
  },
};
