import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FeatherIcon,
  FeatherIconList,
  FlexColumnCenterFullWidth,
  Image,
  ModalBodyContainer,
  ModalDrawerV2,
  ModalSizes,
  ModalVariants,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import moneyJar from '../../assets/images/moneyJar.svg';

import { PrimaryButton } from '../UI/PrimaryButton.style';

type ReferralModalProps = {
  isOpen: boolean;
  onClose: () => void;
  referralLink: string;
};

export function ReferalModal({
  isOpen,
  onClose,
  referralLink,
}: ReferralModalProps): JSX.Element {
  const { t } = useTranslation('general', { keyPrefix: 'referral_modal' });

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const handleClick = async (): Promise<void> => {
    if (referralLink) {
      await navigator.clipboard.writeText(referralLink);
      setIsLinkCopied(true);
    }
  };

  return (
    <ModalDrawerV2
      isOpen={isOpen}
      onClose={onClose}
      title={t('title')}
      size={ModalSizes.SMALL}
      modalVariant={ModalVariants.ACKNOWLEDGEMENT}
      customPrimaryButton={
        <PrimaryButton onClick={handleClick} disabled={isLinkCopied}>
          {isLinkCopied ? (
            <>
              <StreamLineIcon icon={StreamLineIconList.checkcircle} />
              <Typography tag="p3" fontWeight="400">
                {t('copied_link_button')}
              </Typography>
            </>
          ) : (
            <>
              <FeatherIcon icon={FeatherIconList.copy} />
              <Typography tag="p3" fontWeight="400">
                {t('copy_link_button')}
              </Typography>
            </>
          )}
        </PrimaryButton>
      }
      modalMinWidth={{ default: '43.5rem' }}
      bodyInternalSpacing={{ default: '1.6rem 2.4rem' }}
    >
      <ModalBodyContainer>
        <FlexColumnCenterFullWidth>
          <Image src={moneyJar} alt="Bocal rempli d'argent" width="50%" />
          <FlexColumnCenterFullWidth gap={{ default: '1.6rem' }}>
            <Typography tag="p" color="primary900" fontWeight="700">
              {t('subtitle')}
            </Typography>

            <Typography tag="p3" color="primary900" textAlign="center">
              {t('paragraph')}
            </Typography>
          </FlexColumnCenterFullWidth>
        </FlexColumnCenterFullWidth>
      </ModalBodyContainer>
    </ModalDrawerV2>
  );
}
