import fetchCompaniesSaga from './fetchCompanies.saga';
import fetchPcaBalanceSaga from './fetchPcaBalance.saga';
import fetchUserRightsSaga from './fetchUserRights.saga';
import fetchVatBalanceSaga from './fetchVatBalance.saga';
import fetchWidgetVisibilitySaga from './fetchWidgetVisibility.saga';
import setActiveCompanyIdSaga from './setActiveCompanyId.saga';
import setNonJustifiedTransactionsCountSaga from './setNonJustifiedTransactionsCount.saga';

export const companiesSagas = [
  fetchCompaniesSaga(),
  setActiveCompanyIdSaga(),
  fetchPcaBalanceSaga(),
  fetchVatBalanceSaga(),
  setNonJustifiedTransactionsCountSaga(),
  fetchUserRightsSaga(),
  fetchWidgetVisibilitySaga(),
];
