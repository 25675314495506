import type {
  BareCardDto,
  GetCardDto,
} from '@legalplace/bankroot-api/modules/card/dto';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Status, TO_CREATE_CARD_ID } from '../../constants';

import type { CardsState } from './types';

const initialState: CardsState = {
  byId: {},
  allIds: [],
  status: Status.LOADING,
};

export const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    startFetchCards: (state) => {
      state.byId = {};
      state.allIds = [];
      state.status = Status.LOADING;
    },
    fetchCardsSuccess: (
      state,
      { payload: cards }: PayloadAction<BareCardDto[]>
    ) => {
      state.byId = cards.reduce(
        (accumulator, card: BareCardDto) => ({
          ...accumulator,
          [card.id]: card,
        }),
        {} as Record<string, BareCardDto>
      );
      state.allIds = cards.map((card) => card.id);
      state.status = Status.IDLE;
    },
    fetchCardsFailed: (state) => {
      state.status = Status.FAILED;
    },
    resetCards: () => initialState,
    setActiveCardId: (
      state,
      { payload: cardId }: PayloadAction<string | undefined>
    ) => {
      if (cardId !== state.activeCardId) {
        state.activeCardId = cardId;
        state.activeCard = undefined;
      }
    },
    fetchActiveCardSuccess: (
      state,
      { payload: card }: PayloadAction<GetCardDto>
    ) => {
      state.activeCard = card;
      if (state.byId[card.id]) {
        state.byId[card.id].status = card.status;
      }
    },
    fetchActiveCardFailed: (state) => {
      state.activeCard = undefined;
      state.activeCardId = undefined;
    },
    updateActiveCard: (state, { payload: card }: PayloadAction<GetCardDto>) => {
      state.activeCard = card;

      if (state.activeCardId?.includes(TO_CREATE_CARD_ID)) {
        // Should only change if the card goes from TO_CREATE to Processing.
        state.activeCardId = card.id;
      }
      if (!state.allIds.includes(card.id)) {
        state.allIds.unshift(card.id);
      }

      state.byId[card.id] = {
        accountId: card.accountId,
        cardMaskedNumber: card.cardMaskedNumber,
        expiryDate: card.expiryDate,
        id: card.id,
        status: card.status,
        userFullName: card.userFullName,
        associatedUserId: card.associatedUserId,
      };
    },
  },
});

export default cardsSlice.reducer;
