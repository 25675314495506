import {
  FlexCenterAlignedFullWidth,
  FlexContainerBasicColumn,
  styled,
} from '@legalplace/storybook';

export const SynchronizeDisclaimer = styled(FlexContainerBasicColumn)`
  background-color: ${({ theme }) => theme.backgroundColors.warning25};
  border: 1px solid ${({ theme }) => theme.backgroundColors.warning200};
  border-radius: ${({ theme }) => theme.borders.radiusMd};
  padding: ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

export const TitleIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

export const OtherIbanContainer = styled(FlexCenterAlignedFullWidth)`
  gap: ${({ theme }) => theme.spacing.xxsmall};
  align-items: flex-start;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
  }
`;
