import type { GetOperationCategoryDto } from '@legalplace/bankroot-api/modules/operation/dto';
import type { BalanceType } from '@legalplace/shared';

import type { Status } from '../../constants';
import type { RootState } from '../../store';

export const selectMainCategories = (
  state: RootState
): Record<string, GetOperationCategoryDto> => state.categories.byId;

export const selectFetchCategoriesStatus = (state: RootState): Status =>
  state.categories.status;

export const selectCurrentCategoryPath = (
  state: RootState
): GetOperationCategoryDto[] => state.categories.currentCategoryPath;

export const selectCachedCategories = (
  state: RootState,
  balanceType: BalanceType
): GetOperationCategoryDto[] => state.categories.cachedCategories[balanceType];

export const selectCategoriesByExternalId = (
  state: RootState
): Record<string, GetOperationCategoryDto> => state.categories.byExternalId;
