import { useTranslation } from 'react-i18next';

import { OnboardingStep } from '@legalplace/shared';
import { FlexContainerBasicColumn, Typography } from '@legalplace/storybook';

import { useAppDispatch } from '../../../../../hooks/store';
import {
  startFetchOnboardingSteps,
  unskipOnboardingStep,
} from '../../../../../store/onboardingSteps/actions';

import { RibSelectionContainer, SynchronizeButton } from './NoAccount.style';

export function NoAccount(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'contract_signature.rib_selection.no_account',
  });

  const handleSynchronizeClick = (): void => {
    dispatch(unskipOnboardingStep(OnboardingStep.BankAccountsSynchronization));
    dispatch(startFetchOnboardingSteps({ silent: true }));
  };

  return (
    <RibSelectionContainer>
      <FlexContainerBasicColumn gap={{ default: '0.8rem' }}>
        <Typography
          tag="p"
          color="primary900"
          lineHeight={{ default: '2rem' }}
          fontWeight="500"
        >
          {t('title')}
        </Typography>
        <Typography
          tag="p4"
          color="primary900"
          lineHeight={{ default: '2rem' }}
          fontWeight="400"
        >
          {t('message')}
        </Typography>
      </FlexContainerBasicColumn>
      <SynchronizeButton styleType="primary" onClick={handleSynchronizeClick}>
        <Typography
          tag="p4"
          color="white"
          lineHeight={{ default: '1.6rem' }}
          fontWeight="400"
        >
          {t('button_label')}
        </Typography>
      </SynchronizeButton>
    </RibSelectionContainer>
  );
}
