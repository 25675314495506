export enum AddMandateStep {
  MANDATE_INFORMATION = 'mandateInformation',
  PENDING_CONSENT = 'pendingConsent',
  SUCCESS_CONSENT = 'successConsent',
  FAILED_CONSENT = 'failedConsent',
}

export enum SelectSynchronizedAccountStep {
  SELECT_ACCOUNTS = 'selectAccounts',
  CONFIRM_ACCOUNTS_REMOVAL = 'confirmAccountsRemoval',
}

export const SELECT_SYNCHRONIZED_ACCOUNTS_MODAL_MIN_WIDTH = '52rem';
