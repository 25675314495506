import {
  Image,
  StreamLineIcon,
  StreamLineIconList,
} from '@legalplace/storybook';

import { AddBankAccountContainer } from './AddAccountModal.style';

export function BankAccountElement({
  url,
  onClick,
  isLoading,
  logo,
}: {
  url?: string;
  onClick?: () => void;
  isLoading: boolean;
  logo: {
    src: string;
    alt: string;
  };
}): JSX.Element {
  return (
    <AddBankAccountContainer
      isLoading={isLoading}
      onClick={onClick}
      href={url}
      target="_blank"
    >
      <Image src={logo.src} alt={logo.alt} height="1.8rem" />
      <StreamLineIcon height="2rem" icon={StreamLineIconList.arrowright1} />
    </AddBankAccountContainer>
  );
}
