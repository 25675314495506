import { VatValueOptions } from '../../constants/vat.constants';

export const transactions = {
  page_title: 'Transactions',
  back_button: 'Retour',
  cancel_btn_text: 'Annuler',
  releves: 'Relevés',
  no_item: 'Aucune activité pour le moment',
  no_item_subtitle: "Toutes vos transactions s'afficheront ici.",
  no_filtered_item: 'Aucun résultat avec les critères sélectionnés',
  no_filtered_item_subtitle: 'Essayez de réajuster votre recherche.',
  transactions_to_justify_one: '1 transaction à justifier',
  transactions_to_justify_other: '{{count}} transactions à justifier',
  manage_accounts: 'Comptes',
  rib_button: 'RIB',
  rib_information_modal_title: 'Coordonnées bancaires',
  statements: 'Relevés',
  show_transactions: 'Afficher les transactions',
  show_transactions_mobile: 'Afficher',
  bank_account_sync_success: 'Compte bancaire ajouté !',
  // start of pcaBalanceWidget translations
  pca_balance: "Comptes courants d'associés",
  pca_show_details: 'Détails',
  pca_no_debtors: "Aucun associé n'est débiteur.",
  pca_nb_of_debtors_one: '{{count}} associé débiteur',
  pca_nb_of_debtors_other: '{{count}} associés débiteurs',
  approximative_pca_balance:
    "La balance de vos comptes courants d'associés est approximative, justifiez toutes vos transactions pour y remédier.",
  balance_unavailable: 'Indisponible',
  // end of pcaBalanceWidget translations
  balance_summary: {
    retrieving_balance_error_message: 'Erreur de chargement du solde.',
    reload_page_error_message: "Merci d'essayer de recharger la page.",
    message: 'Solde du {{date}} à {{time}}',
    pending_message: '{{pendingBalance}} à venir',
    total_balance: 'Solde total',
    balance_unavailable: 'Indisponible',
    not_subject_to_vat: 'Non assujettie à la TVA',
    manage_accounts: 'Gérer mes comptes',
    datetime_message: 'Le {{date}} à {{time}}',
    pending_balance: 'À venir : {{pendingBalance}}',
    vat_balance: 'Balance de TVA',
    approximative_vat_balance: 'Balance de TVA approximative',
    owed_to_state: "Dû à l'État",
    creditor_balance: "Dû par l'État",
    banners: {
      unsynced_accounts: {
        title_one: '{{count}} compte désynchronisé',
        title_other: '{{count}} comptes désynchronisés',
        message:
          'Il vous faut rétablir la connexion à vos comptes bancaires afin de conserver vos transactions à jour.',
        button_text: 'Résoudre',
      },
      account_closing: {
        title: 'Compte en cours de clôture',
        message: `Ce compte bancaire est en cours de clôture. Vous n'avez rien à faire, LegalPlace s'occupe de tout.`,
      },
    },
  },
  vat_tooltip: {
    message:
      'La balance de TVA est composée de la TVA collectée et de la TVA déductible.',
    help_button_label: 'En savoir plus',
  },
  transaction_dashboard: {
    accounts_filter_title: 'Comptes',
    accounts_filter_subtitle_one: '{{count}} Compte',
    accounts_filter_subtitle_other: '{{count}} Comptes',
    title: 'Filtres {{currentActiveFilterCount}}',
    date_filter_title: 'Date',
    transactionType_filter_title: 'Type',
    proofStatus_filter_title: 'Justificatif',
    operationsStatus_filter_title: 'Catégorisation',
    reset_all_button: 'Réinitialiser tout',
    apply_filters_button: 'Appliquer',
    credit: 'Crédit',
    debit: 'Débit',
    transaction_date: 'Date',
    transaction_type: 'Type',
    transaction_amount: 'Montant',
    footer_text_zero: '0 transaction',
    footer_text_one: '1 transaction',
    footer_text_other: '{{count}} transactions',
    transaction_dashboard_filter_result_text:
      '{{transactionCount}} transactions',
    transaction_dashboard_get_next_page_button: 'Voir plus',
    transaction_toolbar_export_button: 'Exporter',
    searchbar_placeholder: 'Libellé, montant...',
    transaction_dashboard_pending: 'En cours',
    operations_status_filter_button: 'Catégorisation',
    proof_status_filter_action_button: 'Appliquer',
    proof_status_reset_action_button: 'Réinitialiser',
    missing_proof: 'Justificatifs obligatoires manquants',
    uploaded_proof: 'Justificatifs déposés',
    not_categorized: 'Non catégorisées',
    search_category_placeholder: 'Rechercher une catégorie',
    search_category_empty_state:
      'Aucune catégorie ne correspond à votre recherche.',
    manually_categorized: 'Catégorisation manuelle',
    ai_categorized: 'Catégorisation par IA',
    proof_status: {
      Missing: 'Manquants',
      Uploaded: 'Déposés',
    },
    operations_status: {
      NotCategorized: 'Transactions non catégorisées',
    },
    categorization_source: {
      manual: 'Catégorisation manuelle',
      ai: 'Catégorisation par IA',
    },
    not_justified_label: 'À justifier',
    transaction_type_filter_button: 'Type',
    transaction_type_filter_action_button: 'Appliquer',
    transaction_type_reset_action_button: 'Réinitialiser',
    transaction_type_card: 'Paiement par carte',
    transaction_type_withdrawal: 'Retrait au distributeur',
    transaction_type_payment: 'Virement',
    transaction_type_levy: 'Prélèvement',
    transaction_type_expense: 'Note de frais',
    credit_debit_filter_button: 'Crédit / Débit',
    credit_debit_filter_action_button: 'Appliquer',
    credit_debit_reset_action_button: 'Réinitialiser',
    credit_debit_null_choice: 'Indifférent',
    date_filter_button: 'Date',
    date_filter_action_button: 'Appliquer',
    date_reset_action_button: 'Réinitialiser',
    mobile_filter_button: 'Filtrer',
    mobile_filter_action_button: 'Filtrer',
    mobile_reset_action_button: 'Réinitialiser',
    mobile_filter_drawer_title: 'Filtrer',
    reset_button: 'Réinitialiser',
    from: 'Du',
    to: 'au',
    transaction_search_button: 'Recherche',
    no_item: 'Aucune activité pour le moment',
    no_item_subtitle: "Toutes vos transactions s'afficheront ici.",
    account_refreshing: 'Import de vos transactions en cours...',
    account_refreshing_subtitle:
      "Vos transactions apparaîtront ici dès qu'elles auront été synchronisées. Notre IA les analysera ensuite afin de les catégoriser. Rechargez la page dans quelques minutes.",
    no_filtered_item: 'Aucun résultat avec les critères sélectionnés',
    no_filtered_item_subtitle: 'Essayez de réajuster votre recherche.',
    export_success: 'Téléchargement démarré !',
    unknown_error: 'Une erreur est survenue, veuillez réessayer.',
    transaction_type_debit: 'Débit',
    transaction_type_credit: 'Crédit',
    transactionAmount_filter_title: 'Montant',
    amount_from: 'Entre',
    amount_to: 'Et',
    date_from: 'Date de début',
    date_to: 'Date de fin',
    transaction_types: {
      Carte: 'Paiement par carte',
      CarteMagasin: 'Paiement par carte (en magasin)',
      CarteEnLigne: 'Paiement par carte (sur internet)',
      RetraitDistributeur: 'Retrait au distributeur',
      Virement: 'Virement',
      VirementInstantané: 'Virement instantané',
      Prélèvement: 'Prélèvement',
      NoteDeFrais: {
        purchase: 'Note de frais (achats divers)',
        mileage_allowance: 'Note de frais (indemnité {{distance}} km)',
      },
    },
    accounts_filter_button: 'Comptes',
    accounts_filter_action_button: 'Appliquer',
    accounts_reset_action_button: 'Réinitialiser',
    download_started: 'Téléchargement démarré !',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    multiple_categories: 'Catégories multiples',
    to_categorize: 'À catégoriser',
    vat_dropdown: {
      [VatValueOptions.VAT_0_FRA]: '0 % - France',
      [VatValueOptions.VAT_0_UE]: '0 % - UE',
      [VatValueOptions.VAT_0_OTHER]: '0 % - Hors UE',
      [VatValueOptions.VAT_210]: '2,1 %',
      [VatValueOptions.VAT_550]: '5,5 %',
      [VatValueOptions.VAT_850]: '8,5 %',
      [VatValueOptions.VAT_1000]: '10 %',
      [VatValueOptions.VAT_2000]: '20 %',
      [VatValueOptions.VAT_UNDEFINED]: 'TVA',
    },
    file_preview_title: 'Justificatif - {{fileName}}',
    today_date_row: 'Aujourd’hui',
    yesterday_date_row: 'Hier',
    accounting_ongoing: 'Préparation du bilan',
    accounting_closed: 'Exercice clôturé',
    transaction_not_categorized: 'Non catégorisée',
  },
  rib: {
    button_label: 'RIB',
    information_account_holder_address_title: 'Titulaire',
    information_iban_title: 'IBAN',
    information_bic_title: 'BIC',
    successful_copy_to_clipboard_message: 'Copié !',
    information_modal_title: 'Coordonnées bancaires',
    download_button_label: 'Télécharger en PDF',
    download_started: 'Téléchargement démarré !',
    generating: 'Chargement en cours...',
    export: {
      title: "Relevé d'identité bancaire",
      bank: 'Banque',
      iban: 'IBAN',
      bic: 'BIC',
      agency: 'Agence',
      key: 'Clé',
      account: 'Compte',
      owner: 'Titulaire',
    },
  },
  bank_statements: {
    title: 'Relevés bancaires',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    download_started: 'Téléchargement démarré !',
    no_statements:
      'Vous retrouverez ici vos relevés bancaires mensuels, disponibles dès le premier jour du mois suivant.',
    close: 'Fermer',
  },
  capital_deposit_modal: {
    mobile_title: 'Votre capital arrive',
    title: 'Votre capital sera bientôt versé',
    body_part_1:
      "Votre capital est en cours de déblocage et sera versé par la notaire d'ici 48h. Nous vous notifierons par mail dès que le virement sera reçu.",
    body_part_2:
      "Si vous êtes pressé, n'hésitez pas à créditer votre compte avec un virement instantané.",
    button_label: 'OK',
  },
};
