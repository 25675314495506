import type { ElementType } from 'react';

import type { INavigationListItem } from '@legalplace/storybook';
import { NavigationItemV2 } from '@legalplace/storybook';

import { useCurrentDeviceBreakpoint } from '../../hooks/useCurrentDeviceBreakpoint';

import { MenuOnboardingStepper } from './MenuOnboardingStepper';

import { AppBottomNavContainer } from './AppBottomNav.style';

export interface IAppBottomNav {
  navigationItemList: INavigationListItem[];
  menuNavigationItem: INavigationListItem;
  activeItemId?: string;
  linkComponent?: ElementType;
}

export function AppBottomNav({
  navigationItemList,
  menuNavigationItem,
  linkComponent,
  activeItemId,
}: IAppBottomNav): JSX.Element {
  const { isMobile, isDesktop } = useCurrentDeviceBreakpoint();

  const shouldDisplayMenuButton =
    (isMobile && navigationItemList.length > 4) ||
    (!isMobile && !isDesktop && navigationItemList.length > 6);

  const navigationItemListToDisplay = shouldDisplayMenuButton
    ? navigationItemList.slice(0, isMobile ? 3 : 5)
    : navigationItemList;

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '0',
        zIndex: '2',
        width: '100%',
        background: 'white',
      }}
    >
      <MenuOnboardingStepper />
      <AppBottomNavContainer $isInHeader>
        {navigationItemListToDisplay.map((navItem) => (
          <NavigationItemV2
            navItem={navItem}
            linkComponent={linkComponent}
            $isInHeader
            activeItemId={activeItemId}
            key={navItem.id}
          />
        ))}
        {shouldDisplayMenuButton && (
          <NavigationItemV2
            navItem={menuNavigationItem}
            linkComponent={linkComponent}
            $isInHeader
            activeItemId={activeItemId}
            key={menuNavigationItem.id}
          />
        )}
      </AppBottomNavContainer>
    </div>
  );
}
