import {
  flexBase,
  flexBaseColumn,
  flexCenterAllColumn,
  Image,
  styled,
} from '@legalplace/storybook';

import { addStyleInDesktopAndTablet } from '../../UI/Responsive.style';

export const CardDeliveryContainer = styled.div`
  ${flexBaseColumn}
  width: 100%;
  height: 100%;

  ${({ theme }) => addStyleInDesktopAndTablet(theme, `flex-direction: row;`)};
`;

export const OrderCardContainer = styled.div`
  ${flexCenterAllColumn}
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.large};

  border-bottom: ${({ theme }) =>
    `${theme.borders.widthRegular} ${theme.borders.styleRegular} ${theme.typographyColors.grey125}`};
  padding-bottom: ${({ theme }) => theme.spacing.large};

  ${({ theme }) =>
    addStyleInDesktopAndTablet(
      theme,
      `border-right:
        ${theme.borders.widthRegular} ${theme.borders.styleRegular} ${theme.backgroundColors.grey125};
      border-bottom: none;
      padding-right: ${theme.spacing.xlarge};
      padding-bottom: 0;
      `
    )};
`;

export const TitlesContainer = styled.div`
  ${flexCenterAllColumn}
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.small};
`;

export const CardDetailsContainer = styled.div`
  ${flexCenterAllColumn}
  width: 100%;
  height: 100%;
  padding-top: ${({ theme }) => theme.spacing.large};

  ${({ theme }) =>
    addStyleInDesktopAndTablet(
      theme,
      `padding-top: 0;
      padding-left: ${theme.spacing.xlarge};
      `
    )};
`;

export const CardContainer = styled.div`
  ${flexCenterAllColumn}
  gap: ${({ theme }) => theme.spacing.small};
`;

export const CardFeatureContainer = styled.div`
  ${flexBase}
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

export const CardFeaturesContainer = styled.div`
  ${flexBaseColumn};
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.medium};
  gap: ${({ theme }) => theme.spacing.smallMedium};
`;

export const MobileButtonsContainer = styled.div`
  ${flexCenterAllColumn}
  width: 100%;
  height: 100%;
  padding-top: ${({ theme }) => theme.spacing.large};
`;

export const CardImage = styled(Image)`
  border-radius: ${({ theme }) => theme.borders.radiusMd};
`;
