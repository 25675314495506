import { forwardRef, type ReactNode, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import type { ILayoutDeviceSizes } from '@legalplace/storybook';
import { FlexContainerBasic, SubHeader } from '@legalplace/storybook';

import { AccountSuspendedBanner } from '../../components/AccountSuspendedBanner/AccountSuspendedBanner';
import { PageTitle } from '../../components/pageTitle/PageTitle';
import { UpdateTaxCredentialsBanner } from '../../components/UpdateTaxCredentialsBanner/UpdateTaxCredentialsBanner';
import { PageVariant } from '../../constants/pageWrapper.constants';
import { useAppSelector } from '../../hooks/store';
import { useCurrentDeviceBreakpoint } from '../../hooks/useCurrentDeviceBreakpoint';
import { useIsFromMenu } from '../../hooks/useIsFromMenu';
import { selectActiveCompany } from '../../store/companies/selectors';
import { PageWrapper } from '../pageWrapper/PageWrapper';

import { PageTitleContainer } from '../../components/pageTitle/PageTitle.style';
import { PageWrapperWithSubheaderContainer } from './PageWrapperWithSubheader.style';

export interface IPageWrapperWithSubheaderProps {
  pageTitle: string;
  subHeaderContent?: JSX.Element;
  children?: React.ReactNode;
  sideContent?: JSX.Element;
  gap?: ILayoutDeviceSizes;
  pageInternalSpacing?: ILayoutDeviceSizes;
  titleTooltip?: ReactNode;
  shouldPullToRefresh?: boolean;
  shouldWrapTitle?: boolean;
  pageVariant?: PageVariant;
  shouldDisplayAccountSuspendedBanner?: boolean;
  shouldDisplayUpdateTaxCredentialsBanner?: boolean;
  subHeaderCustomRedirectUri?: string;
  isFullScreen?: boolean;
}

export const PageWrapperWithSubheader = forwardRef<
  HTMLDivElement,
  IPageWrapperWithSubheaderProps
>(
  (
    {
      pageTitle,
      subHeaderContent,
      children,
      sideContent,
      gap,
      pageInternalSpacing,
      titleTooltip,
      shouldPullToRefresh,
      shouldWrapTitle,
      pageVariant,
      shouldDisplayAccountSuspendedBanner,
      subHeaderCustomRedirectUri,
      isFullScreen,
      shouldDisplayUpdateTaxCredentialsBanner,
    },
    ref
  ) => {
    const activeCompany = useAppSelector(selectActiveCompany);
    const isFromMenu = useIsFromMenu();

    const { isDesktop } = useCurrentDeviceBreakpoint();

    const shouldDisplaySubHeader = !isDesktop;

    const subHeaderRedirectUri =
      subHeaderCustomRedirectUri || `/${activeCompany?.id}/menu`;

    const [subheaderHeight, setSubheaderHeight] = useState<number>(0);
    const setSubheaderHeightCallback = useCallback(
      (subheaderRef: HTMLDivElement | null) => {
        setSubheaderHeight(subheaderRef?.clientHeight ?? 0);
      },
      []
    );

    return (
      <PageWrapperWithSubheaderContainer>
        <div ref={setSubheaderHeightCallback}>
          {shouldDisplaySubHeader && (
            <FlexContainerBasic spacing={{ default: '8rem 0 0 0' }} />
          )}
          {shouldDisplaySubHeader && shouldDisplayAccountSuspendedBanner && (
            <FlexContainerBasic
              internalspacing={{
                smallMedium: '0',
                xlarge: '9rem 0rem 0rem 0rem',
              }}
            >
              <AccountSuspendedBanner />
            </FlexContainerBasic>
          )}
          {shouldDisplaySubHeader && shouldDisplayUpdateTaxCredentialsBanner && (
            <FlexContainerBasic
              internalspacing={{
                smallMedium: '0',
                xlarge: '9rem 0rem 0rem 0rem',
              }}
            >
              <UpdateTaxCredentialsBanner />
            </FlexContainerBasic>
          )}
          {shouldDisplaySubHeader && (
            <SubHeader
              title={pageTitle}
              uri={subHeaderRedirectUri}
              linkComponent={Link}
              showBackButton={isFromMenu || !!subHeaderCustomRedirectUri}
              topOffset="0"
            >
              {subHeaderContent}
            </SubHeader>
          )}
        </div>
        <FlexContainerBasic>
          <PageWrapper
            pageTitle={pageTitle}
            defaultPageVariant={
              shouldDisplaySubHeader ? PageVariant.SUBHEADER : pageVariant
            }
            shouldHeightBeSet
            gap={gap}
            pageInternalSpacing={pageInternalSpacing}
            shouldPullToRefresh={shouldPullToRefresh}
            shouldDisplayAccountSuspendedBanner={
              shouldDisplayAccountSuspendedBanner && !shouldDisplaySubHeader
            }
            shouldDisplayUpdateTaxCredentialsBanner={
              shouldDisplayUpdateTaxCredentialsBanner && !shouldDisplaySubHeader
            }
            ref={ref}
            isFullScreen={isFullScreen}
            subheaderHeight={subheaderHeight}
          >
            {!shouldDisplaySubHeader && !isFullScreen && (
              <PageTitleContainer shouldWrapTitle={shouldWrapTitle}>
                <PageTitle label={pageTitle} tooltip={titleTooltip} />
                {subHeaderContent}
              </PageTitleContainer>
            )}
            {children}
          </PageWrapper>
          {sideContent}
        </FlexContainerBasic>
      </PageWrapperWithSubheaderContainer>
    );
  }
);
