import { TransactionType } from '@legalplace/shared';
import type {
  ThemeButtonColorsType,
  ThemeColorsType,
} from '@legalplace/storybook';

export const formatAmount = (
  amount: number,
  displayPlusSign = true,
  showDecimals = true
): string => {
  let sign = '';
  if (amount < 0) {
    sign = '-\u00A0';
  } else if (displayPlusSign) {
    sign = '+\u00A0';
  }
  const decimalAmount = parseFloat((Math.abs(amount) / 100).toFixed(2));
  const currencyAmount = decimalAmount.toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    ...(!showDecimals
      ? { maximumFractionDigits: 0, minimumFractionDigits: 0 }
      : {}),
  });
  return `${sign}${currencyAmount}`;
};

export const getAmountBackgroundColor = (
  transactionType: TransactionType,
  transactionAmount: number
): keyof ThemeButtonColorsType => {
  if (transactionType === TransactionType.NoteDeFrais) {
    return 'grey50';
  }
  if (transactionAmount > 0) {
    return 'success25';
  }
  return 'none';
};

export const getAmountTypoColor = (
  transactionType: TransactionType,
  transactionAmount: number
): keyof ThemeColorsType => {
  if (transactionType === TransactionType.NoteDeFrais) {
    return 'grey800';
  }
  if (transactionAmount > 0) {
    return 'success800';
  }
  return 'primary900';
};

export const formatVatAmount = (amount: number): string =>
  `${Math.abs(amount) / 100} %`;
