export const CATEGORIES_WITH_VAT_RELATED_BANNER_MESSAGE = [
  'D36',
  'D37',
  'D20',
  'D33',
  'C23',
  'C26',
  'C14',
  'C27',
  'C24',
  'C25',
  'D32',
  'D19',
];

export const CATEGORIES_WITH_OTHER_BANNER_MESSAGE = [
  'D18',
  'D31',
  'D125',
  'C14',
  'D137',
  'D58',
  'D113',
  'D138',
];

export const SALES_CHANNEL_CATEGORIES_BANNER_MESSAGE =
  "La TVA sur vos ventes sera calculée à partir du rapport de votre canal de vente, que vous devrez nous transmettre lors de votre prochaine échéance de TVA ou bilan de fin d'exercice.";
export const PERSONAL_EXPENSE_CATEGORY_BANNER_MESSAGE =
  "Cette dépense étant considérée comme une dépense personnelle, elle impacte votre compte courant d'associé. Si vous souhaitez que votre société vous indemnise pour des déplacements professionnels effectués avec votre véhicule personnel, vous pouvez créer des Indemnités Kilométriques dans l'onglet Notes de frais.";
export const TRANSPORTATION_EXPENSE_CATEGORY_BANNER_MESSAGE =
  "La TVA est déductible uniquement si votre activité est de type VTC, Auto-Ecole ou Ambulancier, ou si votre véhicule est un utilitaire. Si cela n'est pas le cas, vous devez indiquer 0% de TVA.";
export const VEHICLE_VAT_CATEGORY_BANNER_MESSAGE =
  'La TVA est déductible uniquement si vous avez loué un utilitaire. Si vous avez loué un véhicule de tourisme (voiture classique), vous devez indiquer 0% de TVA.';
