import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { BankIconLoader } from '@legalplace/storybook';

export function SuspenseRoute(): JSX.Element {
  return (
    <Suspense
      fallback={
        <BankIconLoader spacing={{ default: '2.4rem auto' }} width="100%" />
      }
    >
      <Outlet />
    </Suspense>
  );
}
