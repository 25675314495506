import type { GetOperationCategoryDto } from '@legalplace/bankroot-api/modules/operation/dto';

export const addLeafCategories = (
  category: GetOperationCategoryDto,
  leafCategories: GetOperationCategoryDto[]
): void => {
  const { childCategories, searchKeywords, fullLabel } = category;
  if (childCategories.length === 0 && searchKeywords?.some(Boolean)) {
    leafCategories.push({ ...category, label: fullLabel });
  } else {
    childCategories.forEach((childCategory) => {
      addLeafCategories(childCategory, leafCategories);
    });
  }
};

export const getAllLeafCategories = (
  categories: GetOperationCategoryDto[]
): GetOperationCategoryDto[] => {
  const leafCategories: GetOperationCategoryDto[] = [];

  categories.forEach((category) => {
    addLeafCategories(category, leafCategories);
  });

  return leafCategories;
};
