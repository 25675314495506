import { BaseReponsiveContainer, styled } from '@legalplace/storybook';

export const ConditionnalDisplayInMobileTabletOnly = styled(
  BaseReponsiveContainer
)<{ alwaysDisplay: boolean }>`
  ${({ alwaysDisplay }) =>
    alwaysDisplay ? 'display: block' : 'display: none'};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xlarge}) {
    display: block;
  }
`;

export const ShowLargeDesktopOnly = styled(BaseReponsiveContainer)`
  display: block;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xlarge}) {
    display: none;
  }
`;

export const HideInLargeDesktop = styled(BaseReponsiveContainer)`
  display: none;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xlarge}) {
    display: block;
  }
`;
