import type {
  AddressDto,
  FindCompanyDto,
} from '@legalplace/bankroot-api/modules/company/dto';
import { CCA3 } from '@legalplace/storybook';

import { isAddressLpDomiAddress } from '../../../services/utils';

export const CARD_FEATURES_TRANSLATION_KEYS = [
  'card_feature_1',
  'card_feature_2',
  'card_feature_3',
  'card_feature_4',
  'card_feature_5',
];

export const DAY_IN_72_HOURS_WITHOUT_SUNDAYS = [
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'monday',
  'tuesday',
  'wednesday',
];

export const parseDeliveryAddress = (
  activeCompany: FindCompanyDto | undefined
): {
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  country: string;
} => ({
  addressLine1:
    activeCompany?.addressLine1 && activeCompany.addressLine1.length <= 38
      ? activeCompany.addressLine1
      : '',
  addressLine2:
    activeCompany?.addressLine2 && activeCompany.addressLine2.length <= 38
      ? activeCompany.addressLine2
      : '',
  zipCode: activeCompany?.zipCode || '',
  city: activeCompany?.city || '',
  country: activeCompany?.country || CCA3.FRA,
});

export const getDeliveryAddressFormStateFromActiveCompanyAddress =
  (activeCompanyAddress: {
    addressLine1: string;
    addressLine2: string;
    zipCode: string;
    city: string;
    country: string;
  }): Record<string, string | number | boolean> => {
    const { addressLine1, addressLine2, zipCode, city, country } =
      activeCompanyAddress;

    if (isAddressLpDomiAddress(activeCompanyAddress)) {
      return {
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        country: CCA3.FRA,
      };
    }

    return {
      address1: addressLine1 && addressLine1.length <= 38 ? addressLine1 : '',
      address2: addressLine2 && addressLine2.length <= 38 ? addressLine2 : '',
      zipCode: zipCode || '',
      city: city || '',
      country: country || CCA3.FRA,
    };
  };

export const getDeliveryAddressFormStateFromAddress = (
  address: Partial<AddressDto>,
  allowLpDomi = false
): Record<string, string | number | boolean> => {
  const { addressLine1, addressLine2, zipCode, city, country } = address;

  if (
    isAddressLpDomiAddress({
      zipCode: zipCode ?? '',
      addressLine1: addressLine1 ?? '',
      addressLine2: addressLine2 ?? '',
      city: city ?? '',
      country: country ?? '',
    }) &&
    !allowLpDomi
  ) {
    return {
      address1: '',
      address2: '',
      zipCode: '',
      city: '',
      country: CCA3.FRA,
    };
  }

  return {
    address1: addressLine1 && addressLine1.length <= 38 ? addressLine1 : '',
    address2: addressLine2 && addressLine2.length <= 38 ? addressLine2 : '',
    zipCode: zipCode || '',
    city: city || '',
    country: country || CCA3.FRA,
  };
};

export const getAddressFromDeliveryAddressFormState = (
  address: Record<string, string | number | boolean>,
  allowLpDomi = false
): AddressDto => {
  const { address1, address2, zipCode, city, country } = address;
  const addressLine1 = address1 as unknown as string;
  const addressLine2 = address2 as unknown as string;

  if (
    isAddressLpDomiAddress({
      zipCode: (zipCode as unknown as string) ?? '',
      addressLine1: addressLine1 ?? '',
      addressLine2: addressLine2 ?? '',
      city: (city as unknown as string) ?? '',
      country: country as unknown as string,
    }) &&
    !allowLpDomi
  ) {
    return {
      addressLine1: '',
      addressLine2: '',
      zipCode: '',
      city: '',
      country: CCA3.FRA,
    };
  }

  return {
    addressLine1: addressLine1 && addressLine1.length <= 38 ? addressLine1 : '',
    addressLine2: addressLine2 && addressLine2.length <= 38 ? addressLine2 : '',
    zipCode: (zipCode as unknown as string) || '',
    city: (city as unknown as string) || '',
    country: (country as unknown as string) || CCA3.FRA,
  };
};
