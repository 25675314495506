import { StreamLineIcon, StreamLineIconList } from '@legalplace/storybook';

import { CheckboxContainer } from './Checkbox.style';

export interface ICheckbox {
  checked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
}

export function Checkbox({
  checked,
  disabled,
  hasError,
}: ICheckbox): JSX.Element {
  return (
    <CheckboxContainer
      $checked={checked}
      $disabled={disabled}
      $hasError={hasError}
    >
      {checked && (
        <StreamLineIcon
          icon={StreamLineIconList.check1}
          fillColor="white"
          width="1.4rem"
          height="1.4rem"
        />
      )}
    </CheckboxContainer>
  );
}
