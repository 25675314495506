import type { ElementType } from 'react';

import {
  FlexSpaceBetween,
  StreamLineIcon,
  Typography,
  useCurrentBreakpoint,
  useTheme,
} from '@legalplace/storybook';

import type { INavigationListItem } from '../../../../interfaces';

import {
  NavigationListButton,
  Spacer,
  TagContainer,
  TitleContainer,
} from './CollapsableNavigationListItem.style';

export interface ICollapsableNavigationListItem {
  item: INavigationListItem;
  selectedItemId: string;
  isGrouped: boolean;
  linkComponent: ElementType;
  isNavigationDisabled?: boolean;
}

export function CollapsableNavigationListItem({
  item,
  selectedItemId,
  isGrouped,
  linkComponent,
  isNavigationDisabled,
}: ICollapsableNavigationListItem): JSX.Element {
  const { deviceSize } = useCurrentBreakpoint();
  const {
    breakpoints: { xlarge },
  } = useTheme();
  const isDesktop = deviceSize === xlarge;
  return (
    <NavigationListButton
      as={linkComponent}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...((item.uri || item.url) && {
        as: item.uri ? linkComponent : 'a',
        href: item.url,
        to: item.uri,
      })}
      key={item.id}
      $isSelected={item.id === selectedItemId}
      data-button-id={item.id}
    >
      {isGrouped ? (
        <>
          <Spacer />
          <Typography
            tag="p3"
            color={item.id === selectedItemId ? 'primary600' : 'primary900'}
            fontWeight="400"
          >
            {item.label}
          </Typography>
        </>
      ) : (
        <FlexSpaceBetween>
          <TitleContainer>
            <StreamLineIcon
              icon={item.icon}
              fillColor={
                item.id === selectedItemId ? 'primary600' : 'primary900'
              }
              width="2rem"
              height="2rem"
            />
            <Typography
              tag="p3"
              color={item.id === selectedItemId ? 'primary600' : 'primary900'}
              lineHeight={{ default: '2.4rem' }}
              fontWeight="400"
            >
              {item.label}
            </Typography>
          </TitleContainer>
          {item.tag && isDesktop && (
            <TagContainer isNavigationDisabled={isNavigationDisabled}>
              <Typography tag="p5" color="primary600" fontWeight="500">
                {item.tag}
              </Typography>
            </TagContainer>
          )}
        </FlexSpaceBetween>
      )}
    </NavigationListButton>
  );
}
