import { call, put, select, takeEvery } from 'typed-redux-saga';

import { OnboardingStepStatus } from '@legalplace/shared';

import { ONBOARDING_STEPS_COEFFICIENTS } from '../../../constants';
import { getOnboardingStepsRequest } from '../../../services/api/onboardingFlow.api';
import { selectActiveCompanyId } from '../../companies/selectors';
import {
  fetchOnboardingStepsFailed,
  fetchOnboardingStepsSuccess,
  startFetchOnboardingSteps,
} from '../actions';
import { selectOnboardingSteps } from '../selector';

function* handleFetchOnboardingSteps(): Generator {
  try {
    const activeCompanyId = yield* select(selectActiveCompanyId);
    const onboardingSteps = yield* select(selectOnboardingSteps);
    if (!activeCompanyId) {
      return;
    }

    const {
      data: { steps: companyOnboardingSteps, accountingOnboardingInformation },
    } = yield* call(() => getOnboardingStepsRequest(activeCompanyId));

    const stepsLeft = companyOnboardingSteps?.filter(
      ({ status }) => status !== OnboardingStepStatus.Done
    );

    const totalStepsWeight = Object.values(
      ONBOARDING_STEPS_COEFFICIENTS
    ).reduce((acc, coefficient) => acc + coefficient, 0);

    const stepsLeftWeight = stepsLeft.reduce(
      (acc, { step }) => acc + ONBOARDING_STEPS_COEFFICIENTS[step],
      0
    );

    const progressPercentage = Math.floor(
      100 * (1 - stepsLeftWeight / totalStepsWeight)
    );

    const areAllStepsLeftSkipped = stepsLeft.every(({ step: stepLeft }) =>
      onboardingSteps.skippedSteps.includes(stepLeft)
    );

    const currentStep = companyOnboardingSteps?.find(({ step, status }) => {
      if (areAllStepsLeftSkipped) {
        return status !== OnboardingStepStatus.Done;
      }
      return (
        !onboardingSteps.skippedSteps.includes(step) &&
        status !== OnboardingStepStatus.Done
      );
    });

    yield put(
      fetchOnboardingStepsSuccess({
        steps: companyOnboardingSteps,
        stepsLeftCount: stepsLeft.length,
        currentStep,
        progressPercentage,
        accountingOnboardingInformation,
      })
    );
  } catch (error: unknown) {
    console.error(
      `[handleFetchOnboardingSteps] - Error trying to fetch onboarding steps`,
      error
    );
    yield put(fetchOnboardingStepsFailed());
  }
}

function* fetchOnboardingStepsSaga(): Generator {
  yield takeEvery(startFetchOnboardingSteps, handleFetchOnboardingSteps);
}

export default fetchOnboardingStepsSaga;
