import {
  Button,
  StreamLineIcon,
  styled,
  Typography,
} from '@legalplace/storybook';

import { CheckboxContainer } from '../../common/forms/Checkbox/Checkbox.style';

export const AnimatedRightArrow = styled(StreamLineIcon)`
  transition: transform 0.2s ease-out;
`;

export const AcademyCheckboxLabel = styled(Typography)`
  transition: color 0.2s ease-out;
  flex-grow: 1;
  color: ${({ theme }) => theme.typographyColors.primary900};
`;

export const AcademyCheckboxButton = styled(Button)`
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing.small};

  &:hover ${AnimatedRightArrow} {
    transform: translate(0.5rem);
  }
  &:hover ${AcademyCheckboxLabel} {
    color: ${({ theme }) => theme.primary500};
  }
  &:hover ${CheckboxContainer} {
    border-color: ${({ theme }) => theme.primary500};
  }
`;
