import type { SalesChannel } from '@legalplace/bankroot-api/entities';
import type {
  CreateSalesChannelDto,
  GetSalesChannelDto,
  UpdateSalesChannelDto,
} from '@legalplace/bankroot-api/modules/salesChannel/dto';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../services/api/api';

export const salesChannelsApi = createApi({
  reducerPath: 'salesChannelsApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['SalesChannel', 'PaymentMethod'],
  endpoints: (build) => ({
    getSalesChannels: build.query<GetSalesChannelDto[], string>({
      query: (companyId) => ({ url: `/sales-channel?companyId=${companyId}` }),
      providesTags: ['SalesChannel'],
    }),
    updateSalesChannel: build.mutation<
      void,
      { salesChannelId: string; updateSalesChannelDto: UpdateSalesChannelDto }
    >({
      query: ({ salesChannelId, updateSalesChannelDto }) => ({
        url: `/sales-channel/${salesChannelId}`,
        method: 'PATCH',
        body: updateSalesChannelDto,
      }),
      invalidatesTags: ['SalesChannel', 'PaymentMethod'],
    }),
    createSalesChannel: build.mutation<SalesChannel, CreateSalesChannelDto>({
      query: (createSalesChannelDto) => ({
        url: '/sales-channel/',
        method: 'POST',
        body: createSalesChannelDto,
      }),
      invalidatesTags: ['SalesChannel'],
    }),
  }),
});

export const {
  useGetSalesChannelsQuery,
  useUpdateSalesChannelMutation,
  useCreateSalesChannelMutation,
} = salesChannelsApi;
