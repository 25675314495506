import { useEffect } from 'react';

import type { URLOpenListenerEvent } from '@capacitor/app';
import { App } from '@capacitor/app';

import { router } from '../../router';

const { VITE_APP_URL, VITE_APP_APP_ID } = import.meta.env;

export function AppUrlListener(): null {
  useEffect(() => {
    const listener = App.addListener(
      'appUrlOpen',
      (event: URLOpenListenerEvent) => {
        if (
          event.url.includes(VITE_APP_APP_ID || '') ||
          event.url.includes('/mobile/close') ||
          event.url.includes('/bridge-callback')
        ) {
          return;
        }

        const slug = event.url.split(VITE_APP_URL || '').pop();
        if (slug) {
          router.navigate(slug);
        }
      }
    );
    return () => {
      listener.remove();
    };
  }, []);

  return null;
}

export default AppUrlListener;
