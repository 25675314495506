import type { GetBillingQuoteResponseDto } from '@legalplace/bankroot-api/modules/billing/dto';
import type { GetQuoteStatsResponseDto } from '@legalplace/bankroot-api/modules/company/dto';
import type { PageDto } from '@legalplace/bankroot-api/modules/pagination/dto';
import type { BillingQuoteStatus } from '@legalplace/shared';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../../constants';
import type { PaginationOptions } from '../../transactions/types';

import type { QuoteState } from './types';

const initialState: QuoteState = {
  byId: {},
  allIds: [],
  status: Status.LOADING,
  pageStatus: Status.IDLE,
  selectedQuoteId: undefined,
  lastPaginationResult: undefined,
  quoteStats: undefined,
  quoteModalOpeningSource: undefined,
};

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setQuoteModalOpeningSource: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.quoteModalOpeningSource = action.payload;
    },
    startFetchQuotesRequest: (
      state,
      action: PayloadAction<Partial<PaginationOptions>>
    ) => {
      if (!action.payload.page && !action.payload.take) {
        state.status = Status.LOADING;
        state.byId = {};
        state.allIds = [];
      } else {
        state.pageStatus = Status.LOADING;
      }
    },
    fetchQuotesSuccess: (
      state,
      action: PayloadAction<PageDto<GetBillingQuoteResponseDto>>
    ) => {
      state.lastPaginationResult = action.payload.meta;
      let newStateById: Record<string, GetBillingQuoteResponseDto> = {};
      let newStateAllIds: string[] = [];
      if (action.payload.meta.page !== 1) {
        newStateById = state.byId;
        newStateAllIds = state.allIds;
      }
      state.byId = action.payload.data.reduce(
        (accumulator, quote: GetBillingQuoteResponseDto) => ({
          ...accumulator,
          [quote.id]: quote,
        }),
        newStateById
      );
      state.allIds = [
        ...newStateAllIds,
        ...action.payload.data.map((quote) => quote.id),
      ];
      state.status = Status.IDLE;
      state.pageStatus = Status.IDLE;
    },
    resetQuote: () => initialState,
    failQuotesRequest: (state) => {
      state.status = Status.FAILED;
    },
    setSelectedQuoteId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedQuoteId = action.payload;
    },
    startFetchQuoteStatsRequest: (state) => {
      state.quoteStats = undefined;
    },
    fetchQuoteStatsSuccess: (
      state,
      action: PayloadAction<GetQuoteStatsResponseDto>
    ) => {
      state.quoteStats = action.payload;
    },
    fetchQuoteStatsError: (state) => {
      state.quoteStats = undefined;
    },
    updateQuoteStatusById: (
      state,
      action: PayloadAction<{ quoteId: string; status: BillingQuoteStatus }>
    ) => {
      const { quoteId, status } = action.payload;
      if (state.byId[quoteId]) {
        state.byId[quoteId].status = status;
      }
    },
  },
});

export default quoteSlice.reducer;
