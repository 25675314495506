import type { ThemeColorsType } from '@legalplace/storybook';
import {
  flexBaseAlignCenter,
  StreamLineIcon,
  styled,
} from '@legalplace/storybook';

export const InputIcon = styled(StreamLineIcon)``;

export const InputContainer = styled.div<{
  hasError: boolean;
  placeholderColor?: keyof ThemeColorsType;
  shouldGrow?: boolean;
  disabled?: boolean;
  color?: keyof ThemeColorsType;
}>`
  cursor: text;
  background-color: ${({ hasError, theme, disabled }) =>
    hasError || disabled
      ? theme.backgroundColors.grey50
      : theme.backgroundColors.white};
  border-width: ${(props) =>
    props.hasError
      ? props.theme.borders.widthMedium
      : props.theme.borders.widthRegular};
  border-style: solid;
  border-color: ${({ theme, hasError }) =>
    hasError
      ? theme.typographyColors.danger500
      : theme.forms?.formBorderColor || theme.backgroundColors.grey200};
  height: 3.6rem;
  padding: 0 1.4rem;
  border-radius: ${(props) => props.theme.borders.radiusSm};
  flex-grow: ${({ shouldGrow }) => shouldGrow && 1};

  &:focus-within {
    ${({ theme }) =>
      theme.borders.focusedInput && `outline: ${theme.borders.focusedInput}`};
  }

  input,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    border: none;
    flex-grow: 1;
    font-size: ${(props) => props.theme.fontSizes.small} !important;
    ${({ theme }) =>
      theme.forms?.formInputColor && `color: ${theme.forms?.formInputColor};`};
    ${({ theme, color }) =>
      color && `color: ${theme.typographyColors[color]};`};
    background-color: ${({ hasError, theme, disabled }) =>
      hasError || disabled
        ? theme.backgroundColors.grey50
        : theme.backgroundColors.white};
    // Remove background color for chrome autofill
    -webkit-box-shadow: 0 0 0 30px
      ${({ hasError, theme, disabled }) =>
        hasError || disabled
          ? theme.backgroundColors.grey50
          : theme.backgroundColors.white}
      inset !important;
  }

  input:disabled {
    color: ${({ theme }) => theme.typographyColors.grey400};
    pointer-events: none;
  }

  input:focus,
  input:-webkit-autofill:focus {
    outline: none;
  }

  input::placeholder {
    ${({ placeholderColor, theme }) =>
      placeholderColor && `color: ${theme.typographyColors[placeholderColor]}`};
  }
  ${flexBaseAlignCenter};
  gap: ${({ theme }) => theme.spacing.xxsmall};

  ${InputIcon} svg {
    ${({ theme }) =>
      theme.forms?.formInputColor && `color: ${theme.forms?.formInputColor};`};
    ${({ theme, color }) =>
      color && `color: ${theme.typographyColors[color]};`};

    ${({ theme, disabled }) =>
      disabled && `color:${theme.typographyColors.grey400}`};
  }
`;
