import type { PersonalVehicle } from '@legalplace/bankroot-api/entities';
import type {
  CreatePersonalVehicleDto,
  GetPersonalVehicleDto,
} from '@legalplace/bankroot-api/modules/personalVehicle/dto';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../services/api/api';

export const personalVehiclesApi = createApi({
  reducerPath: 'personalVehiclesApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['PersonalVehicles'],
  endpoints: (build) => ({
    getPersonalVehicles: build.query<GetPersonalVehicleDto[], string>({
      query: (companyId) => ({ url: `/personal-vehicle/${companyId}` }),
      providesTags: ['PersonalVehicles'],
    }),
    createPersonalVehicle: build.mutation<
      PersonalVehicle,
      CreatePersonalVehicleDto
    >({
      query: (createPersonalVehicleDto) => ({
        url: `/personal-vehicle`,
        method: 'POST',
        body: createPersonalVehicleDto,
      }),
      invalidatesTags: ['PersonalVehicles'],
    }),
  }),
});

export const { useCreatePersonalVehicleMutation, useGetPersonalVehiclesQuery } =
  personalVehiclesApi;
