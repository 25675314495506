export const myAccountant = {
  page_title: 'Mon comptable',
  title: 'Mon comptable',
  faq_card: {
    title: "Nos articles et vidéos d'aides",
    subtitle:
      'Découvrez plus de 150 articles et vidéos pour gérer au mieux votre activité !',
    help_center_button_label: 'Découvrir nos contenus',
  },
  starter_plan_accountant_card_title: 'Mon comptable',
  default_accountant_card_title: 'Équipe onboarding',
  starter_plan_accountant_card_subtitle:
    'Besoin de conseil ? Prenez rendez-vous !',
  accountant_card_subtitle: 'Du lundi au vendredi de 9:00 à 18:00',
  starter_plan_accountant_card_message:
    "L'offre starter n'offre pas de conseiller",
  accountant_card_message: 'Besoin de conseil ? Prenez rendez-vous !',
  accountant_card_button_label: 'Je prends rendez-vous',
  support_card_title: 'Service client',
  support_card_subtitle: 'Du lundi au vendredi de 9:00 à 18:00',
  support_card_message: 'Une demande urgente ? Écrivez-nous !',
  support_card_mail_to_button_label: 'Assistance rapide',
  unavailable_contact_modal: {
    title: 'Je prends rendez-vous',
    button_label: 'Contacter le service client',
    message_title: 'Votre comptable est actuellement absent.',
    message_subtitle:
      'Nous vous invitons à contacter le service client pour toute question.',
  },
};
