import {
  flexBaseAlignCenter,
  flexBaseColumn,
  styled,
} from '@legalplace/storybook';

const PRICING_COLUMN_WIDTH = '25rem';

export const PricingColumnContainer = styled.div`
  ${flexBaseColumn}
  align-items: center;
  min-width: ${PRICING_COLUMN_WIDTH};
  flex: 0 0 auto;
  scroll-snap-align: center;
`;

export const ColumnContainer = styled.div<{
  isPopular: boolean;
  isSelected?: boolean;
}>`
  ${flexBaseColumn};
  align-items: center;
  text-align: center;
  max-width: ${PRICING_COLUMN_WIDTH};
  border-radius: ${({ theme }) => theme.borders.radiusMd};

  ${({ theme, isPopular }) =>
    isPopular
      ? `
      box-shadow: 0 0 0 1px ${theme.typographyColors.grey300};
        background-color: ${theme.backgroundColors.grey50};
      `
      : `
        margin: ${theme.spacing.medium} 0 0 0;
      `}

  ${({ theme, isSelected }) =>
    isSelected &&
    `
      background-color: ${theme.backgroundColors.primary50};
      box-shadow: 0 0 0 1px ${theme.typographyColors.primary300};
    `}
`;

export const LabelContainer = styled.div`
  ${flexBaseAlignCenter};
  background-color: ${({ theme }) => theme.backgroundColors.grey100};
  padding: ${({ theme }) =>
    `${theme.spacing.xxxxsmall} ${theme.spacing.xxsmall}`};
  border-radius: ${({ theme }) => theme.borders.radiusSm};
  transform: translateY(50%);
`;
