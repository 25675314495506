import { css } from '@legalplace/storybook';

export const fullHeightWithHeaderPageBaseStyles = css`
  height: calc(
    100vh - ${({ theme }) => theme.layout.headerHeight} -
      env(safe-area-inset-top) - env(safe-area-inset-bottom)
  );
  position: relative;
  top: ${({ theme }) => theme.layout.headerHeight};
  width: 100%;
`;

export const fullHeightWithSubHeaderPageBaseStyles = css`
  height: calc(
    100vh - ${({ theme }) => theme.layout.headerHeight} -
      env(safe-area-inset-top) - env(safe-area-inset-bottom)
  );
  position: relative;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.small};
`;

export const fullHeightPageBaseStyles = css`
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  position: relative;
`;

export const fullWidthWithLeftMenuPageBaseStyles = css`
  ${fullHeightPageBaseStyles}
  margin-left: ${({ theme }) => theme.layout.leftMenuWidth};
  width: calc(100vw - ${({ theme }) => theme.layout.leftMenuWidth});
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    margin-left: 0;
    ${fullHeightWithHeaderPageBaseStyles};
  }
`;

export const noMenuPageBaseStyles = css`
  width: 100%;
  // as svh is not supported on every browser, we use 100vh as a fallback
  height: 100vh;
  height: 100svh;
`;
