import type { ILayoutDeviceSizes } from '@legalplace/storybook';
import {
  flexBaseAlignCenter,
  getResponsiveStyle,
  styled,
} from '@legalplace/storybook';

export const InformationMessageContainer = styled.div<{
  width: ILayoutDeviceSizes;
}>`
  ${flexBaseAlignCenter};
  width: fit-content;

  ${({ width }) => width && getResponsiveStyle(width, 'width')};
  padding: ${({ theme }) => theme.spacing.xxsmall};
  gap: ${({ theme }) => theme.spacing.xxxxsmall};
  background-color: ${({ theme }) => theme.backgroundColors.success50};
  color: ${({ theme }) => theme.buttonColors.success700};

  border-radius: ${({ theme }) => theme.borders.radiusSm};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    border-radius: ${({ theme }) => theme.borders.radiusMd};
  }
`;

export const IconAndMessageContainer = styled.div`
  ${flexBaseAlignCenter}
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;
