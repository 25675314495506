import { useTranslation } from 'react-i18next';

import {
  BannerStyle,
  BannerV2,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import logo from '../../../../assets/images/logo.svg';
import { INTERCOM_SYNCHRO_BANK } from '../../../../constants/bankAccount.constant';

import { BankDisclaimerContainer } from './BankSynchronization.style';

export function BankSynchronizationDisclaimer(): JSX.Element {
  const { t } = useTranslation('onboarding', {
    keyPrefix: 'onboarding_v2.bank_synchronization',
  });

  return (
    <BankDisclaimerContainer>
      <img src={logo} width="208" alt="LegalPlace Pro" />
      <Typography
        tag="p3"
        lineHeight={{ default: '2rem' }}
        color="primary900"
        textAlign="center"
      >
        {t('disclaimer_message_1')}
      </Typography>
      <BannerV2
        title={t('data_disclaimer_title')}
        message={t('disclaimer_message_2')}
        bannerStyle={BannerStyle.INFORMATION}
        spacing={{ default: '0 0 1.2rem 0' }}
        icon={StreamLineIconList.informationcircle}
        linkButtonLabel={t('data_disclaimer_button_label')}
        linkButtonHref={INTERCOM_SYNCHRO_BANK}
      />
    </BankDisclaimerContainer>
  );
}
