import { BalanceType, ImmobilisationInputType } from '@legalplace/shared';
import { StreamLineIconList } from '@legalplace/storybook';

export const ALL_CATEGORIES_LABEL = 'Toutes les catégories';

export const ALL_CATEGORIES_ID = 'all-categories';

export const ALL_CATEGORIES_OPERATION_DTO = {
  id: ALL_CATEGORIES_ID,
  displayOrder: 0,
  icon: StreamLineIconList.bento,
  iconColor: 'primary900',
  balanceType: BalanceType.Credit, // any value is fine here
  label: ALL_CATEGORIES_LABEL,
  fullLabel: ALL_CATEGORIES_LABEL,
  immobilisationInput: ImmobilisationInputType.NON_IMMOBILISE,
  requiresVatInput: false,
};

export const CATEGORIES_TO_EXCLUDE_IF_NO_SALES_CHANNEL = ['C26'];

export const CATEGORIES_TO_EXCLUDE_IF_ACTIVE_PHYSICAL_STORE_OR_VTC_PAYMENT_METHOD =
  ['C14'];
export const CATEGORIES_TO_EXCLUDE_IF_NO_ACTIVE_PHYSICAL_STORE_OR_VTC_PAYMENT_METHOD =
  ['C27'];
export const CATEGORIES_TO_EXCLUDE_IF_NO_ACTIVE_PHYSICAL_STORE_OR_FOOD_DELIVERY_SERVICE_PAYMENT_METHOD =
  ['C25'];
export const CATEGORIES_TO_EXCLUDE_IF_NO_CASH_REGISTER_PAYMENT_METHOD = ['C26'];
export const CATEGORIES_TO_EXCLUDE_IF_NO_ACTIVE_PHYSICAL_STORE = ['D13', 'C24'];
export const CATEGORIES_TO_EXCLUDE_IF_ACTIVE_PHYSICAL_STORE = ['D137'];
export const CATEGORIES_TO_EXCLUDE_FROM_USERS = ['D138', 'D142'];
