import type {
  CreateBillingClientRequestDto,
  GetBillingClientResponseDto,
  GetSavedBillingClientResponseDto,
  UpdateBillingClientRequestDto,
} from '@legalplace/bankroot-api/modules/billing/dto';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../../services/api/api';

export const billingClientsApi = createApi({
  reducerPath: 'billingClientsApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['BillingClients'],
  endpoints: (build) => ({
    getBillingClients: build.query<GetSavedBillingClientResponseDto[], string>({
      query: (companyId) => ({
        url: `/billing/client?companyId=${companyId}`,
      }),
      providesTags: ['BillingClients'],
    }),
    createBillingClients: build.mutation<
      GetBillingClientResponseDto,
      CreateBillingClientRequestDto
    >({
      query: (createBillingClientsDto) => ({
        url: `/billing/client`,
        method: 'POST',
        body: createBillingClientsDto,
      }),
      invalidatesTags: ['BillingClients'],
    }),
    updateBillingClients: build.mutation<
      GetBillingClientResponseDto,
      UpdateBillingClientRequestDto & { billingClientId: string }
    >({
      query: ({ billingClientId, ...updateBillingClientsDto }) => ({
        url: `/billing/client/${billingClientId}`,
        method: 'PUT',
        body: { billingClientId, ...updateBillingClientsDto },
      }),
      invalidatesTags: ['BillingClients'],
    }),
    deleteBillingClients: build.mutation<void, string>({
      query: (billingClientId) => ({
        url: `/billing/client/${billingClientId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BillingClients'],
    }),
  }),
});

export const {
  useGetBillingClientsQuery,
  useCreateBillingClientsMutation,
  useUpdateBillingClientsMutation,
  useDeleteBillingClientsMutation,
} = billingClientsApi;
