import {
  flexBaseColumn,
  flexColumnAlignCenter,
  styled,
} from '@legalplace/storybook';

import { addStyleInDesktop } from '../../../UI/Responsive.style';

export const AccountingPlanConfirmationContainer = styled.div`
  ${flexColumnAlignCenter};
  height: 100%;
  gap: ${({ theme }) => theme.spacing.medium};
  max-width: 55rem;
  padding: ${({ theme }) => `0 ${theme.spacing.medium}`};
  ${({ theme }) => addStyleInDesktop(theme, `padding: 8rem 0 0 0;}`)}
`;

export const SelectedOfferCard = styled.div`
  ${flexBaseColumn};
  background: ${({ theme }) => theme.backgroundColors.grey25};
  gap: ${({ theme }) => theme.spacing.xxsmall};
  border: 1px solid ${({ theme }) => theme.backgroundColors.grey300};
  border-radius: ${({ theme }) => theme.borders.radiusMd};
  padding: ${({ theme }) => theme.spacing.medium};
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing.xlarge} 0`};
`;
