export const selectSychronizedAccountsModal = {
  select_modal_title: 'Sélectionnez vos comptes pro',
  remove_account_modal_title:
    "Êtes-vous certain de vouloir annuler l'import de vos comptes bancaires ?",
  submit_button_label: 'Autoriser',
  cancel_button_label: 'Annuler',
  back_button_label: 'Retour',
  number_of_accounts: '{{count}} comptes',
  remove_account_confirmation_message:
    ' Les données relatives à votre compte ne seront pas enregistrées.',
  confirm_cancelation_button_label: "Oui, j'annule",
};
