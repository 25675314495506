export enum RibInformation {
  TITLE = 'title',
  BANK = 'bank',
  IBAN = 'iban',
  BIC = 'bic',
  AGENCY = 'agency',
  KEY = 'key',
  ACCOUNT = 'account',
  OWNER = 'owner',
}

export const FR_IBAN_REGEX = /^FR[0-9]{2}[A-Z0-9]{23}$/;
