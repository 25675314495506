import { StreamLineIcon, Typography } from '@legalplace/storybook';

import type { IInformationMessage } from './informationMessage.interface';

import {
  IconAndMessageContainer,
  InformationMessageContainer,
} from './InformationMessage.style';

export function InformationMessage({
  icon,
  message,
  emphasizedMessage,
  width = { default: '100%' },
}: IInformationMessage): JSX.Element {
  return (
    <InformationMessageContainer width={width}>
      <IconAndMessageContainer>
        {icon && <StreamLineIcon icon={icon} width="1.6rem" height="1.6rem" />}
        <Typography tag="p3" fontWeight="400" textAlign="center">
          {message}
        </Typography>
      </IconAndMessageContainer>
      {emphasizedMessage && (
        <Typography tag="p3" fontWeight="700" textAlign="center">
          {emphasizedMessage}
        </Typography>
      )}
    </InformationMessageContainer>
  );
}
