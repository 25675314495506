export const resetPassword = {
  new_password_incorrect_message:
    "Ce mot de passe n'est pas assez complexe pour sécuriser votre compte (10 caractères, avec une majuscule, une minuscule, un chiffre et un caractère spécial).",
  wrong_confirmation_password: 'Les mots de passe ne sont pas identiques.',
  title: 'Choisissez un mot de passe',
  subtitle:
    'Vous pourrez ensuite vous connecter avec votre e-mail et ce mot de passe.',
  submit_button_label: 'Confirmer mon mot de passe',
  success_toast_message: 'Mot de passe modifié !',
  password_label: 'Nouveau mot de passe',
  password_confirmation_label: 'Confirmer le nouveau mot de passe',
  password_placeholder: 'Nouveau mot de passe',
  expired_reset_link: 'Ce lien de réinitialisation a expiré.',
  same_password_error:
    "Pour des raisons de sécurité, veuillez saisir un mot de passe que vous n'avez pas déjà utilisé sur LegalPlace Pro.",
};
