export default {
  title: 'Entrez vos informations personnelles',
  subtitle:
    'Votre identité est requise pour créer votre compte LegalPlace Pro.',
  last_name_field: 'Nom',
  last_name_placeholder: 'Dupont',
  first_name_field: 'Prénom',
  first_name_placeholder: 'Lucie',
  phone_number_field: 'Numéro de téléphone',
  phone_number_placeholder: '06 12 34 56 78',
  dropdown_placeholder: 'Les réseaux sociaux',
  referrer_field: 'Comment avez-vous connu LegalPlace ? ',
  empty_field_error: 'Veuillez remplir ce champ.',
  invalid_phone_number_error: 'Ce numéro de téléphone est invalide.',
  next_step_button_label: 'Continuer',
};
