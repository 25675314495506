import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  FlexContainerBasicColumnAlignCenter,
  ModalDrawerV2,
  ModalVariants,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { dismissAccountingSubscriptionTrial } from '../../services/api/subscriptionUpsell.api';
import { showToastError } from '../../services/notifications/toast';

export function AccountingTrialEndModal({
  isOpen,
  setIsOpen,
  activeCompanyId,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  activeCompanyId: string;
}): JSX.Element {
  const { t } = useTranslation('general', {
    keyPrefix: 'accounting_trial_end_modal',
  });
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onClose = (): void => {
    setIsOpen(false);
  };

  const dismissAccountingSubscription = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await dismissAccountingSubscriptionTrial({ companyId: activeCompanyId });
      onClose();
      navigate('/', { replace: true });
    } catch (error) {
      showToastError(t('unexpected_error'));
      console.error(
        `[dismissAccountingSubscription] - Can't dismiss accounting subscription trial for company ${activeCompanyId}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalDrawerV2
      isOpen={isOpen}
      onClose={onClose}
      title={t(`title`)}
      modalVariant={ModalVariants.ACTION}
      primaryAction={onClose}
      secondaryButtonProps={{ isLoading }}
      buttonLabels={{
        primary: t(`finalize_registration_button_label`),
        secondary: t(`cancel_registration_button_label`),
      }}
      secondaryAction={dismissAccountingSubscription}
      bodyInternalSpacing={{ default: '1.6rem 2.4rem' }}
    >
      <FlexContainerBasicColumnAlignCenter
        gap={{ default: '1.6rem' }}
        spacing={{ default: '2.4rem' }}
      >
        <StreamLineIcon
          icon={StreamLineIconList.alerttriangle}
          fillColor="warning500"
        />
        <Typography color="primary900" fontWeight="500" textAlign="center">
          {t('message_line_1')}
        </Typography>
        <Typography tag="p3" color="primary900" textAlign="center">
          {t('message_line_2')}
        </Typography>
        <Typography tag="p3" color="primary900" textAlign="center">
          {t('message_line_3')}
        </Typography>
      </FlexContainerBasicColumnAlignCenter>
    </ModalDrawerV2>
  );
}
