import {
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import {
  ArrowModal,
  CloseButton,
  DesktopAcademyModalBackdrop,
  DesktopAcademyModalContainer,
  DesktopAcademyModalContent,
  DesktopAcademyModalHeader,
  TitleContainer,
} from './DesktopAcademyModal.style';

interface IDesktopAcademyModalProps {
  children: React.ReactNode;
  onClose?: () => void;
  title: string;
  isOpen: boolean;
}

export function DesktopAcademyModal({
  children,
  onClose,
  title,
  isOpen,
}: IDesktopAcademyModalProps): JSX.Element {
  return (
    <>
      <DesktopAcademyModalContainer
        isOpen={isOpen}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <ArrowModal />
        <DesktopAcademyModalHeader>
          <TitleContainer>
            <StreamLineIcon
              icon={StreamLineIconList.loginkeys}
              fillColor="primary900"
            />
            <Typography
              tag="p2"
              color="primary900"
              fontWeight="600"
              textAlign="left"
              style={{ flexGrow: '1' }}
            >
              {title}
            </Typography>
            <CloseButton onClick={onClose}>
              <StreamLineIcon
                icon={StreamLineIconList.close}
                width="2rem"
                height="2rem"
              />
            </CloseButton>
          </TitleContainer>
        </DesktopAcademyModalHeader>
        <DesktopAcademyModalContent>{children}</DesktopAcademyModalContent>
      </DesktopAcademyModalContainer>
      <DesktopAcademyModalBackdrop onClick={onClose} isOpen={isOpen} />
    </>
  );
}
