import { Button, styled } from '@legalplace/storybook';

export const CloseButton = styled(Button)`
  min-width: unset;
  width: fit-content;
  border-radius: ${({ theme }) => theme.borders.radiusRounded};
`;

export const ToastButton = styled(Button)`
  box-shadow: none;
  text-decoration: unset;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
