export const MONTHS: Record<number, string> = {
  0: 'jan',
  1: 'fév',
  2: 'mars',
  3: 'avr',
  4: 'mai',
  5: 'juin',
  6: 'juill',
  7: 'août',
  8: 'sep',
  9: 'oct',
  10: 'nov',
  11: 'déc',
};

export const MONTH_TRANSLATION_KEYS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
