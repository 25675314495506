import type { ReactNode } from 'react';
import { useContext } from 'react';

import { Capacitor } from '@capacitor/core';
import { BankIconLoader } from '@legalplace/storybook';

import { ReloadContext } from '../../app.context';

import { ExternalStyledPullToRefresh } from './PullToRefresh.style';

export function PullToRefresh({
  shouldPullToRefresh,
  children,
}: {
  shouldPullToRefresh?: boolean;
  children: ReactNode;
}): JSX.Element {
  const { reload } = useContext(ReloadContext);

  if (!shouldPullToRefresh || !Capacitor.isNativePlatform()) {
    return children as JSX.Element;
  }

  return (
    <ExternalStyledPullToRefresh
      maxPullDownDistance={150}
      pullDownThreshold={90}
      onRefresh={async () => reload()}
      pullingContent={
        <BankIconLoader spacing={{ default: '2.4rem auto' }} width="100%" />
      }
      refreshingContent={
        <BankIconLoader spacing={{ default: '2.4rem auto' }} width="100%" />
      }
    >
      <div>{children}</div>
    </ExternalStyledPullToRefresh>
  );
}
