export const isVersion1MoreRecent = (
  version1?: string,
  version2?: string
): boolean => {
  if (!version1 || !version2) {
    return false;
  }

  const v1Numbers = version1.split('.').map(Number);
  const v2Numbers = version2.split('.').map(Number);

  const maxLength = Math.max(v1Numbers.length, v2Numbers.length);

  for (let index = 0; index < maxLength; index += 1) {
    const v1 = v1Numbers[index] || 0;
    const v2 = v2Numbers[index] || 0;

    if (v1 > v2) {
      return true;
    }
    if (v1 < v2) {
      return false;
    }
  }

  // If we get here, the versions are equal
  return false;
};
