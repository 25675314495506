import type { AccountingOnboardingInformationDto } from '@legalplace/bankroot-api/modules/onboardingFlow/dto/accountingOnboardingInformation.dto';
import type { OnboardingStepItem } from '@legalplace/bankroot-api/modules/onboardingFlow/onboardingFlow.type';
import type { OnboardingStep } from '@legalplace/shared';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../constants';

import type { OnboardingStepsState } from './types';

const initialState: OnboardingStepsState = {
  steps: [],
  stepsLeftCount: undefined,
  currentStep: undefined,
  progressPercentage: undefined,
  status: Status.IDLE,
  isOnboardingDrawerOpen: false,
  skippedSteps: [],
};

export const onboardingStepsSlice = createSlice({
  name: 'onboardingSteps',
  initialState,
  reducers: {
    startFetchOnboardingSteps: (
      state,
      { payload: action }: PayloadAction<{ silent?: boolean } | undefined>
    ) => {
      if (!action?.silent) {
        state.steps = [];
        state.stepsLeftCount = undefined;
        state.currentStep = undefined;
        state.progressPercentage = undefined;
        state.accountingOnboardingInformation = undefined;
        state.status = Status.LOADING;
      }
    },
    fetchOnboardingStepsFailed: (state) => {
      state.steps = [];
      state.stepsLeftCount = undefined;
      state.currentStep = undefined;
      state.progressPercentage = undefined;
      state.accountingOnboardingInformation = undefined;
      state.status = Status.FAILED;
    },
    fetchOnboardingStepsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        steps: OnboardingStepItem[];
        stepsLeftCount?: number;
        currentStep?: OnboardingStepItem;
        progressPercentage?: number;
        accountingOnboardingInformation?: AccountingOnboardingInformationDto;
      }>
    ) => {
      state.steps = payload.steps;
      state.stepsLeftCount = payload.stepsLeftCount;
      state.currentStep = payload.currentStep;
      state.progressPercentage = payload.progressPercentage;
      state.accountingOnboardingInformation =
        payload.accountingOnboardingInformation;
      state.status = Status.SUCCESS;
    },
    setIsOnboardingDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isOnboardingDrawerOpen = payload;
    },
    skipOnboardingStep: (state, { payload }: PayloadAction<OnboardingStep>) => {
      const skippedOnboardingSteps = Array.from(
        new Set([...state.skippedSteps, payload])
      );
      state.skippedSteps = skippedOnboardingSteps;
    },
    unskipOnboardingStep: (
      state,
      { payload }: PayloadAction<OnboardingStep>
    ) => {
      state.skippedSteps = state.skippedSteps.filter(
        (step) => step !== payload
      );
    },
    resetOnboardingSteps: () => initialState,
  },
});

export default onboardingStepsSlice.reducer;
