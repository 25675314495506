import { cardsSlice } from './reducer';

export const {
  startFetchCards,
  fetchCardsFailed,
  fetchCardsSuccess,
  resetCards,
  setActiveCardId,
  fetchActiveCardSuccess,
  fetchActiveCardFailed,
  updateActiveCard,
} = cardsSlice.actions;
