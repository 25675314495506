import type { ILayoutDeviceSizes } from '@legalplace/storybook';
import {
  css,
  flexBaseColumn,
  flexColumnAlignCenter,
  getResponsiveStyle,
  styled,
} from '@legalplace/storybook';

import { PageVariant } from '../../constants/pageWrapper.constants';
import {
  fullHeightWithHeaderPageBaseStyles,
  fullHeightWithSubHeaderPageBaseStyles,
  fullWidthWithLeftMenuPageBaseStyles,
  noMenuPageBaseStyles,
} from '../../mixins';

export const PageContentContainer = styled.div<{
  shouldHeightBeSet: boolean;
  gap?: ILayoutDeviceSizes;
  internalSpacing?: ILayoutDeviceSizes;
  isFullScreen?: boolean;
  pageVariant?: PageVariant;
}>`
  ${flexBaseColumn};
  max-width: 105.6rem;
  width: 100%;
  ${({ theme, internalSpacing, isFullScreen }) =>
    internalSpacing && !isFullScreen
      ? getResponsiveStyle(internalSpacing, 'padding')
      : `padding: ${theme.spacing.large}`};
  ${({ shouldHeightBeSet }) => shouldHeightBeSet && 'height: fit-content'};
  ${({ isFullScreen }) =>
    isFullScreen &&
    `
    max-width: calc(100vw - 26.4rem); 
    height: 100%;
    padding:unset;
    max-width: unset;
    `};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    ${({ pageVariant, isFullScreen, theme }) =>
      pageVariant === PageVariant.DEFAULT &&
      isFullScreen &&
      ` max-width: calc(100vw - ${theme.layout.leftMenuWidth}); `};
  }

  ${({ pageVariant }) =>
    pageVariant === PageVariant.NAVBAR_WITH_FOOTER &&
    `flex-grow:1; justify-content: space-between;`};
  gap: ${({ theme }) => theme.spacing.xxlarge};
  ${({ gap }) => gap && getResponsiveStyle(gap, 'gap')};
`;

const normalPagePadding = css`
  padding-bottom: 16rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-bottom: ${({ theme }) => theme.spacing.xxxxxxlarge};
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding-bottom: ${({ theme }) => theme.spacing.xxlarge};
  }
`;

const fullScreenPagePadding = css`
  padding-bottom: 6.65rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    padding-bottom: 0;
  }
`;

export const BackgroundContainer = styled.main<{
  internalSpacing?: ILayoutDeviceSizes;
  pageVariant?: PageVariant;
  isFullScreen?: boolean;
  isAccountSuspendedBannerDisplayed?: boolean;
  subheaderHeight?: number;
}>`
  ${flexColumnAlignCenter};
  ${({ pageVariant }) => {
    switch (pageVariant) {
      case PageVariant.DEFAULT:
        return fullWidthWithLeftMenuPageBaseStyles;
      case PageVariant.NAVBAR:
      case PageVariant.NAVBAR_WITH_FOOTER:
        return fullHeightWithHeaderPageBaseStyles;
      case PageVariant.SUBHEADER:
        return fullHeightWithSubHeaderPageBaseStyles;
      case PageVariant.NO_MENU:
        return noMenuPageBaseStyles;
      default:
        return '';
    }
  }}
  overflow-y: auto;
  background: ${({ theme }) => theme.backgroundColors.grey25};
  ${({ isFullScreen }) =>
    isFullScreen ? fullScreenPagePadding : normalPagePadding};
  /* Override above padding if needed */
  ${({ internalSpacing }) =>
    internalSpacing && getResponsiveStyle(internalSpacing, 'padding')}
  ${({ subheaderHeight }) =>
    subheaderHeight &&
    `height: calc(100vh - ${subheaderHeight}px - env(safe-area-inset-top) - env(safe-area-inset-bottom))`};
`;

export const ForcedBoxShadow = styled.div`
  position: fixed;
  width: 100%;
  padding: 8rem 0 0 0;
  z-index: 1;
  box-shadow: ${({ theme }) => theme.boxShadows.shadowElevation2};
`;
