import { call, put, select, takeEvery } from 'typed-redux-saga';

import { getCardsRequest } from '../../../services/api/card.api';
import { selectSwanAccountId } from '../../accounts/selectors';
import {
  fetchCardsFailed,
  fetchCardsSuccess,
  startFetchCards,
} from '../actions';

function* handleFetchCards(): Generator {
  let fallbackAccountId = '';
  try {
    fallbackAccountId = (yield select(selectSwanAccountId)) as string;
    if (fallbackAccountId) {
      const response = yield* call(getCardsRequest, fallbackAccountId);
      const { data: cards } = response;
      yield put(fetchCardsSuccess(cards));
    }
  } catch (error: unknown) {
    console.error(
      `[handleFetchCards] - Error trying to fetch cards for fallback account ${fallbackAccountId}`,
      error
    );
    yield put(fetchCardsFailed());
  }
}

function* fetchCardsSaga(): Generator {
  yield takeEvery(startFetchCards, handleFetchCards);
}

export default fetchCardsSaga;
