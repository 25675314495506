import { FlexAlignCenterJustifyCenter, styled } from '@legalplace/storybook';

export const CheckboxContainer = styled(FlexAlignCenterJustifyCenter)<{
  $checked?: boolean;
  $disabled?: boolean;
  $hasError?: boolean;
}>`
  border-width: ${({ theme }) => theme.borders.widthMedium};
  border-style: ${({ theme }) => theme.borders.styleRegular};
  border-radius: 50%;
  min-height: 2.4rem;
  min-width: 2.4rem;

  background-color: ${({ theme, $checked, $disabled, $hasError }) => {
    if ($disabled) {
      return theme.grey200;
    }
    if (!$checked) {
      return theme.white;
    }
    if ($hasError) {
      return theme.danger500;
    }
    return theme.primary500;
  }};

  border-color: ${({ theme, $checked, $disabled, $hasError }) => {
    if ($disabled) {
      return theme.grey200;
    }
    if ($hasError) {
      return theme.danger500;
    }
    if ($checked) {
      return theme.primary500;
    }
    return theme.grey200;
  }};

  :hover {
    border-color: ${({ theme, $checked, $disabled, $hasError }) =>
      !$checked && !$hasError && !$disabled && theme.primary500};
  }

  transition: background-color ${({ theme }) => theme.transition.delay.default},
    border-color ${({ theme }) => theme.transition.delay.default};
`;
