import type { FeatherIconList } from '@legalplace/storybook';
import {
  FeatherIcon,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import type { IInformationModalDrawerContent } from './informationModalDrawerContent.interface';

import {
  ContentContainer,
  FooterContainer,
  InformationsContainer,
  InformationTitleContainer,
} from './InformationModalDrawerContent.style';

export function InformationModalDrawerContent({
  statusIcon,
  statusIconColor,
  title,
  textContent,
  textComponent,
  innerHtmlContent,
  children,
}: IInformationModalDrawerContent): JSX.Element {
  return (
    <InformationsContainer>
      <InformationTitleContainer>
        {statusIcon in StreamLineIconList ? (
          <StreamLineIcon
            icon={statusIcon as StreamLineIconList}
            fillColor={statusIconColor}
          />
        ) : (
          <FeatherIcon
            icon={statusIcon as FeatherIconList}
            fillColor={statusIconColor}
          />
        )}
        <Typography
          tag="p2"
          color="primary900"
          fontWeight="600"
          textAlign="center"
        >
          {title}
        </Typography>
      </InformationTitleContainer>
      <ContentContainer>
        {textContent && (
          <Typography tag="p3" textAlign="center">
            {textContent}
          </Typography>
        )}
        {innerHtmlContent && (
          <Typography
            tag="p3"
            textAlign="center"
            innerHtml={innerHtmlContent}
          />
        )}
        {textComponent ?? ''}
      </ContentContainer>
      {children && <FooterContainer>{children}</FooterContainer>}
    </InformationsContainer>
  );
}
