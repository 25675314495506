import type { FindCompanyDto } from '@legalplace/bankroot-api/modules/company/dto';
import { SubscriptionFeatureStatus } from '@legalplace/shared';

import { V2_ONBOARDINGS_STATUSES } from '../../constants';

export const shouldForceOnboardingCompletion = (
  company: FindCompanyDto | undefined
): boolean =>
  company?.featureMap?.ComptaBase?.status ===
    SubscriptionFeatureStatus.TrialExpired &&
  !company?.featureMap?.BankBase?.isActive &&
  !!company?.setupStatus &&
  V2_ONBOARDINGS_STATUSES.has(company.setupStatus);
