import {
  getDateInParisTimezone,
  getMonthInParisTimezone,
} from '@legalplace/storybook';

import { MONTHS } from '../../constants';

export const formatDate = (dateString: string): string =>
  `0${getDateInParisTimezone(new Date(dateString), 'fr-FR')}`.slice(-2);

export const formatMonth = (dateString: string): string =>
  MONTHS[getMonthInParisTimezone(new Date(dateString), 'fr-FR')];

export const getDate = (date: Date): string =>
  date.toLocaleString('fr-FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone: 'Europe/Paris',
  });

export const getNumericDate = (date: string | Date | undefined): string => {
  if (!date) {
    return '';
  }
  return new Date(date).toLocaleString('fr-FR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    timeZone: 'Europe/Paris',
  });
};

export const formatCompleteDate = (dateString?: Date): string | undefined => {
  if (!dateString) {
    return undefined;
  }

  return new Date(dateString).toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'Europe/Paris',
  });
};

export const getDateTime = (date: string | Date | undefined): string => {
  if (!date) {
    return '';
  }

  const hours = new Date(date).getHours().toString();
  const minutes = new Date(date).getMinutes().toString().padStart(2, '0');

  if (minutes === '00') {
    return `${hours}h`;
  }

  return `${hours}h${minutes}`;
};

export function getDaysBetweenDateAndNow(
  date: Date | string | undefined
): number | undefined {
  if (!date) {
    return undefined;
  }

  const today = new Date();

  if (new Date(date) < today) {
    return undefined;
  }

  const timeDifference = Math.abs(today.getTime() - new Date(date).getTime());

  return Math.ceil(timeDifference / (1000 * 3600 * 24));
}

export function getFullDayDifferenceBetweenDates(
  date1: Date | string,
  date2: Date | string
): number {
  const timeDifference = new Date(date1).getTime() - new Date(date2).getTime();

  return Math.floor(timeDifference / (1000 * 3600 * 24));
}
