import { type DefaultTheme } from '@legalplace/storybook';

export const addStyleInMobile = (
  theme: DefaultTheme,
  styleToAdd: string
): string => `@media screen and (max-width: ${theme.breakpoints.medium}) {
  ${styleToAdd};
}`;

export const addStyleInDesktopAndTablet = (
  theme: DefaultTheme,
  styleToAdd: string
): string => `@media screen and (min-width: ${theme.breakpoints.medium}) {
  ${styleToAdd};
}`;

export const addStyleInDesktop = (
  theme: DefaultTheme,
  styleToAdd: string
): string => `@media screen and (min-width: ${theme.breakpoints.large}) {
  ${styleToAdd};
}`;
