import {
  flexBaseColumn,
  FlexContainerBasicColumn,
  styled,
} from '@legalplace/storybook';

import { AUTO_COMPLETE_DROPDOWN_MAX_HEIGHT } from '../../constants';

import { addStyleInDesktop } from '../UI/Responsive.style';

export const AccountingOnboardingStepContentContainer = styled(
  FlexContainerBasicColumn
)`
  background: white;
  flex-grow: 1;
  align-items: center;
  ${({ theme }) =>
    addStyleInDesktop(
      theme,
      `padding: ${theme.spacing.xxxxxxlarge} 0 0 0;
      justify-content: space-between;`
    )};
`;

export const DoubleFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) =>
    addStyleInDesktop(
      theme,
      `flex-direction: row;
      gap: ${theme.spacing.small};`
    )};
`;

export const EmptyAssociatesContainer = styled.div`
  ${flexBaseColumn};
  justify-content: center;
  align-items: center;
`;

export const CompanySearchAutoCompleteContainer = styled(
  FlexContainerBasicColumn
)`
  gap: ${({ theme }) => theme.spacing.xlarge};
  height: calc(${AUTO_COMPLETE_DROPDOWN_MAX_HEIGHT} + 10rem);
`;

export const OnboardingMobileFooterContainer = styled.div`
  ${flexBaseColumn};
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.typographyColors.grey100};
  padding: ${({ theme }) => theme.spacing.medium};
  width: 100%;
  background: ${({ theme }) => theme.backgroundColors.white};
  gap: ${({ theme }) => theme.spacing.medium};
`;
