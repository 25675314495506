import { call, put, select, takeEvery } from 'typed-redux-saga';

import { getPCABalancesRequest } from '../../../services/api/categorizableItem.api';
import {
  fetchActiveCompanyPcaBalanceFailed,
  fetchActiveCompanyPcaBalanceSuccess,
  startFetchActiveCompanyPcaBalance,
  startSilentFetchActiveCompanyPcaBalance,
} from '../actions';
import { selectActiveCompanyId } from '../selectors';

function* handleFetchPcaBalance(): Generator {
  try {
    const activeCompanyId = yield* select(selectActiveCompanyId);
    if (!activeCompanyId) {
      return;
    }
    const {
      data: { balances },
    } = yield* call(getPCABalancesRequest, activeCompanyId);

    const pcaDebtorCount = balances.filter(
      (pcaBalance) => pcaBalance.balance < 0
    ).length;
    yield put(
      fetchActiveCompanyPcaBalanceSuccess({
        pcaBalances: balances,
        pcaDebtorCount,
      })
    );
  } catch (error: unknown) {
    console.error(
      `[handleFetchPcaBalance] - Error trying to fetch company PCA balance`,
      error
    );
    yield put(fetchActiveCompanyPcaBalanceFailed());
  }
}

function* fetchPcaBalanceSaga(): Generator {
  yield takeEvery(
    [
      startFetchActiveCompanyPcaBalance,
      startSilentFetchActiveCompanyPcaBalance,
    ],
    handleFetchPcaBalance
  );
}

export default fetchPcaBalanceSaga;
