import { onboardingStepsSlice } from './reducer';

export const {
  startFetchOnboardingSteps,
  fetchOnboardingStepsFailed,
  fetchOnboardingStepsSuccess,
  setIsOnboardingDrawerOpen,
  resetOnboardingSteps,
  skipOnboardingStep,
  unskipOnboardingStep,
} = onboardingStepsSlice.actions;
