import { useTranslation } from 'react-i18next';

import { OnboardingStep } from '@legalplace/shared';
import { FlexContainerBasicColumn, Typography } from '@legalplace/storybook';

import { useAppDispatch, useAppSelector } from '../../../../hooks/store';
import { useCurrentDeviceBreakpoint } from '../../../../hooks/useCurrentDeviceBreakpoint';
import { useFetchBankAccountItems } from '../../../../hooks/useFetchBankAccountItems';
import {
  skipOnboardingStep,
  startFetchOnboardingSteps,
} from '../../../../store/onboardingSteps/actions';
import { selectStepsLeftCount } from '../../../../store/onboardingSteps/selector';
import { BankAccountElement } from '../../../Accounts/BankAccountElement';

import { BankSynchronizationDisclaimer } from './BankSynchronizationDisclaimer';

import { PrimaryButton } from '../../../UI/PrimaryButton.style';
import {
  BankSynchronizationContainer,
  SkipButtonContainer,
} from './BankSynchronization.style';

export function BankSynchronizationSelector({
  startSynchronization,
}: {
  startSynchronization: (url: string, isAmpliNeeded?: boolean) => void;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const onboardingStepsLeftCount = useAppSelector(selectStepsLeftCount);
  const bankAccountItems = useFetchBankAccountItems();
  const { isMobile } = useCurrentDeviceBreakpoint();

  const { t } = useTranslation('onboarding', {
    keyPrefix: 'onboarding_v2.bank_synchronization',
  });

  const skipStep = (): void => {
    dispatch(skipOnboardingStep(OnboardingStep.BankAccountsSynchronization));
    dispatch(startFetchOnboardingSteps({ silent: true }));
  };

  const isStepSkippable = onboardingStepsLeftCount !== 1;

  return (
    <BankSynchronizationContainer isMobile={isMobile}>
      <BankSynchronizationDisclaimer />
      <FlexContainerBasicColumn
        gap={{ default: '2.4rem', medium: '0' }}
        style={{ width: '100%' }}
      >
        {bankAccountItems.map((item) => (
          <BankAccountElement
            key={item.key}
            onClick={() => startSynchronization(item.url ?? '', true)}
            isLoading={item.isLoading}
            logo={item.logo}
          />
        ))}
      </FlexContainerBasicColumn>
      <SkipButtonContainer>
        {isStepSkippable && (
          <PrimaryButton onClick={skipStep}>
            <Typography tag="p3" fontWeight="400" color="white">
              {t('skip_to_onboarding_call_button_label')}
            </Typography>
          </PrimaryButton>
        )}
      </SkipButtonContainer>
    </BankSynchronizationContainer>
  );
}
