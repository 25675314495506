import { SwanPhysicalCardStatus } from '@legalplace/shared';

export enum ToCreateCardStatus {
  TO_CREATE = 'ToCreate',
}

export type CardStatusType = ToCreateCardStatus | SwanPhysicalCardStatus;
export const CardStatus = { ...ToCreateCardStatus, ...SwanPhysicalCardStatus };

export const TO_CREATE_CARD_ID = 'to-create-id';

export const TO_PROCESS_CARD_ID = 'to-process-id';

export const CARD_SEPARATOR = '/';

export const CARD_CONTAINER_HEIGHT = '53rem';

export enum AddCardStep {
  DELIVERY_ADDRESS = 'deliveryAddress',
  RECAP_FORM = 'recapForm',
  PENDING_CONSENT = 'pendingConsent',
  SUCCESS_CONSENT = 'successConsent',
  FAILED_CONSENT = 'failedConsent',
}

export enum SuspendCardStep {
  CONFIRMATION = 'confirmation',
  SUCCESS = 'success',
}

export enum ActivateCardStep {
  IDENTIFIER_FORM = 'identifierForm',
  PENDING_CONSENT = 'pendingConsent',
  SUCCESS_CONSENT = 'successConsent',
  FAILED_CONSENT = 'failedConsent',
}

export enum CancelCardStep {
  REASON_INPUT = 'reasonInput',
  SUCCESS = 'success',
}

export enum ReactivateCardStep {
  CONFIRMATION = 'confirmation',
  CARD_LOCKED = 'cardLocked',
  PENDING_CONSENT = 'pendingConsent',
  SUCCESS_CONSENT = 'successConsent',
  FAILED_CONSENT = 'failedConsent',
}

export const LP_DOMICILIATION_ADDRESSES = [
  '60 rue francois 1er',
  '60 rue francois premier',
  '60 rue francois ier',
];

export const LP_DOMICILIATION_ZIPCODE = '75008';

export const DELIVERY_ADDRESS_VALUE_KEY = 'address1';
