import type { URLOpenListenerEvent } from '@capacitor/app';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import type { PluginListenerHandle } from '@capacitor/core/types/definitions';

import type { IPopup } from '../../interfaces';

const { VITE_APP_APP_ID } = import.meta.env;

export class MobilePopup implements IPopup {
  private isTargetWindowLoaded = false;

  private appUrlOpenListener: PluginListenerHandle;

  private targetWindowParameters?: {
    consentUrl: string;
    successCallback: () => Promise<void>;
    errorCallback: () => Promise<void>;
  };

  constructor() {
    this.isTargetWindowLoaded = true;
  }

  // eslint-disable-next-line class-methods-use-this
  openPopup(): void {}

  async redirectAndListenForCallback(
    consentUrl: string,
    successCallback: () => Promise<void>,
    errorCallback: () => Promise<void>
  ): Promise<void> {
    this.targetWindowParameters = {
      consentUrl,
      successCallback,
      errorCallback,
    };

    await Browser.open({
      url: consentUrl,
    });

    let wasRedirectedToApp = false;

    await Browser.addListener('browserFinished', async () => {
      await this.removeAppUrlOpenListener();
      if (!wasRedirectedToApp) {
        await errorCallback();
      }
    });

    this.appUrlOpenListener = await App.addListener(
      'appUrlOpen',
      async (event: URLOpenListenerEvent) => {
        const isIos = Capacitor.getPlatform() === 'ios';
        if (
          event.url === `${VITE_APP_APP_ID}://success` ||
          (event.url.includes('mobile/close') && event.url.includes('Accepted'))
        ) {
          wasRedirectedToApp = true;
          await Browser.removeAllListeners();
          await successCallback();
          if (isIos) {
            await Browser.close();
          }
          await this.removeAppUrlOpenListener();
        } else if (
          event.url === `${VITE_APP_APP_ID}://failure` ||
          (event.url.includes('mobile/close') &&
            !event.url.includes('Accepted'))
        ) {
          wasRedirectedToApp = true;
          await Browser.removeAllListeners();
          await errorCallback();
          if (isIos) {
            await Browser.close();
          }
          await this.removeAppUrlOpenListener();
        }
      }
    );

    this.isTargetWindowLoaded = true;
  }

  get isPopupOpen(): boolean {
    return this.isTargetWindowLoaded;
  }

  async close(): Promise<void> {
    if (Capacitor.getPlatform() === 'ios') {
      await Browser.close();
    }
    this.isTargetWindowLoaded = false;
    await this.removeAppUrlOpenListener();
  }

  async removeAppUrlOpenListener(): Promise<void> {
    if (this.appUrlOpenListener) {
      await this.appUrlOpenListener.remove();
    }
  }
}
