import type { GetBillingInvoiceResponseDto } from '@legalplace/bankroot-api/modules/billing/dto';
import type { GetInvoiceStatsResponseDto } from '@legalplace/bankroot-api/modules/company/dto';
import type { PageDto } from '@legalplace/bankroot-api/modules/pagination/dto';
import type { BillingInvoiceStatus } from '@legalplace/shared';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../../constants';
import type { PaginationOptions } from '../../transactions/types';

import type { InvoiceState } from './types';

const initialState: InvoiceState = {
  byId: {},
  allIds: [],
  status: Status.LOADING,
  pageStatus: Status.IDLE,
  selectedInvoiceId: undefined,
  lastPaginationResult: undefined,
  invoiceStats: undefined,
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setInvoiceModalOpeningSource: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.invoiceModalOpeningSource = action.payload;
    },
    startFetchInvoiceRequest: (
      state,
      action: PayloadAction<Partial<PaginationOptions>>
    ) => {
      if (!action.payload.page && !action.payload.take) {
        state.status = Status.LOADING;
        state.byId = {};
        state.allIds = [];
      } else {
        state.pageStatus = Status.LOADING;
      }
    },
    startFetchInvoiceStatsRequest: (state) => {
      state.invoiceStats = undefined;
    },
    fetchInvoiceStatsSuccess: (
      state,
      action: PayloadAction<GetInvoiceStatsResponseDto>
    ) => {
      state.invoiceStats = action.payload;
    },
    fetchInvoiceStatsError: (state) => {
      state.invoiceStats = undefined;
    },
    fetchInvoiceSuccess: (
      state,
      action: PayloadAction<PageDto<GetBillingInvoiceResponseDto>>
    ) => {
      state.lastPaginationResult = action.payload.meta;
      let newStateById: Record<string, GetBillingInvoiceResponseDto> = {};
      let newStateAllIds: string[] = [];
      if (action.payload.meta.page !== 1) {
        newStateById = state.byId;
        newStateAllIds = state.allIds;
      }
      state.byId = action.payload.data.reduce(
        (accumulator, invoice: GetBillingInvoiceResponseDto) => ({
          ...accumulator,
          [invoice.id]: invoice,
        }),
        newStateById
      );
      state.allIds = [
        ...newStateAllIds,
        ...action.payload.data.map((invoice) => invoice.id),
      ];
      state.status = Status.IDLE;
      state.pageStatus = Status.IDLE;
    },
    resetInvoice: () => initialState,
    failInvoiceRequest: (state) => {
      state.status = Status.FAILED;
    },
    setSelectedInvoiceId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedInvoiceId = action.payload;
    },
    updateInvoiceStatusById: (
      state,
      action: PayloadAction<{ invoiceId: string; status: BillingInvoiceStatus }>
    ) => {
      const { invoiceId, status } = action.payload;
      if (state.byId[invoiceId]) {
        state.byId[invoiceId].status = status;
      }
    },
  },
});

export default invoiceSlice.reducer;
