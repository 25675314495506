import type { GetTransactionQueryDto } from '@legalplace/bankroot-api/modules/transaction/dto';

import type { PaginationOptions } from '../../store/transactions/types';

export const getTransactionsRequestParams = (
  companyId: string,
  filters?: Partial<GetTransactionQueryDto>,
  paginationOptions?: PaginationOptions,
  categorizableItemId?: string
): string => {
  const transactionQueryParams = categorizableItemId
    ? new URLSearchParams({
        companyId,
        categorizableItemId,
      })
    : new URLSearchParams({ companyId });

  if (filters?.transactionType && Array.isArray(filters?.transactionType)) {
    filters.transactionType.forEach((transactionType) =>
      transactionQueryParams.append('transactionType[]', transactionType)
    );
  } else if (filters?.transactionType) {
    transactionQueryParams.append(
      'transactionType[]',
      filters.transactionType.toString()
    );
  }
  if (
    filters?.categorizationSource &&
    Array.isArray(filters?.categorizationSource)
  ) {
    filters.categorizationSource.forEach((categorizationSource) =>
      transactionQueryParams.append(
        'categorizationSource[]',
        categorizationSource
      )
    );
  } else if (filters?.categorizationSource) {
    transactionQueryParams.append(
      'categorizationSource[]',
      filters.categorizationSource.toString()
    );
  }
  if (
    filters?.operationCategoryExternalIds &&
    Array.isArray(filters?.operationCategoryExternalIds)
  ) {
    filters.operationCategoryExternalIds.forEach(
      (operationCategoryExternalId) =>
        transactionQueryParams.append(
          'operationCategoryExternalIds[]',
          operationCategoryExternalId
        )
    );
  } else if (filters?.operationCategoryExternalIds) {
    transactionQueryParams.append(
      'categorizationSource[]',
      filters.operationCategoryExternalIds.toString()
    );
  }

  if (filters?.accounts && Array.isArray(filters?.accounts)) {
    filters.accounts.forEach((accounts) =>
      transactionQueryParams.append('accounts[]', accounts)
    );
  }

  if (filters?.accountingStatus && Array.isArray(filters?.accountingStatus)) {
    filters.accountingStatus.forEach((accountingStatus) =>
      transactionQueryParams.append('accountingStatus[]', accountingStatus)
    );
  } else if (filters?.accountingStatus) {
    transactionQueryParams.append(
      'accountingStatus[]',
      filters.accountingStatus.toString()
    );
  }

  if (filters?.proofStatus && filters.proofStatus.length > 0) {
    filters.proofStatus.forEach((status) =>
      transactionQueryParams.append('proofStatus[]', status)
    );
  }

  if (filters?.operationsStatus && filters.operationsStatus.length > 0) {
    filters.operationsStatus.forEach((status) =>
      transactionQueryParams.append('operationsStatus[]', status)
    );
  }

  if (filters?.search) {
    transactionQueryParams.append('search', filters.search);
  }
  if (filters?.balanceType) {
    filters.balanceType.forEach((type) =>
      transactionQueryParams.append('balanceType[]', type)
    );
  }
  if (filters?.isBeforeUpdatedAt) {
    transactionQueryParams.append(
      'isBeforeUpdatedAt',
      filters.isBeforeUpdatedAt
    );
  }
  if (filters?.isAfterUpdatedAt) {
    transactionQueryParams.append('isAfterUpdatedAt', filters.isAfterUpdatedAt);
  }
  if (filters?.transactionStatus) {
    filters.transactionStatus.forEach((transactionStatus) =>
      transactionQueryParams.append('transactionStatus[]', transactionStatus)
    );
  }
  if (filters?.amountLower) {
    transactionQueryParams.append(
      'amountLower',
      Math.round(filters.amountLower * 100).toString()
    );
  }
  if (filters?.amountUpper) {
    transactionQueryParams.append(
      'amountUpper',
      Math.round(filters.amountUpper * 100).toString()
    );
  }

  if (filters?.bankName && Array.isArray(filters?.bankName)) {
    filters.bankName.forEach((bankName) =>
      transactionQueryParams.append('bankName[]', bankName)
    );
  } else if (filters?.bankName) {
    transactionQueryParams.append('bankName[]', filters.bankName.toString());
  }

  if (paginationOptions) {
    const { page, take } = paginationOptions;
    transactionQueryParams.append('page', page.toString());
    transactionQueryParams.append('take', take.toString());
  }

  return transactionQueryParams.toString();
};
