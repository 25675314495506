import type { AxiosResponse } from 'axios';

import type { GetDashboardWidgetDto } from '@legalplace/bankroot-api/modules/userCompany/dto';

import { api } from './api';

export const getDashboardWidgetsVisibility = (
  userCompanyId: string
): Promise<AxiosResponse<GetDashboardWidgetDto>> =>
  api.get(`/user-company/${userCompanyId}/dashboard-widget`);
