import { CreditTransferSteps } from '../../constants/creditTransfer.constants';
import { VatValueOptions } from '../../constants/vat.constants';

export const creditTransfer = {
  page_title: 'Virements',
  beneficiaries: {
    page_title: 'Bénéficiaires',
    searchbar_placeholder: 'Nom du bénéficiaire',
    reset_button: 'Réinitialiser',
    new_partner: 'Ajouter un bénéficiaire',
    number_of_partners_zero: 'Aucun bénéficiaire',
    number_of_partners_one: '1 bénéficiaire',
    number_of_partners_other: '{{ count }} bénéficiaires',
    beneficiary_name_length_error_message:
      'Le nom doit contenir entre 2 et 70 caractères.',
    start_request_button: 'Nouveau virement',
    beneficiary_modal: {
      add_success_message: 'Bénéficiaire ajouté !',
      delete_success_message: 'Bénéficiaire supprimé !',
      edit_success_message: 'Bénéficiaire modifié !',
      edit_modal_title: 'Modifier un bénéficiaire',
      add_modal_title: 'Ajouter un bénéficiaire',
      delete_modal_title: 'Supprimer un bénéficiaire',
      delete_button: 'Supprimer le bénéficiaire',
      delete_confirm_button: 'Oui, supprimer',
      save_button: 'Enregistrer',
      add_button: 'Ajouter',
      cancel_button: 'Annuler',
      next_step_button: 'Enregistrer',
      delete_subtitle: 'Supprimer "{{beneficiaryName}}" ?',
      delete_warning_message:
        "Cette action est irréversible mais n'affectera pas les virements en cours ou programmés à destination de ce bénéficiaire.",
      beneficiary_name_label: 'Nom et prénom du bénéficiaire',
      beneficiary_label_label: 'Label personnalisé (optionnel)',
      iban_already_in_use_error:
        'Cet IBAN est déjà utilisé pour un bénéficiaire enregistré.',
      name_already_in_use_error:
        'Ce nom est déjà utilisé pour un bénéficiaire enregistré.',
      name_length_error:
        'Ce nom doit contenir entre 2 et 70 caractères pour être enregistré.',
      invalid_iban_error: 'Cet IBAN est incorrect.',
      beneficiary_iban_label: 'IBAN',
    },
  },
  transaction_dashboard: {
    title: 'Filtres {{currentActiveFilterCount}}',
    date_filter_title: 'Date',
    transaction_type_filter_title: 'Type',
    reset_all_button: 'Réinitialiser tout',
    apply_filters_button: 'Appliquer',
    credit: 'Crédit',
    debit: 'Débit',
    transaction_date: 'Date',
    transaction_type: 'Type',
    transaction_amount: 'Montant',
    footer_text_zero: '0 transaction',
    footer_text_one: '1 transaction',
    footer_text_other: '{{count}} transactions',
    transaction_dashboard_filter_result_text:
      '{{transactionCount}} transactions',
    transaction_dashboard_get_next_page_button: 'Voir plus',
    transaction_toolbar_export_button: 'Exporter',
    searchbar_placeholder: 'Libellé, montant...',
    transaction_dashboard_pending: 'En cours',
    transaction_dashboard_rejected: 'Rejeté',
    transaction_type_filter_button: 'Type',
    transaction_type_filter_action_button: 'Appliquer',
    transaction_type_reset_action_button: 'Réinitialiser',
    transaction_type_card_other: 'Paiement par carte',
    transaction_type_card_in_store: 'Paiement par carte (en magasin)',
    transaction_type_card_online: 'Paiement par carte (sur internet)',
    transaction_type_card_withdrawal: 'Retrait au distributeur',
    transaction_type_payment: 'Virement',
    transaction_type_levy: 'Prélèvement',
    transaction_type_expense: 'Note de frais',
    credit_debit_filter_button: 'Crédit / Débit',
    credit_debit_filter_action_button: 'Appliquer',
    credit_debit_reset_action_button: 'Réinitialiser',
    credit_debit_null_choice: 'Indifférent',
    date_filter_button: 'Date',
    date_filter_action_button: 'Appliquer',
    date_reset_action_button: 'Réinitialiser',
    mobile_filter_button: 'Filtrer',
    mobile_filter_action_button: 'Filtrer',
    mobile_reset_action_button: 'Réinitialiser',
    mobile_filter_drawer_title: 'Filtrer',
    reset_button: 'Réinitialiser',
    from: 'Du',
    to: 'au',
    transaction_search_button: 'Recherche',
    no_item: 'Aucun virement effectué pour le moment',
    no_item_subtitle: "Tous vos virements s'afficheront ici.",
    no_filtered_item: 'Aucun résultat avec les critères sélectionnés',
    no_filtered_item_subtitle: 'Essayez de réajuster votre recherche.',
    date_from: 'Date de début',
    date_to: 'Date de fin',
    transactionAmount_filter_title: 'Montant',
    amount_from: 'Entre',
    amount_to: 'Et',
    not_justified_label: 'À justifier',
    transaction_types: {
      Virement: 'Virement',
      VirementInstantané: 'Virement instantané',
    },
    accounts_filter_button: 'Comptes',
    accounts_filter_action_button: 'Appliquer',
    accounts_reset_action_button: 'Réinitialiser',
    download_started: 'Téléchargement démarré !',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    multiple_categories: 'Catégories multiples',
    to_categorize: 'À catégoriser',
    vat_dropdown: {
      [VatValueOptions.VAT_0_FRA]: '0 % - France',
      [VatValueOptions.VAT_0_UE]: '0 % - UE',
      [VatValueOptions.VAT_0_OTHER]: '0 % - Hors UE',
      [VatValueOptions.VAT_210]: '2,1 %',
      [VatValueOptions.VAT_550]: '5,5 %',
      [VatValueOptions.VAT_850]: '8,5 %',
      [VatValueOptions.VAT_1000]: '10 %',
      [VatValueOptions.VAT_2000]: '20 %',
      [VatValueOptions.VAT_UNDEFINED]: 'TVA',
    },
    file_preview_title: 'Justificatif - {{fileName}}',
    today_date_row: 'Aujourd’hui',
    yesterday_date_row: 'Hier',
    accounting_ongoing: 'Préparation du bilan',
    accounting_closed: 'Exercice clôturé',
    transaction_not_categorized: 'Non catégorisée',
  },
  execute_credit_transfer: {
    modal_title: 'Effectuer un virement',
    steps: {
      [CreditTransferSteps.BENEFICIARY]: 'Bénéficiaire',
      [CreditTransferSteps.AMOUNT]: 'Montant',
    },
    start_request_button: 'Nouveau virement',
    cancel_button_label: 'Annuler',
    close_modal_button_label: 'Fermer',
    next_step_button: 'Continuer',
    previous_step_button: 'Retour',
    provenance_message: 'Depuis',
    insufficient_balance_error_message:
      'Ce montant dépasse le solde disponible :',
    transaction_label: 'Motif (optionnel)',
    button_label: 'Payer maintenant',
    pending_consent_title: 'En attente de votre authentification...',
    pending_consent_message:
      'Pour continuer, cliquez sur le lien que nous venons de vous envoyer par SMS.',
    success_consent_title: 'Virement confirmé',
    failed_consent_title: 'Virement annulé',
    failed_consent_message: 'Vous avez annulé ce virement.',
    amount_label: 'Montant',
    beneficiary_name_label: 'Nom et prénom du bénéficiaire',
    beneficiary_label_label: 'Libellé personnalisé (optionnel)',
    beneficiary_iban_label: 'IBAN',
    transaction_label_invalid_character_error_message:
      'Seuls les chiffres, lettres sans accent, -, _, ., + et ? sont autorisés.',
    iban_error_message: 'Cet IBAN est incorrect.',
    iban_already_in_use_error:
      'Cet IBAN est déjà utilisé pour un bénéficiaire enregistré.',
    self_iban_error_message:
      "Il s'agit du compte depuis lequel vous souhaitez effectuer un virement.",
    waiting_consent_message: 'En attente de votre authentification...',
    general_error_message: 'Une erreur est survenue.',
    general_success_message: 'Votre virement a été effectué !',
    save_beneficiary_field: 'Enregistrer ce bénéficiaire',
    save_beneficiary_error_same_values:
      'Il existe déjà un bénéficiaire enregistré avec cet IBAN et ce nom.',
    save_beneficiary_error_same_iban:
      'Il existe déjà un bénéficiaire enregistré avec cet IBAN.',
    save_beneficiary_error_same_name:
      'Il existe déjà un bénéficiaire enregistré avec ce nom.',
    instant_transfer: 'Virement instantané (0,40 € HT)',
  },
};
