import { AccountingPlanName } from '@legalplace/shared';

import { AccountingFeatures } from '../../../constants/accountingFeatures.constants';

export default {
  title: 'Programmez votre rendez-vous avec un {{callInterlocutor}}',
  subtitle:
    'Ce rendez-vous est obligatoire pour paramétrer votre compte impots.gouv, mettre en place vos déclarations comptables et vous donner tous nos conseils sur comment gérer au mieux votre comptabilité.',
  scheduled_meeting: 'Votre rendez vous est programmé !',
  meeting_date_with_info:
    'Rendez-vous le {{meetingDate}} à {{meetingTime}} pour rencontrer votre {{callInterlocutor}}.',
  meeting_date_without_info:
    "Rendez-vous dans votre boite mail pour retrouver la date et l'heure de votre rencontre avec votre {{callInterlocutor}}.",
  link_label_with_info:
    "Vous retrouverez un lien d'accès à votre rendez-vous ici à l'heure du rendez-vous.",
  link_label_without_info:
    "Vous retrouverez un lien d'accès à votre rendez-vous dans le mail de confirmation que vous avez reçu.",
  access_meeting_button_label: 'Accéder à mon rendez-vous',
  show_offers_button_label: 'Voir nos offres',
  pricing_table: {
    pricing_title_label:
      'Choisissez une offre et parlez-en à votre {{callInterlocutor}}',
    pricing_subtitle_label:
      'La sélection de votre offre est facultative et sans engagement à ce stade, votre choix aidera votre {{callInterlocutor}} à préparer votre rendez-vous',
    annual_subscription_label: 'Annuel (2 mois offerts)',
    monthly_subscription_label: 'Mensuel',
    popular_label: 'Populaire',
    column_details: {
      [AccountingPlanName.STARTER]: 'Le minimum pour lancer votre activité',
      [AccountingPlanName.ESSENTIAL]:
        ' Toutes vos obligations avec un comptable dédié',
      [AccountingPlanName.ELITE]:
        'Ne pensez plus à la compta, laissez-nous faire',
    },
    selected_offer: 'Offre sélectionnée',
    select: 'Sélectionner',
    currency: '€',
    from: 'dès',
    excluding_taxes: 'HT /mois',
    billed_annually: 'Paiement annuel',
    [AccountingPlanName.STARTER]: 'Starter',
    [AccountingPlanName.ESSENTIAL]: 'Essentiel',
    [AccountingPlanName.ELITE]: 'Elite',
    pricing_feature: {
      [AccountingFeatures.INTUITIVE_ACCOUNTING_SOFTWARE]:
        'Logiciel et application comptable intuitifs',
      [AccountingFeatures.BILLING_MODULE]: 'Module de facturation',
      [AccountingFeatures.VAT_DECLARATION]: 'Déclaration de TVA',
      [AccountingFeatures.CHAT_ADVICE]: 'Conseil comptable par chat',
      [AccountingFeatures.ACCOUNTING_BALANCE_SHEET_AND_TAX]:
        'Bilan comptable et liasse fiscale',
      [AccountingFeatures.DEDICATED_ACCOUNTANT]: 'Comptable dédié',
      [AccountingFeatures.VIDEO_OR_PHONE_SUPPORT]:
        'Accompagnement par téléphone ou visio',
      [AccountingFeatures.PRIORITY_BALANCE_SHEET]:
        'Bilan comptable prioritaire',
      [AccountingFeatures.MULTI_USERS_ACCESS]: 'Accès multi-users',
      [AccountingFeatures.ANNUAL_LEGAL_OBLIGATIONS]:
        'Obligations juridiques annuelles',
      [AccountingFeatures.TAX_AUDIT_SUPPORT]:
        'Accompagné en cas de contrôle fiscal',
    },
    update_modal: {
      title: 'Modifier mon offre',
      pricing_table_title:
        "Vous souhaitez changer d’offre ? Votre contrat s'adapte automatiquement !",
      pricing_table_subtitle:
        'Votre {{callInterlocutor}} vous a recommandé l’offre qui correspond le mieux aux besoins de votre entreprise, veillez à être certain que votre nouveau choix répond toujours à vos exigences.',
      call_interlocutor: {
        advisor: 'conseiller',
        accountant: 'comptable',
      },
    },
  },
  call_interlocutor: {
    advisor: 'conseiller',
    accountant: 'comptable',
  },
};
