import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { DrawerV2, useDocumentEvent } from '@legalplace/storybook';

import { useCurrentDeviceBreakpoint } from '../../../hooks/useCurrentDeviceBreakpoint';

import { AcademyModalBody } from './AcademyModalBody/AcademyModalBody';
import { AcademyModalFooter } from './AcademyModalFooter/AcademyModalFooter';
import { AcademyModalLearnMore } from './AcademyModalLearnMore/AcademyModalLearnMore';
import { DesktopAcademyModal } from './DesktopAcademyModal/DesktopAcademyModal';

import {
  AcademyDesktopContentContainer,
  AcademyMobileContentContainer,
  AcademyMobileFooterContainer,
} from './AcademyModal.style';

interface IAcademyModalProps {
  isOpen: boolean;
  onClose: () => void;
  openEndMessage: () => void;
}

export function AcademyModal({
  isOpen,
  onClose,
  openEndMessage,
}: IAcademyModalProps): JSX.Element | null {
  const { t } = useTranslation('academy', {
    keyPrefix: 'modal',
  });
  const { isDesktop } = useCurrentDeviceBreakpoint();
  const handleCloseOnEscape = (event: KeyboardEvent | PointerEvent): void => {
    if (event instanceof KeyboardEvent && event.key === 'Escape') {
      onClose?.();
    }
  };
  useDocumentEvent([{ type: 'keydown', callback: handleCloseOnEscape }]);
  const contentRef = useRef<HTMLInputElement>(null);
  const [contentHeight, setContentHeight] = useState(0);
  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [contentRef]);

  if (isDesktop) {
    return createPortal(
      <DesktopAcademyModal onClose={onClose} title={t('title')} isOpen={isOpen}>
        <AcademyDesktopContentContainer>
          <AcademyModalBody openEndMessage={openEndMessage} />
        </AcademyDesktopContentContainer>
        <AcademyModalLearnMore isDesktop onClose={onClose} />
        <AcademyModalFooter onClose={onClose} />
      </DesktopAcademyModal>,
      document.body
    );
  }

  return (
    <DrawerV2
      isOpen={isOpen}
      onClose={onClose}
      title={t('title')}
      height={{ default: '90vh' }}
      onBackdropClick={onClose}
    >
      <AcademyMobileContentContainer
        ref={contentRef}
        contentHeight={contentHeight}
      >
        <AcademyModalBody openEndMessage={openEndMessage} />
      </AcademyMobileContentContainer>
      <AcademyMobileFooterContainer>
        <AcademyModalLearnMore onClose={onClose} />
        <AcademyModalFooter onClose={onClose} />
      </AcademyMobileFooterContainer>
    </DrawerV2>
  );
}
