import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  FlexContainerBasicColumn,
  FlexContainerFullWidth,
  Typography,
} from '@legalplace/storybook';

import { useAppDispatch } from '../../hooks/store';
import { setActiveCompanyId } from '../../store/companies/actions';

import { BannerContentContainer } from './NoConnectionBanner.style';

export function NoConnectionBanner(): JSX.Element | null {
  const { t } = useTranslation('no_connection_banner');
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { companyId } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleOnline = (): void => {
      if (!isOnline && companyId) {
        dispatch(setActiveCompanyId(companyId));
      }
      setIsOnline(true);
    };

    const handleOffline = (): void => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [companyId, dispatch, isOnline]);

  if (isOnline) {
    return null;
  }

  return (
    <FlexContainerFullWidth
      internalspacing={{
        smallMedium: '0',
        large: '1.6rem 1.6rem 0rem 1.6rem',
      }}
    >
      <BannerContentContainer>
        <FlexContainerBasicColumn gap={{ default: '0.8rem' }}>
          <Typography tag="p2" color="white" fontWeight="600">
            {t('title')}
          </Typography>
          <Typography tag="p3" color="white">
            {t('message')}
          </Typography>
        </FlexContainerBasicColumn>
      </BannerContentContainer>
    </FlexContainerFullWidth>
  );
}
