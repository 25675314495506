import {
  FlexAlignCenterJustifySpaceBetween,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { CloseButton, ToastButton } from './Toast.style';

export function ToastWithButton({
  message,
  buttonLabel,
  buttonCallback,
  closeToast,
}: {
  message: string;
  buttonLabel: string;
  buttonCallback: () => void;
  closeToast?: () => void;
}): JSX.Element {
  return (
    <FlexAlignCenterJustifySpaceBetween>
      <Typography tag="p3" textAlign="center">
        {message}
        <ToastButton onClick={buttonCallback}>{buttonLabel}</ToastButton>
      </Typography>
      <CloseButton onClick={closeToast}>
        <StreamLineIcon icon={StreamLineIconList.close} />
      </CloseButton>
    </FlexAlignCenterJustifySpaceBetween>
  );
}
