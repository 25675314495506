import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import type { GetMeetingInfoResponseDto } from '@legalplace/bankroot-api/modules/calendly/dto';
import {
  AccountingOnboardingSource,
  OnboardingCallInterlocutor,
  OnboardingCallStatus,
  STARTING_ACCOUNTING_PRICE_BY_PLAN_AND_PERIODICITY,
} from '@legalplace/shared';
import {
  BankIconLoader,
  Button,
  FlexColumnCenterFullWidth,
  FlexContainerBasicColumnAlignCenter,
  Image,
  ItemSize,
  Typography,
} from '@legalplace/storybook';

import calendarNotificationsPen from '../../../../assets/images/calendarNotificationsPen.svg';
import { useAppSelector } from '../../../../hooks/store';
import { useCurrentDeviceBreakpoint } from '../../../../hooks/useCurrentDeviceBreakpoint';
import {
  getCalendlyCodeRequest,
  getCalendlyMeetingInfo,
} from '../../../../services/api/calendly.api';
import { getDateTime, getNumericDate } from '../../../../services/utils';
import { selectActiveCompany } from '../../../../store/companies/selectors';
import { useGetFeatureFlagsQuery } from '../../../../store/featureFlags/reducer';
import { selectAccountingOnboardingInformation } from '../../../../store/onboardingSteps/selector';
import { selectUserInformations } from '../../../../store/user/selector';
import {
  formatCalendlyUrl,
  isCalendlyScheduledEvent,
} from '../../../calendlyIframe/calendlyIframe.helper';

import { PricingTable } from './pricingTable/PricingTable';

import { PrimaryButton } from '../../../UI/PrimaryButton.style';
import {
  OnboardingCallContainer,
  OnboardingCallIframe,
} from '../../OnboardingV2Steps.style';

export function OnboardingCall({
  notifySuccess,
}: {
  notifySuccess: () => void;
}): JSX.Element | null {
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'onboarding_call',
  });
  const { companyId } = useParams();
  const { data: featureFlags } = useGetFeatureFlagsQuery(companyId, {
    skip: !companyId,
  });
  const activeCompany = useAppSelector(selectActiveCompany);
  const { isMobile } = useCurrentDeviceBreakpoint();
  const userInformation = useAppSelector(selectUserInformations);
  const onboardingInformation = useAppSelector(
    selectAccountingOnboardingInformation
  );
  const [calendlyCompanyCode, setCalendlyCompanyCode] = useState<
    string | undefined
  >();
  const [meetingInformation, setMeetingInformation] = useState<
    GetMeetingInfoResponseDto | undefined
  >();
  const [isMeetingInformationLoading, setIsMeetingInformationLoading] =
    useState(false);
  const [hasMeetingBeenBooked, setHasMeetingBeenBooked] = useState(
    activeCompany?.onboardingCallStatus === OnboardingCallStatus.BOOKED
  );
  const ref = useRef<HTMLDivElement>(null);

  const userFullName = `${userInformation?.firstName} ${userInformation?.lastName}`;
  const isMeetingLinkAvailable = !!meetingInformation?.meetingLink;
  const hasMeetingPassed = meetingInformation?.date
    ? new Date(meetingInformation?.date) < new Date()
    : false;

  useEffect(() => {
    const fetchCalendlyInformations = async (): Promise<void> => {
      if (!activeCompany?.id) {
        return;
      }

      try {
        const { data: code } = await getCalendlyCodeRequest(activeCompany.id);
        setCalendlyCompanyCode(code);
        setIsMeetingInformationLoading(true);
        const {
          data: { date, meetingLink },
        } = await getCalendlyMeetingInfo(activeCompany?.id);
        setMeetingInformation({ date, meetingLink });
      } catch (error: unknown) {
        console.error(
          `[fetchCalendlyInformations] - Error trying to get calendly informations`,
          error
        );
      } finally {
        setIsMeetingInformationLoading(false);
      }

      if (activeCompany.onboardingCallStatus === OnboardingCallStatus.DONE) {
        notifySuccess();
      }

      if (
        hasMeetingPassed &&
        activeCompany.onboardingCallStatus !== OnboardingCallStatus.TODO
      ) {
        notifySuccess();
      }
    };
    fetchCalendlyInformations();
  }, [
    activeCompany?.id,
    activeCompany?.onboardingCallStatus,
    hasMeetingPassed,
    notifySuccess,
    hasMeetingBeenBooked,
  ]);

  useEffect(() => {
    const handleCalendlyEvent = async (event: MessageEvent): Promise<void> => {
      if (!isCalendlyScheduledEvent(event) || !activeCompany?.id) {
        return;
      }

      // adding delay to wait for calendly webhook
      // to be processed by the backend
      setTimeout(() => {
        setHasMeetingBeenBooked(true);
      }, 5000);
    };

    window.addEventListener('message', handleCalendlyEvent);

    return () => {
      window.removeEventListener('message', handleCalendlyEvent);
    };
  }, [activeCompany?.id]);

  const scrollToPricingTable = (): void => {
    ref?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  if (!activeCompany || !userInformation?.email) {
    return null;
  }

  const isMeetingMissed =
    hasMeetingPassed &&
    activeCompany.onboardingCallStatus === OnboardingCallStatus.TODO;

  const shouldShowMeetingConfirmation =
    (!!meetingInformation?.date && !isMeetingMissed) || hasMeetingBeenBooked;

  const isNewOfferFlagActivated =
    !!featureFlags?.ACCOUNTING_OFFER_SELECTION_FLAG;

  if (isMeetingInformationLoading || !calendlyCompanyCode) {
    return <BankIconLoader />;
  }

  const onboardingCallInterlocutor =
    onboardingInformation?.source === AccountingOnboardingSource.CreaPlusCompta
      ? OnboardingCallInterlocutor.Accountant
      : OnboardingCallInterlocutor.Advisor;

  if (shouldShowMeetingConfirmation) {
    return (
      <OnboardingCallContainer>
        <FlexColumnCenterFullWidth
          internalspacing={{ default: '8rem 0' }}
          style={{ height: isMobile ? '100%' : 'unset' }}
        >
          <Image src={calendarNotificationsPen} alt="Calendar" />
          <FlexContainerBasicColumnAlignCenter gap={{ default: '0.8rem' }}>
            <Typography
              tag="p"
              fontWeight="500"
              color="primary900"
              textAlign="center"
            >
              {t('scheduled_meeting')}
            </Typography>
            <Typography
              tag="p3"
              fontWeight="400"
              color="primary900"
              textAlign="center"
              spacing={{ default: '2.4rem' }}
            >
              {meetingInformation?.date
                ? t('meeting_date_with_info', {
                    meetingDate: getNumericDate(meetingInformation.date),
                    meetingTime: getDateTime(meetingInformation.date),
                    callInterlocutor: t(
                      `call_interlocutor.${onboardingCallInterlocutor}`
                    ),
                  })
                : t('meeting_date_without_info', {
                    callInterlocutor: t(
                      `call_interlocutor.${onboardingCallInterlocutor}`
                    ),
                  })}
            </Typography>
          </FlexContainerBasicColumnAlignCenter>
          <Typography
            tag="p3"
            fontWeight="400"
            color="grey500"
            textAlign="center"
            spacing={{ default: '2.4rem' }}
          >
            {isMeetingLinkAvailable
              ? t('link_label_with_info')
              : t('link_label_without_info')}
          </Typography>
          <PrimaryButton
            width="fit-content"
            size={ItemSize.regular}
            internalSpacing={{ default: '1.4rem' }}
            disabled={!isMeetingLinkAvailable}
            forwardedAs={isMeetingLinkAvailable ? 'a' : undefined}
            rel="noopener noreferrer"
            target="_blank"
            href={meetingInformation?.meetingLink}
          >
            {t('access_meeting_button_label')}
          </PrimaryButton>
          {isNewOfferFlagActivated && isMobile && (
            <Button styleType="tertiary" onClick={scrollToPricingTable}>
              <Typography
                tag="p3"
                color="grey700"
                textAlign="center"
                fontWeight="500"
                spacing={{ default: '2.4rem 0' }}
              >
                {t('show_offers_button_label')}
              </Typography>
            </Button>
          )}
        </FlexColumnCenterFullWidth>

        {isNewOfferFlagActivated && (
          <FlexContainerBasicColumnAlignCenter>
            <PricingTable
              ref={ref}
              title={t('pricing_table.pricing_title_label', {
                callInterlocutor: t(
                  `call_interlocutor.${onboardingCallInterlocutor}`
                ),
              })}
              subtitle={t('pricing_table.pricing_subtitle_label', {
                callInterlocutor: t(
                  `call_interlocutor.${onboardingCallInterlocutor}`
                ),
              })}
              accountingSubscriptionPriceByPlanAndPeriodicity={
                STARTING_ACCOUNTING_PRICE_BY_PLAN_AND_PERIODICITY
              }
            />
          </FlexContainerBasicColumnAlignCenter>
        )}
      </OnboardingCallContainer>
    );
  }

  return (
    <OnboardingCallContainer>
      <OnboardingCallIframe
        title="calendly-compta"
        src={formatCalendlyUrl({
          fullName: userFullName,
          email: userInformation?.email,
          companyName: activeCompany?.name,
          siren: activeCompany?.siren,
          phoneNumber: userInformation?.phoneNumber,
          companyCode: calendlyCompanyCode ?? '',
          source: onboardingInformation?.source,
        })}
        frameBorder="0"
        height="100%"
        width="100%"
      />
    </OnboardingCallContainer>
  );
}
