import accounting_trial_end_banner from './accountingTrialEndBanner';
import card_delivery from './cardDelivery';
import contract_signature from './contractSignature';
import credentials_creation from './credentialsCreation';
import onboarding_call from './onboardingCall';
import taxes_credentials from './taxesCredentials';

export const onboardingV2 = {
  credentials_creation,
  onboarding_call,
  taxes_credentials,
  contract_signature,
  card_delivery,
  accounting_trial_end_banner,
};
