import type { FormEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FlexColumnFullWidth,
  FlexContainerBasicColumn,
  Separator,
  Typography,
} from '@legalplace/storybook';

import { useAppSelector } from '../../../../../hooks/store';
import type {
  IRibForm,
  IRibFormError,
  IRibFormState,
} from '../../../../../interfaces/forms/rib.interface';
import { sendRibInformationsRequest } from '../../../../../services/api/onboardingFlow.api';
import { selectAccountsById } from '../../../../../store/accounts/selectors';
import { selectActiveCompanyId } from '../../../../../store/companies/selectors';

import {
  formatRibInformationDto,
  selectFirstAccountWithFrenchIban,
  validateForm,
} from './AccountSelection.helper';
import { AccountSelectionForm } from './AccountSelectionForm';

import {
  CheckboxContainer,
  IbanSelectionForm,
  SubmitButton,
} from './AccountSelection.style';
import { RibSelectionContainer } from './NoAccount.style';

interface IAccountSelectionProps {
  notifySuccess: () => void;
}

export function AccountSelection({
  notifySuccess,
}: IAccountSelectionProps): JSX.Element {
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'contract_signature.rib_selection.account_selection',
  });
  const activeCompanyId = useAppSelector(selectActiveCompanyId);
  const accountsById = useAppSelector(selectAccountsById);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDifferentSubscriptionRib, setIsDifferentSubscriptionRib] =
    useState(false);

  const firstAccountWithFrenchIban = selectFirstAccountWithFrenchIban(
    Object.values(accountsById)
  );

  const [isTaxRibLoading, setIsTaxRibLoading] = useState(false);
  const [taxRibFormValues, setTaxRibFormValues] = useState<IRibForm>({
    accountId: firstAccountWithFrenchIban,
    iban: firstAccountWithFrenchIban
      ? accountsById[firstAccountWithFrenchIban].iban
      : undefined,
  });
  const [taxRibFormErrors, setTaxRibFormErrors] = useState<IRibFormError>({});
  const taxRibFormState: IRibFormState = {
    values: taxRibFormValues,
    setValues: setTaxRibFormValues,
    errors: taxRibFormErrors,
    setErrors: setTaxRibFormErrors,
    isLoading: isTaxRibLoading,
    setIsLoading: setIsTaxRibLoading,
  };

  const [isSubscriptionRibLoading, setIsSubscriptionRibLoading] =
    useState(false);
  const [subscriptionRibFormValues, setSubscriptionRibFormValues] =
    useState<IRibForm>({
      accountId: firstAccountWithFrenchIban,
      iban: firstAccountWithFrenchIban
        ? accountsById[firstAccountWithFrenchIban].iban
        : undefined,
    });
  const [subscriptionRibFormErrors, setSubscriptionRibFormErrors] =
    useState<IRibFormError>({});
  const subscriptionRibFormState: IRibFormState = {
    values: subscriptionRibFormValues,
    setValues: setSubscriptionRibFormValues,
    errors: subscriptionRibFormErrors,
    setErrors: setSubscriptionRibFormErrors,
    isLoading: isSubscriptionRibLoading,
    setIsLoading: setIsSubscriptionRibLoading,
  };

  const isLoading = isTaxRibLoading || isSubscriptionRibLoading;

  const handleOnSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    if (!activeCompanyId) {
      return;
    }

    try {
      setIsSubmitting(true);

      const hasTaxRibError = await validateForm(taxRibFormState, t);
      const hasSubscriptionRibError =
        isDifferentSubscriptionRib &&
        (await validateForm(subscriptionRibFormState, t));

      const ribInformationDto = formatRibInformationDto(
        activeCompanyId,
        taxRibFormValues,
        isDifferentSubscriptionRib
          ? subscriptionRibFormValues
          : taxRibFormValues
      );

      if (hasTaxRibError || hasSubscriptionRibError) {
        console.error(
          `Rib Selection Form has errors with values: ${JSON.stringify(
            ribInformationDto
          )}`
        );
        return;
      }

      await sendRibInformationsRequest(activeCompanyId, ribInformationDto);

      notifySuccess();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCheckboxChange = (): void => {
    setIsDifferentSubscriptionRib(!isDifferentSubscriptionRib);
    setSubscriptionRibFormErrors({});

    if (isDifferentSubscriptionRib) {
      setSubscriptionRibFormValues({});
      return;
    }

    setSubscriptionRibFormValues({
      accountId: selectFirstAccountWithFrenchIban(Object.values(accountsById)),
    });
  };

  return (
    <RibSelectionContainer>
      <FlexContainerBasicColumn gap={{ default: '0.8rem' }}>
        <Typography
          tag="p"
          color="primary900"
          lineHeight={{ default: '2rem' }}
          fontWeight="500"
        >
          {t('title')}
        </Typography>
        <Typography
          tag="p4"
          color="primary900"
          lineHeight={{ default: '2rem' }}
          fontWeight="400"
        >
          {t('message')}
        </Typography>
      </FlexContainerBasicColumn>
      <IbanSelectionForm onSubmit={handleOnSubmit}>
        <FlexColumnFullWidth gap={{ default: '1.6rem' }}>
          <AccountSelectionForm
            ibanDropdownLabel={t('account_tax_dropdown_label')}
            formState={taxRibFormState}
            isSubmitting={isSubmitting}
          />
          <CheckboxContainer>
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              checked={isDifferentSubscriptionRib}
              disabled={isSubmitting}
            />
            <Typography
              tag="p4"
              color="grey700"
              lineHeight={{ default: '2rem' }}
              fontWeight="400"
            >
              {t('checkbox_label')}
            </Typography>
          </CheckboxContainer>
        </FlexColumnFullWidth>
        {isDifferentSubscriptionRib && (
          <>
            <Separator />
            <FlexColumnFullWidth gap={{ default: '1.6rem' }}>
              <AccountSelectionForm
                ibanDropdownLabel={t('account_subscription_dropdown_label')}
                formState={subscriptionRibFormState}
                isSubmitting={isSubmitting}
              />
            </FlexColumnFullWidth>
          </>
        )}
        <SubmitButton
          styleType="primary"
          type="submit"
          isLoading={isLoading || isSubmitting}
          disabled={isLoading || isSubmitting}
          height={{ default: '3.6rem' }}
        >
          <Typography
            tag="p4"
            color="white"
            lineHeight={{ default: '1.6rem' }}
            fontWeight="400"
          >
            {t('button_label')}
          </Typography>
        </SubmitButton>
      </IbanSelectionForm>
    </RibSelectionContainer>
  );
}
