import { BridgeAccountTypes } from '@legalplace/shared';

export const accounts = {
  page_title: 'Comptes',
  back_button: 'Retour',
  modal: {
    resync_account: {
      title: 'Compte désynchronisé',
      buttons: {
        resync_btn_text: 'Resynchroniser',
        close_btn_text: 'Fermer',
      },
      default_message:
        'Nous rencontrons un problème de synchronisation avec votre banque. Veuillez cliquer sur le bouton "Resynchroniser mon compte" ou vérifier vos identifiants auprès de votre banque.',
    },
  },
  cards: {
    types: {
      [BridgeAccountTypes.CHECKING]: 'Compte courant',
      [BridgeAccountTypes.SAVINGS]: 'Compte d’épargne',
      [BridgeAccountTypes.BROKERAGE]: 'Compte de courtage',
      [BridgeAccountTypes.CARD]: 'Compte carte',
      [BridgeAccountTypes.LOAN]: 'Compte de prêt',
      [BridgeAccountTypes.FRENCH_PEA]: 'Compte PEA',
      [BridgeAccountTypes.PENDING]: 'Compte d’attente',
      [BridgeAccountTypes.LIFE_INSURANCE]: 'Assurance vie',
      [BridgeAccountTypes.SPECIAL]: 'Compte spécial',
      [BridgeAccountTypes.UNKNOWN]: 'Compte bancaire',
    },
    regular: {
      button_label: 'Voir détails',
    },
    unavailable: {
      content: 'Indisponible',
      synchronization_issue: 'Compte désynchronisé',
      button_label: 'Résoudre',
    },
    archived: {
      content: 'Compte archivé',
    },
    account_synchronisation_pending: 'En cours de synchronisation',
  },
};
