import { TaxSystem, VatPlan } from '@legalplace/shared';

export default {
  title: ' Informations de comptabilité',
  subtitle: 'Donnez-nous les détails comptables sur votre société.',
  tax_system_dropdown_label: 'Régime fiscal',
  tax_system_tooltip_message:
    'Cette information est disponible dans les statuts de votre entreprise.',
  tax_system_dropdown_placeholder: 'IS (impôts sur les sociétés)',
  vat_plan_dropdown_label: 'Régime de TVA',
  vat_plan_tooltip_message:
    'Cette information est disponible dans les statuts de votre entreprise.',
  vat_plan_dropdown_placeholder: 'Annuel',
  activity_start_date_picker_label: "Date de début d'activité",
  activity_start_date_picker_placeholder: '30/10/2015',
  first_accounting_closure_date_picker_label: 'Première clôture',
  first_accounting_closure_date_picker_placeholder: '01/04/2022',
  accounting_closing_month_dropdown_label: 'Mois de clôture',
  accounting_closing_month_dropdown_placeholder: 'Décembre',
  back_button_label: 'Retour',
  next_button_label: 'Continuer',
  vat_plan_option_label: {
    [VatPlan.MENSUEL]: 'Mensuel',
    [VatPlan.TRIMESTRIEL]: 'Trimestriel',
    [VatPlan.ANNUEL]: 'Annuel',
    [VatPlan.EXONERE]: 'Exonéré',
  },
  tax_system_option_label: {
    [TaxSystem.IS]: 'IS (impôts sur les sociétés)',
    [TaxSystem.BNC]: 'BNC (bénéfices non commerciaux)',
    [TaxSystem.BIC]: 'BIC (bénéfices industriels et commerciaux)',
    [TaxSystem.RF]: 'RF (revenu foncier)',
  },
  mandatory_field_error: 'Veuillez remplir ce champ.',
  first_accounting_closure_date_error:
    "La date de clôture doit être postérieure à la date de début d'activité.",
  month: {
    January: 'Janvier',
    February: 'Février',
    March: 'Mars',
    April: 'Avril',
    May: 'Mai',
    June: 'Juin',
    July: 'Juillet',
    August: 'Août',
    September: 'Septembre',
    October: 'Octobre',
    November: 'Novembre',
    December: 'Décembre',
  },
};
