import type { AxiosResponse } from 'axios';

import type { GetAssociateBalancesDTO } from '@legalplace/bankroot-api/modules/categorizableItem/dto';

import { api } from './api';

export const getPCABalancesRequest = (
  companyId: string
): Promise<AxiosResponse<GetAssociateBalancesDTO>> =>
  api.get(`/categorizable-item/balances?companyId=${companyId}`);
