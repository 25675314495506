import { ActivityType } from '@legalplace/shared';

export default {
  title: 'Votre entreprise',
  subtitle: 'Donnez-nous les détails sur votre société.',
  company_name_label: 'Dénomination sociale',
  activity_type_option_label: {
    [ActivityType.SERVICE]: 'Service',
    [ActivityType.ECOMMERCE]: 'E-commerce',
    [ActivityType.COMMERCE]: 'Commerce',
    [ActivityType.HCR]: 'Hôtel - Café - Restaurant',
    [ActivityType.BTP]: 'BTP',
    [ActivityType.IMMOBILIER]: 'Immobilier',
    [ActivityType.TAXI_VTC]: 'Taxi - VTC',
    [ActivityType.ARTISAN]: 'Artisan',
    [ActivityType.CONSULTANT]: 'Consultant',
    [ActivityType.TRANSPORT_ROUTIER]: 'Transport routier',
    [ActivityType.AUTRES]: 'Autres',
  },
  company_name_placeholder: 'Librairie',
  last_name_placeholder: 'Dupont',
  capital_label: 'Capital social en €',
  capital_placeholder: '1000',
  siren_label: 'SIREN',
  siren_tooltip_message:
    'Un SIREN existant est obligatoire, il est obtenu après votre immatriculation et visible sur votre KBIS.',
  siren_placeholder: '000 000 000',
  siren_length_error: 'Le SIREN doit contenir 9 chiffres.',
  invalid_siren_error: 'Le SIREN est invalide.',
  zip_code_length_error: 'Le code postal doit contenir 5 chiffres.',
  legal_status_label: "Forme de l'entreprise",
  activity_type_label: "Type d'activité",
  legal_status_placeholder: 'SARL',
  activity_type_placeholder: 'Service',
  address_label: 'Adresse',
  address_placeholder: '60 rue François Premier',
  zip_code_label: 'Code postal',
  zip_code_placeholder: '75008',
  city_label: 'Ville',
  city_placeholder: 'Paris',
  empty_field_error: 'Veuillez remplir ce champ.',
  back_button_label: 'Retour',
  next_button_label: 'Continuer',
};
