import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@legalplace/storybook';

import { ampli } from '../../../../ampli/api';
import { useAppSelector } from '../../../../hooks/store';
import { useUpdateAcademyStatusToSkippedMutation } from '../../../../store/academy/reducer';
import { selectActiveCompany } from '../../../../store/companies/selectors';

import {
  ButtonContainer,
  CancelButton,
  ConfirmButton,
} from './AcademyModalFooter.style';

interface IAcademyModalFooterProps {
  onClose: () => void;
}

export function AcademyModalFooter({
  onClose,
}: IAcademyModalFooterProps): JSX.Element {
  const { t } = useTranslation('academy', {
    keyPrefix: 'modal.footer',
  });
  const [shouldConfirmDoNotShowAgain, setShouldConfirmDoNotShowAgain] =
    useState(false);

  const activeCompany = useAppSelector(selectActiveCompany);
  const [updateAcademyStatusToSkipped] =
    useUpdateAcademyStatusToSkippedMutation();

  const handleConfirmDoNotShowAgain = (): void => {
    try {
      if (activeCompany?.id) {
        updateAcademyStatusToSkipped(activeCompany.id);
        ampli.academyDismissed();
      }
      onClose();
    } catch (error: unknown) {
      console.error(
        `[handleConfirmDoNotShowAgain] - Error while updating academy status to skipped for company ${activeCompany?.id}`,
        error
      );
    }
  };

  return (
    <ButtonContainer>
      {shouldConfirmDoNotShowAgain ? (
        <>
          <ConfirmButton onClick={handleConfirmDoNotShowAgain}>
            <Typography tag="p5" color="danger500" fontWeight="500">
              {t('confirm_do_not_show')}
            </Typography>
          </ConfirmButton>
          <CancelButton onClick={() => setShouldConfirmDoNotShowAgain(false)}>
            <Typography tag="p5" color="primary500" fontWeight="500">
              {t('cancel')}
            </Typography>
          </CancelButton>
        </>
      ) : (
        <Button onClick={() => setShouldConfirmDoNotShowAgain(true)}>
          <Typography tag="p5" color="primary900" fontWeight="500">
            {t('do_not_show_again')}
          </Typography>
        </Button>
      )}
    </ButtonContainer>
  );
}
