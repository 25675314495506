import type { GetCategorizableItemBalanceDto } from '@legalplace/bankroot-api/modules/categorizableItem/dto';
import type { FindCompanyDto } from '@legalplace/bankroot-api/modules/company/dto';
import type { GetDashboardWidgetDto } from '@legalplace/bankroot-api/modules/userCompany/dto';
import type { RightType, TaxSystem } from '@legalplace/shared';
import { createSelector } from '@reduxjs/toolkit';

import { Status } from '../../constants';
import type { RootState } from '../../store';

export const selectFallbackCompanyId = (state: RootState): string | undefined =>
  state.companies.allIds && state.companies.allIds[0];

export const selectActiveCompany = (
  state: RootState
): FindCompanyDto | undefined => {
  const {
    activeCompany: { id: activeCompanyId },
  } = state.companies;
  if (activeCompanyId && state.companies.byId[activeCompanyId]) {
    return state.companies.byId[activeCompanyId];
  }
  return undefined;
};

export const selectActiveUserCompanyId = (
  state: RootState
): string | undefined => {
  const activeCompany = selectActiveCompany(state);

  if (activeCompany && activeCompany.userCompanies?.length === 1) {
    return activeCompany.userCompanies[0].id;
  }

  return undefined;
};

export const selectUserCompanyHasRight =
  (rightType: RightType) =>
  (state: RootState): boolean =>
    state.companies.activeCompany.rights?.some(
      (right) => right === rightType
    ) || false;

export const selectActiveCompanyId = (state: RootState): string | undefined => {
  const {
    activeCompany: { id: activeCompanyId },
  } = state.companies;
  return activeCompanyId;
};

export const selectActiveCompanyPcaBalance = (
  state: RootState
): number | undefined =>
  state.companies.activeCompany.pcaBalances?.reduce(
    (totalBalance, { balance }) => balance + totalBalance,
    0
  );

export const selectActiveCompanyPcaBalances = (
  state: RootState
): GetCategorizableItemBalanceDto[] | undefined =>
  state.companies.activeCompany.pcaBalances;

export const selectActiveCompanyVatBalances = (
  state: RootState
): {
  vatBalance?: number;
  collectedVat?: number;
  deductableVat?: number;
  paymentVat?: number;
  creditedVat?: number;
  vatBalanceAtStart?: number;
  vatReferenceDate?: string;
} => state.companies.activeCompany;

export const selectCompaniesById = (
  state: RootState
): Record<string, FindCompanyDto> => state.companies.byId;

export const selectSwanCompaniesById = (
  state: RootState
): Record<string, FindCompanyDto> =>
  Object.values(state.companies.byId).reduce((acc, company) => {
    if (company.featureMap?.BankBase?.isActive) {
      return { ...acc, [company.id]: company };
    }
    return acc;
  }, {} as Record<string, FindCompanyDto>);

export const selectNonJustifiedTransactionsCount = (
  state: RootState
): number | undefined =>
  state.companies.activeCompany.nonJustifiedTransactionsCount;

export const selectNonCategorizedTransactionsCount = (
  state: RootState
): number | undefined =>
  state.companies.activeCompany.nonCategorizedTransactionsCount;

export const selectTransactionsMissingProofCount = (
  state: RootState
): number | undefined =>
  state.companies.activeCompany.transactionsMissingProofCount;

export const selectPcaBalanceLoading = (state: RootState): boolean =>
  state.companies.pcaBalanceStatus === Status.LOADING;

export const selectAreVatBalancesLoading = (state: RootState): boolean =>
  state.companies.vatBalanceStatus === Status.LOADING;

export const selectAreVatBalancesFetchedWithSuccess = (
  state: RootState
): boolean => state.companies.vatBalanceStatus === Status.SUCCESS;

export const selectHasCompanyWithBankSubscription = createSelector(
  (state: RootState) => state.companies.byId,
  (companiesById): boolean | undefined =>
    Object.values(companiesById).some(
      (company) => company.featureMap?.BankBase?.isActive
    )
);

export const selectDashboardWidgetVisibilityStatus = (
  state: RootState
): 'idle' | 'loading' | 'failed' | 'success' =>
  state.companies.activeCompany.widgetVisibilityStatus ?? 'idle';

export const selectDashboardWidgetVisibility = (
  state: RootState
): GetDashboardWidgetDto | undefined =>
  state.companies.activeCompany.widgetVisibility;

export const selectTaxSystem = (state: RootState): TaxSystem | undefined => {
  const activeCompany = selectActiveCompany(state);
  return activeCompany?.taxSystem;
};

export const selectLastFinishedAccountingClosureEndDate = (
  state: RootState
): Date | undefined => {
  const activeCompany = selectActiveCompany(state);
  return activeCompany?.lastFinishedAccountingClosureEndDate;
};

export const selectLastOngoingCollaboratorAccountingClosureEndDate = (
  state: RootState
): Date | undefined => {
  const activeCompany = selectActiveCompany(state);
  return activeCompany?.lastOngoingCollaboratorAccountingClosureEndDate;
};

export const selectCurrentAccountingClosureStartDate = (
  state: RootState
): Date | undefined => {
  const activeCompany = selectActiveCompany(state);
  return activeCompany?.currentAccountingClosureStartDate;
};
