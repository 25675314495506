import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import type { AccountingPlanName } from '@legalplace/shared';
import {
  FlexContainerBasicAlignCenter,
  FlexContainerBasicColumn,
  Separator,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { mapAccountingSubscriptionPlanToFeatures } from './PricingFeatures.helper';

export function PricingFeatures({
  accountingSubscriptionPlan,
}: {
  accountingSubscriptionPlan: AccountingPlanName;
}): JSX.Element {
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'onboarding_call.pricing_table',
  });

  const pricingColumns = mapAccountingSubscriptionPlanToFeatures(
    accountingSubscriptionPlan
  );

  return (
    <FlexContainerBasicColumn>
      {pricingColumns.map(({ feature: pricingFeature, isAvailable }, index) => (
        <Fragment key={pricingFeature}>
          <FlexContainerBasicAlignCenter
            gap={{ default: '1.2rem' }}
            internalspacing={{ default: '1.6rem 1.6rem' }}
          >
            {isAvailable ? (
              <StreamLineIcon
                icon={StreamLineIconList.check1}
                fillColor="success600"
                width="1.6rem"
              />
            ) : (
              <StreamLineIcon
                icon={StreamLineIconList.close}
                fillColor="danger500"
                width="2rem"
              />
            )}

            <Typography
              tag="p3"
              color={isAvailable ? 'primary900' : 'grey200'}
              textAlign="left"
            >
              {t(`pricing_feature.${pricingFeature}`)}
            </Typography>
          </FlexContainerBasicAlignCenter>
          {index !== pricingColumns.length - 1 && (
            <Separator backgroundColor="grey50" />
          )}
        </Fragment>
      ))}
    </FlexContainerBasicColumn>
  );
}
