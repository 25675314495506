import {
  PaymentMethodType,
  SALES_CHANNEL_TYPE_TO_PAYMENT_METHOD_TYPE,
} from '@legalplace/shared';

export const AUTO_GENERATED_PAYMENT_METHOD_TYPES = Object.values(
  SALES_CHANNEL_TYPE_TO_PAYMENT_METHOD_TYPE
);

export const NOT_AUTO_GENERATED_PAYMENT_METHOD_TYPES = Object.values(
  PaymentMethodType
).filter(
  (paymentMethodType) =>
    !AUTO_GENERATED_PAYMENT_METHOD_TYPES.includes(paymentMethodType)
);

export const FOOD_DELIVERY_SERVICE_PAYMENT_METHOD_TYPES = [
  PaymentMethodType.UBER_EATS,
  PaymentMethodType.DELIVEROO,
  PaymentMethodType.JUST_EAT,
  PaymentMethodType.EATIC,
  PaymentMethodType.LYVEAT,
  PaymentMethodType.TOO_GOOD_TO_GO,
];

export const VTC_PAYMENT_METHOD_TYPES = [
  PaymentMethodType.UBER,
  PaymentMethodType.BOLT,
  PaymentMethodType.HEETCH,
  PaymentMethodType.LECAB,
  PaymentMethodType.FREENOW,
  PaymentMethodType.MARCELCAB,
  PaymentMethodType.MONTRANSPORT,
  PaymentMethodType.MYSAM,
];
