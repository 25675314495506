export const addAccountingSubscription = {
  page_title: 'Essai comptabilité',
  success_toast_message: 'Votre nouvel abonnement a été pris en compte.',
  success_title_message: "C'est parti ! Merci pour votre confiance !",
  success_title_ligne_1:
    "Nous allons créer vos livres comptables et notre Intelligence Artificielle va commencer à catégoriser vos dépenses. Vous pourrez ensuite retrouver l'ensemble de vos indicateurs de pilotage dans l'onglet 'Tableau de Bord' de votre application LPPro. Cette opération peut prendre quelques minutes.",
  success_title_ligne_2:
    'Nous vous inviterons à prendre rendez-vous avec votre comptable pour faire le point sur votre activité et initier notre collaboration !',
  success_page_head_title: 'Comptabilité',
};
