import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import type { TFunction } from 'react-i18next';

import type { GetCardDto } from '@legalplace/bankroot-api/modules/card/dto';
import { SwanPhysicalCardStatus } from '@legalplace/shared';
import { BankTertiaryButton, Typography } from '@legalplace/storybook';

import { useAppDispatch, useAppSelector } from '../../../../hooks/store';
import {
  addCardRequest,
  confirmConsentRequest,
} from '../../../../services/api/card.api';
import { skipCardOrderRequest } from '../../../../services/api/onboardingFlow.api';
import { getPopup } from '../../../../services/api/popup.api';
import { showToastError } from '../../../../services/notifications/toast';
import { selectSwanAccountId } from '../../../../store/accounts/selectors';
import {
  setActiveCardId,
  updateActiveCard,
} from '../../../../store/cards/actions';
import { selectActiveCompany } from '../../../../store/companies/selectors';
import { selectUserInformations } from '../../../../store/user/selector';
import { DeliveryAddressRecap } from '../../../onboarding/CardDelivery/DeliveryAddress/DeliveryAddressRecap';

import { PrimaryButton } from '../../../UI/PrimaryButton.style';
import { CardDeliveryButtonsContainer } from './CardDelivery.style';

export function CardDeliveryValidation({
  t,
  deliveryAddressFormState,
  notifySuccess,
  setIsEditing,
}: {
  t: TFunction;
  deliveryAddressFormState: Record<string, string | number | boolean>;
  notifySuccess: () => void;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const activeCompany = useAppSelector(selectActiveCompany);
  const userInformations = useAppSelector(selectUserInformations);

  const [isCardOrderRequestPending, setIsCardOrderRequestPending] =
    useState(false);

  const swanAccountId = useAppSelector(selectSwanAccountId);

  const successConsentCallback = async (cardId: string): Promise<void> => {
    await confirmConsentRequest(cardId);
    dispatch(
      updateActiveCard({
        id: cardId,
        status: SwanPhysicalCardStatus.Processing,
        associatedUserId: userInformations?.userId,
        userFullName: `${userInformations?.firstName} ${userInformations?.lastName}`,
      } as GetCardDto)
    );
    dispatch(setActiveCardId(cardId));
    setIsCardOrderRequestPending(false);
  };

  const errorConsentCallback = async (): Promise<void> => {
    setIsCardOrderRequestPending(false);
  };

  const orderCardButtonClickHandler = async (): Promise<void> => {
    setIsCardOrderRequestPending(true);

    const consentPopup = getPopup();
    consentPopup.openPopup();

    try {
      if (!swanAccountId) {
        throw new Error('No accountId found');
      }
      if (!userInformations?.userId) {
        throw new Error('No userId found');
      }
      const { address1, address2, zipCode, city, country } =
        deliveryAddressFormState;
      const { cardId, consentUrl } = await addCardRequest({
        addressLine1: address1.toString(),
        addressLine2: address2.toString(),
        city: city.toString(),
        country: country.toString(),
        zipCode: zipCode.toString(),
        accountId: swanAccountId,
        futurUserIdAssociatedToCard: userInformations.userId,
      });
      if (consentUrl && consentPopup.isPopupOpen) {
        await consentPopup.redirectAndListenForCallback(
          consentUrl,
          async () => {
            await successConsentCallback(cardId);
          },
          errorConsentCallback
        );
      } else {
        throw new Error('No consentUrl found');
      }
      notifySuccess();
    } catch (error: unknown) {
      await consentPopup.close();
      setIsCardOrderRequestPending(false);
      errorConsentCallback();
      showToastError(t('unexpected_error'));
      console.error(
        `[orderCardHandler] - Account id or user id undefined or consentUrl not found for account id : ${swanAccountId}`,
        error
      );
      throw new Error(
        `[orderCardHandler] - Account id or user id undefined or consentUrl not found for account id : ${swanAccountId}`
      );
    }
  };

  const skipCardStep = async (): Promise<void> => {
    try {
      await skipCardOrderRequest();
      notifySuccess();
    } catch (error: unknown) {
      console.error(
        `[skipCardDeliveryStep] - Error trying to skip card delivery step for company id : ${activeCompany?.id}`,
        error
      );
    }
  };

  return (
    <>
      <DeliveryAddressRecap
        t={t}
        activeCompanyAddress={{
          addressLine1: deliveryAddressFormState.address1?.toString(),
          addressLine2: deliveryAddressFormState.address2?.toString(),
          zipCode: deliveryAddressFormState.zipCode?.toString(),
          city: deliveryAddressFormState.city?.toString(),
          country: deliveryAddressFormState.country?.toString(),
        }}
        setIsEditing={setIsEditing}
      />
      <CardDeliveryButtonsContainer>
        <BankTertiaryButton onClick={skipCardStep} width="fit-content">
          <Typography tag="p3" color="grey700" fontWeight="500">
            {t('skip_button_label')}
          </Typography>
        </BankTertiaryButton>
        <PrimaryButton
          onClick={orderCardButtonClickHandler}
          isLoading={isCardOrderRequestPending}
          disabled={isCardOrderRequestPending}
          width={{ default: '100%', medium: 'fit-content' }}
        >
          {t('order_card_button_label')}
        </PrimaryButton>
      </CardDeliveryButtonsContainer>
    </>
  );
}
