import type { AxiosResponse } from 'axios';

import type { DismissAccountingSubscriptionTrialDto } from '@legalplace/bankroot-api/modules/subscription/dto';

import { api } from './api';

export const dismissAccountingSubscriptionTrial = (
  dismissAccountingSubscriptionTrialDto: DismissAccountingSubscriptionTrialDto
): Promise<AxiosResponse<void>> =>
  api.post(
    `/subscription/accounting/trial/dismiss`,
    dismissAccountingSubscriptionTrialDto
  );
