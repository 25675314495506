import type { useEffect } from 'react';
import { createContext } from 'react';

export interface IReloadContext {
  reload: () => void;
  useReloadEffect: typeof useEffect;
}

export const ReloadContext = createContext<IReloadContext>({
  reload: () => null,
  useReloadEffect: () => null,
});
