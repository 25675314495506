import type { TFunction } from 'react-i18next';

import type { FindAccountDto } from '@legalplace/bankroot-api/modules/account/dto';
import type { UpdateRibInformationRequestDto } from '@legalplace/bankroot-api/modules/onboardingFlow/dto';

import { FR_IBAN_REGEX } from '../../../../../constants';
import type {
  IRibForm,
  IRibFormError,
  IRibFormState,
} from '../../../../../interfaces/forms/rib.interface';
import {
  callIbanValidationEndpoint,
  validateIbanForAccountId,
} from '../../../../../services/api/account.api';

export const formatRibInformationDto = (
  companyId: string,
  taxRibValues: IRibForm,
  subscriptionRibValues: IRibForm
): UpdateRibInformationRequestDto => ({
  taxRibInformation: {
    accountId: taxRibValues.accountId,
    iban: taxRibValues.accountId ? undefined : taxRibValues.iban,
    bic: taxRibValues.bic,
  },
  subscriptionRibInformation: {
    companyId,
    accountId: subscriptionRibValues.accountId,
    iban: subscriptionRibValues.accountId
      ? undefined
      : subscriptionRibValues.iban,
  },
});

export const selectFirstAccountWithFrenchIban = (
  accounts: FindAccountDto[]
): string | undefined =>
  accounts.find((account) => account.iban?.startsWith('FR'))?.id || undefined;

export const validateForm = async (
  formState: IRibFormState,
  t: TFunction
): Promise<boolean> => {
  const {
    values: { accountId, iban, bic },
  } = formState;
  const errors: IRibFormError = {};

  if (!accountId && !iban && !bic) {
    errors.accountIdErrorMessage = t('error.empty_field');
  }

  if (accountId) {
    if (iban && !iban.startsWith('FR')) {
      errors.accountIdErrorMessage = t('error.dropdown_fr_iban_only');
    } else {
      const {
        data: { valid },
      } = await validateIbanForAccountId(accountId);
      if (!valid) {
        errors.accountIdErrorMessage = t('error.dropdown_invalid_account');
      }
    }
  }

  if (!accountId) {
    if (!iban) {
      errors.ibanErrorMessage = t('error.empty_field');
    } else if (iban.length !== 27) {
      errors.ibanErrorMessage = t('error.iban_length');
    } else if (!iban.match(FR_IBAN_REGEX)) {
      errors.ibanErrorMessage = t('error.fr_iban_only');
    } else {
      const {
        data: { valid },
      } = await callIbanValidationEndpoint(iban);
      if (!valid) {
        errors.ibanErrorMessage = t('error.iban_invalid');
      }
    }
  }

  formState.setErrors(errors);
  return Object.values(errors).some((error) => !!error);
};
