import { call, put, select, takeEvery } from 'typed-redux-saga';

import { getCompanyUserRequest } from '../../../services/api/company.api';
import { selectActiveCompanyId } from '../../companies/selectors';
import {
  fetchCompanyUsersFailed,
  fetchCompanyUsersSuccess,
  startFetchCompanyUsers,
} from '../actions';

function* handleFetchCompanyUsers(): Generator {
  let activeCompanyId = '';

  try {
    activeCompanyId = (yield select(selectActiveCompanyId)) as string;
    if (activeCompanyId) {
      const response = yield* call(getCompanyUserRequest, activeCompanyId);
      const { data: companyUsers } = response;
      yield put(fetchCompanyUsersSuccess(companyUsers));
    }
  } catch (error: unknown) {
    console.error(
      `[handleFetchCompanyUsers] - Error trying to fetch company users for companyId ${activeCompanyId}`,
      error
    );
    yield put(fetchCompanyUsersFailed());
  }
}

function* fetchCompanyUsersSaga(): Generator {
  yield takeEvery(startFetchCompanyUsers, handleFetchCompanyUsers);
}

export default fetchCompanyUsersSaga;
