export enum ModifyImmobilisationModalSteps {
  INFORMATION_STEP = 'information-step',
  DEPRECIATION_STEP = 'depreciation-step',
}

export enum AssociateImmobilisationModalSteps {
  IMMOBILISATION_CHOICE_STEP = 'immobilisation-choice-step',
  IMMOBILISATION_PREVIEW_STEP = 'immobilisation-preview-step',
}

export enum SellImmobilisationModalSteps {
  POTENTIAL_OPERATIONS_STEP = 'potential-operations-step',
  CONFIRMATION_STEP = 'confirmation-step',
}
