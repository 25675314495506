import { AccountingOnboardingSource } from '@legalplace/shared';

import { CALENDLY_SCHEDULED_EVENT_NAME } from '../../constants';

const {
  VITE_APP_CALENDLY_ACCOUNTING_URL,
  VITE_APP_CALENDLY_SALES_ACCOUNTING_URL,
} = import.meta.env;

export const isCalendlyScheduledEvent = (event: MessageEvent): boolean =>
  event.origin === 'https://calendly.com' &&
  event.data.event &&
  event.data.event.indexOf('calendly.') === 0 &&
  event.data.event === CALENDLY_SCHEDULED_EVENT_NAME;

export const getAccountingCalendlyUrl = (
  source?: AccountingOnboardingSource
): string => {
  if (!source || source === AccountingOnboardingSource.CreaPlusCompta) {
    return VITE_APP_CALENDLY_ACCOUNTING_URL;
  }

  return VITE_APP_CALENDLY_SALES_ACCOUNTING_URL;
};

export const formatCalendlyUrl = ({
  fullName,
  email,
  companyCode,
  source,
  companyName,
  siren,
  phoneNumber,
}: {
  fullName: string;
  email?: string;
  companyName?: string;
  siren?: string;
  phoneNumber?: string;
  companyCode: string;
  source?: AccountingOnboardingSource;
}): string => {
  const iframeParams = new URLSearchParams({
    embed_domain: '1',
    embed_type: 'Inline',
    hide_gdpr_banner: '1',
    name: fullName,
    email: email ?? '',
    utm_content: companyCode,
  });

  const isAccountantCalendly =
    source === AccountingOnboardingSource.CreaPlusCompta || !source;
  if (isAccountantCalendly) {
    iframeParams.set('a1', companyName ?? '');
  } else {
    iframeParams.set('a1', phoneNumber ?? '');
    iframeParams.set('a2', siren ?? '');
  }

  return `${getAccountingCalendlyUrl(source)}?${iframeParams.toString()}`;
};
