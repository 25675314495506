import type { BareCardDto } from '@legalplace/bankroot-api/modules/card/dto';
import type { GetCardDto } from '@legalplace/bankroot-api/modules/card/dto/getCard.dto';
import {
  CARD_ACTIVE_STATUSES,
  RightType,
  UserCompanyStatus,
} from '@legalplace/shared';
import { createSelector } from '@reduxjs/toolkit';

import type { CardStatusType } from '../../constants';
import {
  CARD_SEPARATOR,
  Status,
  TO_CREATE_CARD_ID,
  ToCreateCardStatus,
} from '../../constants';
import type { RootState } from '../../store';
import { selectUserCompanyHasRight } from '../companies/selectors';
import {
  selectCompanyUsers,
  selectCompanyUsersFetchStatus,
  selectUserInformations,
} from '../user/selector';

import type { CardListInformation } from './types';

export const selectCardList = createSelector(
  (state: RootState) => selectCompanyUsers(state),
  (state: RootState) => selectUserCompanyHasRight(RightType.EDIT_USERS)(state),
  (state: RootState) => selectUserInformations(state),
  (state: RootState) => selectCompanyUsersFetchStatus(state),
  (state: RootState) => state.cards.allIds,
  (state: RootState) => state.cards.byId,
  (state: RootState) => state.cards.status,
  (
    companyUsers,
    hasEditCompanyUserRight,
    { userId: currentUserId },
    companyUserStatus,
    allCardIds,
    cardById,
    cardStatus
  ): CardListInformation[] => {
    if (companyUserStatus === Status.LOADING || cardStatus === Status.LOADING) {
      return [];
    }
    const hasActiveCard: Record<string, boolean> = {};
    const cardList: CardListInformation[] = allCardIds.map((cardId) => {
      const card = cardById[cardId];
      const statusLabel =
        card.cardMaskedNumber && card.cardMaskedNumber !== ''
          ? card.cardMaskedNumber
          : `status_label_${card.status}`;

      if (CARD_ACTIVE_STATUSES.includes(card.status)) {
        hasActiveCard[card.associatedUserId] = true;
      }

      return {
        id: card.id,
        status: card.status,
        ownerName: card.userFullName,
        statusLabel,
      };
    });

    companyUsers.forEach((companyUser) => {
      if (!hasEditCompanyUserRight && companyUser.userId !== currentUserId) {
        return;
      }

      if (
        companyUser.status === UserCompanyStatus.ACTIVE &&
        !hasActiveCard[companyUser.userId]
      ) {
        cardList.unshift({
          id: `${TO_CREATE_CARD_ID}${CARD_SEPARATOR}${companyUser.userId}`,
          status: ToCreateCardStatus.TO_CREATE,
          ownerName: `${companyUser.firstName} ${companyUser.lastName}`,
          statusLabel: 'status_label_ToCreate',
        });
      }
    });
    return cardList;
  }
);

/** Selector for GTM tracking. Returns the status of the first card:
 *  If user has no card returns TO_CREATE
 *  If no active card returns Deactivated */
export const selectAccountCardStatus = createSelector(
  (state: RootState) => selectCardList(state),
  (cards: CardListInformation[]): CardStatusType | undefined => cards[0]?.status
);

export const selectCardFetchStatus = (state: RootState): Status =>
  state.cards.status;

export const selectHasFirstCardAlreadyBeenOrdered = createSelector(
  (state: RootState) => selectCardList(state),
  (state: RootState) => selectCardFetchStatus(state),
  (cards: CardListInformation[], cardFetchStatus: Status): boolean =>
    cardFetchStatus !== Status.LOADING &&
    cards[0]?.status !== ToCreateCardStatus.TO_CREATE
);

export const selectActiveCard = (state: RootState): GetCardDto | undefined =>
  state.cards.activeCard;

export const selectActiveCardId = (state: RootState): string | undefined =>
  state.cards.activeCardId;

export const selectCardsById = (
  state: RootState
): Record<string, BareCardDto> => state.cards.byId;
