export const login = {
  page_title: 'Connexion',
  close_account_success_message: 'Compte clôturé !',
  close_account_cancel_message: 'Vous avez annulé la clôture du compte.',
  request_expired: 'Votre demande a expiré.',
  unknown_error: 'Une erreur est survenue, veuillez réessayer.',
  invalid_credentials_error_message:
    'Votre adresse e-mail ou votre mot de passe est incorrect.',
  reset_user_account_error_message:
    'Pour des raisons de sécurité, veuillez réinitialiser votre mot de passe via le lien que vous avez reçu par e-mail.',
  'last-visited-item-separator': 'Dernière visite',
  form_title: 'Connexion',
  form_subtitle: 'Ravi de vous revoir !',
  forgot_password: "J'ai oublié mon mot de passe",
  submit_button_label: 'Me connecter',
  email_label: 'E-mail',
  password_label: 'Mot de passe',
  email_placeholder: 'nomprenom@email.fr',
  password_placeholder: 'Mot de passe',
  email_format_error_message: 'Cette adresse e-mail est invalide.',
  login_links: {
    legal_notice_label: 'Mentions légales',
    privacy_policy_label: 'Charte de protection des données',
    terms_of_service_label: 'Conditions générales',
  },
  confirm_connexion_title: 'Confirmez votre connexion',
  confirm_connexion_button: 'Confirmer ma connexion',
};
