import React from 'react';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import axios from 'axios';
import i18n from 'i18next';

import { App as AppCapacitor } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/react';

import { ampli } from './ampli/api';
import { setupApiInterceptors } from './services/api/api.interceptor';
import { englishTranslations } from './translations/english';
import { frenchTranslations } from './translations/french';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }
}

i18n.use(initReactI18next).init({
  resources: {
    fr: { ...frenchTranslations },
    en: { ...englishTranslations },
  },
  lng: 'fr',
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
});

if (import.meta.env.VITE_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://d7f514978aca4519bc27e873769da91d@o309372.ingest.sentry.io/6395477',
    release: import.meta.env.VITE_APP_VERSION,
    tracesSampleRate: 0.0,
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'The object can not be found here',
    ],
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    allowUrls: [import.meta.env.VITE_APP_URL],
    beforeSend: (event, hint) => {
      const error = hint.originalException;

      if (
        error &&
        /Loading (CSS )?chunk [\d]+ failed/.test(
          (error as unknown as Error).message || ''
        )
      ) {
        event.tags = event.tags || {};
        event.tags.knownErrorType = 'chunk-error';
      }

      if (
        error &&
        (/Unexpected token '<'/.test(
          (error as unknown as Error).message || ''
        ) ||
          /SyntaxError: expected expression, got '<'/.test(
            (error as unknown as Error).message || ''
          ))
      ) {
        event.tags = event.tags || {};
        event.tags.knownErrorType = '403-error';
      }

      if (
        error &&
        /ResizeObserver loop limit exceeded/.test(
          (error as unknown as Error).message || ''
        )
      ) {
        event.tags = event.tags || {};
        event.tags.knownErrorType = 'resize-observer-error';
      }

      // Ignore chunk errors if it's the first time they happen
      // If they happen again after a refresh a different error is thrown and that one isn't ignored in sentry
      if (
        error &&
        (/(Failed to fetch dynamically imported|error loading dynamically imported module|Importing a module script failed)/.test(
          (error as unknown as Error).message || ''
        ) ||
          (error as Error).message === 'Load failed') &&
        !/Failed to fetch chunk after reload/.test(
          (error as unknown as Error).message || ''
        )
      ) {
        event.tags = event.tags || {};
        event.tags.knownErrorType = 'chunk-error';
      }

      // Following error is known to be caused when user translates
      // a page using his browser
      // It does not affect the user experience so we ignore it
      if (
        error &&
        /Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node./.test(
          (error as unknown as Error).message || ''
        )
      ) {
        event.tags = event.tags || {};
        event.tags.knownErrorType = 'translation-react-error';
      }

      if (axios.isAxiosError(error) && error.response?.status === 401) {
        return null;
      }

      if (error && (error as unknown as Error).cause === 401) {
        return null;
      }

      if (
        axios.isAxiosError(error) &&
        error.config?.url &&
        error.config?.method &&
        error.response?.status
      ) {
        event.fingerprint = [
          error.config.url,
          error.config.method,
          error.response.status.toString(),
        ];
      }

      return event;
    },
  });

  if (Capacitor.isNativePlatform()) {
    AppCapacitor.getInfo().then((value) => {
      Sentry.setTag(
        'mobile.app.platform',
        `${Capacitor.getPlatform()}-${value?.version}`
      );
    });
  }
}

let isReloadingChunks = false;

window.addEventListener('vite:preloadError', (event) => {
  const refreshDateString = window.sessionStorage.getItem(
    `retry-vite-chunk-refresh-date`
  );
  const refreshDate = refreshDateString
    ? new Date(refreshDateString)
    : undefined;

  if (!refreshDate || new Date().getTime() - refreshDate.getTime() >= 500) {
    window.sessionStorage.setItem(
      `retry-vite-chunk-refresh-date`,
      new Date().toISOString()
    );
    isReloadingChunks = true;
    window.location.reload();
  } else if (!isReloadingChunks) {
    throw new Error(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      `Failed to fetch chunk after reload: ${(event as any).payload}`
    );
  }
});

const parseCookies = (str: string): { [key: string]: string } => {
  try {
    return str
      .split(';')
      .map((v) => v.split('='))
      .reduce((acc, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
        return acc;
      }, {} as { [key: string]: string });
  } catch (e) {
    return {};
  }
};

if (import.meta.env.VITE_APP_ENVIRONMENT !== 'development') {
  const cookies = parseCookies(document.cookie || '');
  const optOutCookie = Boolean(cookies['rgpd-cookie-opt-out'] || false);

  ampli.load({
    client: {
      apiKey: import.meta.env.VITE_AMPLITUDE_API_KEY,
      configuration: {
        serverZone: 'EU',
        optOut: optOutCookie,
        defaultTracking: true,
      },
    },
  });
}

setupApiInterceptors();

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
