import { flexBaseAlignCenter, styled } from '@legalplace/storybook';

export const ToggleCardContainer = styled.div`
  ${flexBaseAlignCenter};
  justify-content: space-between;
  border: 0.1rem solid ${({ theme }) => theme.backgroundColors.grey100};
  border-radius: ${({ theme }) => theme.borders.radiusMd};
  padding: ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.xsmall};
`;
