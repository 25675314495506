import type { FeatureFlags } from '@legalplace/shared';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../services/api/api';

export const featureFlagsApi = createApi({
  reducerPath: 'featureFlagsApi',
  baseQuery: reactToolkitBaseQuery,
  endpoints: (build) => ({
    getFeatureFlags: build.query<FeatureFlags, string | undefined>({
      query: (companyId) => ({
        url: `/feature-flags?companyId=${companyId ?? ''}`,
      }),
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = featureFlagsApi;
