import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Capacitor } from '@capacitor/core';
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update';
import * as Sentry from '@sentry/react';
import {
  ModalDrawerV2,
  ModalVariants,
  StreamLineIconList,
} from '@legalplace/storybook';

import { isVersion1MoreRecent } from '../../services/utils';
import { InformationModalDrawerContent } from '../informationModalDrawerContent/InformationModalDrawerContent';

export function ForceAppUpdateDrawer(): JSX.Element {
  const { t } = useTranslation('mobile', {
    keyPrefix: 'force_app_update',
  });

  const [forceAppUpdate, setForceAppUpdate] = useState<boolean>(false);

  const getAvailableAppVersion = async (): Promise<{
    availableVersion: string | undefined;
    currentVersion: string | undefined;
  }> => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (Capacitor.getPlatform() === 'android') {
      return {
        availableVersion: result.availableVersionCode,
        currentVersion: result.currentVersionCode,
      };
    }

    return {
      availableVersion: result.availableVersionName,
      currentVersion: result.currentVersionName,
    };
  };

  useEffect(() => {
    const checkIfAppNeedsUpdate = async (): Promise<void> => {
      try {
        const { availableVersion, currentVersion } =
          await getAvailableAppVersion();
        if (isVersion1MoreRecent(availableVersion, currentVersion)) {
          setForceAppUpdate(true);
        }
      } catch (error: unknown) {
        console.error(error);
        Sentry.captureException(error, {
          tags: { knownErrorType: 'app-update' },
        });
      }
    };
    checkIfAppNeedsUpdate();
  });

  const openAppStore = async (): Promise<void> => {
    try {
      const {
        updateAvailability,
        immediateUpdateAllowed,
        flexibleUpdateAllowed,
      } = await AppUpdate.getAppUpdateInfo();
      if (updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
        if (immediateUpdateAllowed) {
          await AppUpdate.performImmediateUpdate();
          return;
        }
        if (flexibleUpdateAllowed) {
          await AppUpdate.startFlexibleUpdate();
          return;
        }
      }
      await AppUpdate.openAppStore();
    } catch (error: unknown) {
      console.error(error);
      Sentry.captureException(error, {
        tags: { knownErrorType: 'app-update' },
      });
      await AppUpdate.openAppStore();
    }
  };

  return (
    <ModalDrawerV2
      isOpen={forceAppUpdate}
      onClose={() => {}}
      modalVariant={ModalVariants.ACKNOWLEDGEMENT}
      primaryAction={openAppStore}
      buttonLabels={{
        primary: t('modal_button'),
      }}
    >
      <InformationModalDrawerContent
        statusIcon={StreamLineIconList.informationcircle}
        statusIconColor="primary500"
        title={t('modal_title')}
        textContent={t('modal_message')}
      />
    </ModalDrawerV2>
  );
}
