/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';

import {
  FlexContainerBasicAlignCenter,
  StreamLineIcon,
  StreamLineIconList,
} from '@legalplace/storybook';

import { CollapsableSectionType } from '../../constants';
import type { ICollapsableSection } from '../../interfaces';

import {
  CollapsableBodyContainer,
  CollapsableContainer,
  CollapsableSectionContainer,
  SectionHeader,
  StyledText,
  TitleContainer,
} from './CollapsableSection.style';

export function CollapsableSection({
  title,
  icon,
  defaultCollapsedState = false,
  children,
  triggerCloseSymbol,
  collapseOtherSections,
  groupId,
  isActive,
  headerRightText,
  type = CollapsableSectionType.DEFAULT,
  draggable,
  triggerOpenSymbol,
}: ICollapsableSection): JSX.Element {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsedState);
  const [bodyContentHeight, setBodyContentHeight] = useState(0);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const shouldSectionBeHighlighted =
    type === CollapsableSectionType.DEFAULT && isActive && isCollapsed;

  useEffect(() => {
    setBodyContentHeight(bodyRef.current?.offsetHeight || 0);
  }, [isCollapsed, bodyRef, children]);

  useEffect(() => {
    if (triggerCloseSymbol) {
      setIsCollapsed(true);
    }
  }, [triggerCloseSymbol]);

  useEffect(() => {
    if (triggerOpenSymbol) {
      setIsCollapsed(false);
    }
  }, [triggerOpenSymbol]);

  const toggleCollapse = (): void => {
    setIsCollapsed((previousCollapsedState) => {
      if (previousCollapsedState) {
        collapseOtherSections?.(groupId);
      }

      return !previousCollapsedState;
    });
  };

  return (
    <CollapsableSectionContainer
      type={type}
      {...draggable?.dragHandleProps}
      {...draggable?.draggableProps}
      ref={draggable?.innerRef}
    >
      <SectionHeader
        onClick={toggleCollapse}
        isCollapsed={isCollapsed}
        $isActive={shouldSectionBeHighlighted}
        sectionType={type}
      >
        <TitleContainer sectionType={type}>
          {icon && (
            <StreamLineIcon
              icon={icon}
              width="2rem"
              height="2rem"
              fillColor={
                shouldSectionBeHighlighted ? 'primary600' : 'primary900'
              }
            />
          )}
          <StyledText
            tag="p3"
            color={shouldSectionBeHighlighted ? 'primary600' : 'primary900'}
            fontWeight={type === CollapsableSectionType.DEFAULT ? '400' : '500'}
            noShrink={type === CollapsableSectionType.DEFAULT}
          >
            {title}
          </StyledText>
        </TitleContainer>
        <FlexContainerBasicAlignCenter gap={{ default: '1.6rem' }}>
          {headerRightText && (
            <StyledText
              tag="p3"
              color="primary900"
              fontWeight={
                type === CollapsableSectionType.DEFAULT ? '400' : '500'
              }
              noShrink
            >
              {headerRightText}
            </StyledText>
          )}
          <StreamLineIcon
            icon={
              isCollapsed
                ? StreamLineIconList.arrowright1
                : StreamLineIconList.arrowup1
            }
            fillColor="primary900"
            width={type === CollapsableSectionType.DEFAULT ? '1.6rem' : '2rem'}
            height={type === CollapsableSectionType.DEFAULT ? '1.6rem' : '2rem'}
          />
        </FlexContainerBasicAlignCenter>
      </SectionHeader>
      <CollapsableContainer
        isCollapsed={isCollapsed}
        maxHeight={bodyContentHeight}
      >
        <CollapsableBodyContainer ref={bodyRef}>
          {children}
        </CollapsableBodyContainer>
      </CollapsableContainer>
    </CollapsableSectionContainer>
  );
}
