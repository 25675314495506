import type { ReactNode } from 'react';

import {
  FlexContainerBasicAlignCenter,
  Image,
  Typography,
} from '@legalplace/storybook';

import { PageTitleWrapper } from './PageTitle.style';

type PageTitleProps = {
  label: string;
  icon?: string;
  alt?: string;
  tooltip?: ReactNode;
};

export function PageTitle({
  label,
  icon,
  alt,
  tooltip,
}: PageTitleProps): JSX.Element {
  return (
    <PageTitleWrapper>
      <FlexContainerBasicAlignCenter gap={{ default: '0.8rem' }}>
        <Typography tag="h5" color="primary900" fontWeight="600">
          {label}
        </Typography>
        {tooltip}
      </FlexContainerBasicAlignCenter>
      {icon && <Image src={icon} alt={alt} />}
    </PageTitleWrapper>
  );
}
