import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ISelectOption } from '@legalplace/storybook';
import {
  Dropdown,
  FlexContainerBasicColumn,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { INTERCOM_SYNCHRO_BANK } from '../../../../../constants/bankAccount.constant';
import { useAppSelector } from '../../../../../hooks/store';
import type { IRibFormState } from '../../../../../interfaces/forms/rib.interface';
import { validateIbanForAccountId } from '../../../../../services/api/account.api';
import { formatFrenchIban, stripString } from '../../../../../services/utils';
import { selectAccountsById } from '../../../../../store/accounts/selectors';
import { InputWrapper } from '../../../../common/forms/inputWrapper/InputWrapper';
import { Input } from '../../../../common/forms/styledInput/Input';

import {
  OtherIbanContainer,
  SynchronizeDisclaimer,
  TitleIconContainer,
} from './AccountSelectionForm.style';

interface IAccountSelectionFormProps {
  ibanDropdownLabel: string;
  formState: IRibFormState;
  isSubmitting: boolean;
}

export function AccountSelectionForm({
  ibanDropdownLabel,
  formState: { values, setValues, errors, setErrors, isLoading, setIsLoading },
  isSubmitting,
}: IAccountSelectionFormProps): JSX.Element {
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'contract_signature.rib_selection.account_selection',
  });
  const accountsById = useAppSelector(selectAccountsById);
  const [selectedAccountId, setSelectedAccountId] = useState(values.accountId);

  const accountOptions = Object.values(accountsById)
    .filter((account) => account.iban)
    .map((account) => ({
      label: `${account.bankName} - ${account.iban}`,
      value: account.id,
      selected: account.id === selectedAccountId,
    }));
  accountOptions.push({
    label: t('other_account_dropdown_label'),
    value: 'other',
    selected: selectedAccountId === 'other',
  });

  const handleDropdownChange = async (
    option?: ISelectOption | undefined
  ): Promise<void> => {
    const accountId = option?.value as string;
    setSelectedAccountId(accountId);
    setValues({
      accountId: option?.value === 'other' ? undefined : accountId,
      iban:
        option?.value === 'other' ? undefined : accountsById[accountId].iban,
    });
    setErrors({});

    if (option?.value === 'other') {
      return;
    }

    if (!accountsById[accountId].iban?.startsWith('FR')) {
      setErrors({
        accountIdErrorMessage: t('error.dropdown_fr_iban_only'),
      });
      return;
    }

    setIsLoading(true);
    try {
      const {
        data: { valid },
      } = await validateIbanForAccountId(accountId);
      if (!valid) {
        setErrors({
          accountIdErrorMessage: t('error.dropdown_invalid_account'),
        });
      }
    } catch (error) {
      console.error('Error while validating IBAN', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleIbanChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newIban = stripString(event.target.value);
    const oldIban = values.iban ?? '';

    const newLength = newIban.length;
    const oldLength = oldIban.length;

    const cursor = event.target.selectionStart ?? 0;

    let offset = 0;
    if (
      newLength > oldLength &&
      (cursor % 5 === 0 || newLength - oldLength > 1)
    ) {
      offset = Math.ceil((newLength - oldLength) / 4);
    }

    const pointer = cursor + offset;
    window.requestAnimationFrame(() => {
      event.target.setSelectionRange(pointer, pointer);
    });

    setValues({
      ...values,
      iban: newIban,
    });
    setErrors({});
  };

  return (
    <FlexContainerBasicColumn gap={{ default: '0.8rem' }}>
      <InputWrapper name="account_dropdown" label={ibanDropdownLabel}>
        <Dropdown
          options={accountOptions}
          defaultOption={accountOptions.find(
            (option) => option.value === selectedAccountId
          )}
          onChange={handleDropdownChange}
          height={{ default: '3.6rem' }}
          disabled={isLoading || isSubmitting}
          hasError={
            !!errors.accountIdErrorMessage && selectedAccountId !== 'other'
          }
          errorMessage={errors.accountIdErrorMessage}
        />
      </InputWrapper>
      {selectedAccountId === 'other' && (
        <FlexContainerBasicColumn>
          <OtherIbanContainer>
            <InputWrapper
              name="iban"
              label={t('other_account_iban_field_label')}
              hasError={!!errors.ibanErrorMessage}
              errorMessage={errors.ibanErrorMessage}
            >
              <Input
                placeholderColor="grey300"
                name="iban"
                type="text"
                placeholder="FR** **** **** **** **** **** 986"
                value={formatFrenchIban(values.iban)}
                onChange={handleIbanChange}
                hasError={!!errors.ibanErrorMessage}
                maxLength={33}
              />
            </InputWrapper>
          </OtherIbanContainer>
          <SynchronizeDisclaimer>
            <FlexContainerBasicColumn gap={{ default: '0.4rem' }}>
              <TitleIconContainer>
                <StreamLineIcon
                  icon={StreamLineIconList.alertcircle}
                  fillColor="warning500"
                />
                <Typography
                  tag="p4"
                  color="warning900"
                  lineHeight={{ default: '2rem' }}
                  fontWeight="600"
                >
                  {t('synchronize_disclaimer.title')}
                </Typography>
              </TitleIconContainer>
              <Typography
                tag="p5"
                color="warning900"
                lineHeight={{ default: '1.6rem' }}
                fontWeight="400"
              >
                {t('synchronize_disclaimer.message')}
              </Typography>
            </FlexContainerBasicColumn>
            <Typography
              tag="p5"
              color="primary500"
              lineHeight={{ default: '2rem' }}
              fontWeight="400"
            >
              <a href={INTERCOM_SYNCHRO_BANK} target="_blank" rel="noreferrer">
                {t('synchronize_disclaimer.know_more')}
              </a>
            </Typography>
          </SynchronizeDisclaimer>
        </FlexContainerBasicColumn>
      )}
    </FlexContainerBasicColumn>
  );
}
