import type {
  CreatePaymentMethodDto,
  GetPaymentMethodDto,
  PaymentMethodSuggestionDto,
  UpdatePaymentMethodDto,
} from '@legalplace/bankroot-api/modules/paymentMethod/dto';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../services/api/api';

export const paymentMethodsApi = createApi({
  reducerPath: 'paymentMethodsApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['PaymentMethod'],
  endpoints: (build) => ({
    getPaymentMethods: build.query<GetPaymentMethodDto[], string>({
      query: (companyId) => ({ url: `/payment-method?companyId=${companyId}` }),
      providesTags: ['PaymentMethod'],
    }),
    createPaymentMethod: build.mutation<
      GetPaymentMethodDto,
      CreatePaymentMethodDto
    >({
      query: (createPaymentMethodDto) => ({
        url: '/payment-method',
        method: 'POST',
        body: createPaymentMethodDto,
      }),
      invalidatesTags: ['PaymentMethod'],
    }),
    updatePaymentMethod: build.mutation<
      GetPaymentMethodDto,
      {
        paymentMethodId: string;
        updatePaymentMethodDto: UpdatePaymentMethodDto;
      }
    >({
      query: ({ paymentMethodId, updatePaymentMethodDto }) => ({
        url: `/payment-method/${paymentMethodId}`,
        method: 'PATCH',
        body: updatePaymentMethodDto,
      }),
      invalidatesTags: ['PaymentMethod'],
    }),
    getWalletCreationSuggestion: build.query<
      PaymentMethodSuggestionDto,
      string
    >({
      query: (companyId) => ({
        url: `/payment-method/creation-suggestion?companyId=${companyId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetPaymentMethodsQuery,
  useCreatePaymentMethodMutation,
  useGetWalletCreationSuggestionQuery,
  useUpdatePaymentMethodMutation,
} = paymentMethodsApi;
