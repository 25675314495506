import { FULL_SELECT_COUNTRY_OPTIONS } from '@legalplace/storybook';

const COUNTRY_TRANSLATIONS = FULL_SELECT_COUNTRY_OPTIONS.reduce(
  (acc, { value, label }) => {
    acc[`${value}`] = label;
    return acc;
  },
  {} as Record<string, string>
);

export default {
  title: 'Votre carte est prête à partir !',
  subtitle: "Confirmez-nous simplement l'adresse de livraison.",
  info_message: 'Livraison estimée',
  info_message_emphasized: '{{deliveryDay}} prochain',
  confirm_address_button_label: 'Continuer',
  order_card_button_label: 'Confirmer et continuer',
  skip_button_label: "Je n'ai pas besoin de carte",
  card_feature_1: 'Carte Mastercard incluse dans votre offre',
  card_feature_2: "Paiements et retraits jusqu'à 20 000 € par mois",
  card_feature_3: 'Compatible Apple Pay et Google Pay',
  card_feature_4: "Entièrement paramétrable depuis l'application",
  card_feature_5: 'Assurance contre la fraude',
  update_address_button_label: "Modifier l'adresse",
  card_price: '0 €',
  unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
  country: {
    ...COUNTRY_TRANSLATIONS,
  },
  monday: 'lundi',
  tuesday: 'mardi',
  wednesday: 'mecredi',
  thursday: 'jeudi',
  friday: 'vendredi',
  saturday: 'samedi',
  sunday: 'dimanche',
  card_delivery_title: 'Livraison de votre carte',
  card_feature_button_label: 'Voir les avantages de la carte',
  address_field: 'Adresse',
  address_placeholder: "4 rue de l'adresse",
  address_placeholder_2: "Complément d'adresse (optionnel)",
  zip_code_field: 'Code postal',
  zip_code_placeholder: '75000',
  city_field: 'Ville',
  city_placeholder: 'Paris',
  country_field: 'Pays',
  country_placeholder: 'France',
  empty_field_error: 'Veuillez remplir ce champ',
  zip_code_length_error_message:
    'Le code postal doit contenir entre 3 et 10 caractères selon le pays',
  french_zip_code_error_message: 'Renseignez un code postal valide',
  address_error_message:
    "Renseignez une adresse de réception qui n'est pas celle de votre domiciliation.",
};
