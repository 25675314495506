export const areArrayEqual = <T>(array1: T[], array2: T[]): boolean =>
  array1.length === array2.length &&
  array1.every((element) => array2.includes(element));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areObjectsEqualWithRecursion = <T extends Record<string, any>>(
  obj1: T,
  obj2: T,
  level = 1
): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if the keys are the same
  if (!keys1.every((key) => keys2.includes(key))) {
    return false;
  }

  // Check if the values of the keys are equal (up to the specified level)
  let areEqual = true;
  keys1.forEach((key) => {
    const value1 = obj1[key];
    const value2 = obj2[key];

    // Convert empty string to null
    const processedValue1 = value1 === '' ? null : value1;
    const processedValue2 = value2 === '' ? null : value2;

    // If both values are objects, perform comparison recursively up to the specified level
    if (
      typeof processedValue1 === 'object' &&
      typeof processedValue2 === 'object' &&
      !!processedValue1 &&
      !!processedValue2
    ) {
      if (Array.isArray(processedValue1) || Array.isArray(processedValue2)) {
        areEqual = areArrayEqual(processedValue1, processedValue2);
        return;
      }

      if (level > 1) {
        // Recur into the nested objects with a reduced level
        if (
          !areObjectsEqualWithRecursion(
            processedValue1,
            processedValue2,
            level - 1
          )
        ) {
          areEqual = false;
        }
      } else if (processedValue1 !== processedValue2) {
        // If we reach the specified level, perform shallow comparison
        areEqual = false;
      }
    } else if (processedValue1 !== processedValue2) {
      // If the values are different, the objects are not equal
      areEqual = false;
    }
  });

  return areEqual;
};

export const delay = (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const base64ToArrayBuffer = (b64encoded: string): ArrayBufferLike => {
  const binaryString = window.atob(b64encoded);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const stringToArrayBuffer = (source: string): ArrayBufferLike => {
  const bytes = new Uint8Array(source.length);
  for (let i = 0; i < source.length; i += 1) {
    bytes[i] = source.charCodeAt(i);
  }
  return bytes.buffer;
};

export const base64urlToBase64 = (source: string): string => {
  // Replace non-url compatible chars with base64 standard chars
  let result = source.replace(/-/g, '+').replace(/_/g, '/');

  // Pad out with standard base64 required padding characters
  const pad = result.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error('Invalid input length');
    }
    result += new Array(5 - pad).join('=');
  }

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areArraysOfObjectsEqual = <T extends Record<string, any>>(
  arr1: T[],
  arr2: T[],
  level: number = 1
): boolean => {
  // Check if the arrays are of the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Iterate through elements of both arrays
  for (let i = 0; i < arr1.length; i += 1) {
    // Use the provided function to compare objects at the current level
    if (!areObjectsEqualWithRecursion(arr1[i], arr2[i], level)) {
      return false; // Return false if any pair of objects is not equal
    }
  }

  // If all pairs of objects were equal, return true
  return true;
};
