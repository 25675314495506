import { styled } from '@legalplace/storybook';

import { addStyleInDesktop } from '../UI/Responsive.style';

export const BannerContentContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundColors.primary900};
  padding: ${({ theme }) => theme.spacing.medium};
  ${({ theme }) =>
    addStyleInDesktop(theme, `border-radius:${theme.borders.radiusMd};`)};
`;
