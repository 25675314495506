import { useCallback, useState } from 'react';

import { OnboardingCallStatus } from '@legalplace/shared';

import { useAppSelector } from '../../hooks/store';
import { useGetAcademyQuery } from '../../store/academy/reducer';
import { selectActiveCompany } from '../../store/companies/selectors';

import { AcademyMenuItem } from './AcademyMenuItem/AcademyMenuItem';
import { AcademyEndMessageModal } from './AcademyModal/AcademyEndMessageModal/AcademyEndMessageModal';
import { AcademyModal } from './AcademyModal/AcademyModal';

interface IAcademyProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export function Academy({
  isOpen,
  setIsOpen,
}: IAcademyProps): JSX.Element | null {
  const activeCompany = useAppSelector(selectActiveCompany);
  const { data = { sections: [] } } = useGetAcademyQuery(
    activeCompany?.id ?? '',
    { skip: !activeCompany?.id }
  );

  const [shouldOpenEndingMessage, setShouldOpenEndingMessage] =
    useState<boolean>(false);

  const openEndMessage = useCallback((): void => {
    setShouldOpenEndingMessage(true);
  }, []);

  if (
    !activeCompany?.featureMap?.ComptaBase?.isActive ||
    activeCompany.onboardingCallStatus === OnboardingCallStatus.TODO
  ) {
    return null;
  }

  if (shouldOpenEndingMessage) {
    return (
      <AcademyEndMessageModal
        isOpen={shouldOpenEndingMessage}
        onClose={() => setShouldOpenEndingMessage(false)}
      />
    );
  }

  if (data.sections.length === 0) {
    return null;
  }

  return (
    <>
      <AcademyMenuItem isAcademyOpen={isOpen} setIsAcademyOpen={setIsOpen} />
      <AcademyModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        openEndMessage={openEndMessage}
      />
    </>
  );
}
