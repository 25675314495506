import { call, put, select, takeEvery } from 'typed-redux-saga';

import type {
  BareCardDto,
  GetCardDto,
} from '@legalplace/bankroot-api/modules/card/dto';
import type { CompanyUserDto } from '@legalplace/bankroot-api/modules/company/dto';
import { SwanPhysicalCardStatus } from '@legalplace/shared';
import type { PayloadAction } from '@reduxjs/toolkit';

import {
  CARD_SEPARATOR,
  CardStatus,
  TO_CREATE_CARD_ID,
  TO_PROCESS_CARD_ID,
} from '../../../constants';
import { getCardByIdRequest } from '../../../services/api/card.api';
import { selectFallbackAccountId } from '../../accounts/selectors';
import { selectCompanyUsers } from '../../user/selector';
import {
  fetchActiveCardFailed,
  fetchActiveCardSuccess,
  setActiveCardId,
} from '../actions';
import { selectActiveCardId, selectCardsById } from '../selectors';

const createDummyCardWithStatus = ({
  cardId,
  status,
  accountId,
  cancelationDate,
  cardMaskedNumber = '',
  expiryDate = '',
  userFullName = '',
  associatedUserId = '',
}: {
  cardId: string;
  status: SwanPhysicalCardStatus;
  accountId: string;
  cancelationDate?: Date;
  cardMaskedNumber?: string;
  expiryDate?: string;
  userFullName?: string;
  associatedUserId?: string;
}): GetCardDto => ({
  id: cardId,
  status,
  cardDesignUrl: '',
  cardMaskedNumber,
  expiryDate,
  monthlySpending: 0,
  monthlySpendingLimit: 0,
  monthlyCardWithdrawalSpending: 0,
  monthlyCardWithdrawalSpendingLimit: 0,
  accountId,
  cancelationDate,
  userFullName,
  associatedUserId,
});

function* handleSetActiveCardId({
  payload: cardId,
}: PayloadAction<string | undefined>): Generator {
  if (!cardId) {
    return;
  }
  const fallbackAccountId = (yield select(selectFallbackAccountId)) as string;
  const companyUsers = (yield select(selectCompanyUsers)) as CompanyUserDto[];
  if (cardId.includes(TO_CREATE_CARD_ID)) {
    const companyUser = companyUsers.find(
      (user) => user.userId === cardId.split(CARD_SEPARATOR)[1]
    );
    yield put(
      fetchActiveCardSuccess(
        createDummyCardWithStatus({
          cardId,
          status: CardStatus.TO_CREATE as unknown as SwanPhysicalCardStatus,
          accountId: fallbackAccountId,
          associatedUserId: companyUser?.userId,
          userFullName: `${companyUser?.firstName} ${companyUser?.lastName}`,
        })
      )
    );
    return;
  }
  if (cardId.includes(TO_PROCESS_CARD_ID)) {
    const companyUser = companyUsers.find(
      (user) => user.userId === cardId.split(CARD_SEPARATOR)[1]
    );
    yield put(
      fetchActiveCardSuccess({
        id: cardId,
        status: CardStatus.Processing as unknown as SwanPhysicalCardStatus,
        cardDesignUrl: '',
        cardMaskedNumber: '',
        expiryDate: '',
        monthlySpending: 0,
        monthlySpendingLimit: 0,
        monthlyCardWithdrawalSpendingLimit: 0,
        monthlyCardWithdrawalSpending: 0,
        accountId: fallbackAccountId,
        associatedUserId: companyUser?.userId || '',
        userFullName: `${companyUser?.firstName} ${companyUser?.lastName}`,
      })
    );
    return;
  }

  const cardsById = (yield select(selectCardsById)) as Record<
    string,
    BareCardDto
  >;

  const bareCard = cardsById[cardId];
  if (bareCard) {
    const { status, cancelationDate, cardMaskedNumber, expiryDate } = bareCard;
    if (status === SwanPhysicalCardStatus.Processing) {
      yield put(
        fetchActiveCardSuccess(
          createDummyCardWithStatus({
            cardId,
            status: CardStatus.Processing,
            accountId: fallbackAccountId,
            userFullName: bareCard.userFullName,
            associatedUserId: bareCard.associatedUserId,
          })
        )
      );
      return;
    }

    if (
      status === SwanPhysicalCardStatus.Canceling ||
      status === SwanPhysicalCardStatus.Canceled ||
      status === SwanPhysicalCardStatus.Suspended
    ) {
      yield put(
        fetchActiveCardSuccess(
          createDummyCardWithStatus({
            cardId,
            status,
            accountId: fallbackAccountId,
            cancelationDate,
            cardMaskedNumber,
            expiryDate,
            userFullName: bareCard.userFullName,
            associatedUserId: bareCard.associatedUserId,
          })
        )
      );
      return;
    }
  }

  if (fallbackAccountId) {
    try {
      const response = yield* call(getCardByIdRequest, cardId);
      const { data: card } = response;
      const currentCardId = (yield select(selectActiveCardId)) as string;
      if (card && card.id === currentCardId) {
        yield put(fetchActiveCardSuccess(card));
      }
    } catch (error: unknown) {
      console.error(
        `[handleSetActiveCardId] - Error trying to set active card ${cardId} for account ${fallbackAccountId}`,
        error
      );
      yield put(fetchActiveCardFailed());
    }
  }
}

function* setActiveCardIdSaga(): Generator {
  yield takeEvery(setActiveCardId, handleSetActiveCardId);
}

export default setActiveCardIdSaga;
