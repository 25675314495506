import { useCallback, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { OnboardingStep, SubscriptionFeatureStatus } from '@legalplace/shared';
import { BankIconLoader, LoadingManager } from '@legalplace/storybook';

import { Status } from '../../constants';
import { PageVariant } from '../../constants/pageWrapper.constants';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { useCurrentDeviceBreakpoint } from '../../hooks/useCurrentDeviceBreakpoint';
import { selectActiveCompany } from '../../store/companies/selectors';
import { setPageVariant } from '../../store/layout/actions';
import { startFetchOnboardingSteps } from '../../store/onboardingSteps/actions';
import {
  selectOnboardingSteps,
  selectOnboardingStepsFetchStatus,
} from '../../store/onboardingSteps/selector';

import { BankSynchronization } from './OnboardingSteps/BankSynchronization/BankSynchronization';
import { CardDelivery } from './OnboardingSteps/CardDelivery/CardDelivery';
import { ContractSignature } from './OnboardingSteps/ContractSignature/ContractSignature';
import { CredentialsCreation } from './OnboardingSteps/CredentialsCreation/CredentialsCreation';
import { OnboardingCall } from './OnboardingSteps/OnboardingCall/OnboardingCall';
import { TaxesCredentials } from './OnboardingSteps/TaxesCredentials/TaxesCredentials';
import { AccountingTrialEndBanner } from './AccountingTrialEndBanner';

import { OnboardingStepContentContainer } from './OnboardingV2Steps.style';

export function OnboardingStepContent(): JSX.Element | null {
  const { currentStep, stepsLeftCount, accountingOnboardingInformation } =
    useAppSelector(selectOnboardingSteps);
  const onboardingStepsFetchStatus = useAppSelector(
    selectOnboardingStepsFetchStatus
  );
  const { isMobileOrTablet, isSmallDesktop } = useCurrentDeviceBreakpoint();

  const shouldShowMobileVersion = isMobileOrTablet || isSmallDesktop;

  const dispatch = useAppDispatch();

  const refreshSteps = useCallback(() => {
    dispatch(startFetchOnboardingSteps({ silent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!shouldShowMobileVersion) {
      dispatch(
        setPageVariant(
          currentStep?.step === OnboardingStep.CredentialsCreation
            ? PageVariant.NO_MENU
            : PageVariant.DEFAULT
        )
      );
    }
  }, [dispatch, currentStep?.step, shouldShowMobileVersion]);

  const activeCompany = useAppSelector(selectActiveCompany);

  const shouldShowAccountingTrialEndBanner =
    activeCompany?.featureMap?.ComptaBase?.status ===
      SubscriptionFeatureStatus.TrialExpired &&
    !accountingOnboardingInformation?.isContractSigned;

  const isOnboardingCallStep =
    currentStep?.step === OnboardingStep.OnboardingCall;
  const isContractSignatureStep =
    currentStep?.step === OnboardingStep.ContractSignature &&
    !accountingOnboardingInformation?.isContractSigned;

  const isFullScreenStep =
    isOnboardingCallStep ||
    isContractSignatureStep ||
    shouldShowAccountingTrialEndBanner;

  if (stepsLeftCount === 0 && !shouldShowMobileVersion) {
    return <Navigate to="/" replace />;
  }

  return (
    <LoadingManager
      customLoadingComponent={<BankIconLoader spacing={{ default: 'auto' }} />}
      isLoading={onboardingStepsFetchStatus === Status.LOADING}
    >
      <OnboardingStepContentContainer
        isFullScreen={isFullScreenStep}
        shouldShowAccountingTrialEndBanner={shouldShowAccountingTrialEndBanner}
      >
        {shouldShowAccountingTrialEndBanner && <AccountingTrialEndBanner />}
        {currentStep?.step === OnboardingStep.CredentialsCreation && (
          <CredentialsCreation notifySuccess={refreshSteps} />
        )}
        {currentStep?.step === OnboardingStep.ContractSignature && (
          <ContractSignature notifySuccess={refreshSteps} />
        )}
        {currentStep?.step === OnboardingStep.OnboardingCall && (
          <OnboardingCall notifySuccess={refreshSteps} />
        )}
        {currentStep?.step === OnboardingStep.BankAccountsSynchronization && (
          <BankSynchronization notifySuccess={refreshSteps} />
        )}
        {currentStep?.step === OnboardingStep.TaxesCredentials && (
          <TaxesCredentials notifySuccess={refreshSteps} />
        )}
        {currentStep?.step === OnboardingStep.CardDelivery && (
          <CardDelivery notifySuccess={refreshSteps} />
        )}
      </OnboardingStepContentContainer>
    </LoadingManager>
  );
}
