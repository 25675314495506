import { useTranslation } from 'react-i18next';

import type { SubscriptionPricesByPlanAndPeriodicity } from '@legalplace/bankroot-api/modules/accountingSubscriptionPricing/accountingSubscriptionPricing.type';
import {
  AccountingOnboardingSource,
  OnboardingCallInterlocutor,
} from '@legalplace/shared';
import { ModalDrawerV2, ModalSizes } from '@legalplace/storybook';

import { useAppSelector } from '../../../../hooks/store';
import { selectAccountingOnboardingInformation } from '../../../../store/onboardingSteps/selector';
import { PricingTable } from '../OnboardingCall/pricingTable/PricingTable';

export function UpdateAccountingPlanModal({
  isOpen,
  onClose,
  accountingSubscriptionPriceByPlanAndPeriodicity,
}: {
  isOpen: boolean;
  onClose: () => void;
  accountingSubscriptionPriceByPlanAndPeriodicity: SubscriptionPricesByPlanAndPeriodicity;
}): JSX.Element {
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'onboarding_call.pricing_table.update_modal',
  });

  const accountingOnboardingInformation = useAppSelector(
    selectAccountingOnboardingInformation
  );

  const onboardingCallInterlocutor =
    accountingOnboardingInformation?.source ===
    AccountingOnboardingSource.CreaPlusCompta
      ? OnboardingCallInterlocutor.Accountant
      : OnboardingCallInterlocutor.Advisor;

  return (
    <ModalDrawerV2
      isOpen={isOpen}
      onClose={onClose}
      title={t('title')}
      bodyInternalSpacing={{ default: 'unset' }}
      size={ModalSizes.LARGE}
      modalHeight={{ default: '80%' }}
    >
      <PricingTable
        title={t('pricing_table_title')}
        subtitle={t('pricing_table_subtitle', {
          callInterlocutor: t(
            `call_interlocutor.${onboardingCallInterlocutor}`
          ),
        })}
        shouldDisplaySeparator
        accountingSubscriptionPriceByPlanAndPeriodicity={
          accountingSubscriptionPriceByPlanAndPeriodicity
        }
      />
    </ModalDrawerV2>
  );
}
