import type {
  INavigationListGroup,
  INavigationListItem,
} from '../../../interfaces';

export function groupItemsByGroup(
  itemList: INavigationListItem[]
): INavigationListGroup[] {
  const groups: INavigationListGroup[] = [];
  const groupMap: { [groupId: string]: INavigationListGroup } = {};

  itemList.forEach((item) => {
    const { groupId, groupLabel, groupIcon, groupShowSeparator } = item;

    if (!groupMap[groupId]) {
      const group: INavigationListGroup = {
        groupId,
        groupLabel,
        groupIcon,
        groupShowSeparator,
        items: [],
      };
      groups.push(group);
      groupMap[groupId] = group;
    }

    groupMap[groupId].items.push(item);
  });

  return groups;
}
