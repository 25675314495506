/* eslint-disable react/jsx-props-no-spreading */
import {
  type DetailedHTMLProps,
  type InputHTMLAttributes,
  useCallback,
  useRef,
  useState,
} from 'react';

import {
  Button,
  StreamLineIcon,
  StreamLineIconList,
  type ThemeColorsType,
} from '@legalplace/storybook';

import { InputContainer } from '../inputWrapper/InputWrapper.style';

export function PasswordField({
  hasError,
  placeholderColor,
  className,
  disabled,
  color,
  ...props
}: Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'type'
> & {
  hasError: boolean;
  color?: keyof ThemeColorsType;
  placeholderColor?: keyof ThemeColorsType;
  className?: string;
  disabled?: boolean;
}): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const onContainerClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const shouldDisplayEyeButton = !disabled;

  return (
    <InputContainer
      hasError={hasError}
      placeholderColor={placeholderColor}
      onClick={onContainerClick}
      className={className}
      disabled={disabled}
      color={color}
    >
      <input
        ref={inputRef}
        {...props}
        disabled={disabled}
        type={isVisible ? 'text' : 'password'}
      />
      {shouldDisplayEyeButton && (
        <Button styleType="tertiary" onClick={() => setIsVisible((i) => !i)}>
          <StreamLineIcon
            icon={
              isVisible
                ? StreamLineIconList.iconeeyeoff
                : StreamLineIconList.Eyes
            }
            width="2rem"
            fillColor="primary900"
          />
        </Button>
      )}
    </InputContainer>
  );
}
