import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { PageVariant } from '../../constants/pageWrapper.constants';

import type { LayoutState } from './types';

const initialState: LayoutState = {
  pageVariant: PageVariant.DEFAULT,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setPageVariant: (state, action: PayloadAction<PageVariant>) => {
      state.pageVariant = action.payload;
    },
  },
});

export default layoutSlice.reducer;
