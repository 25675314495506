import type { FindAccountDto } from '@legalplace/bankroot-api/modules/account/dto';
import {
  AccountAccountingStatus,
  AccountStatus,
  BankName,
  FAKE_BRIDGE_ACCOUNT_ID,
} from '@legalplace/shared';
import { createSelector } from '@reduxjs/toolkit';

import { getUnsyncedAccountsCount } from '../../components/homeWidgets/widgets.helper';
import { getFormattedAccountsListForFilter } from '../../components/transactionDashboard/TransactionDashboard.helper';
import { Status } from '../../constants';
import type { IAccountFilterItem } from '../../interfaces';
import type { RootState } from '../../store';

export const selectFallbackAccountId = (state: RootState): string | undefined =>
  state.accounts.allIds && state.accounts.allIds[0];

export const selectSwanAccountId = (state: RootState): string | undefined =>
  Object.values(state.accounts.byId).find(
    (account) => account.bankName === BankName.SWAN
  )?.id;

const selectBalance = (
  state: RootState,
  balanceKey: 'availableBalance' | 'bookedBalance' | 'pendingBalance'
): number | undefined => {
  const { allIds } = state.accounts;
  if (allIds.length) {
    return allIds.reduce(
      (acc, accountId) =>
        acc + (state.accounts.byId[accountId][balanceKey] ?? 0),
      0
    );
  }
  return undefined;
};

export const selectHasNoAccounts = (state: RootState): boolean =>
  state.accounts.status !== Status.LOADING && !state.accounts.allIds?.length;

export const selectAvailableBalance = (state: RootState): number | undefined =>
  selectBalance(state, 'availableBalance');

export const selectBookedBalance = (state: RootState): number | undefined => {
  if (selectHasNoAccounts(state)) {
    return 0;
  }

  return selectBalance(state, 'bookedBalance');
};

export const selectPendingBalance = (state: RootState): number | undefined =>
  selectBalance(state, 'pendingBalance');

export const selectBalanceLoading = (state: RootState): boolean => {
  if (selectHasNoAccounts(state)) {
    return false;
  }

  return (
    typeof selectBookedBalance(state) === 'undefined' ||
    typeof selectPendingBalance(state) === 'undefined'
  );
};

export const selectAccountStatus = (state: RootState): Status =>
  state.accounts.status;

export const selectIsBankAccountSuspended = (state: RootState): boolean =>
  Object.values(state.accounts.byId).find(({ bankName }) =>
    BankName.SWAN.toString().includes(bankName)
  )?.bankAccountStatus === AccountStatus.Suspended;

export const selectAccountsById = (
  state: RootState
): Record<string, FindAccountDto> => state.accounts.byId;

export const selectAccountsAllIds = (state: RootState): string[] =>
  state.accounts.allIds;

export const selectAreAccountsLoading = (state: RootState): boolean =>
  state.accounts.status === Status.LOADING;

export const selectHasRefreshingAccounts = (state: RootState): boolean =>
  Object.values(state.accounts.byId).some(({ isRefreshing }) => isRefreshing);

export const selectHasBridgeAccounts = (state: RootState): boolean =>
  Object.values(state.accounts.byId).some(
    ({ bankName }) =>
      ![BankName.SWAN.toString(), BankName.EXPENSES.toString()].includes(
        bankName
      )
  );

export const selectUnsyncedAccountsCount = (state: RootState): number =>
  getUnsyncedAccountsCount(state.accounts.byId);

export const selectHasOnlySwanAccounts = (state: RootState): boolean =>
  !!selectSwanAccountId(state) && selectAccountsAllIds(state).length === 1;

export const selectPendingAccounts = createSelector(
  (state: RootState) => state.accounts.byId,
  (accountsById): FindAccountDto[] => {
    const pendingAccount = Object.values(accountsById).find(
      ({ accountingStatus, isRefreshing }) =>
        accountingStatus === AccountAccountingStatus.PENDING && !isRefreshing
    );

    const bridgeItemWithPendingAccounts = pendingAccount?.bridgeItemId;

    const pendingAccounts = Object.values(accountsById).filter(
      ({ bridgeItemId, accountingStatus, id, externalAccountConnectionId }) =>
        (externalAccountConnectionId ||
          bridgeItemId === bridgeItemWithPendingAccounts) &&
        accountingStatus === AccountAccountingStatus.PENDING &&
        id !== FAKE_BRIDGE_ACCOUNT_ID
    );

    return pendingAccounts;
  }
);

export const selectAccountFilterItems = createSelector(
  (state: RootState) => state.accounts.byId,
  (accountsById): IAccountFilterItem[] =>
    getFormattedAccountsListForFilter(accountsById)
);
