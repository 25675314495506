import accounting_information from './accountingInformation';
import associates from './associates';
import company_information from './companyInformation';
import company_search from './companySearch';
import confirm_email from './confirmEmail';
import email from './email';
import user_information from './userInformation';

export const companyAccountingOnboarding = {
  user_information,
  associates,
  accounting_information,
  company_information,
  email,
  confirm_email,
  company_search,
};
