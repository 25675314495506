import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ModalDrawerV2,
  ModalVariants,
  Typography,
} from '@legalplace/storybook';

import {
  SELECT_SYNCHRONIZED_ACCOUNTS_MODAL_MIN_WIDTH,
  SelectSynchronizedAccountStep,
} from '../../constants';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { finalizeBridgeItemRequest } from '../../services/api/bridgeItem.api';
import { finalizeExternalAccountConnectionRequest } from '../../services/api/externalAccount.api';
import { startFetchAccounts } from '../../store/accounts/actions';
import { selectPendingAccounts } from '../../store/accounts/selectors';
import { selectActiveCompanyId } from '../../store/companies/selectors';
import { selectIsOnboardingDrawerOpen } from '../../store/onboardingSteps/selector';

import {
  getInitialNewAccountsToSynchronize,
  getNewAccountsToSynchronizeValues,
} from './selectSynchronizedAccountsModal.helper';
import { SelectSynchronizedAccountsToggleList } from './SelectSynchronizedAccountsToggleList';

export function SelectSynchronizedAccountsModal(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('select_synchronized_accounts_modal');

  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState<SelectSynchronizedAccountStep>(
    SelectSynchronizedAccountStep.SELECT_ACCOUNTS
  );
  const isOnboardingPage = window.location.href.includes('onboarding-v2');
  const isOnboardingDrawerOpen = useAppSelector(selectIsOnboardingDrawerOpen);
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = (): void => {
    setCurrentStep(SelectSynchronizedAccountStep.SELECT_ACCOUNTS);
    setIsOpen(false);
  };
  const pendingAccounts = useAppSelector(selectPendingAccounts);
  const [newAccountsToSynchronize, setNewAccountsToSynchronize] = useState<
    Record<string, boolean>
  >({});
  const accountsToSynchronize = getNewAccountsToSynchronizeValues(
    newAccountsToSynchronize
  );
  const companyId = useAppSelector(selectActiveCompanyId);

  const isSelectAccountStep =
    currentStep === SelectSynchronizedAccountStep.SELECT_ACCOUNTS;

  useEffect(() => {
    if (pendingAccounts.length) {
      setNewAccountsToSynchronize(
        getInitialNewAccountsToSynchronize(pendingAccounts)
      );
    }
  }, [pendingAccounts]);

  useEffect(() => {
    if (
      pendingAccounts.length > 1 &&
      !isOnboardingDrawerOpen &&
      !isOnboardingPage
    ) {
      setIsOpen(true);
    }
  }, [isOnboardingDrawerOpen, isOnboardingPage, pendingAccounts]);

  useEffect(() => {
    if (
      pendingAccounts.length === 1 &&
      accountsToSynchronize.length &&
      !isOnboardingDrawerOpen &&
      !isOnboardingPage
    ) {
      finalizeBridgeItemRequest(
        pendingAccounts[0].bridgeItemId as string,
        accountsToSynchronize
      )
        .then(() => dispatch(startFetchAccounts()))
        .catch(() =>
          console.error(
            `[finalizeBridgeItem] - Error automatically finalizing bridge item ${pendingAccounts[0].bridgeItemId}`
          )
        );
    }
  }, [
    accountsToSynchronize,
    dispatch,
    isOnboardingDrawerOpen,
    isOnboardingPage,
    pendingAccounts,
  ]);

  useEffect(() => {
    if (pendingAccounts.length === 0) {
      setIsOpen(false);
    }
  }, [pendingAccounts.length]);

  const finalizeBridgeItem = async (
    pendingBridgeItemId: string,
    selectedAccountIds: string[] = []
  ): Promise<void> => {
    try {
      setIsLoading(true);
      await finalizeBridgeItemRequest(pendingBridgeItemId, selectedAccountIds);
      dispatch(startFetchAccounts());
    } catch (error: unknown) {
      console.error(
        `[finalizeBridgeItem] - Error finalizing bridge item ${pendingBridgeItemId}`
      );
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const finalizeExternalAccountConnection = async (
    pendingExternalAuthId: string,
    selectedAccountIds: string[] = []
  ): Promise<void> => {
    try {
      setIsLoading(true);
      await finalizeExternalAccountConnectionRequest(
        pendingExternalAuthId,
        selectedAccountIds,
        companyId
      );
      dispatch(startFetchAccounts());
    } catch (error: unknown) {
      console.error(
        `[finalizeExternalAuth] - Error finalizing external auth ${pendingExternalAuthId}`
      );
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };
  const finalizeAccounts = async (): Promise<void> => {
    if (
      !pendingAccounts?.length ||
      (!pendingAccounts[0].bridgeItemId &&
        !pendingAccounts[0].externalAccountConnectionId)
    ) {
      return;
    }

    const {
      bridgeItemId: pendingBridgeItemId,
      externalAccountConnectionId: pendingExternalAccountConnectionId,
    } = pendingAccounts[0];

    const selectedAccountIds = isSelectAccountStep ? accountsToSynchronize : [];

    if (pendingBridgeItemId) {
      finalizeBridgeItem(pendingBridgeItemId, selectedAccountIds);
    } else if (pendingExternalAccountConnectionId) {
      finalizeExternalAccountConnection(
        pendingExternalAccountConnectionId,
        selectedAccountIds
      );
    }
  };

  const toggleStep = (): void => {
    const newStep = isSelectAccountStep
      ? SelectSynchronizedAccountStep.CONFIRM_ACCOUNTS_REMOVAL
      : SelectSynchronizedAccountStep.SELECT_ACCOUNTS;
    setCurrentStep(newStep);
  };

  return (
    <ModalDrawerV2
      isOpen={isOpen}
      onClose={
        isSelectAccountStep
          ? () =>
              setCurrentStep(
                SelectSynchronizedAccountStep.CONFIRM_ACCOUNTS_REMOVAL
              )
          : undefined
      }
      title={
        isSelectAccountStep
          ? t('select_modal_title')
          : t('remove_account_modal_title')
      }
      isDrawerFullHeight={isSelectAccountStep}
      modalMaxHeight={{ default: 'calc(100% - 3.2rem)' }}
      modalMinWidth={{ default: SELECT_SYNCHRONIZED_ACCOUNTS_MODAL_MIN_WIDTH }}
      modalVariant={ModalVariants.ACTION}
      secondaryAction={toggleStep}
      buttonLabels={{
        primary: isSelectAccountStep
          ? t('submit_button_label')
          : t('confirm_cancelation_button_label'),
        secondary: isSelectAccountStep
          ? t('cancel_button_label')
          : t('back_button_label'),
      }}
      primaryAction={finalizeAccounts}
      primaryButtonProps={{
        isLoading,
        disabled: isSelectAccountStep && !accountsToSynchronize?.length,
        styleType: isSelectAccountStep ? 'primary' : 'danger',
      }}
    >
      {currentStep === SelectSynchronizedAccountStep.SELECT_ACCOUNTS && (
        <SelectSynchronizedAccountsToggleList
          newAccountsToSynchronize={newAccountsToSynchronize}
          setNewAccountsToSynchronize={setNewAccountsToSynchronize}
        />
      )}
      {currentStep ===
        SelectSynchronizedAccountStep.CONFIRM_ACCOUNTS_REMOVAL && (
        <Typography tag="p3" color="primary900">
          {t('remove_account_confirmation_message')}
        </Typography>
      )}
    </ModalDrawerV2>
  );
}
