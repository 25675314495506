import { call, put, select, takeEvery } from 'typed-redux-saga';

import type { PayloadAction } from '@reduxjs/toolkit';

import { getActiveFiltersFromQueryParams } from '../../../../components/transactionDashboard/TransactionDashboard.helper';
import { QUOTE_ACTIVE_FILTERS } from '../../../../constants';
import { getQuotesRequest } from '../../../../services/api/billing/quotes.api';
import { selectActiveCompanyId } from '../../../companies/selectors';
import type { PaginationOptions } from '../../../transactions/types';
import {
  failQuotesRequest,
  fetchQuotesSuccess,
  startFetchQuotesRequest,
} from '../actions';

function* handleFetchQuotes(
  action: PayloadAction<Partial<PaginationOptions>>
): Generator {
  let companyId = '';
  try {
    companyId = (yield select(selectActiveCompanyId)) as string;
    const filters = getActiveFiltersFromQueryParams(
      QUOTE_ACTIVE_FILTERS,
      new URLSearchParams(window.location.search)
    );
    const { page = 1, take = 10 } = action.payload;

    const response = yield* call(getQuotesRequest, companyId, filters, {
      page,
      take,
    });
    const { data: quote } = response;
    yield put(fetchQuotesSuccess(quote));
  } catch (error: unknown) {
    console.error(
      `[handleFetchQuote] - Error trying to fetch quote for company : ${companyId}`,
      error
    );
    yield put(failQuotesRequest());
  }
}

function* fetchQuotesSaga(): Generator {
  yield takeEvery(startFetchQuotesRequest, handleFetchQuotes);
}

export default fetchQuotesSaga;
