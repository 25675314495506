import {
  Button,
  css,
  FlexAlignCenterJustifySpaceBetween,
  FlexContainerBasicAlignCenter,
  FlexContainerBasicColumn,
  keyframes,
  styled,
} from '@legalplace/storybook';

const closeAnimation = keyframes`
  0% {
    visibility:visible;
  }
  100% {
    visibility:visible;
  }
`;

export const DesktopAcademyModalBackdrop = styled.div<{ isOpen: boolean }>`
  background-color: rgba(5, 18, 89, 0.2);
  position: fixed;
  top: 0;
  left: ${({ theme }) => theme.layout.leftMenuWidth};
  width: 100%;
  height: 100%;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease;
  z-index: 1;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      animation: 0.3s ${closeAnimation};
    `}
`;

export const DesktopAcademyModalContainer = styled(FlexContainerBasicColumn)<{
  isOpen: boolean;
}>`
  background-color: ${({ theme }) => theme.white};
  position: fixed;
  top: 0;
  left: ${({ theme }) => theme.layout.leftMenuWidth};
  margin: ${({ theme }) => theme.spacing.small};
  border-radius: ${({ theme }) => theme.borders.modalRadiusMd};
  z-index: 2;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: scale(${({ isOpen }) => (isOpen ? 1 : 0)});
  transform-origin: 0 10.8rem;
  transition: transform 0.3s, opacity 0.3s;
`;

export const DesktopAcademyModalHeader = styled(FlexContainerBasicAlignCenter)`
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const TitleContainer = styled(FlexAlignCenterJustifySpaceBetween)`
  gap: ${({ theme }) => theme.spacing.xxsmall};
  width: 100%;
`;

export const CloseButton = styled(Button)`
  width: fit-content;
`;

export const DesktopAcademyModalContent = styled(FlexContainerBasicColumn)`
  padding: ${({ theme }) =>
    `0 ${theme.spacing.medium} ${theme.spacing.medium} ${theme.spacing.medium}};`};
  gap: ${({ theme }) => theme.spacing.medium};
`;

export const ArrowModal = styled.div`
  position: absolute;
  top: 10.8rem;
  left: -1.5%;
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.white};
  rotate: 45deg;
`;
