import { call, put, select, takeEvery } from 'typed-redux-saga';

import type { FindCompanyDto } from '@legalplace/bankroot-api/modules/company/dto';
import { SubscriptionFeatureStatus } from '@legalplace/shared';
import * as Sentry from '@sentry/react';

import { setActiveCompany } from '../../../services/api/analytics.api';
import { startFetchAccounts } from '../../accounts/actions';
import { startFetchInvoiceRequest } from '../../billing/invoice/actions';
import { startFetchQuotesRequest } from '../../billing/quote/actions';
import { startFetchOnboardingSteps } from '../../onboardingSteps/actions';
import { startFetchCompanyUsers } from '../../user/actions';
import {
  setActiveCompanyId,
  setActiveVatStartDate,
  startFetchActiveCompanyDashboardWidgetVisibility,
  startFetchActiveCompanyPcaBalance,
  startFetchActiveCompanyUserRights,
  startFetchActiveCompanyVatBalance,
} from '../actions';
import { selectActiveCompany } from '../selectors';

function* handleSetActiveCompanyId(): Generator {
  const activeCompany = (yield select(selectActiveCompany)) as FindCompanyDto;
  yield put(startFetchOnboardingSteps());
  const { featureMap } = activeCompany;
  const hasExpiredAccountingTrial =
    featureMap?.ComptaBase?.status === SubscriptionFeatureStatus.TrialExpired;
  const hasAccounting = featureMap?.ComptaBase?.isActive;
  const hasBank = featureMap?.BankBase?.isActive;

  if (hasBank || hasAccounting || hasExpiredAccountingTrial) {
    try {
      yield* call(setActiveCompany, activeCompany.id);
    } catch (error: unknown) {
      console.error(error);
      Sentry.captureException(error);
    }
    yield put(startFetchAccounts());
    yield put(startFetchActiveCompanyVatBalance());
    yield put(startFetchActiveCompanyPcaBalance());
    yield put(startFetchActiveCompanyDashboardWidgetVisibility());
    yield put(startFetchCompanyUsers());
    yield put(startFetchActiveCompanyUserRights());
    yield put(setActiveVatStartDate());

    const hasInvoicing = activeCompany.featureMap?.Invoicing?.isActive;

    if (hasInvoicing) {
      yield put(startFetchInvoiceRequest({ page: 1, take: 10 }));
      yield put(startFetchQuotesRequest({ page: 1, take: 10 }));
    }
  }
}

function* setActiveCompanyIdSaga(): Generator {
  yield takeEvery(setActiveCompanyId, handleSetActiveCompanyId);
}

export default setActiveCompanyIdSaga;
