export enum CreditTransferSteps {
  BENEFICIARY = 'beneficiary',
  AMOUNT = 'amount',
  PENDING_CONSENT = 'pendingConsent',
  FAILED_CONSENT = 'failedConsent',
  SUCCESS_CONSENT = 'SuccessConsent',
}

export const BENEFICIARY_NAME_MINIMUM_LENGTH = 2;
export const BENEFICIARY_NAME_MAXIMUM_LENGTH = 70;

export const BENEFICIARY_AUTO_COMPLETE_OPTIONS = {
  keys: ['name'],
  threshold: 0.0,
  ignoreLocation: true,
};

export const USER_INPUT_ID = 'userInput';

export const BENEFICIARY_ITEM_MODEL = {
  name: '',
  id: USER_INPUT_ID,
  iban: '',
  accountId: '',
};
