import type { TFunction } from 'react-i18next';

import type { FindAccountDto } from '@legalplace/bankroot-api/modules/account/dto/findAccount.dto';
import {
  AccountAccountingStatus,
  BankName,
  FAKE_BRIDGE_ACCOUNT_ID,
} from '@legalplace/shared';

export const getUnsyncedAccountsCount = (
  accounts: Record<string, FindAccountDto>
): number =>
  Object.values(accounts).filter(
    ({ bankName, accountStatus, accountingStatus, id }) =>
      bankName !== BankName.SWAN &&
      accountStatus !== 0 &&
      (accountingStatus === AccountAccountingStatus.ACTIVE ||
        (accountingStatus === AccountAccountingStatus.PENDING &&
          id === FAKE_BRIDGE_ACCOUNT_ID))
  ).length;

export const getBannerTitleAndSubtitle = (
  desynchronizedAccountsCount: number,
  nonCategorizedTransactionCount: number,
  transactionsWithMissingProofCount: number,
  isCompanySubjectToVat: boolean,
  t: TFunction
):
  | {
      title: string;
      subtitle: string;
      actionLabel: string;
      action: 'see-transactions' | 'synchronize-account';
    }
  | undefined => {
  if (desynchronizedAccountsCount > 0) {
    return {
      title: t('desynchronized_accounts.title'),
      subtitle: t('desynchronized_accounts.subtitle'),
      actionLabel: t('actions.synchronize_account'),
      action: 'synchronize-account',
    };
  }
  if (
    nonCategorizedTransactionCount > 0 &&
    transactionsWithMissingProofCount > 0
  ) {
    return {
      title: t('missing_proof_and_uncategorized.title', {
        uncategorized_transaction_count: nonCategorizedTransactionCount,
        missing_proof_count: transactionsWithMissingProofCount,
      }),
      subtitle: t(
        `missing_proof_and_uncategorized.subtitle${
          isCompanySubjectToVat ? '' : '_no_vat'
        }`
      ),
      actionLabel: t('actions.see_transactions'),
      action: 'see-transactions',
    };
  }
  if (nonCategorizedTransactionCount > 0) {
    return {
      title: t('uncategorized_transactions.title', {
        uncategorized_transaction_count: nonCategorizedTransactionCount,
      }),
      subtitle: t(
        `uncategorized_transactions.subtitle${
          isCompanySubjectToVat ? '' : '_no_vat'
        }`
      ),
      actionLabel: t('actions.see_transactions'),
      action: 'see-transactions',
    };
  }
  if (transactionsWithMissingProofCount > 0) {
    return {
      title: t('missing_proof.title', {
        missing_proof_count: transactionsWithMissingProofCount,
      }),
      subtitle: t('missing_proof.subtitle'),
      actionLabel: t('actions.see_transactions'),
      action: 'see-transactions',
    };
  }
  return undefined;
};
