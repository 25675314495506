import { AccountingPlanName } from '@legalplace/shared';

import type { AccountingFeatures } from '../../../../../../constants/accountingFeatures.constants';
import {
  ELITE_FEATURES,
  ESSENTIAL_FEATURES,
  STARTER_FEATURES,
} from '../../../../../../constants/accountingFeatures.constants';

const AccountingSubscriptionPlanToFeatures: Record<
  AccountingPlanName,
  { feature: AccountingFeatures; isAvailable: boolean }[]
> = {
  [AccountingPlanName.STARTER]: STARTER_FEATURES,
  [AccountingPlanName.ESSENTIAL]: ESSENTIAL_FEATURES,
  [AccountingPlanName.ELITE]: ELITE_FEATURES,
};

export function mapAccountingSubscriptionPlanToFeatures(
  accountingSubscriptionPlan: AccountingPlanName
): { feature: AccountingFeatures; isAvailable: boolean }[] {
  return AccountingSubscriptionPlanToFeatures[accountingSubscriptionPlan];
}
