import { Fragment, useState } from 'react';

import type { AcademyArticleDto } from '@legalplace/bankroot-api/modules/academy/dto';
import type { StreamLineIconList } from '@legalplace/storybook';
import { FlexContainerBasicColumn, Separator } from '@legalplace/storybook';

import { useAppSelector } from '../../../../hooks/store';
import { useGetAcademyQuery } from '../../../../store/academy/reducer';
import { selectActiveCompany } from '../../../../store/companies/selectors';
import { ProgressCollapse } from '../../../common/layout/ProgressCollapse/ProgressCollapse';
import { AcademyCheckbox } from '../../AcademyCheckbox/AcademyCheckbox';

import {
  computeProgress,
  getNextUncompletedSectionIndex,
} from './AcademyModalBody.helper';

interface IAcademyModalBodyProps {
  openEndMessage: () => void;
}

export function AcademyModalBody({
  openEndMessage,
}: IAcademyModalBodyProps): JSX.Element {
  const activeCompany = useAppSelector(selectActiveCompany);
  const { data = { sections: [] } } = useGetAcademyQuery(
    activeCompany?.id ?? '',
    { skip: !activeCompany?.id }
  );
  const { sections } = data;
  const [openedSectionIndex, setOpenedSectionIndex] = useState<number>(-1);

  const handleCollapse = (index: number): void => {
    setOpenedSectionIndex(index === openedSectionIndex ? -1 : index);
  };

  const remainingArticlesForSection: AcademyArticleDto[][] = sections.map(
    (section) => section.articles.filter((article) => !article.isRead)
  );

  const openNextSection = (articleId: string): void => {
    const remainingArticlesInCurrentSection =
      remainingArticlesForSection[openedSectionIndex];
    const allRemainingArticles = remainingArticlesForSection.flat();

    const shouldOpenNextSectionIfCurrentIsDone =
      remainingArticlesInCurrentSection.length === 1 &&
      remainingArticlesInCurrentSection[0].id === articleId;

    if (shouldOpenNextSectionIfCurrentIsDone) {
      setOpenedSectionIndex(
        getNextUncompletedSectionIndex(sections, openedSectionIndex)
      );
    }

    const shouldToggleEndingMessage =
      allRemainingArticles.length === 1 &&
      allRemainingArticles[0].id === articleId;

    if (shouldToggleEndingMessage) {
      openEndMessage();
    }
  };

  return (
    <FlexContainerBasicColumn gap={{ default: ' 0.8rem' }}>
      {sections.map((section, index) => (
        <Fragment key={section.title}>
          {index !== 0 && <Separator />}
          <ProgressCollapse
            title={section.title}
            icon={section.icon as StreamLineIconList}
            subtitle={section.duration}
            progress={computeProgress(section)}
            fillColor="primary800"
            arrowColor="primary500"
            fontColor="primary900"
            iconBackgroundColor="pink50"
            isOpen={openedSectionIndex === index}
            setIsOpen={() => handleCollapse(index)}
          >
            <FlexContainerBasicColumn gap={{ default: ' 1.2rem' }}>
              {section.articles.map((article) => (
                <AcademyCheckbox
                  key={article.id}
                  label={article.title}
                  href={article.url}
                  isChecked={article.isRead}
                  articleId={article.id}
                  openNextSection={openNextSection}
                />
              ))}
            </FlexContainerBasicColumn>
          </ProgressCollapse>
        </Fragment>
      ))}
    </FlexContainerBasicColumn>
  );
}
