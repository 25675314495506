import { Button, styled } from '@legalplace/storybook';

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.small};
  width: fit-content;
  align-self: flex-end;
`;

export const ConfirmButton = styled(Button)`
  width: fit-content;
`;

export const CancelButton = styled(Button)`
  width: fit-content;
`;
