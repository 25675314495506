import type { AccountingOnboardingInformationDto } from '@legalplace/bankroot-api/modules/onboardingFlow/dto/accountingOnboardingInformation.dto';

import { Status } from '../../constants';
import type { RootState } from '../../store';

import type { OnboardingStepsState } from './types';

export const selectOnboardingSteps = (state: RootState): OnboardingStepsState =>
  state.onboardingSteps;

export const selectOnboardingStepsFetchStatus = (state: RootState): Status =>
  state.onboardingSteps?.status ?? Status.IDLE;

export const selectIsOnboardingDrawerOpen = (state: RootState): boolean =>
  state.onboardingSteps.isOnboardingDrawerOpen;

export const selectAccountingOnboardingInformation = (
  state: RootState
): AccountingOnboardingInformationDto | undefined =>
  state.onboardingSteps?.accountingOnboardingInformation;

export const selectStepsLeftCount = (state: RootState): number | undefined =>
  state.onboardingSteps?.stepsLeftCount;

export const selectSkippedSteps = (state: RootState): string[] =>
  state.onboardingSteps?.skippedSteps;
