export const formatStringForComparison = (
  stringWithDiactriticsOrUpperCase?: string
): string => {
  if (!stringWithDiactriticsOrUpperCase) {
    return '';
  }
  return stringWithDiactriticsOrUpperCase
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim();
};

export const capitalizeFirstLetter = (value: string): string =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const stripString = (stringToStrip: string): string =>
  stringToStrip.toUpperCase().replace(/[^0-9A-Z]/g, '');
