import type {
  CreateCategorizableItemDto,
  GetAssociateBalancesDTO,
  GetCategorizableItemDto,
  UpdateCategorizableItemRequestDto,
} from '@legalplace/bankroot-api/modules/categorizableItem/dto';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../services/api/api';

export const categorizableItemsApi = createApi({
  reducerPath: 'categorizableItemsApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['CategorizableItem'],
  endpoints: (build) => ({
    getCategorizableItems: build.query<GetCategorizableItemDto[], string>({
      query: (companyId) => ({
        url: `/categorizable-item?companyId=${companyId}`,
      }),
      providesTags: ['CategorizableItem'],
    }),
    addNewCategorizableItem: build.mutation<
      GetCategorizableItemDto,
      CreateCategorizableItemDto
    >({
      query: (addNewCategorizableItemDto) => ({
        url: `/categorizable-item`,
        method: 'POST',
        body: addNewCategorizableItemDto,
      }),
      invalidatesTags: ['CategorizableItem'],
    }),
    updateCategorizableItem: build.mutation<
      GetCategorizableItemDto,
      {
        categorizableItemId: string;
        updateCategorizableItemDto: UpdateCategorizableItemRequestDto;
      }
    >({
      query: ({ categorizableItemId, updateCategorizableItemDto }) => ({
        url: `/categorizable-item/${categorizableItemId}`,
        method: 'PATCH',
        body: updateCategorizableItemDto,
      }),
      invalidatesTags: ['CategorizableItem'],
    }),
    getPCABalances: build.query<GetAssociateBalancesDTO, string>({
      query: (companyId) => ({
        url: `/categorizable-item/balances?companyId=${companyId}`,
      }),
    }),
  }),
});

export const {
  useGetCategorizableItemsQuery,
  useAddNewCategorizableItemMutation,
  useGetPCABalancesQuery,
  useUpdateCategorizableItemMutation,
} = categorizableItemsApi;
