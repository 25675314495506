import { forwardRef, Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { SubscriptionPricesByPlanAndPeriodicity } from '@legalplace/bankroot-api/modules/accountingSubscriptionPricing/accountingSubscriptionPricing.type';
import {
  AccountingPlanName,
  SubscriptionPeriodicity,
} from '@legalplace/shared';
import {
  FlexContainerBasic,
  Separator,
  Typography,
} from '@legalplace/storybook';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { useCurrentDeviceBreakpoint } from '../../../../../hooks/useCurrentDeviceBreakpoint';
import { selectAccountingPlanRequest } from '../../../../../services/api/onboardingFlow.api';
import { selectActiveCompanyId } from '../../../../../store/companies/selectors';
import { startFetchOnboardingSteps } from '../../../../../store/onboardingSteps/actions';
import { selectAccountingOnboardingInformation } from '../../../../../store/onboardingSteps/selector';

import { PricingColumn } from './pricingColumn/PricingColumn';
import { PricingToggle } from './pricingToggle/PricingToggle';

import {
  ColumnsContainer,
  PricingContainer,
  TextContainer,
} from '../PricingTable.style';

export const PricingTable = forwardRef<
  HTMLDivElement,
  {
    title: string;
    subtitle: string;
    shouldDisplaySeparator?: boolean;
    accountingSubscriptionPriceByPlanAndPeriodicity: SubscriptionPricesByPlanAndPeriodicity;
  }
>(
  (
    {
      title,
      subtitle,
      shouldDisplaySeparator,
      accountingSubscriptionPriceByPlanAndPeriodicity,
    },
    ref
  ) => {
    const { t } = useTranslation('onboarding_v2', {
      keyPrefix: 'onboarding_call.pricing_table',
    });
    const dispatch = useAppDispatch();
    const companyId = useAppSelector(selectActiveCompanyId);
    const onboardingInformation = useAppSelector(
      selectAccountingOnboardingInformation
    );

    const [subscriptionPeriodicity, setSubscriptionPeriodicity] =
      useState<SubscriptionPeriodicity>(
        onboardingInformation?.periodicity || SubscriptionPeriodicity.YEARLY
      );

    const [selectedPlan, setSelectedPlan] = useState<
      AccountingPlanName | undefined
    >(onboardingInformation?.plan);

    const { isMobile } = useCurrentDeviceBreakpoint();

    const scrollRef = useRef<HTMLInputElement | null>(null);

    const accountingSubscriptionPlans = Object.values(AccountingPlanName);

    const selectPlanHandler = async (
      plan: AccountingPlanName,
      periodicity: SubscriptionPeriodicity
    ): Promise<void> => {
      if (!companyId) {
        return;
      }
      setSelectedPlan(plan);
      try {
        await selectAccountingPlanRequest(companyId, { plan, periodicity });
        dispatch(startFetchOnboardingSteps({ silent: true }));
      } catch (error: unknown) {
        console.error(
          `[selectPlan] Error while selecting plan for companyId ${companyId}: ${error}`
        );
      }
    };

    useEffect(() => {
      if (scrollRef.current) {
        scrollRef.current?.scrollTo({
          left: scrollRef.current.offsetWidth / 2,
          behavior: 'smooth',
        });
      }
    }, [subscriptionPeriodicity]);

    if (subscriptionPeriodicity === SubscriptionPeriodicity.BIYEARLY) {
      throw new Error('Biyearly subscription is not supported');
    }

    return (
      <>
        {!isMobile && shouldDisplaySeparator && <Separator />}
        <PricingContainer ref={ref}>
          <TextContainer>
            <Typography
              tag="p"
              color="primary900"
              fontWeight="500"
              textAlign="center"
              spacing={{ default: '0 0 1.6rem 0' }}
            >
              {title}
            </Typography>
            <Typography
              tag="p3"
              color="primary900"
              textAlign="center"
              spacing={{ default: '0 0 1.6rem 0' }}
            >
              {subtitle}
            </Typography>
          </TextContainer>
          <FlexContainerBasic spacing={{ default: '1.6rem 0' }}>
            <PricingToggle
              subscriptionPeriodicity={subscriptionPeriodicity}
              setSubscriptionPeriodicity={setSubscriptionPeriodicity}
              t={t}
            />
          </FlexContainerBasic>
          <ColumnsContainer ref={scrollRef}>
            {accountingSubscriptionPlans.map((accountingSubscriptionPlan) => (
              <Fragment key={accountingSubscriptionPlan}>
                <PricingColumn
                  subscriptionPeriodicity={subscriptionPeriodicity}
                  accountingSubscriptionPlan={accountingSubscriptionPlan}
                  isPopular={
                    !selectedPlan &&
                    accountingSubscriptionPlan === AccountingPlanName.ESSENTIAL
                  }
                  isSelected={accountingSubscriptionPlan === selectedPlan}
                  onPlanSelect={selectPlanHandler}
                  price={
                    accountingSubscriptionPriceByPlanAndPeriodicity[
                      accountingSubscriptionPlan
                    ][subscriptionPeriodicity]
                  }
                />
              </Fragment>
            ))}
          </ColumnsContainer>
        </PricingContainer>
      </>
    );
  }
);
