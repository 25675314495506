import {
  type Dispatch,
  Fragment,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { PERIODICITY_TO_MONTHLY_FACTOR } from '@legalplace/bankroot-api/constants';
import {
  AccountingOnboardingSource,
  OnboardingCallInterlocutor,
  STARTING_ACCOUNTING_PRICE_BY_PLAN_AND_PERIODICITY,
  SubscriptionPeriodicity,
} from '@legalplace/shared';
import {
  BankIconLoader,
  BankTertiaryButton,
  FlexContainerBasic,
  FlexContainerBasicAlignCenter,
  FlexContainerBasicColumn,
  Separator,
  ShowDesktopOnly,
  ShowMobileTabletOnly,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { ACCOUNTING_FEATURES_SUMMARY_TRANSLATIONS_KEYS } from '../../../../constants/accountingFeatures.constants';
import { useAppSelector } from '../../../../hooks/store';
import {
  generateContractUrlRequest,
  getSubscriptionPricesRequest,
} from '../../../../services/api/onboardingFlow.api';
import { selectActiveCompanyId } from '../../../../store/companies/selectors';
import { useGetFeatureFlagsQuery } from '../../../../store/featureFlags/reducer';
import { selectAccountingOnboardingInformation } from '../../../../store/onboardingSteps/selector';
import { OnboardingMobileFooter } from '../../../CompanyAccountingOnboardingSteps/steps/OnboardingMobileFooter';

import { UpdateAccountingPlanModal } from './UpdateAccountingPlanModal';

import { PrimaryButton } from '../../../UI/PrimaryButton.style';
import { OnboardingStepBodyContainer } from '../../OnboardingV2Steps.style';
import { SelectedOfferCard } from './ContractSignature.style';

export function AccountingPlanConfirmation({
  setHasConfirmedAccountingPlan,
  setContractSignatureUrl,
}: {
  setHasConfirmedAccountingPlan: Dispatch<SetStateAction<boolean>>;
  setContractSignatureUrl: Dispatch<SetStateAction<string | undefined>>;
}): JSX.Element | null {
  const { t } = useTranslation('onboarding', {
    keyPrefix: 'onboarding_v2.contract_signature',
  });
  const [isSelectPlanModalOpen, setIsSelectPlanModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const companyId = useAppSelector(selectActiveCompanyId);

  const accountingOnboardingInformation = useAppSelector(
    selectAccountingOnboardingInformation
  );
  const [isLoadingPrices, setIsLoadingPrices] = useState(false);
  const { data: featureFlags } = useGetFeatureFlagsQuery(companyId, {
    skip: !companyId,
  });

  const [
    accountingSubscriptionPriceByPlanAndPeriodicity,
    setAccountingSubscriptionPriceByPlanAndPeriodicity,
  ] = useState(STARTING_ACCOUNTING_PRICE_BY_PLAN_AND_PERIODICITY);

  useEffect(() => {
    const getSubscriptionPrices = async (): Promise<void> => {
      if (!companyId) {
        return;
      }
      try {
        setIsLoadingPrices(true);
        const { data: subscriptionPrices } = await getSubscriptionPricesRequest(
          companyId
        );
        setAccountingSubscriptionPriceByPlanAndPeriodicity(subscriptionPrices);
      } catch (error: unknown) {
        console.error(
          `[getSubscriptionPrices] - Error getting subscription prices for company ${companyId}`,
          error
        );
      } finally {
        setIsLoadingPrices(false);
      }
    };

    getSubscriptionPrices();
  }, [companyId]);

  const confirmAccountingPlan = async (): Promise<void> => {
    if (!companyId) {
      return;
    }
    try {
      setIsLoading(true);
      const {
        data: { contractUrl },
      } = await generateContractUrlRequest(companyId);
      setHasConfirmedAccountingPlan(true);
      setContractSignatureUrl(contractUrl);
    } catch (error: unknown) {
      console.error(
        `[confirmAccountingPlan] - Error confirming accounting plan`,
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const { plan, monthlyPrice, periodicity } =
    accountingOnboardingInformation || {};

  if (!plan || !monthlyPrice || !periodicity) {
    throw new Error('plan, monthly price or periodicity must not be empty');
  }

  if (periodicity === SubscriptionPeriodicity.BIYEARLY) {
    throw new Error('Biyearly subscription is not supported');
  }

  const displayedPrice = Math.ceil(
    accountingSubscriptionPriceByPlanAndPeriodicity[plan][periodicity] /
      100 /
      PERIODICITY_TO_MONTHLY_FACTOR[periodicity]
  );

  const isBilledAnnually = periodicity === SubscriptionPeriodicity.YEARLY;

  const onboardingCallInterlocutor =
    accountingOnboardingInformation?.source ===
    AccountingOnboardingSource.CreaPlusCompta
      ? OnboardingCallInterlocutor.Accountant
      : OnboardingCallInterlocutor.Advisor;

  if (isLoadingPrices) {
    return <BankIconLoader spacing={{ default: 'auto auto' }} />;
  }

  return (
    <>
      <OnboardingStepBodyContainer internalspacing={{ default: '2.4rem' }}>
        <FlexContainerBasicColumn gap={{ default: '0.8rem' }}>
          <Typography tag="p" fontWeight="500" color="primary900">
            {t('confirm_plan_title')}
          </Typography>
          <Typography tag="p3" color="primary900">
            {t('confirm_plan_subtitle', {
              callInterlocutor: t(
                `call_interlocutor.${onboardingCallInterlocutor}`
              ),
            })}
          </Typography>
        </FlexContainerBasicColumn>
        <SelectedOfferCard>
          <Typography tag="h4" fontWeight="600" color="primary900">
            {t(`plan_name.${plan}`)}
          </Typography>
          <FlexContainerBasic>
            <Typography tag="h5" fontWeight="700" color="primary500">
              {displayedPrice}
            </Typography>
            <sup>
              <Typography color="primary900">{t('excluding_taxes')}</Typography>
            </sup>
          </FlexContainerBasic>
          {isBilledAnnually && (
            <Typography
              tag="p4"
              color="grey700"
              spacing={{ default: '0.4rem 0' }}
              fontWeight="500"
            >
              {t('billed_annually')}
            </Typography>
          )}
          <Typography tag="p3" color="grey700">
            {t(`plan_description.${plan}`)}
          </Typography>
          <Separator
            backgroundColor="grey300"
            spacing={{ default: '0.8rem 0' }}
          />
          {ACCOUNTING_FEATURES_SUMMARY_TRANSLATIONS_KEYS[plan].map(
            (featureTranslationKey) => (
              <Fragment key={featureTranslationKey}>
                <FlexContainerBasicAlignCenter
                  gap={{ default: '0.8rem' }}
                  internalspacing={{ default: '0.8rem 0' }}
                >
                  <StreamLineIcon
                    icon={StreamLineIconList.check1}
                    fillColor="success600"
                    height="1.2rem"
                  />
                  <Typography tag="p4" color="grey700">
                    {t(`plan_features.${featureTranslationKey}`)}
                  </Typography>
                </FlexContainerBasicAlignCenter>
              </Fragment>
            )
          )}
        </SelectedOfferCard>
        <ShowDesktopOnly>
          <FlexContainerBasicAlignCenter
            gap={{ default: '2.4rem' }}
            spacing={{ default: '0 0 0 auto' }}
          >
            <BankTertiaryButton onClick={() => setIsSelectPlanModalOpen(true)}>
              <Typography tag="p3" color="grey700">
                {t('edit_plan_button_label')}
              </Typography>
            </BankTertiaryButton>
            <PrimaryButton
              width={{ default: 'fit-content' }}
              isLoading={isLoading}
              onClick={confirmAccountingPlan}
            >
              {t('go_to_signature_button_label')}
            </PrimaryButton>
          </FlexContainerBasicAlignCenter>
        </ShowDesktopOnly>
      </OnboardingStepBodyContainer>
      <ShowMobileTabletOnly width={{ default: '100%' }}>
        <OnboardingMobileFooter
          onClick={confirmAccountingPlan}
          isLoading={isLoading}
          primaryButtonLabel={t('go_to_signature_button_label')}
          secondaryButtonLabel={t('edit_plan_button_label')}
          onSecondaryButtonClick={
            featureFlags?.ACCOUNTING_OFFER_SELECTION_FLAG
              ? () => setIsSelectPlanModalOpen(true)
              : undefined
          }
        />
      </ShowMobileTabletOnly>
      <UpdateAccountingPlanModal
        isOpen={isSelectPlanModalOpen}
        onClose={() => setIsSelectPlanModalOpen(false)}
        accountingSubscriptionPriceByPlanAndPeriodicity={
          accountingSubscriptionPriceByPlanAndPeriodicity
        }
      />
    </>
  );
}
