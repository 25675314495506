import {
  flexBase,
  flexBaseAlignCenter,
  flexCenterAllColumn,
  styled,
} from '@legalplace/storybook';

export const InformationTitleContainer = styled.div`
  ${flexCenterAllColumn};
  margin: ${({ theme }) => `${theme.spacing.xsmall} 0`};
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

export const ContentContainer = styled.div`
  ${flexBase};
  color: ${({ theme }) => theme.typographyColors.grey600};
  padding: ${({ theme }) => theme.spacing.xxsmall};
  gap: ${({ theme }) => theme.spacing.xxxsmall};
`;

export const FooterContainer = styled.div`
  ${flexBaseAlignCenter};
  gap: ${({ theme }) => theme.spacing.xsmall};
  margin: ${({ theme }) => `${theme.spacing.large} 0 0 0`};
`;

export const InformationsContainer = styled.div`
  ${flexCenterAllColumn};
`;
