import {
  Button,
  flexBaseAlignCenter,
  flexBaseColumn,
  styled,
} from '@legalplace/storybook';

export const MainContainer = styled.div`
  ${flexBaseColumn};
  flex-grow: 1;
  width: 100%;
`;

export const NavigationListContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.xxsmall};
  border-top: 1px solid ${({ theme }) => theme.backgroundColors.grey125};
  ${flexBaseColumn};
  flex: 1 1 0;
  overflow-y: auto;
`;

export const FooterContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.xxsmall};
`;

export const FooterActionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.xxsmall};
  width: 100%;
  align-items: center;
`;

export const ButtonsGroupContainer = styled.div`
  ${flexBaseAlignCenter}
  justify-content: space-evenly;
  height: 3.2rem;
`;

export const VerticalDivider = styled.div`
  border-left: 1px solid ${(props) => props.theme.backgroundColors.grey125};
  height: 100%;
  width: 0;
  padding-left: ${({ theme }) => theme.spacing.xxsmall};
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
`;

export const LeftMenuButton = styled(Button)`
  border-radius: ${(props) => props.theme.borders.radiusMd};

  &:hover {
    background-color: ${(props) => props.theme.backgroundColors.grey50};
  }

  height: 100%;
  width: 100%;
`;

export const NavigationListButton = styled(Button)<{ $isSelected?: boolean }>`
  ${flexBaseAlignCenter};
  width: 100%;
  height: 4rem;
  padding: ${({ theme }) =>
    `${theme.spacing.xxxxsmall} ${theme.spacing.xxsmall}`};
  text-decoration: unset;
  gap: ${({ theme }) => theme.spacing.xxsmall};
  background-color: ${({ $isSelected, theme }) =>
    `${$isSelected && theme.backgroundColors.primary50};
    `};
  border-radius: ${({ $isSelected, theme }) =>
    $isSelected && theme.borders.radiusSm};
`;

export const LeftMenuContainer = styled.div`
  ${flexBaseColumn};
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: ${(props) => props.theme.layout.leftMenuWidth};
  background-color: ${(props) => props.theme.backgroundColors.white};
  border-right: 1px solid ${(props) => props.theme.backgroundColors.grey100};
`;
