import type { ILayoutDeviceSizes, ITheme } from '@legalplace/storybook';
import {
  Button,
  flexBase,
  flexBaseAlignCenter,
  styled,
} from '@legalplace/storybook';

import { addStyleInMobile } from '../UI/Responsive.style';

export const PageTitleWrapper = styled.div`
  ${flexBase};
  gap: ${(props: { theme: ITheme }) => props.theme.spacing.xxsmall};
`;

export const PageTitleContainer = styled.div<{
  spacing?: ILayoutDeviceSizes;
  shouldWrapTitle?: boolean;
}>`
  ${flexBaseAlignCenter};
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xsmall}) {
    ${({ shouldWrapTitle }) =>
      shouldWrapTitle && 'justify-content: center; flex-wrap: wrap;'};
    gap: ${(props: { theme: ITheme }) => props.theme.spacing.xxsmall};
  }
`;

export const ButtonContainer = styled.div`
  ${flexBase};
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

export const ActionButton = styled(Button)`
  min-width: 8.5rem;

  ${({ theme }) => addStyleInMobile(theme, `min-width: unset;`)}
`;
