import {
  flexBase,
  flexBaseColumn,
  FlexContainerBasicColumn,
  styled,
} from '@legalplace/storybook';

import { fullHeightPageBaseStyles } from '../../mixins';

import { addStyleInDesktop } from '../UI/Responsive.style';

export const OnboardingMenuContainer = styled.div`
  ${flexBaseColumn};
  ${fullHeightPageBaseStyles};
  align-items: flex-start;
  width: 26.8rem;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.backgroundColors.white};
  border-right: 1px solid ${({ theme }) => theme.typographyColors.grey100};
  padding: ${({ theme }) => `0 ${theme.spacing.small}`};
  flex-shrink: 0;
`;

export const OnboardingStepStatusCheckbox = styled.div<{
  isComplete?: boolean;
  isCurrentStep?: boolean;
}>`
  ${flexBase};
  height: 2rem;
  width: 2rem;
  padding: ${({ theme }) => theme.spacing.xxxxxsmall};
  justify-content: center;
  border-radius: 100%;
  background-color: ${({ theme, isComplete }) =>
    isComplete ? theme.backgroundColors.pink200 : theme.backgroundColors.white};

  border: ${({ isComplete, isCurrentStep, theme }) =>
    isComplete || isCurrentStep
      ? `2px solid ${theme.backgroundColors.pink200}`
      : `1px solid ${theme.backgroundColors.grey300}`};
  z-index: 1;
`;

export const OnboardingStepCheckerContainer = styled.div`
  ${flexBaseColumn};
  position: relative;
`;

export const OnboardingStepperItemContainer = styled.div`
  ${flexBase};
  gap: ${({ theme }) => theme.spacing.xsmall};

  :not(&:last-of-type) ${OnboardingStepCheckerContainer} {
    :after {
      ${({ theme }) =>
        `
    height: 5rem;
    border-left: 1px solid ${theme.backgroundColors.greytainted100};
    position: absolute;
    content: '';
    top: 1.5rem;
    left: 0.9rem;
    z-index: 0;
    `}
    }
  }
`;

export const OnboardingStepContentContainer = styled(FlexContainerBasicColumn)<{
  isFullScreen?: boolean;
  shouldShowAccountingTrialEndBanner?: boolean;
}>`
  background: white;
  flex-grow: 1;
  align-items: center;
  max-height: 100vh;
  overflow-y: auto;
  padding-top: ${({ shouldShowAccountingTrialEndBanner, theme }) =>
    shouldShowAccountingTrialEndBanner
      ? `${theme.spacing.xxxsmall}`
      : `${theme.spacing.medium}`};
  ${({ theme, isFullScreen }) =>
    addStyleInDesktop(
      theme,
      `padding-top: ${isFullScreen ? 'unset' : '8rem'}`
    )};
`;

export const OnboardingCallContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const OnboardingStepBodyContainer = styled(FlexContainerBasicColumn)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
  }
  width: 50%;
  gap: ${({ theme }) => theme.spacing.xlarge};
`;

export const OnboardingCallIframe = styled.iframe`
  @media (min-width: ${({ theme }) => theme.breakpoints.xxlarge}) {
    // force horizontal calendar iframe layout on desktop
    min-width: 100rem;
  }
`;

export const WaitingContractContainer = styled(FlexContainerBasicColumn)`
  gap: ${({ theme }) => theme.spacing.medium};
  padding-top: ${({ theme }) => theme.spacing.xxxxxxlarge};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-width: 40%;
  }
`;

// Remove the div created by reverse portal from the dom style ( as well as the current div )
export const PortalStyle = styled.div`
  display: contents;
  & > div {
    display: contents;
  }
`;

export const OnboardingStepCardContainer = styled(FlexContainerBasicColumn)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 100%;
  }
  width: 75%;
  gap: ${({ theme }) => theme.spacing.xlarge};
  padding: ${({ theme }) => theme.spacing.medium};
  max-width: 70rem;
  ${({ theme }) => addStyleInDesktop(theme, `padding: unset; `)}
`;

export const OnboardingFooterContainer = styled.div`
  ${flexBase};
  justify-content: space-between;
  align-items: center;
`;
