export const popupWindow = (
  url: string,
  windowName: string,
  width: number,
  height: number
): Window | null => {
  const y =
    (window?.top?.outerHeight || 0) / 2 +
    (window?.top?.screenY || 0) -
    height / 2;
  const x =
    (window?.top?.outerWidth || 0) / 2 +
    (window?.top?.screenX || 0) -
    width / 2;
  return window?.open(
    url,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
  );
};
