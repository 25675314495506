import { StreamLineIconList } from '@legalplace/storybook';

export enum SettingsSection {
  EMAIL = 'email',
  PASSWORD = 'motDePasse',
  PHONE = 'telephone',
  NOTIFICATIONS = 'notifications',
  COMPANY_ID = 'companyId',
  ADDRESS = 'adresse',
  PROFESSIONAL_VEHICLES = 'vehiculesProfessionnels',
  INSURANCES = 'assurances',
  LOANS = 'emprunts',
  LEASING = 'credits-bails',
  BRANCHES = 'filiales',
  SALES_CHANNELS = 'canauxDeVentes',
  INVOICES = 'factures',
  USERS = 'utilisateurs',
  TAX_CREDENTIALS = 'identifiantsImpots',
}

export const SETTINGS_SECTION_LIST = 'liste';

export const ACCOUNTING_SETTINGS_ITEMS = [
  {
    id: 'vehiculesProfessionnels',
    label: 'Véhicules professionnels',
    uri: `?section=${SettingsSection.PROFESSIONAL_VEHICLES}`,
    streamLineIconName: StreamLineIconList.car1,
  },
  {
    id: 'assurances',
    label: 'Assurances',
    uri: `?section=${SettingsSection.INSURANCES}`,
    streamLineIconName: StreamLineIconList.insurancehands,
  },
  {
    id: 'emprunts',
    label: 'Emprunts',
    uri: `?section=${SettingsSection.LOANS}`,
    streamLineIconName: StreamLineIconList.accountingbills,
  },
  {
    id: 'credits-bails',
    label: 'Credit-bail',
    uri: `?section=${SettingsSection.LEASING}`,
    streamLineIconName: StreamLineIconList.accountingbills1,
  },
  {
    id: 'filiales',
    label: 'Filiales',
    uri: `?section=${SettingsSection.BRANCHES}`,
    streamLineIconName: StreamLineIconList.buildingsmodern,
  },
  {
    id: 'canauxDeVentes',
    label: 'Canaux de ventes',
    uri: `?section=${SettingsSection.SALES_CHANNELS}`,
    streamLineIconName: StreamLineIconList.shop,
  },
];

export const ACCOUNT_ACCOUNTING_SETTINGS_ITEMS = [
  {
    id: 'factures',
    label: 'Factures',
    uri: `?section=${SettingsSection.INVOICES}`,
    streamLineIconName: StreamLineIconList.commonfiletext,
  },
];

export const COMPANY_USER_ITEM = {
  id: 'utilisateurs',
  label: 'Utilisateurs',
  uri: `?section=${SettingsSection.USERS}`,
  streamLineIconName: StreamLineIconList.houseuser,
};

export const INVOICE_SETTINGS_LINK =
  'https://lp-so-compta.chargebeeportal.com/portal/';
