import { useCurrentBreakpoint, useTheme } from '@legalplace/storybook';

export const useCurrentDeviceBreakpoint = (): {
  isMobile: boolean;
  isMobileOrTablet: boolean;
  isSmallDesktop: boolean;
  isDesktop: boolean;
  isTabletDesktop: boolean;
  isTablet: boolean;
} => {
  const { deviceSize } = useCurrentBreakpoint();
  const {
    breakpoints: { xxsmall, medium, large, xlarge },
  } = useTheme();

  return {
    isMobile: deviceSize === xxsmall,
    isMobileOrTablet: deviceSize === xxsmall || deviceSize === medium,
    isSmallDesktop: deviceSize === large,
    isDesktop: deviceSize === xlarge,
    isTabletDesktop: deviceSize !== xxsmall,
    isTablet: deviceSize === medium,
  };
};
