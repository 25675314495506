import type { ChangeEventHandler, FormEvent } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PASSWORD_REGEX } from '@legalplace/shared';
import {
  CheckBox,
  FieldTypes,
  FlexColumnFullWidth,
  FlexContainerBasicColumn,
  ShowDesktopOnly,
  ShowMobileTabletOnly,
  Typography,
} from '@legalplace/storybook';

import {
  PRIVACY_POLICY_LINK,
  TERMS_OF_SERVICE_LINK,
} from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/store';
import { initPasswordRequest } from '../../../../services/api/auth.api';
import { showToastError } from '../../../../services/notifications/toast';
import { setUserInformations } from '../../../../store/user/actions';
import { selectUserInformations } from '../../../../store/user/selector';
import { InputWrapper } from '../../../common/forms/inputWrapper/InputWrapper';
import { OnboardingMobileFooter } from '../../../CompanyAccountingOnboardingSteps/steps/OnboardingMobileFooter';

import { HiddenField } from '../../../UI/HiddenField.style';
import { PrimaryButton } from '../../../UI/PrimaryButton.style';
import { OnboardingStepBodyContainer } from '../../OnboardingV2Steps.style';
import {
  OnboardingForm,
  StyledPasswordField,
  SubmitButtonContainer,
  UserAgreementTypography,
} from './CredentialsCreation.style';

export function CredentialsCreation({
  notifySuccess,
}: {
  notifySuccess: () => void;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'credentials_creation',
  });
  const userInformation = useAppSelector(selectUserInformations);

  const [newPasswordValue, setNewPasswordValue] = useState('');
  const [confirmationNewPasswordValue, setConfirmationNewPasswordValue] =
    useState('');

  const [errorMessage, setErrorMessage] = useState<{
    password?: string;
    confirmationPassword?: string;
    acknowledgement?: string;
  }>({});

  const [hasAcknowledgedTerms, setHasAcknowledgedTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTrustedDevice, setIsTrustedDevice] = useState(false);

  const createPassword = async (
    event?: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event?.preventDefault();
    const newErrors: Record<string, string> = {};
    try {
      setIsLoading(true);
      if (!PASSWORD_REGEX.test(newPasswordValue)) {
        newErrors.password = t('password_error');
      }
      if (newPasswordValue !== confirmationNewPasswordValue) {
        newErrors.confirmationPassword = t('password_confirmation_error');
      }

      if (!hasAcknowledgedTerms) {
        newErrors.acknowledgement = t('user_agreement_not_accepted');
      }

      setErrorMessage(newErrors);
      if (Object.values(newErrors).length) {
        return;
      }
      await initPasswordRequest({
        password: newPasswordValue,
        isTrustedDevice,
      });
      dispatch(
        setUserInformations({ ...userInformation, isFirstLogin: false })
      );
      notifySuccess();
    } catch (error: unknown) {
      console.error(`[createPassword] - Error trying to reset password`, error);
      showToastError(t('unexpected_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const onPasswordChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const password = event.target.value;
      setNewPasswordValue(password);
      if (errorMessage.password && PASSWORD_REGEX.test(password)) {
        setErrorMessage((errors) => ({
          ...errors,
          password: undefined,
        }));
      }
    },
    [errorMessage.password]
  );

  const onPasswordConfirmationChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        const password = event.target.value;
        setConfirmationNewPasswordValue(password);
        if (
          errorMessage.confirmationPassword &&
          newPasswordValue === password
        ) {
          setErrorMessage((errors) => ({
            ...errors,
            confirmationPassword: undefined,
          }));
        }
      },
      [errorMessage.confirmationPassword, newPasswordValue]
    );

  return (
    <OnboardingForm onSubmit={createPassword}>
      <OnboardingStepBodyContainer internalspacing={{ default: '2.4rem' }}>
        <FlexContainerBasicColumn gap={{ default: '0.8rem' }}>
          <Typography
            tag="p"
            fontWeight="600"
            color="primary900"
            textAlign="left"
          >
            {t('title')}
          </Typography>
          <Typography tag="p3" color="primary900" textAlign="left">
            {t('password_criteria')}
          </Typography>
        </FlexContainerBasicColumn>
        <FlexContainerBasicColumn gap={{ default: '1.6rem' }}>
          {userInformation.email && (
            <HiddenField
              name="email"
              valueKey="email"
              fieldType={FieldTypes.EMAIL}
              autoComplete="email username"
              valueProps={{ value: userInformation.email }}
              disabled
            />
          )}
          <InputWrapper
            name="newPassword"
            label={t('password_field')}
            hasError={!!errorMessage.password}
            errorMessage={errorMessage.password}
          >
            <StyledPasswordField
              placeholder="**********"
              autoComplete="new-password"
              onChange={onPasswordChange}
              hasError={!!errorMessage.password}
              placeholderColor="grey300"
            />
          </InputWrapper>
          <InputWrapper
            name="newPasswordConfirmation"
            label={t('password_confirmation_field')}
            hasError={!!errorMessage.confirmationPassword}
            errorMessage={errorMessage.confirmationPassword}
          >
            <StyledPasswordField
              name="newPasswordConfirmation"
              placeholder="**********"
              autoComplete="new-password"
              onChange={onPasswordConfirmationChange}
              hasError={!!errorMessage.confirmationPassword}
              placeholderColor="grey300"
              color="grey700"
            />
          </InputWrapper>
        </FlexContainerBasicColumn>
        <FlexColumnFullWidth gap={{ default: '1.2rem' }}>
          <div>
            <CheckBox
              onChange={() => {
                setErrorMessage((errors) => ({
                  ...errors,
                  acknowledgement: undefined,
                }));
                setHasAcknowledgedTerms((prev) => !prev);
              }}
              checked={hasAcknowledgedTerms}
              name="acknowledgement-checkbox"
            >
              <UserAgreementTypography
                tag="p4"
                color="greytainted700"
                spacing={{ default: '0 0 0 0.8rem' }}
                innerHtml={t('user_agreement', {
                  tosLink: TERMS_OF_SERVICE_LINK,
                  privacyPolicyLink: PRIVACY_POLICY_LINK,
                })}
              />
            </CheckBox>
            {errorMessage.acknowledgement && (
              <Typography
                tag="p4"
                color="danger500"
                textAlign="left"
                spacing={{ default: '0 0 0 3.2rem' }}
              >
                {errorMessage.acknowledgement}
              </Typography>
            )}
          </div>
          <CheckBox
            onChange={(e) => {
              setIsTrustedDevice(e.target.checked);
            }}
            checked={isTrustedDevice}
            name="acknowledgement-checkbox"
          >
            <UserAgreementTypography
              tag="p4"
              color="greytainted700"
              spacing={{ default: '0 0 0 0.8rem' }}
            >
              {t('trust_device')}
            </UserAgreementTypography>
          </CheckBox>
        </FlexColumnFullWidth>
        <ShowDesktopOnly>
          <SubmitButtonContainer>
            <PrimaryButton
              width={{ default: 'fit-content' }}
              isLoading={isLoading}
              type="submit"
            >
              {t('access_app')}
            </PrimaryButton>
          </SubmitButtonContainer>
        </ShowDesktopOnly>
      </OnboardingStepBodyContainer>
      <ShowMobileTabletOnly width={{ default: '100%' }}>
        <OnboardingMobileFooter
          onClick={createPassword}
          primaryButtonLabel={t('access_app')}
          isLoading={isLoading}
        />
      </ShowMobileTabletOnly>
    </OnboardingForm>
  );
}
