import {
  Button,
  FlexAlignCenterJustifySpaceBetween,
  FlexContainerBasicColumn,
  FlexContainerBasicColumnAlignCenter,
  styled,
} from '@legalplace/storybook';

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled(Button)`
  width: fit-content;
`;

export const AcademyEndMessageModalHeader = styled(
  FlexAlignCenterJustifySpaceBetween
)`
  padding: ${({ theme }) =>
    `0 ${theme.spacing.small} ${theme.spacing.medium} ${theme.spacing.small}};`};
`;

export const AcademyEndMessageModalBody = styled(FlexContainerBasicColumn)`
  padding: ${({ theme }) =>
    `0 ${theme.spacing.small} ${theme.spacing.small} ${theme.spacing.small}};`};
  gap: ${({ theme }) => theme.spacing.xxxsmall};
`;

export const AcademyEndMessageModalFooter = styled(
  FlexContainerBasicColumnAlignCenter
)`
  border-top: 1px solid ${({ theme }) => theme.grey200};
  padding: ${({ theme }) => theme.spacing.small};
`;
