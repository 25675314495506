import { Button, flexBaseColumn, styled } from '@legalplace/storybook';

export const IbanSelectionForm = styled.form`
  ${flexBaseColumn};
  width: 100%;
  gap: ${({ theme }) => theme.spacing.medium};
  align-items: flex-start;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

export const SubmitButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing.xsmall};
  align-self: flex-end;
  width: fit-content;
`;
