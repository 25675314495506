import type { UpdateTaxesCredentialsDto } from '@legalplace/bankroot-api/modules/taxesCredentials/dto/updateTaxesCredentials.dto';
import type { TaxCredentialsStatus } from '@legalplace/shared';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../services/api/api';

export const taxesCredentialsApi = createApi({
  reducerPath: 'taxesCredentialsApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['TaxesCredentials'],
  endpoints: (build) => ({
    getTaxesCredentials: build.query<
      { taxCredentialStatus: TaxCredentialsStatus },
      string
    >({
      query: (companyId) => ({
        url: `/company/${companyId}/tax-credentials/status`,
      }),
      providesTags: ['TaxesCredentials'],
    }),
    skipTaxesCredentials: build.mutation<void, string>({
      query: (companyId) => ({
        url: `/company/${companyId}/tax-credentials/skip`,
        method: 'PATCH',
      }),
      invalidatesTags: ['TaxesCredentials'],
    }),
    updateTaxCredentials: build.mutation<
      void,
      {
        taxesCredentials: UpdateTaxesCredentialsDto;
        companyId: string;
      }
    >({
      query: ({ taxesCredentials, companyId }) => ({
        url: `/company/${companyId}/tax-credentials`,
        method: 'POST',
        body: taxesCredentials,
      }),
      invalidatesTags: ['TaxesCredentials'],
    }),
    updateActivationCode: build.mutation<
      void,
      { companyId: string; activationCode: string }
    >({
      query: ({ companyId, activationCode }) => ({
        url: `/company/${companyId}/tax-credentials/activation-code`,
        method: 'PUT',
        body: { activationCode },
      }),
      invalidatesTags: ['TaxesCredentials'],
    }),
  }),
});

export const {
  useGetTaxesCredentialsQuery,
  useSkipTaxesCredentialsMutation,
  useUpdateTaxCredentialsMutation,
  useUpdateActivationCodeMutation,
} = taxesCredentialsApi;
