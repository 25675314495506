import { call, put, select, takeEvery } from 'typed-redux-saga';

import { getDashboardWidgetsVisibility } from '../../../services/api/dashboardWidget.api';
import {
  fetchActiveCompanyDashboardWidgetVisibilityFailed,
  fetchActiveCompanyDashboardWidgetVisibilitySuccess,
  startFetchActiveCompanyDashboardWidgetVisibility,
} from '../actions';
import { selectActiveUserCompanyId } from '../selectors';

function* handleFetchWidgetVisibility(): Generator {
  try {
    const activeUserCompanyId = yield* select(selectActiveUserCompanyId);
    if (!activeUserCompanyId) {
      throw new Error(
        'Cannot fetch widget visibility: no active user company ID found'
      );
    }
    const { data: fetchedWidgetVisibility } = yield* call(
      getDashboardWidgetsVisibility,
      activeUserCompanyId
    );

    yield put(
      fetchActiveCompanyDashboardWidgetVisibilitySuccess(
        fetchedWidgetVisibility
      )
    );
  } catch (error: unknown) {
    console.error(
      `[handleFetchDashboardWidgetVisibility] - Error trying to fetch dashboard widget visivbility`,
      error
    );
    yield put(fetchActiveCompanyDashboardWidgetVisibilityFailed());
  }
}

function* fetchWidgetVisibilitySaga(): Generator {
  yield takeEvery(
    startFetchActiveCompanyDashboardWidgetVisibility,
    handleFetchWidgetVisibility
  );
}

export default fetchWidgetVisibilitySaga;
