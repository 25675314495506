import type { Dispatch, SetStateAction } from 'react';

import { FlexContainerBasicColumn } from '@legalplace/storybook';

import { EllipsableTypography } from '../UI/EllipsableTypography.style';
import { Toggle } from '../UI/Toggle.style';
import { ToggleCardContainer } from './ToggleCard.style';

export function ToggleCard({
  title,
  subtitle,
  content,
  checked,
  onChange,
  option,
}: {
  title?: string;
  subtitle?: string;
  content: string;
  checked: boolean;
  onChange: Dispatch<SetStateAction<Record<string, boolean>>>;
  option: string;
}): JSX.Element {
  return (
    <ToggleCardContainer>
      <FlexContainerBasicColumn gap={{ default: '1.2rem' }}>
        <FlexContainerBasicColumn>
          <EllipsableTypography
            tag="p3"
            color="primary900"
            isEllipsed
            fontWeight="500"
          >
            {title}
          </EllipsableTypography>
          {subtitle && (
            <EllipsableTypography tag="p5" color="grey600">
              {subtitle}
            </EllipsableTypography>
          )}
        </FlexContainerBasicColumn>
        <EllipsableTypography tag="p5" color="grey600">
          {content}
        </EllipsableTypography>
      </FlexContainerBasicColumn>
      <Toggle
        checked={checked}
        onChange={(event) =>
          onChange((previousValues) => ({
            ...previousValues,
            [option]: event.target.checked,
          }))
        }
        color="primary500"
      />
    </ToggleCardContainer>
  );
}
