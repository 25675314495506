export default {
  title: 'Liste des associé(e)s et dirigeant(e)s',
  subtitle:
    'Entrez l’identité de l’ensemble des associé(e)s et dirigeant(e)s de l’entreprise.',
  add_associates_button_label: 'Nouvel(le) associé(e) ou dirigeant(e)',
  error_message:
    "La saisie d'un associé et d'un dirigeant est obligatoire pour passer à l'étape suivante",
  back_button_label: 'Retour',
  next_button_label: 'Continuer',
  associate_and_director_subtext: 'Associé(e) et dirigeant(e)',
  associate_subtext: 'Associé(e)',
  director_subtext: 'Dirigeant(e)',
  empty_associates_line_1:
    "Aucun associé(e)s et/ou dirigeant(e)s n'est enregistré.",
  empty_associates_line_2: 'Cette étape est obligatoire pour la suite.',
  add_or_edit_modal_drawer: {
    cancel_button_label: 'Retour',
    title: {
      add: "Ajout d'associé(e)s et dirigeant(e)s",
      edit: "Modification d'associé(e)s et dirigeant(e)s",
    },
    submit_button_label: {
      add: 'Ajouter',
      edit: 'Modifier',
    },
    associate_with_existing_name_error:
      'Un associé avec le même nom existe déjà',
    empty_field_error: 'Veuillez remplir ce champ.',
    associate_toggle_label: 'Associé(e)',
    director_toggle_label: 'Dirigeant(e)',
    associate_type_label: 'Type',
    associate_type_placeholder: 'Type',
    associate_type_option: {
      physical: 'Personne physique (particulier)',
      legal_entity: 'Personne morale (société)',
    },
    company_search_dropdown_label: 'Rechercher une société',
    company_search_dropdown_placeholder: 'Entreprise',
    no_company_found: 'Aucune entreprise trouvée',
    company_name_input_label: 'Raison sociale',
    siren_input_label: 'SIREN',
    associate_lastname_input_label: 'Nom',
    associate_lastname_placeholder: 'Dupont',
    associate_firstname_input_label: 'Prénom',
    associate_firstname_placeholder: 'Lucie',
    manual_legal_entity_associate_button_label: "Je ne trouve pas l'entreprise",
  },
};
