import { billingClient } from './billingClient';
import { billingDocument } from './billingDocument';
import { billingInvoice } from './billingInvoice';
import { billingItem } from './billingItem';
import { billingQuote } from './billingQuote';

export const billing = {
  billingItem,
  billingClient,
  billingQuote,
  billingInvoice,
  billingDocument,
};
