import { invoiceSlice } from './reducer';

export const {
  failInvoiceRequest,
  startFetchInvoiceRequest,
  fetchInvoiceSuccess,
  resetInvoice,
  startFetchInvoiceStatsRequest,
  fetchInvoiceStatsSuccess,
  fetchInvoiceStatsError,
  updateInvoiceStatusById,
  setSelectedInvoiceId,
  setInvoiceModalOpeningSource,
} = invoiceSlice.actions;
