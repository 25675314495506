import { toast } from 'react-toastify';

import { StreamLineIcon, StreamLineIconList } from '@legalplace/storybook';

import { Toast, ToastWithButton } from '../../components/toast';

export const showToastError = (message: string): void => {
  toast.error(
    ({ closeToast }) => <Toast message={message} closeToast={closeToast} />,
    {
      icon: <StreamLineIcon icon={StreamLineIconList.alertcircle} />,
    }
  );
};

export const showToastErrorWithButton = (
  message: string,
  buttonLabel: string,
  buttonCallback: () => void
): void => {
  toast.error(
    ({ closeToast }) => (
      <ToastWithButton
        message={message}
        closeToast={closeToast}
        buttonLabel={buttonLabel}
        buttonCallback={buttonCallback}
      />
    ),
    {
      icon: <StreamLineIcon icon={StreamLineIconList.alertcircle} />,
    }
  );
};

export const showToastSuccess = (message: string): void => {
  toast.success(
    ({ closeToast }) => <Toast message={message} closeToast={closeToast} />,
    {
      icon: <StreamLineIcon icon={StreamLineIconList.checkcircle} />,
    }
  );
};

export const showToastInfo = (message: string): void => {
  toast.info(({ closeToast }) => (
    <Toast message={message} closeToast={closeToast} />
  ));
};
