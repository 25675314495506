import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

export const getDeviceId = async (): Promise<string | null> => {
  if (Capacitor.isNativePlatform()) {
    const { value } = await Preferences.get({ key: 'deviceId' });
    return value;
  }

  return localStorage.getItem('deviceId');
};

export const setDeviceId = async (deviceId: string): Promise<void> => {
  if (Capacitor.isNativePlatform()) {
    await Preferences.set({ key: 'deviceId', value: deviceId });
    return;
  }

  localStorage.setItem('deviceId', deviceId);
};

export const saveInLocalStorage = async (
  key: string,
  value: string
): Promise<void> => {
  try {
    if (Capacitor.isNativePlatform()) {
      await Preferences.set({ key, value });
    } else {
      localStorage.setItem(key, value);
    }
  } catch (error: unknown) {
    console.error(
      `[saveInLocalStorage] - Error trying to set value in local storage`,
      error
    );
  }
};

export const getValueFromLocalStorage = async (
  key: string
): Promise<string | null> => {
  try {
    if (Capacitor.isNativePlatform()) {
      const storedValue = await Preferences.get({ key });
      return storedValue?.value;
    }
    return localStorage.getItem(key);
  } catch (error: unknown) {
    console.error(
      `[getValueFromLocalStorage] - Error trying to get value from local storage`,
      error
    );
    return null;
  }
};
