export const accountSuspendedBanner = {
  title:
    'Votre compte professionnel est actuellement suspendu pour des raisons de sécurité.',
  message1:
    'Toutes les opérations, y compris les virements et paiements par carte, sont bloquées. ',
  message2:
    '-> Vérifiez votre boîte mail pour les détails et les prochaines étapes.',
  message3:
    '-> Merci de nous contacter, nous sommes là pour vous aider à régulariser votre situation rapidement.',

  button_label: 'Contactez-nous',
};
