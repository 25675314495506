import type { StockGeography } from '@legalplace/shared';
import {
  SALES_CHANNEL_TYPE_TO_LABEL,
  SalesChannelType,
  SalesType,
} from '@legalplace/shared';

export const SALES_CHANNEL_TYPE_OPTIONS = Object.values(SalesChannelType).map(
  (salesChannelType) => ({
    label: SALES_CHANNEL_TYPE_TO_LABEL[salesChannelType],
    selected: false,
    value: salesChannelType,
  })
);

export const INTIAL_STOCK_GEOGRAPHIES_VALUES = Object.values(SalesType).reduce(
  (acc, stockGeography) => ({ ...acc, [stockGeography]: false }),
  {} as Record<StockGeography, boolean>
);

export const INITAL_SALES_TYPES_VALUES = Object.values(SalesType).reduce(
  (acc, salesType) => ({ ...acc, [salesType]: false }),
  {} as Record<SalesType, boolean>
);
