import type { AxiosResponse } from 'axios';

import type { CreateQontoConnectionDto } from '@legalplace/bankroot-api/modules/externalAccount/dto/createQontoConnection.dto';
import type { SolveQontoConnectionDto } from '@legalplace/bankroot-api/modules/externalAccount/dto/solveQontoConnection.dto';

import { api } from './api';

export const createQontoExternalAccount = (
  code: string,
  companyId: string
): Promise<AxiosResponse<CreateQontoConnectionDto>> =>
  api.post(`/qonto/connect/`, { code, companyId });

export const solveQontoExternalAccountConnexion = (
  code: string,
  externalAccountConnectionId: string,
  companyId: string
): Promise<AxiosResponse<SolveQontoConnectionDto>> =>
  api.post(`/qonto/solve/${externalAccountConnectionId}`, {
    code,
    companyId,
  });

export const finalizeExternalAccountConnectionRequest = (
  externalAccountConnectionId: string,
  accountIds: string[],
  companyId?: string
): Promise<AxiosResponse<void>> =>
  api.post(`/external-account/${externalAccountConnectionId}/finalize`, {
    accountIds,
    companyId,
  });
