import type { ILayoutDeviceSizes } from '@legalplace/storybook';
import {
  flexBaseAlignCenter,
  flexFullAlignCenterSpaceBetween,
  getResponsiveStyle,
  LpContainer,
  styled,
  textEllipsis,
  Typography,
} from '@legalplace/storybook';

import { addStyleInDesktopAndTablet } from '../UI/Responsive.style';

export const CardDetailsContainer = styled(LpContainer)`
  ${({ theme }) => addStyleInDesktopAndTablet(theme, `position: sticky;`)}
  top: 2rem;
  min-height: 30rem;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.backgroundColors.grey100};
`;

export const CardImage = styled.img`
  border-radius: 13px;
  object-fit: contain;
  width: 100%;
`;

export const CardDetailsHeaderLine = styled.div<{
  internalSpacing?: ILayoutDeviceSizes;
  mobileOnly?: boolean;
}>`
  ${flexFullAlignCenterSpaceBetween};
  ${({ internalSpacing }) =>
    internalSpacing && getResponsiveStyle(internalSpacing, 'padding')};

  ${({ theme, mobileOnly }) =>
    mobileOnly && addStyleInDesktopAndTablet(theme, `display:none;`)}
`;

export const OwnerNameTypography = styled(Typography)`
  ${textEllipsis}
`;

export const LoaderContainer = styled.div`
  ${flexBaseAlignCenter};
  padding: ${({ theme }) => theme.spacing.xxxxxxlarge} 0;
`;
