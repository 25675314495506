import { useTranslation } from 'react-i18next';

import { BannerStyle, BannerV2 } from '@legalplace/storybook';

import { useCurrentDeviceBreakpoint } from '../../hooks/useCurrentDeviceBreakpoint';

export function AccountSuspendedBanner(): JSX.Element {
  const { t } = useTranslation('account_suspended_banner');

  const { isMobileOrTablet } = useCurrentDeviceBreakpoint();

  return (
    <BannerV2
      bannerStyle={BannerStyle.DANGER}
      title={t('title')}
      messages={[t('message1'), t('message2'), t('message3')]}
      actionButtonType="danger"
      actionButtonLabel={t('button_label')}
      onActionButtonClick={() => {}}
      isHelpActionButton
      width={{ default: '100%' }}
      borderRadius={isMobileOrTablet ? 'ctaPrimary' : 'radiusSm'}
    />
  );
}
