export const forgotPassword = {
  modal_title: 'Mot de passe oublié ?',
  subtitle_message:
    'Vous recevrez un e-mail pour choisir un nouveau mot de passe.',
  email_label: 'E-mail',
  email_placeholder: 'nomprenom@email.fr',
  submit_button_label: 'Réinitialiser mon mot de passe',
  email_format_error_message: 'Cette adresse e-mail est invalide.',
  success_title: 'E-mail de réinitialisation envoyé !',
  success_message:
    "Si l'adresse {{email}} est associée à un compte LegalPlace Pro, vous allez recevoir un e-mail contenant un lien de réinitialisation. <br><br> Si tel n'est pas le cas, pensez à vérifier vos spams ou retrouvez la bonne adresse e-mail en vérifiant celle à laquelle {{supportEmail}} vous a contacté par le passé.",
  close_button_label: 'Retour',
};
