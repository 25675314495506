import { flexBaseColumn, styled } from '@legalplace/storybook';

export const DesktopAcademyContainer = styled.div<{
  $isSelected?: boolean;
}>`
  ${flexBaseColumn};
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.xxsmall}`};
  text-decoration: unset;
  gap: ${({ theme }) => theme.spacing.xxsmall};
  border-radius: ${({ theme }) => theme.borders.radiusSm};
  background-color: ${({ theme, $isSelected }) =>
    $isSelected && theme.backgroundColors.primary50};
  :hover {
    background-color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.backgroundColors.primary100
        : theme.backgroundColors.grey50};
  }
`;

export const MobileAcademyContainer = styled.div`
  ${flexBaseColumn};
  width: 100%;
  background: ${({ theme }) => theme.backgroundColors.primary100};
  border-radius: ${({ theme }) => theme.borders.radiusMd};
  padding: ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.xxxxsmall};
`;
