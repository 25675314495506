import type { AxiosResponse } from 'axios';

import type {
  GetInvoiceStatsResponseDto,
  GetQuoteStatsResponseDto,
  UpdateBillingInformationRequestDto,
} from '@legalplace/bankroot-api/modules/company/dto';
import type { GetLogoDto } from '@legalplace/bankroot-api/modules/company/dto/getLogo.dto';
import type { PresignedFileUploadDto } from '@legalplace/bankroot-api/modules/file/dto';

import { api } from '../api';

export const updateBillingInformationRequest = (
  companyId: string,
  billingInformationBody: UpdateBillingInformationRequestDto
): Promise<AxiosResponse<void>> =>
  api.patch(
    `/company/${companyId}/billing-information`,
    billingInformationBody
  );

export const getUploadLogoUrlRequest = async (
  companyId: string,
  fileName: string
): Promise<AxiosResponse<PresignedFileUploadDto>> =>
  api.get(
    `/company/${companyId}/billing-information/logo/upload-url?fileName=${encodeURIComponent(
      fileName
    )}`
  );

export const getLogoRequest = (
  companyId: string
): Promise<AxiosResponse<GetLogoDto>> =>
  api.get(`/company/${companyId}/billing-information/logo`);

export const addLogoRequest = async (
  companyId: string,
  fileId: string
): Promise<AxiosResponse<GetLogoDto>> =>
  api.post(`/company/${companyId}/billing-information/logo`, {
    fileId,
  });

export const deleteLogoRequest = async (
  companyId: string
): Promise<AxiosResponse<void>> =>
  api.delete(`/company/${companyId}/billing-information/logo`);

export const getLogoDownloadUrlRequest = async (
  companyId: string
): Promise<AxiosResponse<string>> =>
  api.get(`/company/${companyId}/billing-information/logo/download-url`);

export const getInvoiceStatsRequest = async (
  companyId: string
): Promise<AxiosResponse<GetInvoiceStatsResponseDto>> =>
  api.get(`/company/${companyId}/billing-information/invoice-stats`);

export const getQuoteStatsRequest = async (
  companyId: string
): Promise<AxiosResponse<GetQuoteStatsResponseDto>> =>
  api.get(`/company/${companyId}/billing-information/quote-stats`);
