export default {
  title: 'Inscription à impots.gouv',
  subtitle:
    'L’inscription à impots.gouv est une obligation pour toutes les sociétés nouvellement créées.',
  download_guide_label: 'Consulter notre guide',
  email_field: 'Identifiant',
  password_field: 'Mot de passe',
  validate_credentials: 'Valider vos identifiants',
  encrypted_informations: 'Vos informations sont chiffrées',
  email_placeholder: 'nomprenom@email.fr',
  email_error: 'Cette adresse e-mail est invalide.',
  unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
};
