import { useTranslation } from 'react-i18next';

import {
  Button,
  FlexContainerBasic,
  FlexContainerBasicAlignCenter,
  FlexSpaceBetween,
  Separator,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { ampli } from '../../../ampli/api';
import type { IAcademyMenuItem } from '../../../interfaces/academyMenuItem.interface';

import {
  HideInLargeDesktop,
  ShowLargeDesktopOnly,
} from '../../common/layout/responsive.style';
import {
  DesktopAcademyContainer,
  MobileAcademyContainer,
} from './AcademyMenuItem.style';

export function AcademyMenuItem({
  isAcademyOpen,
  setIsAcademyOpen,
}: IAcademyMenuItem): JSX.Element | null {
  const { t } = useTranslation('academy', {
    keyPrefix: 'menu',
  });

  const handleOnClick = (): void => {
    if (!isAcademyOpen) {
      ampli.academyOpened();
    }
    setIsAcademyOpen(!isAcademyOpen);
  };

  return (
    <>
      <ShowLargeDesktopOnly data-intercom-target="academy-button">
        <Button onClick={handleOnClick} width={{ default: '100%' }}>
          <DesktopAcademyContainer $isSelected={isAcademyOpen}>
            <FlexSpaceBetween>
              <FlexContainerBasicAlignCenter gap={{ default: '0.8rem' }}>
                <StreamLineIcon
                  icon={StreamLineIconList.loginkeys}
                  fillColor={isAcademyOpen ? 'primary600' : 'primary900'}
                  width="2rem"
                  height="2rem"
                />
                <Typography
                  tag="p3"
                  color={isAcademyOpen ? 'primary600' : 'primary900'}
                  lineHeight={{ default: '2rem' }}
                  fontWeight="400"
                >
                  {t('title')}
                </Typography>
                <StreamLineIcon
                  icon={StreamLineIconList.arrowright1}
                  fillColor={isAcademyOpen ? 'primary600' : 'primary900'}
                  width="1.6rem"
                  height="1.6rem"
                />
              </FlexContainerBasicAlignCenter>
            </FlexSpaceBetween>
            <Typography
              tag="p5"
              color="primary900"
              lineHeight={{ default: '1.6rem' }}
              fontWeight="400"
              textAlign={{ default: 'left' }}
            >
              {t('subtitle')}
            </Typography>
          </DesktopAcademyContainer>
        </Button>
      </ShowLargeDesktopOnly>

      <HideInLargeDesktop>
        <Button onClick={handleOnClick} width={{ default: '100%' }}>
          <MobileAcademyContainer>
            <FlexContainerBasic gap={{ default: '0.8rem' }}>
              <StreamLineIcon
                icon={StreamLineIconList.loginkeys}
                fillColor="primary900"
                width="2rem"
                height="2rem"
              />
              <Typography
                tag="p4"
                color="primary900"
                lineHeight={{ default: '2.4rem' }}
                fontWeight="600"
              >
                {t('title')}
              </Typography>
            </FlexContainerBasic>
            <Typography
              tag="p5"
              color="primary900"
              lineHeight={{ default: '1.6rem' }}
              fontWeight="400"
              textAlign={{ default: 'left' }}
            >
              {t('subtitle')}
            </Typography>
          </MobileAcademyContainer>
        </Button>
      </HideInLargeDesktop>

      <ShowLargeDesktopOnly>
        <Separator
          backgroundColor="grey125"
          spacing={{ default: '0.8rem 0' }}
        />
      </ShowLargeDesktopOnly>
    </>
  );
}
