import { transactionsSlice } from './reducer';

export const {
  fetchTransactionSuccess,
  startFetchTransactions,
  fetchTransactionsFailed,
  setFilterParameters,
  resetTransactions,
  updateTransaction,
  partialUpdateTransaction,
  updateCurrentOperation,
  setEditingExpenseId,
  setDuplicatingExpenseId,
} = transactionsSlice.actions;
