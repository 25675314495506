import type { AxiosResponse } from 'axios';

import type { ConnectBridgeItemDtoWithAction } from '@legalplace/bankroot-api/bridge/dto';
import type { ConnectBridgeItemDto } from '@legalplace/bankroot-api/bridge/dto/connectBridgeItem.dto';

import { getUserOrigin } from '../utils';

import { api } from './api';

export const getBridgeItemCreationUrlRequest = (
  companyId: string,
  shouldUseOrigin = true,
  bankId?: number
): Promise<AxiosResponse<ConnectBridgeItemDto>> =>
  api.post(
    `/bridge-item/connect/${companyId}${
      shouldUseOrigin ? `?origin=${getUserOrigin()}` : ''
    }`,
    { bankId }
  );

export const getBridgeItemSolveUrlRequest = (
  bridgeItemId: string,
  accountId: string
): Promise<AxiosResponse<ConnectBridgeItemDtoWithAction>> =>
  api.get(`/bridge-item/solve/${bridgeItemId}/${accountId}?expectAction=true`);

export const getBridgeItemWithoutAccountsSolveUrlRequest = (
  bridgeItemId: string
): Promise<AxiosResponse<ConnectBridgeItemDtoWithAction>> =>
  api.get(`/bridge-item/solve/${bridgeItemId}?expectAction=true`);

export const finalizeBridgeItemRequest = (
  bridgeItemId: string,
  accountIds: string[]
): Promise<AxiosResponse<void>> =>
  api.post(`/bridge-item/${bridgeItemId}/finalize`, { accountIds });
