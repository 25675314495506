import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  AccountingPlanName,
  SubscriptionPeriodicity,
} from '@legalplace/shared';
import {
  Button,
  FlexContainerBasicColumn,
  Typography,
} from '@legalplace/storybook';

import { useAppSelector } from '../../../../../../hooks/store';
import { selectAccountingOnboardingInformation } from '../../../../../../store/onboardingSteps/selector';
import { PricingFeatures } from '../pricingFeatures/PricingFeatures';
import { PricingLabel } from '../pricingLabel/PricingLabel';

import {
  ColumnContainer,
  LabelContainer,
  PricingColumnContainer,
} from './PricingColumn.style';

export function PricingColumn({
  subscriptionPeriodicity,
  accountingSubscriptionPlan,
  isPopular,
  isSelected,
  price,
  onPlanSelect,
}: {
  subscriptionPeriodicity: SubscriptionPeriodicity;
  accountingSubscriptionPlan: AccountingPlanName;
  isPopular: boolean;
  isSelected: boolean;
  onPlanSelect: (
    plan: AccountingPlanName,
    periodicity: SubscriptionPeriodicity
  ) => void;
  price: number;
}): JSX.Element {
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'onboarding_call.pricing_table',
  });
  const accountingOnboardingInformation = useAppSelector(
    selectAccountingOnboardingInformation
  );
  const hasUpdatedPlanOrPeriodicity =
    accountingOnboardingInformation?.plan !== accountingSubscriptionPlan ||
    accountingOnboardingInformation?.periodicity !== subscriptionPeriodicity;

  useEffect(() => {
    if (isSelected && hasUpdatedPlanOrPeriodicity) {
      onPlanSelect(accountingSubscriptionPlan, subscriptionPeriodicity);
    }
  }, [
    accountingSubscriptionPlan,
    hasUpdatedPlanOrPeriodicity,
    isSelected,
    onPlanSelect,
    subscriptionPeriodicity,
  ]);

  return (
    <PricingColumnContainer>
      {isPopular && (
        <LabelContainer>
          <Typography tag="p5" color="greytainted600">
            {t('popular_label')}
          </Typography>
        </LabelContainer>
      )}
      <ColumnContainer isPopular={isPopular} isSelected={isSelected}>
        <FlexContainerBasicColumn
          internalspacing={{ default: '2.4rem 1.6rem' }}
        >
          <Typography tag="h6" color="primary900" fontWeight="600">
            {t(`${accountingSubscriptionPlan}`)}
          </Typography>
          <PricingLabel
            subscriptionPeriodicity={subscriptionPeriodicity}
            price={price}
          />
          <Typography
            tag="p4"
            color="grey700"
            spacing={{ default: '1.6rem 0' }}
          >
            {t(`column_details.${`${accountingSubscriptionPlan}`}`)}
          </Typography>
          <Button
            styleType="primary"
            onClick={() => {
              if (isSelected) {
                return;
              }
              onPlanSelect(accountingSubscriptionPlan, subscriptionPeriodicity);
            }}
            internalSpacing={{ default: '0.8rem 1.2rem' }}
          >
            <Typography tag="p3" color="white">
              {isSelected
                ? t('selected_offer')
                : `${t('select')} ${t(`${accountingSubscriptionPlan}`)}`}
            </Typography>
          </Button>
        </FlexContainerBasicColumn>
        <PricingFeatures
          accountingSubscriptionPlan={accountingSubscriptionPlan}
        />
      </ColumnContainer>
    </PricingColumnContainer>
  );
}
