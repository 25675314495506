import { all } from 'redux-saga/effects';

import { accountsSagas } from './accounts/sagas/accounts.saga';
import { billingSagas } from './billing/sagas';
import { cardsSagas } from './cards/sagas/cards.saga';
import { categoriesSaga } from './categories/sagas/categories.saga';
import { companiesSagas } from './companies/sagas/companies.saga';
import { onboardingStepsSaga } from './onboardingSteps/saga/onboardingSteps.saga';
import { transactionsSagas } from './transactions/sagas/transactions.saga';
import { userSagas } from './user/sagas/user.saga';

export function* rootSaga(): Generator<unknown> {
  yield all([
    ...companiesSagas,
    ...transactionsSagas,
    ...accountsSagas,
    ...userSagas,
    ...cardsSagas,
    ...categoriesSaga,
    ...billingSagas,
    ...onboardingStepsSaga,
  ]);
}
