import { Button, flexBaseAlignCenter, styled } from '@legalplace/storybook';

export const TitleContainer = styled.div`
  ${flexBaseAlignCenter}
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

export const NavigationListButton = styled(Button)<{ $isSelected?: boolean }>`
  ${flexBaseAlignCenter};
  width: 100%;
  height: 4rem;
  padding: ${({ theme }) =>
    `${theme.spacing.xxxxsmall} ${theme.spacing.xxsmall}`};
  text-decoration: unset;
  gap: ${({ theme }) => theme.spacing.xxsmall};
  :hover {
    background-color: ${({ theme }) => theme.backgroundColors.grey50};
  }
  border-radius: ${({ theme }) => theme.borders.radiusSm};

  ${({ $isSelected, theme }) =>
    $isSelected &&
    `background-color: ${theme.backgroundColors.primary50};
    :hover {
      background-color: ${theme.backgroundColors.primary100};
    }
  `};
`;

export const Spacer = styled.div`
  width: 2rem;
  height: 2rem;
`;

export const TagContainer = styled.div<{
  isNavigationDisabled?: boolean;
}>`
  padding: ${({ theme }) =>
    `${theme.spacing.xxxxsmall} ${theme.spacing.xsmall}`};
  border-radius: ${({ theme }) => theme.borders.radiusRounded};
  border: ${({ theme, isNavigationDisabled }) =>
    `${theme.borders.widthRegular} ${theme.borders.styleRegular}
      ${
        isNavigationDisabled
          ? theme.typographyColors.greytainted300
          : theme.typographyColors.primary500
      }`};
`;
