import type { FindAccountDto } from '@legalplace/bankroot-api/modules/account/dto';
import {
  BankName,
  FAKE_BRIDGE_ACCOUNT_ID,
  TransactionType,
} from '@legalplace/shared';
import type {
  ThemeButtonColorsType,
  ThemeColorsType,
} from '@legalplace/storybook';

import { SWAN_ACCOUNT_DISPLAYED_NAME } from '../../constants/bankName.constants';
import type {
  IAccountFilterItem,
  IActiveFilters,
  IFilters,
} from '../../interfaces';

/**
 * Attributes of the filter object by section
 */
const filterAttributesBySection: Record<
  keyof IActiveFilters,
  (keyof IFilters)[]
> = {
  transactionType: ['transactionType'],
  search: ['search'],
  balanceType: ['balanceType'],
  date: ['isAfterUpdatedAt', 'isBeforeUpdatedAt'],
  transactionAmount: ['amountLower', 'amountUpper'],
  proofStatus: ['proofStatus'],
  operationsStatus: ['operationsStatus'],
  accounts: ['accounts'],
  billingInvoiceStatuses: ['billingInvoiceStatuses'],
  billingQuoteStatuses: ['billingQuoteStatuses'],
  accountingStatus: ['accountingStatus'],
  categorizationSource: ['categorizationSource'],
  operationCategoryExternalIds: ['operationCategoryExternalIds'],
};

/**
 * Indicates whether a filter attribute is an array of values or a single value
 */
const isArrayAttribute: Record<keyof IFilters, boolean> = {
  transactionType: true,
  search: false,
  balanceType: true,
  isBeforeUpdatedAt: false,
  isAfterUpdatedAt: false,
  transactionStatus: true,
  amountLower: false,
  amountUpper: false,
  accounts: true,
  billingInvoiceStatuses: true,
  billingQuoteStatuses: true,
  proofStatus: true,
  operationsStatus: true,
  accountingStatus: true,
  categorizationSource: true,
  operationCategoryExternalIds: true,
};

// Checks that arrays are equal regardless of order.
// Assumes that elements are not repeated.
const areArraysEqual = <T>(a?: T[], b?: T[]): boolean => {
  if (!a || !b) {
    return !a && !b;
  }
  if (a.length !== b.length) {
    return false;
  }
  const setA = new Set(a);
  const isUnequal = b.some((value) => !setA.has(value));
  return !isUnequal;
};

export const clearFiltersFromQueryParams = (
  queryParams: URLSearchParams
): URLSearchParams => {
  Object.entries(isArrayAttribute).forEach(([attribute, isArray]) => {
    if (isArray) {
      queryParams.delete(`${attribute}[]`);
      return;
    }
    queryParams.delete(attribute);
  });
  return queryParams;
};

export const isAttributePresent = (
  queryParams: URLSearchParams,
  key: keyof IFilters
): boolean => {
  if (isArrayAttribute[key]) {
    return queryParams.get(`${key}[]`) !== null;
  }
  return queryParams.get(key) !== null;
};

/**
 * Get array value from queryParams, and handle empty state.
 * For example, with the url: path?a[]=b&a[]=c&b[]=
 * - get(a) => [b,c]
 * - get(b) => undefined
 * - get(c) => null
 */
export const getQueryParamArrayValue = <T extends keyof IFilters>(
  queryParams: URLSearchParams,
  key: T
): IFilters[T] | undefined | null => {
  const arrayKey = `${key}[]`;
  const values = queryParams.getAll(arrayKey);
  if (values.length === 0) {
    return null;
  }
  if (values.length === 1 && values[0] === '') {
    return undefined;
  }
  return values as IFilters[T];
};

export const setQueryParamArrayValue = (
  queryParams: URLSearchParams,
  key: string,
  values?: { toString: () => string }[]
): void => {
  if (values === undefined) {
    queryParams.set(`${key}[]`, '');
    return;
  }
  values.forEach((value) => {
    queryParams.append(`${key}[]`, value.toString());
  });
};

export const getQueryParamValue = (
  queryParams: URLSearchParams,
  key: string
): undefined | null | string => {
  const value = queryParams.get(key);
  return value === '' ? undefined : value;
};

export const getQueryParamsForActiveFilters = (
  filters: IFilters,
  activeFilters: IActiveFilters,
  locationQueryParams: URLSearchParams,
  defaultFilters: IFilters
): URLSearchParams => {
  const queryParams = clearFiltersFromQueryParams(locationQueryParams);

  Object.entries(activeFilters).forEach(([filterSection, isSectionActive]) => {
    if (!isSectionActive) {
      return;
    }

    filterAttributesBySection[filterSection as keyof IActiveFilters].forEach(
      <T extends keyof IFilters>(filterAttribute: T) => {
        const value: IFilters[T] = filters[filterAttribute];
        const defaultFilterValue: IFilters[T] = defaultFilters[filterAttribute];

        if (isArrayAttribute[filterAttribute] && Array.isArray(value)) {
          if (
            Array.isArray(defaultFilterValue) &&
            areArraysEqual(value, defaultFilterValue)
          ) {
            return;
          }
          setQueryParamArrayValue(queryParams, filterAttribute, value);
          return;
        }
        if (defaultFilters[filterAttribute] === filters[filterAttribute]) {
          return;
        }
        let stringValue: string;
        if (value === undefined || value === '') {
          return;
        }
        if (typeof value === 'number') {
          stringValue = Math.round(100 * value).toString();
        } else {
          stringValue = value.toString();
        }
        queryParams.set(filterAttribute, stringValue);
      }
    );
  });

  return queryParams;
};

export const getActiveFiltersFromQueryParams = (
  activeFilters: IActiveFilters,
  queryParams: URLSearchParams
): IFilters => {
  let filters: IFilters = {};

  Object.entries(activeFilters).forEach(([filterSection, isSectionActive]) => {
    if (!isSectionActive) {
      return;
    }

    filterAttributesBySection[filterSection as keyof IActiveFilters].forEach(
      (filterAttribute) => {
        if (!isAttributePresent(queryParams, filterAttribute)) {
          return;
        }
        if (isArrayAttribute[filterAttribute]) {
          const arrayValue = getQueryParamArrayValue(
            queryParams,
            filterAttribute
          );
          if (arrayValue !== null) {
            filters = {
              ...filters,
              [filterAttribute]: arrayValue,
            };
          }
          return;
        }
        let value: string | number | null | undefined = getQueryParamValue(
          queryParams,
          filterAttribute
        );
        if (value === null) {
          return;
        }
        if (
          filterAttribute === 'amountLower' ||
          filterAttribute === 'amountUpper'
        ) {
          value =
            value === undefined || !Number.isInteger(+value)
              ? undefined
              : +value / 100;
        }
        filters = {
          ...filters,
          [filterAttribute]: value,
        };
      }
    );
  });

  return filters;
};

export const getAmountBackgroundColor = (
  amount: string,
  transactionType: TransactionType
): keyof ThemeButtonColorsType => {
  if (amount.includes('+')) {
    return 'success25';
  }
  if (transactionType === TransactionType.NoteDeFrais) {
    return 'grey50';
  }
  return 'none';
};

export const getAmountTypographyColor = (
  amount: string,
  transactionType: TransactionType
): keyof ThemeColorsType => {
  if (amount.includes('+')) {
    return 'success800';
  }
  if (transactionType === TransactionType.NoteDeFrais) {
    return 'grey800';
  }
  return 'primary900';
};

export const getFormattedAccountsListForFilter = (
  accountsObject: Record<string, FindAccountDto>
): IAccountFilterItem[] =>
  Object.values(accountsObject)
    .filter(({ id }) => id !== FAKE_BRIDGE_ACCOUNT_ID)
    .map(({ id, bankName, name }) => ({
      id,
      name:
        bankName === BankName.SWAN
          ? SWAN_ACCOUNT_DISPLAYED_NAME
          : `${bankName} - ${name}`,
    }));
