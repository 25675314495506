import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FlexAlignCenterJustifySpaceBetween,
  FlexContainerBasicColumn,
  Typography,
} from '@legalplace/storybook';

import { useAppSelector } from '../../hooks/store';
import { formatAmount } from '../../services/utils';
import { selectPendingAccounts } from '../../store/accounts/selectors';
import { ToggleCard } from '../toggleCard/ToggleCard';

export function SelectSynchronizedAccountsToggleList({
  newAccountsToSynchronize,
  setNewAccountsToSynchronize,
}: {
  newAccountsToSynchronize: Record<string, boolean>;
  setNewAccountsToSynchronize: Dispatch<
    SetStateAction<Record<string, boolean>>
  >;
}): JSX.Element | null {
  const { t } = useTranslation('select_synchronized_accounts_modal');

  const pendingAccounts = useAppSelector(selectPendingAccounts);

  const generalAccountBalance = pendingAccounts.reduce(
    (acc, account) => (account.bookedBalance || 0) + acc,
    0
  );

  if (!pendingAccounts.length) {
    return null;
  }

  const { bankName } = pendingAccounts[0];

  return (
    <FlexContainerBasicColumn gap={{ default: '2.4rem' }}>
      <FlexContainerBasicColumn gap={{ default: '0.8rem' }}>
        <FlexAlignCenterJustifySpaceBetween>
          <Typography tag="p2" color="primary900" fontWeight="500">
            {bankName}
          </Typography>
          <Typography tag="p2" color="grey600">
            {formatAmount(generalAccountBalance, false)}
          </Typography>
        </FlexAlignCenterJustifySpaceBetween>
        <Typography tag="p5" color="grey600">
          {t('number_of_accounts', { count: pendingAccounts.length })}
        </Typography>
      </FlexContainerBasicColumn>
      <FlexContainerBasicColumn gap={{ default: '0.8rem' }}>
        {pendingAccounts.map(({ name, iban, bookedBalance, id }) => (
          <ToggleCard
            key={id}
            title={name}
            subtitle={iban}
            content={formatAmount(bookedBalance, false)}
            checked={!!newAccountsToSynchronize?.[id]}
            onChange={setNewAccountsToSynchronize}
            option={id}
          />
        ))}
      </FlexContainerBasicColumn>
    </FlexContainerBasicColumn>
  );
}
