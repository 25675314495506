import type { FindAccountDto } from '@legalplace/bankroot-api/modules/account/dto';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../constants';

import type { AccountsState } from './types';

const initialState: AccountsState = {
  byId: {},
  allIds: [],
  status: Status.IDLE,
};

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    startFetchAccounts: (state) => {
      state.byId = {};
      state.allIds = [];
      state.status = Status.LOADING;
    },
    startSilentFetchAccounts: () => {},
    fetchAccountsSuccess: (state, action: PayloadAction<FindAccountDto[]>) => {
      state.byId = action.payload.reduce(
        (accumulator, account: FindAccountDto) => ({
          ...accumulator,
          [account.id]: account,
        }),
        {} as Record<string, FindAccountDto>
      );
      state.allIds = action.payload.map((account) => account.id);
      state.status = Status.SUCCESS;
    },
    fetchAccountsFailed: (state) => {
      state.status = Status.FAILED;
    },
    resetAccounts: () => initialState,
  },
});

export default accountsSlice.reducer;
