import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  initialDeliveryFormState,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import cardDesign from '../../../../assets/images/cardDesign.png';
import { useAppSelector } from '../../../../hooks/store';
import { selectHasFirstCardAlreadyBeenOrdered } from '../../../../store/cards/selectors';
import { selectActiveCompany } from '../../../../store/companies/selectors';
import { InformationMessage } from '../../../informationMessage/InformationMessage';
import {
  CARD_FEATURES_TRANSLATION_KEYS,
  DAY_IN_72_HOURS_WITHOUT_SUNDAYS,
  getDeliveryAddressFormStateFromActiveCompanyAddress,
  parseDeliveryAddress,
} from '../../../onboarding/CardDelivery/CardDelivery.helper';

import { CardDeliveryForm } from './CardDeliveryForm';
import { CardDeliveryValidation } from './CardDeliveryValidation';

import {
  CardFeatureContainer,
  CardFeaturesContainer,
} from '../../../onboarding/CardDelivery/CardDelivery.style';
import { OnboardingStepCardContainer } from '../../OnboardingV2Steps.style';
import {
  CardDeliveryCardAndInformationsContainer,
  CardDeliveryInformationsContainer,
  CardImageDelivery,
} from './CardDelivery.style';

export function CardDelivery({
  notifySuccess,
}: {
  notifySuccess: () => void;
}): JSX.Element {
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'card_delivery',
  });

  const activeCompany = useAppSelector(selectActiveCompany);
  const cardHasAlreadyBeenOrdered = useAppSelector(
    selectHasFirstCardAlreadyBeenOrdered
  );

  const [isEditing, setIsEditing] = useState(true);

  const [deliveryAddressFormState, setDeliveryAddressFormState] = useState(
    initialDeliveryFormState
  );

  useEffect(() => {
    if (cardHasAlreadyBeenOrdered) {
      notifySuccess();
    }
  }, [cardHasAlreadyBeenOrdered, notifySuccess]);

  useEffect(() => {
    if (activeCompany) {
      const newActiveCompanyAddress = parseDeliveryAddress(activeCompany);

      setDeliveryAddressFormState(
        getDeliveryAddressFormStateFromActiveCompanyAddress(
          newActiveCompanyAddress
        )
      );
    }
  }, [activeCompany]);

  const deliveryDay = DAY_IN_72_HOURS_WITHOUT_SUNDAYS[new Date().getDay()];

  return (
    <OnboardingStepCardContainer>
      <CardDeliveryCardAndInformationsContainer>
        <CardImageDelivery src={cardDesign} alt="Card design" />
        <CardDeliveryInformationsContainer>
          <Typography tag="p2" fontWeight="500" color="primary900">
            {t('card_delivery_title')}
          </Typography>
          <InformationMessage
            icon={StreamLineIconList.deliverytruck}
            message={t('info_message')}
            emphasizedMessage={t('info_message_emphasized', {
              deliveryDay: t(deliveryDay),
            })}
          />

          <CardFeaturesContainer>
            {CARD_FEATURES_TRANSLATION_KEYS.map((translationKey) => (
              <CardFeatureContainer key={translationKey}>
                <StreamLineIcon
                  icon={StreamLineIconList.check1}
                  width="1.2rem"
                  height="1.2rem"
                  spacing={{ default: '4px' }}
                />
                <Typography
                  tag="p3"
                  fontWeight="400"
                  color="primary900"
                  innerHtml={t(translationKey)}
                />
              </CardFeatureContainer>
            ))}
          </CardFeaturesContainer>
        </CardDeliveryInformationsContainer>
      </CardDeliveryCardAndInformationsContainer>
      {isEditing ? (
        <CardDeliveryForm
          t={t}
          setIsEditing={setIsEditing}
          deliveryAddressFormState={deliveryAddressFormState}
          setDeliveryAddressFormState={setDeliveryAddressFormState}
          notifySuccess={notifySuccess}
        />
      ) : (
        <CardDeliveryValidation
          t={t}
          deliveryAddressFormState={deliveryAddressFormState}
          notifySuccess={notifySuccess}
          setIsEditing={setIsEditing}
        />
      )}
    </OnboardingStepCardContainer>
  );
}
