import { useTranslation } from 'react-i18next';

import {
  ModalDrawerV2,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { INTERCOM_START_URL } from '../../../../constants/academy.constants';

import { ArticlesButton } from '../AcademyModalLearnMore/AcademyModalLearnMore.style';
import {
  AcademyEndMessageModalBody,
  AcademyEndMessageModalFooter,
  AcademyEndMessageModalHeader,
  CloseButton,
  CloseButtonContainer,
} from './AcademyEndMessageModal.style';

interface IAcademyEndMessageModal {
  isOpen: boolean;
  onClose: () => void;
}

export function AcademyEndMessageModal({
  isOpen,
  onClose,
}: IAcademyEndMessageModal): JSX.Element | null {
  const { t } = useTranslation('academy', {
    keyPrefix: 'modal.end_message',
  });

  return (
    <ModalDrawerV2
      modalWidth={{ default: '30%' }}
      isOpen={isOpen}
      onBackdropClick={onClose}
      onClose={onClose}
    >
      <CloseButtonContainer>
        <CloseButton onClick={onClose}>
          <StreamLineIcon
            icon={StreamLineIconList.close}
            width="2rem"
            height="2rem"
          />
        </CloseButton>
      </CloseButtonContainer>

      <AcademyEndMessageModalHeader>
        <Typography
          tag="p2"
          color="primary900"
          fontWeight="600"
          textAlign="center"
          style={{ flexGrow: '1' }}
        >
          {t('title')}
        </Typography>
      </AcademyEndMessageModalHeader>

      <AcademyEndMessageModalBody>
        <Typography
          tag="p3"
          color="primary900"
          fontWeight="600"
          textAlign="center"
          style={{ flexGrow: '1' }}
        >
          {t('subtitle')}
        </Typography>
        <Typography
          tag="p3"
          color="primary900"
          fontWeight="400"
          textAlign="center"
          style={{ flexGrow: '1' }}
        >
          {t('description')}
        </Typography>
      </AcademyEndMessageModalBody>

      <AcademyEndMessageModalFooter>
        <ArticlesButton
          forwardedAs="a"
          target="_blank"
          rel="noopener noreferrer"
          href={INTERCOM_START_URL}
          onClick={onClose}
        >
          {t('articles_button')}
        </ArticlesButton>
      </AcademyEndMessageModalFooter>
    </ModalDrawerV2>
  );
}
