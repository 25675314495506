import { call, put, select, takeEvery } from 'typed-redux-saga';

import { getInvoiceStatsRequest } from '../../../../services/api/billing/billingInformation.api';
import { selectActiveCompanyId } from '../../../companies/selectors';
import {
  fetchInvoiceStatsError,
  fetchInvoiceStatsSuccess,
  startFetchInvoiceStatsRequest,
} from '../actions';

function* handleFetchInvoiceStats(): Generator {
  let companyId = '';
  try {
    companyId = (yield select(selectActiveCompanyId)) as string;
    const response = yield* call(getInvoiceStatsRequest, companyId);
    const { data: invoice } = response;
    yield put(fetchInvoiceStatsSuccess(invoice));
  } catch (error: unknown) {
    console.error(
      `[handleFetchInvoiceStats] - Error trying to fetch invoice stats for company : ${companyId}`,
      error
    );
    yield put(fetchInvoiceStatsError());
  }
}

function* fetchInvoiceSagaStats(): Generator {
  yield takeEvery(startFetchInvoiceStatsRequest, handleFetchInvoiceStats);
}

export default fetchInvoiceSagaStats;
