import { ActivityType, LegalStatus, OnboardingStep } from '@legalplace/shared';

export const EXCLUDED_LEGAL_STATUSES_FROM_ONBOARDING = [
  LegalStatus.EI,
  LegalStatus.SELARL,
  LegalStatus.SELAS,
  LegalStatus.AutoEntrepeneur,
  LegalStatus.Association,
];

export const LEGAL_STATUS_ONBOARDING_OPTIONS = Object.values(LegalStatus)
  .filter(
    (legalStatusValue) =>
      !EXCLUDED_LEGAL_STATUSES_FROM_ONBOARDING.includes(legalStatusValue)
  )
  .map((legalStatusOption) => ({
    label: legalStatusOption,
    value: legalStatusOption,
  }));

export const EXCLUDED_ACTIVITY_TYPES_FROM_ONBOARDING = [
  ActivityType.PROF_LIB,
  ActivityType.MEDICAL,
  ActivityType.ASSOCIATION,
  ActivityType.CRYPTO_BLOCKCHAIN_NFT,
];

export const ACTIVITY_TYPE_OPTIONS = Object.values(ActivityType)
  .filter(
    (activityType) =>
      !EXCLUDED_ACTIVITY_TYPES_FROM_ONBOARDING.includes(activityType)
  )
  .map((activityTypeValue) => ({
    label: activityTypeValue,
    value: activityTypeValue,
  }));

export const NOT_LOGGED_IN_ACCOUNTING_ONBOARDING_STEPS = Object.values(
  OnboardingStep
).filter((step) => step !== OnboardingStep.CardDelivery);
