import type { JustificationWrapper } from '@legalplace/bankroot-api/dto';
import type { GetAssociateBalancesDTO } from '@legalplace/bankroot-api/modules/categorizableItem/dto';
import type {
  FindCompanyDto,
  NestedBillingInformationDto,
} from '@legalplace/bankroot-api/modules/company/dto';
import type { GetDashboardWidgetDto } from '@legalplace/bankroot-api/modules/userCompany/dto';
import type { CompanySetupStatus, RightType } from '@legalplace/shared';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../constants';

import type { CompaniesState } from './types';

const initialState: CompaniesState = {
  byId: {},
  allIds: [],
  status: Status.IDLE,
  activeCompany: {},
  pcaBalanceStatus: Status.IDLE,
  vatBalanceStatus: Status.IDLE,
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setActiveCompanyId: (state, action: PayloadAction<string>) => {
      state.activeCompany.id = action.payload;
    },
    startFetchActiveCompanyPcaBalance: (state) => {
      state.activeCompany.pcaBalances = undefined;
      state.activeCompany.pcaDebtorCount = undefined;
      state.pcaBalanceStatus = Status.LOADING;
    },
    startSilentFetchActiveCompanyPcaBalance: () => {},
    fetchActiveCompanyPcaBalanceSuccess: (
      state,
      {
        payload: { pcaBalances, pcaDebtorCount },
      }: PayloadAction<{
        pcaBalances?: GetAssociateBalancesDTO['balances'];
        pcaDebtorCount?: number;
      }>
    ) => {
      state.activeCompany.pcaBalances = pcaBalances;
      state.activeCompany.pcaDebtorCount = pcaDebtorCount;
      state.pcaBalanceStatus = Status.IDLE;
    },
    fetchActiveCompanyPcaBalanceFailed: (state) => {
      state.activeCompany.pcaBalances = undefined;
      state.activeCompany.pcaDebtorCount = undefined;
      state.pcaBalanceStatus = Status.FAILED;
    },
    startFetchActiveCompanyVatBalance: (state) => {
      state.activeCompany.vatBalance = undefined;
      state.vatBalanceStatus = Status.LOADING;
    },
    startSilentFetchActiveCompanyVatBalance: () => {},
    fetchActiveCompanyVatBalanceSuccess: (
      state,
      {
        payload: {
          vatBalance,
          nonJustifiedTransactionsCount,
          nonCategorizedTransactionsCount,
          transactionsMissingProofCount,
          collectedVat,
          deductableVat,
          paymentVat,
          creditedVat,
          vatBalanceAtStart,
          vatReferenceDate,
        },
      }: PayloadAction<{
        vatBalance: number | undefined;
        nonJustifiedTransactionsCount: number;
        collectedVat: number | undefined;
        nonCategorizedTransactionsCount: number | undefined;
        transactionsMissingProofCount: number | undefined;
        deductableVat: number | undefined;
        paymentVat: number | undefined;
        creditedVat: number | undefined;
        vatBalanceAtStart: number | undefined;
        vatReferenceDate: string | undefined;
      }>
    ) => {
      state.activeCompany.vatBalance = vatBalance;
      state.activeCompany.nonJustifiedTransactionsCount =
        nonJustifiedTransactionsCount;
      state.activeCompany.collectedVat = collectedVat;
      state.activeCompany.deductableVat = deductableVat;
      state.activeCompany.paymentVat = paymentVat;
      state.activeCompany.nonCategorizedTransactionsCount =
        nonCategorizedTransactionsCount;
      state.activeCompany.transactionsMissingProofCount =
        transactionsMissingProofCount;
      state.activeCompany.creditedVat = creditedVat;
      state.activeCompany.vatBalanceAtStart = vatBalanceAtStart;
      state.activeCompany.vatReferenceDate = vatReferenceDate;
      state.vatBalanceStatus = Status.SUCCESS;
    },
    fetchActiveCompanyVatBalanceFailed: (state) => {
      state.activeCompany.vatBalance = undefined;
      state.vatBalanceStatus = Status.FAILED;
    },
    setJustificationTransactionsCount: (
      state,
      {
        payload: {
          nonCategorizedTransactionsCount,
          nonJustifiedTransactionsCount,
          transactionsMissingProofCount,
        },
      }: PayloadAction<
        Pick<
          JustificationWrapper,
          | 'nonCategorizedTransactionsCount'
          | 'nonJustifiedTransactionsCount'
          | 'transactionsMissingProofCount'
        >
      >
    ) => {
      state.activeCompany.nonJustifiedTransactionsCount =
        nonJustifiedTransactionsCount;
      state.activeCompany.nonCategorizedTransactionsCount =
        nonCategorizedTransactionsCount;
      state.activeCompany.transactionsMissingProofCount =
        transactionsMissingProofCount;
    },
    startFetchCompanies: (state) => {
      state.status = Status.LOADING;
    },
    fetchCompaniesSuccess: (state, action: PayloadAction<FindCompanyDto[]>) => {
      state.byId = action.payload.reduce(
        (accumulator, company: FindCompanyDto) => ({
          ...accumulator,
          [company.id]: company,
        }),
        {} as Record<string, FindCompanyDto>
      );
      state.allIds = action.payload.map((company) => company.id);
      state.status = Status.IDLE;
    },
    fetchCompaniesFailed: (state) => {
      state.status = Status.FAILED;
    },
    resetCompanies: () => initialState,
    setActiveCompanySetupStatus: (
      state,
      {
        payload: { companyId, companySetupStatus },
      }: PayloadAction<{
        companyId: string;
        companySetupStatus: CompanySetupStatus;
      }>
    ) => {
      if (state.byId[companyId]) {
        state.byId[companyId].setupStatus = companySetupStatus;
      }
    },
    setActiveVatStartDate: (state) => {
      if (state.activeCompany.id) {
        state.activeCompany.vatStartDate =
          state.byId[state.activeCompany.id]?.vatStartDate;
      }
    },
    setActiveCompanyBillingInformation: (
      state,
      {
        payload: { companyId, billingInformation },
      }: PayloadAction<{
        companyId: string;
        billingInformation: NestedBillingInformationDto;
      }>
    ) => {
      if (state.byId[companyId]) {
        state.byId[companyId].billingInformation = billingInformation;
      }
    },
    startFetchActiveCompanyUserRights: (state) => {
      state.activeCompany.rights = undefined;
    },
    setActiveCompanyUserRights: (
      state,
      { payload }: PayloadAction<RightType[]>
    ) => {
      state.activeCompany.rights = payload;
    },
    startFetchActiveCompanyDashboardWidgetVisibility: (state) => {
      state.activeCompany.widgetVisibility = undefined;
      state.activeCompany.widgetVisibilityStatus = Status.LOADING;
    },
    fetchActiveCompanyDashboardWidgetVisibilityFailed: (state) => {
      state.activeCompany.widgetVisibility = undefined;
      state.activeCompany.widgetVisibilityStatus = Status.FAILED;
    },
    fetchActiveCompanyDashboardWidgetVisibilitySuccess: (
      state,
      { payload }: PayloadAction<GetDashboardWidgetDto>
    ) => {
      state.activeCompany.widgetVisibility = payload;
      state.activeCompany.widgetVisibilityStatus = Status.SUCCESS;
    },
  },
});

export default companiesSlice.reducer;
