import { call, put, select, takeEvery } from 'typed-redux-saga';

import { getAccountsRequest } from '../../../services/api/account.api';
import { startFetchCards } from '../../cards/actions';
import { selectActiveCompanyId } from '../../companies/selectors';
import {
  fetchAccountsFailed,
  fetchAccountsSuccess,
  startFetchAccounts,
  startSilentFetchAccounts,
} from '../actions';

function* handleFetchAccounts(): Generator {
  let activeCompanyId = '';
  try {
    activeCompanyId = (yield select(selectActiveCompanyId)) as string;
    if (activeCompanyId) {
      const response = yield* call(getAccountsRequest, activeCompanyId);
      const { data: accounts } = response;
      yield put(fetchAccountsSuccess(accounts));
      yield put(startFetchCards());
    }
  } catch (error: unknown) {
    console.error(
      `[handleFetchAccounts] - Error trying to fetch accounts for company ${activeCompanyId}`,
      error
    );
    yield put(fetchAccountsFailed());
  }
}

function* fetchAccountsSaga(): Generator {
  yield takeEvery(
    [startFetchAccounts, startSilentFetchAccounts],
    handleFetchAccounts
  );
}

export default fetchAccountsSaga;
