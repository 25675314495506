import { FlexContainerBasicColumn, styled } from '@legalplace/storybook';

export const AcademyDesktopContentContainer = styled(FlexContainerBasicColumn)`
  overflow-y: auto;
  max-height: calc(100vh - 34rem);
`;

export const AcademyMobileContentContainer = styled(FlexContainerBasicColumn)<{
  contentHeight: number;
}>`
  overflow-y: auto;
  flex-grow: 1;
  max-height: ${({ contentHeight }) =>
    contentHeight ? `${contentHeight}px` : ''};
`;

export const AcademyMobileFooterContainer = styled(FlexContainerBasicColumn)`
  gap: ${({ theme }) => theme.spacing.medium};
  background: ${({ theme }) => theme.backgroundColors.white};
  padding: ${({ theme }) => `${theme.spacing.small} 0`};
`;
