import { companiesSlice } from './reducer';

export const {
  startFetchCompanies,
  fetchCompaniesFailed,
  fetchCompaniesSuccess,
  setActiveCompanyId,
  resetCompanies,
  fetchActiveCompanyPcaBalanceFailed,
  fetchActiveCompanyPcaBalanceSuccess,
  fetchActiveCompanyVatBalanceFailed,
  fetchActiveCompanyVatBalanceSuccess,
  startFetchActiveCompanyPcaBalance,
  startFetchActiveCompanyVatBalance,
  setJustificationTransactionsCount,
  startSilentFetchActiveCompanyPcaBalance,
  startSilentFetchActiveCompanyVatBalance,
  setActiveCompanySetupStatus,
  setActiveCompanyBillingInformation,
  startFetchActiveCompanyUserRights,
  setActiveCompanyUserRights,
  startFetchActiveCompanyDashboardWidgetVisibility,
  fetchActiveCompanyDashboardWidgetVisibilityFailed,
  fetchActiveCompanyDashboardWidgetVisibilitySuccess,
  setActiveVatStartDate,
} = companiesSlice.actions;
