export const mobile = {
  unavailable_in_mobile: {
    modal_title: 'Indisponible sur l’application',
    modal_message:
      'Cette fonctionnalité est pour l’instant uniquement disponible depuis un navigateur. Rendez-vous sur <a href="https://pro.legalplace.fr">https://pro.legalplace.fr</a> pour poursuivre.',
    cancel_button_label: 'Annuler',
  },
  force_app_update: {
    modal_title: 'Nouvelle version disponible',
    modal_message:
      "Veuillez mettre à jour l'application depuis l'AppStore pour profiter des dernières nouveautés.",
    modal_button: 'Mettre à jour',
  },
};
