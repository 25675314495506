import type { Dispatch, SetStateAction } from 'react';
import type { TFunction } from 'react-i18next';

import type { AddressDto } from '@legalplace/bankroot-api/modules/company/dto';
import { Button } from '@legalplace/storybook';

import {
  AddressContainer,
  DeliveryAddressRecapContainer,
  DeliveryAddressRecapTypography,
} from './DeliveryAddressRecap.style';

export function DeliveryAddressRecap({
  t,
  activeCompanyAddress,
  setIsEditing,
}: {
  t: TFunction;
  activeCompanyAddress: AddressDto;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const { addressLine1, addressLine2, city, country, zipCode } =
    activeCompanyAddress;

  const updateAddressButtonClickHandler = (): void => {
    setIsEditing(true);
  };

  return (
    <DeliveryAddressRecapContainer>
      <AddressContainer>
        <DeliveryAddressRecapTypography>
          {addressLine1}
        </DeliveryAddressRecapTypography>
        {addressLine2 !== '' && (
          <DeliveryAddressRecapTypography>
            {addressLine2}
          </DeliveryAddressRecapTypography>
        )}
        <DeliveryAddressRecapTypography>
          {zipCode} {city}
        </DeliveryAddressRecapTypography>
        <DeliveryAddressRecapTypography>
          {t(`country.${country}`)}
        </DeliveryAddressRecapTypography>
      </AddressContainer>
      <Button
        styleType="tertiary"
        spacing={{ default: '0 auto' }}
        onClickHandler={updateAddressButtonClickHandler}
      >
        {t('update_address_button_label')}
      </Button>
    </DeliveryAddressRecapContainer>
  );
}
