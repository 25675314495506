import type { AxiosResponse } from 'axios';

import type { GetMeetingInfoResponseDto } from '@legalplace/bankroot-api/modules/calendly/dto';

import { api } from './api';

export const getCalendlyCodeRequest = (
  companyId: string
): Promise<AxiosResponse<string>> =>
  api.get(`/calendly/code?companyId=${companyId}`);

export const getCalendlyMeetingInfo = (
  companyId: string
): Promise<AxiosResponse<GetMeetingInfoResponseDto>> =>
  api.get(`/calendly/${companyId}/meeting-info`);
