import { FormField, styled } from '@legalplace/storybook';

// Used to add a hidden email field for the password manager.
// If display:none is used, it is not detected.
export const HiddenField = styled(FormField)`
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
`;
