export const banner = {
  uncategorized_transactions_one: '1 transaction à catégoriser',
  uncategorized_transactions_other: '{{count}} transactions à catégoriser',
  missing_proofs_one: '1 justificatif obligatoire manquant',
  missing_proofs_other: '{{count}} justificatifs obligatoires manquants',
  actions: {
    synchronize_account: 'Synchroniser votre compte',
    see_transactions: 'Voir les transactions',
  },
  desynchronized_accounts: {
    title: 'Compte désynchronisé',
    subtitle:
      'Votre compte bancaire n’est plus synchronisé à l’application. Synchronisez votre compte pour avoir vos transactions à jour.',
  },
  missing_proof_and_uncategorized: {
    title:
      '$t(uncategorized_transactions, {"count": {{uncategorized_transaction_count}} }) et $t(missing_proofs, {"count": {{missing_proof_count}} })',
    subtitle:
      'Votre balance de TVA est actuellement approximative. Catégorisez et justifiez toutes vos transactions pour voir votre balance de TVA exacte.',
    subtitle_no_vat:
      'Catégorisez et justifiez toutes vos transactions pour valider vos échéances.',
  },
  missing_proof: {
    title: '$t(missing_proofs, {"count": {{missing_proof_count}} })',
    subtitle:
      'Il manque des documents pour valider vos échéances, déposez vos justificatifs obligatoires.',
  },
  uncategorized_transactions: {
    title:
      '$t(uncategorized_transactions, {"count": {{uncategorized_transaction_count}} })',
    subtitle:
      'Votre balance de TVA est actuellement approximative. Catégorisez toutes vos transactions pour voir votre balance de TVA exacte.',
    subtitle_no_vat:
      'Catégorisez toutes vos transactions pour valider vos échéances.',
  },
  wallet_warning: {
    title:
      '{{transaction_count}} encaissements {{payment_method_name}}, déclarer mon activité !',
    subtitle:
      "Suivez et catégorisez automatiquement vos encaissements, obtenez une vue d'ensemble claire de toutes vos activités de vente.",
    action_label: 'Déclarer mon activité sur {{payment_method_name}}',
    action_label_mobile: 'Déclarer mon activité',
  },
  transaction_locked: {
    accounting_period_closed_title: 'Exercice clôturé',
    accounting_period_closed_message:
      'Vous ne pouvez pas modifier cette transaction car cet exercice a été clôturé.',
    accounting_period_pending_review_title: 'Préparation du Bilan',
    accounting_period_pending_review_message:
      'La finalisation du bilan par votre comptable pour cet exercice empêche toute modification de cette transaction.',
    contact_support: 'Contacter mon comptable',
  },
  transaction_immobilised: {
    transaction_immobilised_title: 'Votre transaction est immobilisée',
    transaction_immobilised_message:
      'Les modifications que vous ferez sur cette opération impacterons l’immobilisation associée.',
    closed_accounting_closure_title: 'Votre transaction est immobilisée',
    closed_accounting_closure_message:
      'L’immobilisation associée a déjà été déclarée lors d’un bilan, vous ne pouvez pas modifier l’opération.',
    immobilisation_sold_title: 'L’immobilisation a été déclarée comme vendue',
    immobilisation_sold_message:
      'Pour modifier cette opération, contactez votre comptable.',
    see_immobilisations_button_label: 'Voir mes immobilisations',
  },
};
