import { flexBaseAlignCenter, styled } from '@legalplace/storybook';

export const AppBottomNavContainer = styled.div<{
  $isInHeader?: boolean;
}>`
  ${flexBaseAlignCenter};
  gap: ${({ theme }) => theme.spacing.xxsmall};

  ${({ $isInHeader, theme }) =>
    $isInHeader &&
    `@media screen and (max-width: ${theme.breakpoints.xxlarge}) {
      gap: unset;
      z-index: 1;
      left: 0;
      bottom: 0;
      box-shadow: ${theme.boxShadows.shadowElevationReversed};
      background-color: ${theme.backgroundColors.white};
      width: 100%;
      justify-content: space-around;
  }`}
`;
