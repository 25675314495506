import type { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import axios from 'axios';

import { Capacitor } from '@capacitor/core';
import type { PresignedFileUploadDto } from '@legalplace/bankroot-api/modules/file/dto';
import { UserOrigin } from '@legalplace/shared';
import type { SerializedError } from '@reduxjs/toolkit';

import { CapacitorHttpClient } from '../http/capacitor.helper';

const apiEndpoint = import.meta.env.VITE_APP_API_URL;

export const api = Capacitor.isNativePlatform()
  ? new CapacitorHttpClient({
      baseURL: apiEndpoint,
      withCredentials: true,
      headers: {
        'lpp-user-origin':
          Capacitor.getPlatform() === 'ios'
            ? UserOrigin.IOS
            : UserOrigin.ANDROID,
      },
    })
  : axios.create({
      baseURL: apiEndpoint,
      withCredentials: true,
      headers: {
        'lpp-user-origin': UserOrigin.WEB,
      },
    });

export const downloadFileRequest = async (
  downloadUrl: string
): Promise<AxiosResponse<Blob>> =>
  axios.get(downloadUrl, {
    responseType: 'blob',
  });

export const uploadFileRequest = async (
  presignedFileUploadDto: PresignedFileUploadDto,
  file: File
): Promise<void> => {
  const formData = new FormData();
  formData.append('Content-Type', file.type);
  Object.keys(presignedFileUploadDto.headers).forEach((key) => {
    formData.append(key, presignedFileUploadDto.headers[key]);
  });
  formData.append('file', file);
  await axios.post(presignedFileUploadDto.url, formData, {
    headers: {
      'Content-Type': `multipart/form-data;`,
    },
  });
};

export const reactToolkitBaseQuery = async <T, D = unknown>({
  url,
  method = 'GET',
  body,
  params = {},
  headers = {},
}: {
  url: string;
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';
  body?: D;
  params?: Record<string, unknown>;
  headers?: AxiosRequestHeaders;
}): Promise<
  | {
      data: T;
      error?: undefined;
      meta?: { response: unknown };
    }
  | {
      error: SerializedError;
      data?: undefined;
      meta?: { response: unknown };
    }
> => {
  try {
    const response = await api.request<T>({
      url,
      method,
      data: body,
      params,
      headers,
    });
    return { data: response.data };
  } catch (error) {
    return {
      error: {
        code: (error as AxiosError).response?.status.toString(),
      },
      meta: {
        response: error,
      },
    };
  }
};
