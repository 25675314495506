export const migrationInformation = {
  page_title: 'Information migration',
  title: 'Votre logiciel de comptabilité évolue !',
  migration_information:
    'Vous avez désormais accès à la nouvelle version de notre logiciel de comptabilité : LegalPlace Pro !',
  security_information:
    'Pour plus de sécurité, il est nécessaire de redéfinir votre mot de passe.',
  reset_email_information:
    "Vous avez reçu un email avec un lien vous permettant de renseigner votre nouveau mot de passe. Si vous ne l'avez pas reçu,",
  forgot_password: 'cliquez ici.',
};
