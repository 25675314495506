import type { AxiosResponse } from 'axios';

import type { GetOperationCategoryDto } from '@legalplace/bankroot-api/modules/operation/dto';
import type { BalanceType } from '@legalplace/shared';

import { api } from './api';

export const getCategoriesRequest = (
  balanceType: BalanceType
): Promise<AxiosResponse<GetOperationCategoryDto[]>> =>
  api.get(`/operation/categories?balanceType=${balanceType}`);
