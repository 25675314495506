import { useEffect, useMemo, useState } from 'react';

import type { IReloadContext } from '../app.context';

export const useReloadContext = (): IReloadContext => {
  const [reloadSymbol, setReloadSymbol] = useState(Symbol('reload'));

  return useMemo(
    () => ({
      reload: () => setReloadSymbol(Symbol('reload')),
      useReloadEffect: (onReload, deps) => {
        // onReload cannot be set in deps array as it would trigger useEffect indefinitely
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(onReload, [...(deps || []), reloadSymbol]);
      },
    }),
    [reloadSymbol]
  );
};
