import type { CompanyUserDto } from '@legalplace/bankroot-api/modules/company/dto';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../constants';

import type { UserState } from './type';

const initialState: UserState = {
  companyUsers: {
    byId: {},
    allIds: [],
    status: Status.LOADING,
  },
  loggedInState: Status.IDLE,
  userInformation: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedIn: (state) => {
      state.loggedInState = Status.SUCCESS;
    },
    setUserInformations: (
      state,
      action: PayloadAction<{
        firstName?: string;
        lastName?: string;
        userId?: string;
        phoneNumber?: string;
        email?: string;
        isFirstLogin?: boolean;
      }>
    ) => {
      state.userInformation = {
        ...action.payload,
      };
    },
    updateCompanyUser: (
      state,
      { payload: companyUser }: PayloadAction<Partial<CompanyUserDto>>
    ) => {
      if (companyUser.userId && state.companyUsers.byId[companyUser.userId]) {
        state.companyUsers.byId[companyUser.userId] = {
          ...state.companyUsers.byId[companyUser.userId],
          ...companyUser,
        };
      }
    },
    startFetchCompanyUsers: (state) => {
      state.companyUsers.byId = {};
      state.companyUsers.allIds = [];
      state.companyUsers.status = Status.LOADING;
    },
    fetchCompanyUsersSuccess: (
      state,
      { payload: companyUsers }: PayloadAction<CompanyUserDto[]>
    ) => {
      state.companyUsers.byId = companyUsers.reduce(
        (accumulator, companyUser: CompanyUserDto) => ({
          ...accumulator,
          [companyUser.userId]: companyUser,
        }),
        {} as Record<string, CompanyUserDto>
      );
      state.companyUsers.allIds = companyUsers.map(
        (companyUser) => companyUser.userId
      );
      state.companyUsers.status = Status.IDLE;
    },
    fetchCompanyUsersFailed: (state) => {
      state.companyUsers.status = Status.FAILED;
    },
    startLogoutUser: () => ({
      ...initialState,
      loggedInState: Status.FAILED,
    }),
  },
});

export default userSlice.reducer;
