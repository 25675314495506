import { stripString } from './string';

export const stripIban = (iban: string): string =>
  iban.toUpperCase().replace(/[^0-9A-Z]/g, '');

export const formatFrenchIban = (iban?: string): string => {
  if (!iban) {
    return '';
  }
  const strippedIban = stripString(iban);
  return strippedIban.replace(/([A-Z0-9]{4})/g, '$1 ').trim();
};

export const splitFrenchIban = (
  iban: string
): {
  bank: string;
  agency: string;
  account: string;
  key: string;
} => ({
  bank: iban.substring(4, 9),
  agency: iban.substring(9, 14),
  account: iban.substring(14, 25),
  key: iban.substring(25, 27),
});
