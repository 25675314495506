import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BannerStyle,
  BannerV2,
  FlexColumnFullWidth,
  FlexContainerBasicAlignCenter,
  ModalDrawerV2,
  ModalVariants,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { useAppSelector } from '../../hooks/store';
import { selectActiveCompany } from '../../store/companies/selectors';
import { useUpdateActivationCodeMutation } from '../../store/taxesCredentials/reducer';
import { InputWrapper } from '../common/forms/inputWrapper/InputWrapper';
import { PasswordField } from '../common/forms/passwordField/PasswordField';

export type ActivationCodeModalProps = {
  setIsModalOpen: (isModalOpen: boolean) => void;
  isModalOpen: boolean;
};

export function ActivationCodeModal({
  isModalOpen,
  setIsModalOpen,
}: ActivationCodeModalProps): JSX.Element {
  const { t } = useTranslation('update_tax_credentials_banner');
  const onCloseModal = (): void => setIsModalOpen(false);
  const [activationCode, setActivationCode] = useState('');
  const activeCompany = useAppSelector(selectActiveCompany) ?? { id: '' };
  const [updateActivationCode, { isLoading }] =
    useUpdateActivationCodeMutation();

  const onCloseAndReset = (): void => {
    onCloseModal();
    setActivationCode('');
  };

  const onButtonClick = async (): Promise<void> => {
    try {
      await updateActivationCode({
        companyId: activeCompany.id,
        activationCode,
      });
      onCloseAndReset();
    } catch (error: unknown) {
      console.error(
        '[updateActivationCode] - Error while updating activation code',
        error
      );
    }
  };

  return (
    <ModalDrawerV2
      title={t('activation_code.title')}
      isOpen={isModalOpen}
      onClose={onCloseAndReset}
      onBackdropClick={onCloseAndReset}
      modalVariant={ModalVariants.ACTION}
      primaryAction={onButtonClick}
      buttonLabels={{ primary: t('activation_code.button_label') }}
      primaryButtonProps={{ disabled: !activationCode, isLoading }}
    >
      <FlexColumnFullWidth gap={{ default: '2.5rem' }}>
        <FlexColumnFullWidth gap={{ default: '2.5rem' }}>
          <BannerV2
            title={t('activation_code.banner_title')}
            bannerStyle={BannerStyle.INFORMATION}
            icon={StreamLineIconList.informationcircle}
          />
          <FlexColumnFullWidth>
            <InputWrapper
              name="activation-code-input-field"
              label={t('activation_code.input_field_label')}
            >
              <PasswordField
                name="activation-code-input-field"
                onChange={(e) => setActivationCode(e.target.value)}
                placeholder={''.padStart(9, '•')}
                placeholderColor="grey300"
                color="grey700"
                hasError={false}
                disabled={false}
                value={activationCode}
              />
            </InputWrapper>
            <FlexContainerBasicAlignCenter>
              <StreamLineIcon
                icon={StreamLineIconList.lock}
                fillColor="grey400"
              />
              <Typography
                tag="p4"
                fontWeight="400"
                color="greytainted400"
                textAlign="left"
              >
                {t('activation_code.encrypted_information_message')}
              </Typography>
            </FlexContainerBasicAlignCenter>
          </FlexColumnFullWidth>
        </FlexColumnFullWidth>
      </FlexColumnFullWidth>
    </ModalDrawerV2>
  );
}
