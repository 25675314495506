export default {
  title: 'Définissez votre mot de passe',
  password_criteria:
    'Votre mot de passe doit contenir au moins 8 caractères, inclure au moins une majuscule et un caractère spécial.',
  password_field: 'Mot de passe',
  password_confirmation_field: 'Confirmez votre mot de passe',
  password_error:
    "Ce mot de passe n'est pas assez complexe pour sécuriser votre compte (8 caractères, avec une majuscule, une minuscule, un chiffre et un caractère spécial).",
  password_confirmation_error: 'Veuillez inscrire un mot de passe identique.',
  user_agreement:
    'J’accepte les <a href="{{tosLink}}" target="_blank" rel="noreferrer">conditions générales d’utilisation</a> ' +
    'et la <a href="{{privacyPolicyLink}}" target="_blank" rel="noreferrer">politique de confidentialité</a>',
  user_agreement_not_accepted:
    'Acceptez les conditions générales d’utilisations pour accéder à votre espace.',
  trust_device: 'Ignorer la validation à deux étapes sur cet appareil.',
  access_app: 'Accéder à mon espace',
  unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
};
