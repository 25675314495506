import { lazy } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import { SuspenseRoute } from './components/suspenseRoute/SuspenseRoute';
import { useCurrentDeviceBreakpoint } from './hooks/useCurrentDeviceBreakpoint';
import { Menu } from './pages/menu/Menu';
import { ProtectedRoute } from './pages/ProtectedRoute';

const Auth = lazy(() => import('./pages/Auth'));
const MigrationInformation = lazy(
  () => import('./pages/migrationInformation/MigrationInformation')
);
const Beneficiaries = lazy(() => import('./pages/beneficiaries/Beneficiaries'));
const Cards = lazy(() => import('./pages/Cards/Cards'));
const DirectDebit = lazy(() => import('./pages/directDebit/DirectDebit'));
const Mandates = lazy(() => import('./pages/mandates/Mandates'));
const CloseTab = lazy(() => import('./pages/CloseTab'));
const QontoCallBack = lazy(() => import('./pages/QontoCallback'));

const Transactions = lazy(() => import('./pages/transactions/Transactions'));
const Popup = lazy(() => import('./pages/Popup'));
const MobileCloseTab = lazy(() => import('./pages/MobileCloseTab'));
const CreditTransfer = lazy(
  () => import('./pages/creditTransfer/CreditTransfer')
);
const Onboarding = lazy(() => import('./pages/onboarding/Onboarding'));
const Home = lazy(() => import('./pages/home/Home'));
const Login = lazy(() => import('./pages/Login'));
const OnboardingV2 = lazy(() => import('./pages/onboardingV2/OnboardingV2'));

const Invoices = lazy(() => import('./pages/billing/Invoice'));
const Quotes = lazy(() => import('./pages/billing/Quote'));

const BillingClients = lazy(() => import('./pages/billing/BillingClients'));

const BillingCatalogItems = lazy(
  () => import('./pages/billing/BillingCatalogItems')
);

const Reset = lazy(() => import('./pages/Reset'));

const Settings = lazy(() => import('./pages/settings/Settings'));
const InvalidateRequest = lazy(
  () => import('./pages/invalidateRequest/InvalidateRequest')
);
const Accounts = lazy(() => import('./pages/Accounts'));
const AddAccount = lazy(() => import('./pages/AddAccount'));
const Expenses = lazy(() => import('./pages/expenses/Expenses'));
const PartnerCurrentAccounts = lazy(
  () => import('./pages/partnerCurrentAccounts/PartnerCurrentAccounts')
);
const PartnerCurrentAccountHome = lazy(
  () => import('./pages/partnerCurrentAccountHome/PartnerCurrentAccountHome')
);
const BridgeCallback = lazy(
  () => import('./pages/bridgeCallback/BridgeCallback')
);

const CloseAccount = lazy(() => import('./pages/closeAccount/CloseAccount'));

const Deactivated = lazy(() => import('./pages/deactivated/Deactivated'));

const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));

const Accounting = lazy(() => import('./pages/accounting/Accounting'));

const UpsellBank = lazy(() => import('./pages/upsell/Bank'));
const UpsellAccounting = lazy(() => import('./pages/upsell/AccountingUpsell'));

const AddAccountingSubscription = lazy(
  () => import('./pages/addAccountingSubscription/AddAccountingSubscription')
);

const OneTimeLogin = lazy(() => import('./pages/oneTimeLogin/OneTimeLogin'));
const ConfirmAccountingOnboarding = lazy(
  () =>
    import('./pages/confirmAccountingOnboarding/ConfirmAccountingOnboarding')
);

const CompanyAccountingOnboarding = lazy(
  () =>
    import('./pages/companyAccountingOnboarding/CompanyAccountingOnboarding')
);

const OnboardingRedirection = lazy(
  () => import('./pages/onboardingRedirection/OnboardingRedirection')
);

const MyAccountant = lazy(() => import('./pages/myAccountant/MyAccountant'));

const Immobilisations = lazy(
  () => import('./pages/immobilisations/Immobilisations')
);

const LockedAccount = lazy(() => import('./pages/lockedAccount/LockedAccount'));

export const router = createBrowserRouter([
  {
    element: <SuspenseRoute />,
    children: [
      { path: '/information-migration', element: <MigrationInformation /> },
      {
        path: '/invalider-requete/:authConfirmationRequestId',
        element: <InvalidateRequest />,
      },
      { path: '/bridge-callback', element: <BridgeCallback /> },
      { path: '/mot-de-passe', element: <Reset /> },
      { path: '/premiere-connexion', element: <OneTimeLogin /> },
      {
        path: '/confirmation-onboarding',
        element: <ConfirmAccountingOnboarding />,
      },
      { path: '/connexion', element: <Login /> },
      { path: '/compte-bloque', element: <LockedAccount /> },
      { path: '/qonto-callback', element: <QontoCallBack /> },
      { path: '/close', element: <CloseTab /> },
      { path: '/mobile/close', element: <MobileCloseTab /> },
      { path: '/popup', element: <Popup /> },
      {
        path: '/comptabilite-inscription',
        element: <CompanyAccountingOnboarding />,
        children: [{ path: ':accountingOnboardingPage' }, { path: '' }],
      },
      { path: '/auth', element: <Auth /> },
      {
        path: '/:companyId/souscription-abonnement-comptabilite',
        element: <AddAccountingSubscription />,
      },
    ],
  },
  {
    element: <ProtectedRoute />,
    children: [
      { path: '/:companyId/onboarding-v2', element: <OnboardingV2 /> },
      { path: '/:companyId/bienvenue', element: <Onboarding /> },
      { path: '/:companyId/inactif', element: <Deactivated /> },
      { path: '/:companyId', element: <Transactions /> },
      { path: '/:companyId/tableau-de-bord', element: <Dashboard /> },
      {
        path: '/:companyId/virements/beneficiaires',
        element: <Beneficiaries />,
      },
      { path: '/:companyId/cartes', element: <Cards /> },
      { path: '/:companyId/prelevements', element: <DirectDebit /> },
      { path: '/:companyId/prelevements/mandats', element: <Mandates /> },
      { path: '/:companyId/virements', element: <CreditTransfer /> },
      { path: '/:companyId/parametres', element: <Settings /> },
      { path: '/:companyId/comptes', element: <Accounts /> },
      { path: '/:companyId/ajout-compte', element: <AddAccount /> },
      { path: '/:companyId/notes-de-frais', element: <Expenses /> },
      { path: '/:companyId/immobilisations', element: <Immobilisations /> },
      { path: '/:companyId/mon-comptable', element: <MyAccountant /> },
      { path: '/:companyId/factures', element: <Invoices /> },
      { path: '/:companyId/menu', element: <Menu /> },
      { path: '/:companyId/factures/devis', element: <Quotes /> },
      { path: '/:companyId/factures/clients', element: <BillingClients /> },
      {
        path: '/:companyId/factures/prestations',
        element: <BillingCatalogItems />,
      },
      {
        path: '/:companyId/comptes-courants-associes',
        element: <PartnerCurrentAccounts />,
      },
      {
        path: '/:companyId/comptes-courants-associes/:categorizableItemId',
        element: <PartnerCurrentAccountHome />,
      },
      { path: '/:companyId/cloture', element: <CloseAccount /> },
      { path: '/:companyId/comptabilite', element: <Accounting /> },
      { path: '/:companyId/decouvrir-compte-pro', element: <UpsellBank /> },
      {
        path: '/:companyId/decouvrir-comptabilite',
        element: <UpsellAccounting />,
      },
      { path: '/redirection-onboarding', element: <OnboardingRedirection /> },
      { path: '/', element: <Home /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]);

export function Router(): JSX.Element {
  const { isMobile } = useCurrentDeviceBreakpoint();
  if (window.intercomSettings) {
    window.intercomSettings.hide_default_launcher = isMobile;
  }

  return <RouterProvider router={router} />;
}
