import {
  flexBaseAlignCenter,
  flexColumnAlignCenter,
  styled,
} from '@legalplace/storybook';

const COLUMNS_WIDTH = '80rem';

export const ColumnsContainer = styled.div`
  ${flexBaseAlignCenter}
  padding: ${({ theme }) => `0 ${theme.spacing.medium}`};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;
    width: 100vw;
    padding: ${({ theme }) =>
      `0 ${theme.spacing.medium} ${theme.spacing.medium} ${theme.spacing.medium}`};
  }
`;

export const PricingContainer = styled.div`
  ${flexColumnAlignCenter};
  padding: ${({ theme }) =>
    `${theme.spacing.xxxlarge} ${theme.spacing.xxxxxxlarge}`};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: unset;
  }
`;

export const TextContainer = styled.div`
  max-width: ${COLUMNS_WIDTH};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: ${({ theme }) => `${theme.spacing.medium}`};
  }
`;
