import type { AccountRibInformationDTO } from '@legalplace/bankroot-api/modules/account/dto';
import type { AddressDto } from '@legalplace/bankroot-api/modules/company/dto';

import {
  LP_DOMICILIATION_ADDRESSES,
  LP_DOMICILIATION_ZIPCODE,
} from '../../constants';

export const formatAddress = (
  address: AccountRibInformationDTO['address']
): string[] => [
  `${address.addressLine1}`,
  `${address.addressLine2}`,
  address.postalCode
    ? `${address.postalCode} ${address.city}`
    : `${address.city}`,
];

export const isLpDomiAddress = (
  deliveryFormState: Record<string, string | number | boolean>
): boolean => {
  const { address1, zipCode } = deliveryFormState;
  if (zipCode !== LP_DOMICILIATION_ZIPCODE) {
    return false;
  }
  const formatedAddress = address1
    .toString()
    .trim()
    .toLowerCase()
    .replace('ç', 'c')
    .replace(',', '');

  return LP_DOMICILIATION_ADDRESSES.includes(formatedAddress);
};

export const isAddressLpDomiAddress = (addressDto: AddressDto): boolean => {
  const { addressLine1, zipCode } = addressDto;
  if (zipCode !== LP_DOMICILIATION_ZIPCODE) {
    return false;
  }
  const formatedAddress = addressLine1
    .toString()
    .trim()
    .toLowerCase()
    .replace('ç', 'c')
    .replace(',', '');

  return LP_DOMICILIATION_ADDRESSES.includes(formatedAddress);
};
