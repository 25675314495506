import { BankIconLoader } from '@legalplace/storybook';

import { useAppSelector } from '../../../../../hooks/store';
import {
  selectAreAccountsLoading,
  selectHasNoAccounts,
} from '../../../../../store/accounts/selectors';

import { AccountSelection } from './AccountSelection';
import { NoAccount } from './NoAccount';

interface IRibSelectionProps {
  notifySuccess: () => void;
}

export function RibSelection({
  notifySuccess,
}: IRibSelectionProps): JSX.Element {
  const areAccountsLoading = useAppSelector(selectAreAccountsLoading);
  const hasNoAccounts = useAppSelector(selectHasNoAccounts);

  if (areAccountsLoading) {
    return <BankIconLoader />;
  }

  if (hasNoAccounts) {
    return <NoAccount />;
  }

  return <AccountSelection notifySuccess={notifySuccess} />;
}
