export const partnerCurrentAccounts = {
  page_title: "Comptes courants d'associés",
  back_button_label: 'Retour',
  show_transactions: 'Afficher les transactions',
  warning_transactions_to_justify_one:
    'Attention, les montants peuvent être incorrects car il vous reste 1 transaction à justifier.',
  warning_transactions_to_justify_other:
    'Attention, les montants peuvent être incorrects car il vous reste {{count}} transactions à justifier.',
  last_pca_balance_registration:
    'Solde au {{lastRegisteredBalanceDate}} : {{lastRegisteredBalance}}',
  // start of bank_account_card translations
  regular: {
    button_label: 'Voir détails',
    partner_debtor_message: 'Cet associé est débiteur.',
  },
  // end of bank_account_card translations
};
