import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { CreditTransferSteps } from '../../constants';

import type { CreditTransferState, ICreditTransferValues } from './types';

const initialState: CreditTransferState = {
  creditTransferStep: CreditTransferSteps.BENEFICIARY,
  creditTransferValues: {},
};

export const creditTransferSlice = createSlice({
  name: 'creditTransfer',
  initialState,
  reducers: {
    setCreditTransferStep(
      state,
      { payload }: PayloadAction<CreditTransferSteps>
    ) {
      state.creditTransferStep = payload;
    },
    setCreditTransferValues(
      state,
      { payload }: PayloadAction<ICreditTransferValues>
    ) {
      state.creditTransferValues = payload;
    },
  },
});

export default creditTransferSlice.reducer;
