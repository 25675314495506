import { AccountingPlanName } from '@legalplace/shared';

export enum AccountingFeatures {
  INTUITIVE_ACCOUNTING_SOFTWARE = 'intuitive_accounting_software',
  BILLING_MODULE = 'billing_module',
  VAT_DECLARATION = 'vat_declaration',
  CHAT_ADVICE = 'chat_advice',
  ACCOUNTING_BALANCE_SHEET_AND_TAX = 'accounting_balance_sheet_and_tax',
  DEDICATED_ACCOUNTANT = 'dedicated_accountant',
  VIDEO_OR_PHONE_SUPPORT = 'video_or_phone_support',
  PRIORITY_BALANCE_SHEET = 'priority_balance_sheet',
  MULTI_USERS_ACCESS = 'multi_user_access',
  ANNUAL_LEGAL_OBLIGATIONS = 'annual_legal_obligation',
  TAX_AUDIT_SUPPORT = 'tax_audit_support',
}

export const STARTER_FEATURES: {
  feature: AccountingFeatures;
  isAvailable: boolean;
}[] = [
  {
    feature: AccountingFeatures.INTUITIVE_ACCOUNTING_SOFTWARE,
    isAvailable: true,
  },
  { feature: AccountingFeatures.BILLING_MODULE, isAvailable: true },
  { feature: AccountingFeatures.VAT_DECLARATION, isAvailable: true },
  { feature: AccountingFeatures.CHAT_ADVICE, isAvailable: true },
  {
    feature: AccountingFeatures.ACCOUNTING_BALANCE_SHEET_AND_TAX,
    isAvailable: false,
  },
  { feature: AccountingFeatures.DEDICATED_ACCOUNTANT, isAvailable: false },
  { feature: AccountingFeatures.VIDEO_OR_PHONE_SUPPORT, isAvailable: false },
  { feature: AccountingFeatures.PRIORITY_BALANCE_SHEET, isAvailable: false },
  { feature: AccountingFeatures.MULTI_USERS_ACCESS, isAvailable: false },
  { feature: AccountingFeatures.ANNUAL_LEGAL_OBLIGATIONS, isAvailable: false },
  { feature: AccountingFeatures.TAX_AUDIT_SUPPORT, isAvailable: false },
];

export const ESSENTIAL_FEATURES: {
  feature: AccountingFeatures;
  isAvailable: boolean;
}[] = [
  {
    feature: AccountingFeatures.INTUITIVE_ACCOUNTING_SOFTWARE,
    isAvailable: true,
  },
  { feature: AccountingFeatures.BILLING_MODULE, isAvailable: true },
  { feature: AccountingFeatures.VAT_DECLARATION, isAvailable: true },
  { feature: AccountingFeatures.CHAT_ADVICE, isAvailable: true },
  {
    feature: AccountingFeatures.ACCOUNTING_BALANCE_SHEET_AND_TAX,
    isAvailable: true,
  },
  { feature: AccountingFeatures.DEDICATED_ACCOUNTANT, isAvailable: true },
  { feature: AccountingFeatures.VIDEO_OR_PHONE_SUPPORT, isAvailable: true },
  { feature: AccountingFeatures.PRIORITY_BALANCE_SHEET, isAvailable: false },
  { feature: AccountingFeatures.MULTI_USERS_ACCESS, isAvailable: false },
  { feature: AccountingFeatures.ANNUAL_LEGAL_OBLIGATIONS, isAvailable: false },
  { feature: AccountingFeatures.TAX_AUDIT_SUPPORT, isAvailable: false },
];

export const ELITE_FEATURES: {
  feature: AccountingFeatures;
  isAvailable: boolean;
}[] = [
  {
    feature: AccountingFeatures.INTUITIVE_ACCOUNTING_SOFTWARE,
    isAvailable: true,
  },
  { feature: AccountingFeatures.BILLING_MODULE, isAvailable: true },
  { feature: AccountingFeatures.VAT_DECLARATION, isAvailable: true },
  { feature: AccountingFeatures.CHAT_ADVICE, isAvailable: true },
  {
    feature: AccountingFeatures.ACCOUNTING_BALANCE_SHEET_AND_TAX,
    isAvailable: true,
  },
  { feature: AccountingFeatures.DEDICATED_ACCOUNTANT, isAvailable: true },
  { feature: AccountingFeatures.VIDEO_OR_PHONE_SUPPORT, isAvailable: true },
  { feature: AccountingFeatures.PRIORITY_BALANCE_SHEET, isAvailable: true },
  { feature: AccountingFeatures.MULTI_USERS_ACCESS, isAvailable: true },
  { feature: AccountingFeatures.ANNUAL_LEGAL_OBLIGATIONS, isAvailable: true },
  { feature: AccountingFeatures.TAX_AUDIT_SUPPORT, isAvailable: true },
];

export const ACCOUNTING_FEATURES_SUMMARY_TRANSLATIONS_KEYS: Record<
  AccountingPlanName,
  string[]
> = {
  [AccountingPlanName.STARTER]: [
    'invoicing_module',
    'vat_declaration',
    'accounting_support',
  ],
  [AccountingPlanName.ESSENTIAL]: [
    'starter_offer_included',
    'balance_sheet',
    'dedicated_accountant',
    'phone_video_support',
  ],
  [AccountingPlanName.ELITE]: [
    'essential_offer_included',
    'priority_balance_sheet',
    'multi_user_access',
    'mandatory_legal_obligations',
    'tax_audit_support',
  ],
};
