import {
  BaseHyperlink,
  flexFullAlignCenterSpaceBetween,
  styled,
} from '@legalplace/storybook';

import { addStyleInDesktopAndTablet } from '../UI/Responsive.style';

export const AddBankAccountContainer = styled(BaseHyperlink)<{
  isLoading?: boolean;
}>`
  ${flexFullAlignCenterSpaceBetween};
  padding: 1.2rem 0;
  ${({ theme }) =>
    addStyleInDesktopAndTablet(theme, `padding: 2.4rem 1.6rem 2.4rem 2.4rem`)};

  cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'pointer')};
  :hover {
    background-color: ${({ theme }) => theme.backgroundColors.grey50};
    transition: padding-right 0.3s ease-out;
    padding-right: 0.8rem;
    border-radius: ${({ theme }) => theme.borderRadiusMd};
  }
`;
