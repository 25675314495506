import { useCallback, useEffect, useState } from 'react';

import CicLogo from '../assets/images/bankLogo/cic.svg';
import otherLogo from '../assets/images/bankLogo/other.svg';
import qontoLogo from '../assets/images/bankLogo/qonto.svg';
import shineLogo from '../assets/images/bankLogo/shine.svg';
import {
  CIC_BRIDGE_BANK_ID,
  SHINE_BRIDGE_BANK_ID,
} from '../constants/bankAccount.constant';
import type { BankAccountItem } from '../interfaces/bankAccountItem.interface';
import { getBridgeItemCreationUrlRequest } from '../services/api/bridgeItem.api';
import { selectActiveCompanyId } from '../store/companies/selectors';

import { useAppSelector } from './store';

export function useFetchBankAccountItems(): BankAccountItem[] {
  const activeCompanyId = useAppSelector(selectActiveCompanyId);

  const [bridgeItemCreationUrl, setBridgeItemCreationUrl] = useState<
    string | undefined
  >();
  const [
    isBridgeItemCreationUrlFetchLoading,
    setIsBridgeItemCreationUrlFetchLoading,
  ] = useState(false);
  const base64State = Buffer.from(
    JSON.stringify({
      companyId: activeCompanyId,
      isInPopup: true,
    })
  ).toString('base64');
  const QONTO_SYNCHRO_URL = `${
    import.meta.env.VITE_APP_QONTO_OAUTH_URL
  }&state=${base64State}`;

  const handleFetchBridgeItemCreationUrl =
    useCallback(async (): Promise<void> => {
      setIsBridgeItemCreationUrlFetchLoading(true);
      try {
        if (activeCompanyId) {
          const {
            data: { redirectUrl },
          } = await getBridgeItemCreationUrlRequest(activeCompanyId, true);

          setBridgeItemCreationUrl(redirectUrl);
        }
        setIsBridgeItemCreationUrlFetchLoading(false);
      } catch (error) {
        console.error(error);
      }
    }, [activeCompanyId]);

  useEffect(() => {
    // Call Bridge Item Creation route only if user has checked acknowledgement and url has not been fetched yet
    if (!bridgeItemCreationUrl) {
      handleFetchBridgeItemCreationUrl();
    }
  }, [bridgeItemCreationUrl, handleFetchBridgeItemCreationUrl]);

  return [
    {
      key: 'qonto',
      logo: { src: qontoLogo, alt: 'Qonto' },
      isLoading: false,
      url: QONTO_SYNCHRO_URL,
    },
    {
      key: 'shine',
      logo: { src: shineLogo, alt: 'Shine' },
      isLoading: isBridgeItemCreationUrlFetchLoading,
      url: `${bridgeItemCreationUrl}/banks/${SHINE_BRIDGE_BANK_ID}`,
    },
    {
      key: 'cic',
      logo: { src: CicLogo, alt: 'Cic' },
      isLoading: isBridgeItemCreationUrlFetchLoading,
      url: `${bridgeItemCreationUrl}/banks/${CIC_BRIDGE_BANK_ID}`,
    },
    {
      key: 'other',
      logo: { src: otherLogo, alt: 'other' },
      isLoading: isBridgeItemCreationUrlFetchLoading,
      url: bridgeItemCreationUrl,
    },
  ];
}
