import type { GetSupportProfileDto } from '@legalplace/bankroot-api/modules/supportProfile/dto';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../services/api/api';

export const supportProfileApi = createApi({
  reducerPath: 'supportProfileApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['SupportProfile'],
  endpoints: (build) => ({
    getSupportProfile: build.query<GetSupportProfileDto, string>({
      query: (companyId) => ({
        url: `/support-profile?companyId=${companyId ?? ''}`,
      }),
      providesTags: ['SupportProfile'],
    }),
  }),
});

export const { useGetSupportProfileQuery } = supportProfileApi;
