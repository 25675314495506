import { forwardRef, useEffect } from 'react';

import { FlexContainerFullWidth } from '@legalplace/storybook';

import { AccountSuspendedBanner } from '../../components/AccountSuspendedBanner/AccountSuspendedBanner';
import { NoConnectionBanner } from '../../components/NoConnectionBanner/NoConnectionBanner';
import { UpdateTaxCredentialsBanner } from '../../components/UpdateTaxCredentialsBanner/UpdateTaxCredentialsBanner';
import { PageVariant } from '../../constants/pageWrapper.constants';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import type { IPageWrapperProps } from '../../interfaces/pageWrapperProps.interface';
import { selectActiveCompany } from '../../store/companies/selectors';
import { setPageVariant } from '../../store/layout/actions';
import { selectPageVariant } from '../../store/layout/selectors';
import { PullToRefresh } from '../pullToRefresh/PullToRefresh';

import { BackgroundContainer, PageContentContainer } from './PageWrapper.style';

export const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
  (
    {
      pageTitle,
      defaultPageVariant = PageVariant.DEFAULT,
      shouldHeightBeSet = true,
      children,
      gap,
      pageInternalSpacing,
      shouldPullToRefresh,
      contentInternalSpacing,
      isFullScreen,
      shouldDisplayAccountSuspendedBanner,
      shouldDisplayUpdateTaxCredentialsBanner,
      subheaderHeight,
    },
    ref
  ) => {
    const currentCompany = useAppSelector(selectActiveCompany);
    const pageVariant = useAppSelector(selectPageVariant);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (!defaultPageVariant || defaultPageVariant === PageVariant.NOT_SET) {
        return;
      }
      dispatch(setPageVariant(defaultPageVariant));
    }, [defaultPageVariant, dispatch]);

    useEffect(() => {
      document.title = `${
        currentCompany ? `${currentCompany.name} -` : ''
      } ${pageTitle}`;
    });

    return (
      <BackgroundContainer
        internalSpacing={pageInternalSpacing}
        pageVariant={pageVariant}
        ref={ref}
        isFullScreen={isFullScreen}
        isAccountSuspendedBannerDisplayed={shouldDisplayAccountSuspendedBanner}
        subheaderHeight={subheaderHeight}
      >
        <NoConnectionBanner />
        {shouldDisplayAccountSuspendedBanner && (
          <FlexContainerFullWidth
            internalspacing={{
              smallMedium: '0',
              large: '1.6rem 1.6rem 0rem 1.6rem',
            }}
          >
            <AccountSuspendedBanner />
          </FlexContainerFullWidth>
        )}
        {shouldDisplayUpdateTaxCredentialsBanner && (
          <FlexContainerFullWidth
            internalspacing={{
              smallMedium: '0',
              large: '1.6rem 1.6rem 0rem 1.6rem',
            }}
          >
            <UpdateTaxCredentialsBanner />
          </FlexContainerFullWidth>
        )}
        <PullToRefresh shouldPullToRefresh={shouldPullToRefresh}>
          <PageContentContainer
            shouldHeightBeSet={shouldHeightBeSet}
            gap={gap}
            internalSpacing={
              contentInternalSpacing || {
                default: '1.6rem',
                medium: '2.4rem',
                large: '4rem',
              }
            }
            isFullScreen={isFullScreen}
            pageVariant={pageVariant}
          >
            {children}
          </PageContentContainer>
        </PullToRefresh>
      </BackgroundContainer>
    );
  }
);
