import createSagaMiddleware from 'redux-saga';

import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';

import { academyApi } from './store/academy/reducer';
import accountsReducer from './store/accounts/reducer';
import { beneficiariesApi } from './store/beneficiariesV2/reducer';
import { billingCatalogItemsApi } from './store/billing/catalogItemsV2/reducer';
import { billingClientsApi } from './store/billing/clientsV2/reducer';
import { billingReducer } from './store/billing/reducer';
import cardsReducer from './store/cards/reducer';
import categoriesReducer from './store/categories/reducer';
import { categorizableItemsApi } from './store/categorizableItems/reducer';
import companiesReducer from './store/companies/reducer';
import { companyFinancesApi } from './store/companyFinances/reducer';
import creditTransferReducer from './store/creditTransfer/reducer';
import { featureFlagsApi } from './store/featureFlags/reducer';
import { immobilisationApi } from './store/immobilisations/reducer';
import layoutReducer from './store/layout/reducer';
import { mandatesApi } from './store/mandates/reducer';
import onboardingStepsReducer from './store/onboardingSteps/reducer';
import { paymentMethodsApi } from './store/paymentMethods/reducer';
import { personalVehiclesApi } from './store/personalVehicles/reducer';
import { rootSaga } from './store/sagas';
import { salesChannelsApi } from './store/salesChannels/reducer';
import { supportProfileApi } from './store/supportProfile/reducer';
import { taxesCredentialsApi } from './store/taxesCredentials/reducer';
import transactionsReducer from './store/transactions/reducer';
import userReducer from './store/user/reducer';

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: {
    companies: companiesReducer,
    transactions: transactionsReducer,
    accounts: accountsReducer,
    cards: cardsReducer,
    user: userReducer,
    billing: billingReducer,
    creditTransfer: creditTransferReducer,
    categories: categoriesReducer,
    onboardingSteps: onboardingStepsReducer,
    layout: layoutReducer,
    [immobilisationApi.reducerPath]: immobilisationApi.reducer,
    [beneficiariesApi.reducerPath]: beneficiariesApi.reducer,
    [billingCatalogItemsApi.reducerPath]: billingCatalogItemsApi.reducer,
    [billingClientsApi.reducerPath]: billingClientsApi.reducer,
    [featureFlagsApi.reducerPath]: featureFlagsApi.reducer,
    [academyApi.reducerPath]: academyApi.reducer,
    [supportProfileApi.reducerPath]: supportProfileApi.reducer,
    [companyFinancesApi.reducerPath]: companyFinancesApi.reducer,
    [taxesCredentialsApi.reducerPath]: taxesCredentialsApi.reducer,
    [personalVehiclesApi.reducerPath]: personalVehiclesApi.reducer,
    [mandatesApi.reducerPath]: mandatesApi.reducer,
    [paymentMethodsApi.reducerPath]: paymentMethodsApi.reducer,
    [salesChannelsApi.reducerPath]: salesChannelsApi.reducer,
    [categorizableItemsApi.reducerPath]: categorizableItemsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true })
      .concat(sagaMiddleware)
      .concat(immobilisationApi.middleware)
      .concat(beneficiariesApi.middleware)
      .concat(billingCatalogItemsApi.middleware)
      .concat(billingClientsApi.middleware)
      .concat(featureFlagsApi.middleware)
      .concat(academyApi.middleware)
      .concat(supportProfileApi.middleware)
      .concat(companyFinancesApi.middleware)
      .concat(taxesCredentialsApi.middleware)
      .concat(personalVehiclesApi.middleware)
      .concat(mandatesApi.middleware)
      .concat(paymentMethodsApi.middleware)
      .concat(salesChannelsApi.middleware)
      .concat(categorizableItemsApi.middleware),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
