import type {
  CreateBeneficiaryDto,
  FindBeneficiaryDto,
  UpdateBeneficiaryDto,
} from '@legalplace/bankroot-api/modules/beneficiaries/dto';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../services/api/api';

export const beneficiariesApi = createApi({
  reducerPath: 'beneficiariesApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['Beneficiary'],
  endpoints: (build) => ({
    getBeneficiaries: build.query<FindBeneficiaryDto[], string>({
      query: (accountId) => ({ url: `/beneficiaries?accountId=${accountId}` }),
      providesTags: ['Beneficiary'],
    }),
    createBeneficiary: build.mutation<FindBeneficiaryDto, CreateBeneficiaryDto>(
      {
        query: (createBeneficiaryDto) => ({
          url: '/beneficiaries',
          method: 'POST',
          body: createBeneficiaryDto,
        }),
        invalidatesTags: ['Beneficiary'],
      }
    ),
    updateBeneficiary: build.mutation<FindBeneficiaryDto, UpdateBeneficiaryDto>(
      {
        query: (updateBeneficiaryDto) => ({
          url: `/beneficiaries/${updateBeneficiaryDto.beneficiaryId}`,
          method: 'PUT',
          body: updateBeneficiaryDto,
        }),
        invalidatesTags: ['Beneficiary'],
      }
    ),
    deleteBeneficiary: build.mutation<void, string>({
      query: (beneficiaryId) => ({
        url: `/beneficiaries/${beneficiaryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Beneficiary'],
    }),
  }),
});

export const {
  useCreateBeneficiaryMutation,
  useDeleteBeneficiaryMutation,
  useGetBeneficiariesQuery,
  useUpdateBeneficiaryMutation,
} = beneficiariesApi;
