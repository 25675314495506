import { useTranslation } from 'react-i18next';

import {
  AccountingOnboardingSource,
  OnboardingCallInterlocutor,
} from '@legalplace/shared';
import {
  FlexContainerBasicColumn,
  Image,
  Typography,
} from '@legalplace/storybook';

import contractSignSvg from '../../../../assets/images/contract-sign-edit.svg';
import { useAppSelector } from '../../../../hooks/store';
import { selectAccountingOnboardingInformation } from '../../../../store/onboardingSteps/selector';

import { WaitingContractContainer } from '../../OnboardingV2Steps.style';

export function WaitingContract({
  shouldDisplaySecondLine,
}: {
  shouldDisplaySecondLine: boolean;
}): JSX.Element {
  const { t } = useTranslation('onboarding', {
    keyPrefix: 'onboarding_v2.contract_signature',
  });

  const accountingOnboardingInformation = useAppSelector(
    selectAccountingOnboardingInformation
  );

  const onboardingCallInterlocutor =
    accountingOnboardingInformation?.source ===
    AccountingOnboardingSource.CreaPlusCompta
      ? OnboardingCallInterlocutor.Accountant
      : OnboardingCallInterlocutor.Advisor;

  return (
    <WaitingContractContainer>
      <Image src={contractSignSvg} height="12.4rem" />
      <FlexContainerBasicColumn>
        <Typography tag="p3" color="greytainted700" textAlign="center">
          {t('contract_not_ready_line_1', {
            callInterlocutor: t(
              `call_interlocutor.${onboardingCallInterlocutor}`
            ),
          })}
        </Typography>
        {shouldDisplaySecondLine && (
          <Typography tag="p3" color="greytainted700" textAlign="center">
            {t('contract_not_ready_line_2')}
          </Typography>
        )}
      </FlexContainerBasicColumn>
    </WaitingContractContainer>
  );
}
