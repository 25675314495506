import { flexBase, styled, Typography } from '@legalplace/storybook';

import { PasswordField } from '../../../common/forms/passwordField/PasswordField';

export const UserAgreementTypography = styled(Typography)`
  a {
    text-decoration: underline;
  }
`;

export const StyledPasswordField = styled(PasswordField)`
  input::placeholder {
    position: relative;
    top: ${({ theme }) => theme.spacing.xxxxxsmall};
  }
`;

export const OnboardingForm = styled.form`
  display: contents;
`;

export const SubmitButtonContainer = styled.div`
  ${flexBase};
  padding-top: ${({ theme }) => theme.spacing.small};
  justify-content: flex-end;
`;
