import { call, put, select, takeEvery } from 'typed-redux-saga';

import { getQuoteStatsRequest } from '../../../../services/api/billing/billingInformation.api';
import { selectActiveCompanyId } from '../../../companies/selectors';
import {
  fetchQuoteStatsError,
  fetchQuoteStatsSuccess,
  startFetchQuoteStatsRequest,
} from '../actions';

function* handleFetchQuoteStats(): Generator {
  let companyId = '';
  try {
    companyId = (yield select(selectActiveCompanyId)) as string;
    const response = yield* call(getQuoteStatsRequest, companyId);
    const { data: quote } = response;
    yield put(fetchQuoteStatsSuccess(quote));
  } catch (error: unknown) {
    console.error(
      `[handleFetchQuoteStats] - Error trying to fetch quote stats for company : ${companyId}`,
      error
    );
    yield put(fetchQuoteStatsError());
  }
}

function* fetchQuoteSagaStats(): Generator {
  yield takeEvery(startFetchQuoteStatsRequest, handleFetchQuoteStats);
}

export default fetchQuoteSagaStats;
