import { BankTertiaryButton, Typography } from '@legalplace/storybook';

import { PrimaryButton } from '../../UI/PrimaryButton.style';
import { OnboardingMobileFooterContainer } from '../CompanyAccountingOnboardingSteps.style';

export function OnboardingMobileFooter({
  onClick,
  primaryButtonLabel,
  onSecondaryButtonClick,
  secondaryButtonLabel,
  disabled,
  isLoading,
}: {
  onClick?: () => void;
  primaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
  secondaryButtonLabel?: string;
  disabled?: boolean;
  isLoading?: boolean;
}): JSX.Element | null {
  if (!onClick) {
    return null;
  }

  const shouldDisplaySecondaryButton =
    !!onSecondaryButtonClick && !!secondaryButtonLabel;

  return (
    <OnboardingMobileFooterContainer>
      <PrimaryButton
        width={{ default: '100%' }}
        onClick={onClick}
        disabled={!!disabled}
        isLoading={!!isLoading}
      >
        <Typography tag="p3">{primaryButtonLabel}</Typography>
      </PrimaryButton>
      {shouldDisplaySecondaryButton && (
        <BankTertiaryButton
          width={{ default: '100%' }}
          onClick={onSecondaryButtonClick}
        >
          {secondaryButtonLabel}
        </BankTertiaryButton>
      )}
    </OnboardingMobileFooterContainer>
  );
}
