import {
  AssociateImmobilisationModalSteps,
  ModifyImmobilisationModalSteps,
} from '../../constants';

export const immobilisations = {
  page_title: 'Immobilisations',
  stats: {
    accounting_value_label: 'Valeur nette comptable',
    depreciated_total_label: 'Total amorti',
    next_closure_depreciation_label: 'Amortissement au prochain bilan',
    tooltip: {
      next_depreciation_message:
        'Prévision du montant total amorti à la prochaine échéance de Bilan',
      depreciation_total_amount_message:
        'Somme de tous les amortissements passés',
      accounting_value_message: `Somme des valeurs nettes comptables (Prix d'achat - Amortissements passés) de vos actifs`,
    },
  },
  banners: {
    title: 'Aller plus loin',
    message:
      'Découvrez comment les amortissements fonctionnent dans notre application et optimisez la gestion de vos immobilisations avec notre guide complet.',
    action: 'En savoir plus',
  },
  row: {
    widget: {
      duration_title_one: '1 an',
      duration_title_other: '{{count}} ans',
      no_duration: '/',
      no_duration_message: 'Non amortissable',
      duration_message: 'Durée d’immobilisation',
      total_amount_message: 'Prix d’achat',
      current_value_message: 'Valeur nette comptable',
      sale_date_message: 'Date de cession',
      sale_amount_message: 'Prix de revente',
      added_value_message: 'Plus-value',
    },
    content: {
      purchaseDate: 'Acheté le',
      capitalizationDate: 'Mise en service le',
      capitalizationEndDate: 'Immobilisé jusqu’au',
      purchaseAmount: 'Prix d’achat :',
      valueAtSale: 'Valeur à la cession :',
      actions: {
        show_depreciation: 'Tableau des amortissements',
        modify: 'Modifier',
        declare_as_sold: 'Déclarer comme vendue',
        delete: 'Annuler l’immobilisation',
        associate_operations: 'Associer des immobilisations',
      },
      widget: {
        duration_title_one: '1 an',
        duration_title_other: '{{count}} ans',
        no_duration: '/',
        no_duration_message: 'Non amortissable',
        duration_message: 'Durée d’immobilisation',
        total_amount_message: 'Prix d’achat',
        current_value_message: 'Valeur nette comptable',
        sale_date_message: 'Date de cession',
        sale_amount_message: 'Prix de revente',
        added_value_message: 'Plus-value',
      },
    },
    depreciation: {
      drawer_title: 'Voir l’amortissement',
      header: {
        period: 'Exercice',
        depreciated_amount: 'Montant amorti',
        accounting_amount: 'Valeur comptable',
      },
      no_depreciation_title: "Cette immobilisation n'est pas amortie",
      no_depreciation_message:
        'Les immobilisations {{categoryLabel}} ne sont pas soumises aux amortissements.',
      period: {
        past: 'Bilans passés',
        year: 'Bilan {{year}}',
      },
    },
    tooltip: {
      sale_date_message: 'Déclarée au moment de la revente',
      sale_amount_message: 'Déclaré au moment de la revente',
      added_value_message:
        'Prix de vente - Valeur nette comptable au moment de la vente',
      no_duration_message: `Non soumis à l'amortissement car cet actif ne perd pas de valeur avec le temps.`,
      duration_message: `Vous pouvez modifier la durée par défaut en cliquant sur 'Modifier'`,
      total_amount_message: `Vérifiez les informations en cliquant sur 'Modifier'`,
      current_value_message: `Le prix d'achat - la somme des amortissements passés`,
    },
  },
  files: {
    upload_file: 'Ajouter un document',
    allowed_types: 'PDF, PNG, JPEG',
    confirm_delete: 'Oui, supprimer',
    cancel_delete: 'Annuler',
    unexpected_error: 'Une erreur est survenue',
    file_preview_title: 'Fichier - {{fileName}}',
  },
  modify_modal: {
    modal_title: 'Modifier',
    [ModifyImmobilisationModalSteps.INFORMATION_STEP]: 'Informations de base',
    [ModifyImmobilisationModalSteps.DEPRECIATION_STEP]: 'Amortissement',
    immobilisation_name_placeholder: 'Renommez votre immobilisation',
    immobilisation_name_label: 'Dénomination',
    empty_field_error: 'Veuillez remplir ce champ.',
    purchase_date_picker_label: 'Date d’achat',
    purchase_date_picker_placeholder: '12/12/2024',
    capitalization_date_picker_label: 'Date de mise en service',
    capitalization_date_picker_placeholder: '12/12/2024',
    second_hand_toggle_label: 'C’est un achat d’occasion',
    operation_title_label: 'Opérations rattachées',
    depreciation_duration_dropdown_label: 'Durée d’amortissement',
    depreciation_duration_dropdown_placeholder: '3 ans',
    next_button: 'Continuer',
    go_back_button: 'Retour',
    validate_button: 'Valider',
    duration_option_label_one: '{{count}} an',
    duration_option_label_other: '{{count}} ans',
    unexpected_error: 'Une erreur est survenue',
    capitalization_date_before_purchase_error:
      "La mise en service ne peut pas précéder l'achat.",
    capitalization_date_in_finished_period_error:
      'Bilan clôturé, la date doit correspondre à une période comptable ouverte.',
    capitalization_date_in_locked_period_error:
      'Bilan en préparation, la date doit correspondre à une période comptable ouverte.',
  },
  delete_modal: {
    modal_title: `Annuler l'immobilisation`,
    cannot_delete_subtitle: `Vous ne pouvez pas annuler cette immobilisation !`,
    cannot_delete_message: `Les immobilisations de type {{categoryLabel}} ne peuvent être annulées. <br><br> Si vous souhaitez l'annuler vous devez re-catégoriser votre transaction.`,
    ok_button: 'Ok',
    delete_subtitle: `Vous êtes sur le point d'annuler cette immobilisation.`,
    delete_message: `Vous risquez de perdre les informations de cette immobilisation. <br><br> Souhaitez-vous continuer ?`,
    validate_button: 'Oui, continuer',
    go_back_button: 'Annuler',
    unexpected_error: 'Une erreur est survenue',
    delete_success_message: 'Immobilisation supprimée',
  },
  associate_modal: {
    modal_title: 'Associer des immobilisations',
    [AssociateImmobilisationModalSteps.IMMOBILISATION_CHOICE_STEP]: 'Sélection',
    [AssociateImmobilisationModalSteps.IMMOBILISATION_PREVIEW_STEP]:
      'Confirmation',
    unexpected_error: 'Une erreur est survenue',
    next_button: 'Continuer',
    go_back_button: 'Retour',
    validate_button: 'Valider',
    choice_step_subtitle:
      'Sélectionnez les immobilisations à rassembler avec celle-ci.',
    duration_value_one: '{{count}} an',
    duration_value_other: '{{count}} ans',
    duration_subtitle: 'Durée d’immobilisation',
    no_similar_immobilisations: 'Vous n’avez aucune immobilisation à rattacher',
    amount_subtitle: 'Prix d’achat',
    warning_banner: {
      title: 'Vous ne trouvez pas ?',
      message:
        'Vérifiez que votre immobilisation est présente dans la page “Immobilisations” et a la même catégorie.',
    },
    preview_step_subtitle:
      'Voici une prévisualisation de votre nouvelle immobilisation.',
    preview_step_subtitle_2:
      'Vous pourrez modifier les informations de la nouvelle immobilisation plus tard.',
    operation_title_label: 'Opérations rattachées',
  },
  sell_modal: {
    modal_title: 'Céder cette immobilisation',
    select_operation_label:
      'Sélectionnez les transactions qui représente la cession de cette immobilisation.',
    warning_banner_title: 'Vous ne trouvez pas ?',
    warning_banner_message: `Vérifiez que votre transaction est bien catégorisée en “Vente d'une immobilisation”.`,
    toggle_label: 'J’ai cédé gratuitement cette immobilisation',
    sale_date_label: 'Date de la cession',
    confirmation_title: 'Vous êtes sur le point de céder cette immobilisation.',
    confirmation_message:
      'L’action que vous-vous apprêtez à faire est définitive.',
    confirmation_message_2: 'Souhaitez-vous continuer ?',
    continue_button: 'Continuer',
    go_back_button: 'Retour',
    confirm_button: 'Oui, continuer',
    abort_button: 'Annuler',
    empty_field_error: 'Veuillez remplir ce champ.',
    unexpected_error: 'Une erreur est survenue',
    selected_sale_date_before_purchase_error:
      "La cession ne peut pas précéder l'achat.",
    selected_sale_date_before_capitalization_error:
      'La cession ne peut pas précéder la mise en service.',
    selected_sale_date_in_finished_period_error:
      'Bilan clôturé, la date doit correspondre à une période comptable ouverte.',
    selected_sale_date_in_locked_period_error:
      'Bilan en préparation, la date doit correspondre à une période comptable ouverte.',
    no_operation_found: 'Aucune transaction trouvée',
  },
};
