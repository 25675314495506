import type { AxiosResponse } from 'axios';

import type { IBillingDocumentFilters } from '@legalplace/bankroot-api/interfaces';
import type {
  CreateQuoteRequestDto,
  CreateQuoteResponseDto,
  GetBillingQuoteResponseDto,
  GetQuoteFileDownloadUrlsResponseDto,
  UpdateQuoteRequestDto,
} from '@legalplace/bankroot-api/modules/billing/dto';
import type { PageDto } from '@legalplace/bankroot-api/modules/pagination/dto';

import type { PaginationOptions } from '../../../store/transactions/types';
import { getDocumentRequestParams } from '../../billing/requestParams';
import { api } from '../api';

export const getQuotesRequest = (
  companyId: string,
  filters?: IBillingDocumentFilters,
  paginationOptions?: PaginationOptions
): Promise<AxiosResponse<PageDto<GetBillingQuoteResponseDto>>> =>
  api.get(
    `/billing/quote?${getDocumentRequestParams(
      companyId,
      filters,
      paginationOptions
    )}`
  );

export const createQuoteRequest = (
  billingQuote: CreateQuoteRequestDto
): Promise<AxiosResponse<CreateQuoteResponseDto>> =>
  api.post(`/billing/quote/`, billingQuote);

export const updateQuoteRequest = (
  quoteId: string,
  updateBillingQuote: UpdateQuoteRequestDto
): Promise<AxiosResponse<GetBillingQuoteResponseDto>> =>
  api.patch(`/billing/quote/${quoteId}`, updateBillingQuote);

export const deleteQuoteRequest = (
  quoteId: string
): Promise<AxiosResponse<void>> => api.delete(`/billing/quote/${quoteId}`);
export const getBillingQuoteFileDownloadUrls = (
  quoteId: string
): Promise<AxiosResponse<GetQuoteFileDownloadUrlsResponseDto>> =>
  api.get(`/billing/quote/${quoteId}/files`);
