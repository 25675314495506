import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

import { delay } from '../utils';

const { VITE_APP_GTM_ID, VITE_APP_GTM_AUTH_ID, VITE_APP_GTM_VERSION_ID } =
  import.meta.env;

async function loadGoogleTagManager(l: string, i: string): Promise<void> {
  window[l] = window[l] || [];
  window[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const dl = l !== 'dataLayer' ? `&l=${l}` : '';
  const url = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}&gtm_auth=${VITE_APP_GTM_AUTH_ID}&gtm_preview=env-${VITE_APP_GTM_VERSION_ID}&gtm_cookies_win=x`;
  await new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export const initApp = async (): Promise<void> => {
  if (Capacitor.getPlatform() === 'ios') {
    // Temporarily disabled intercom on iOS to be able to release
    //
    // const response = await AppTrackingTransparency.requestPermission();
    // if (response.status === 'authorized') {
    //   await loadGoogleTagManager('dataLayer', VITE_APP_GTM_ID || '');
    // }
    let safeAreaEnvVar = window
      .getComputedStyle(document.documentElement, null)
      .getPropertyValue('--safe-area-inset-top');
    let nbOfTries = 0;
    while (!safeAreaEnvVar && nbOfTries < 100) {
      safeAreaEnvVar = window
        .getComputedStyle(document.documentElement, null)
        .getPropertyValue('--safe-area-inset-top');
      nbOfTries += 1;
      // eslint-disable-next-line no-await-in-loop
      await delay(1000);
    }
  } else {
    await loadGoogleTagManager('dataLayer', VITE_APP_GTM_ID || '');
  }

  // force light status bar on mobile as the app is mostly white
  await StatusBar.setStyle({ style: Style.Light });

  await SplashScreen.hide();
};
