import { Button, styled } from '@legalplace/storybook';

import { addStyleInDesktop } from '../UI/Responsive.style';

export const UpdateTaxCredentialsBannerContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundColors.primary900};
  padding: ${({ theme }) => theme.spacing.medium};
  ${({ theme }) =>
    addStyleInDesktop(theme, `border-radius:${theme.borders.radiusMd};`)};
`;

export const UpdateTaxCredentialsBannerCloseButton = styled(Button)`
  width: fit-content;
  border-radius: ${({ theme }) => theme.borders.radiusRounded};

  &:hover {
    background-color: ${({ theme }) => theme.backgroundColors.grey100};
  }
`;
