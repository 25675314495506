import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BankButton,
  FlexColumnCenterFullWidth,
  Image,
  Typography,
} from '@legalplace/storybook';

import rabbitHat from '../../../../assets/images/rabbitHat.svg';
import { useAppDispatch, useAppSelector } from '../../../../hooks/store';
import { startSilentFetchAccounts } from '../../../../store/accounts/actions';
import { selectAccountsById } from '../../../../store/accounts/selectors';
import { AccountResyncModal } from '../../../Accounts/AccountResyncModal';

import { BankSynchronizationContainer } from './BankSynchronization.style';

export function BankSynchronizationError(): JSX.Element {
  const { t } = useTranslation('onboarding', {
    keyPrefix: 'onboarding_v2.bank_synchronization',
  });
  const [isResyncModalOpen, setIsResyncModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const accountsById = useAppSelector(selectAccountsById);

  const itemsToBeSolved = Object.values(accountsById).find(
    ({ accountStatus }) => accountStatus !== 0
  );

  useEffect(() => {
    dispatch(startSilentFetchAccounts());
  }, [dispatch]);

  return (
    <BankSynchronizationContainer>
      <Image src={rabbitHat} width="12.45rem" />
      <FlexColumnCenterFullWidth gap={{ default: '1.4rem' }}>
        <Typography
          tag="p3"
          lineHeight={{ default: '2rem' }}
          color="greytainted700"
          textAlign="center"
        >
          {itemsToBeSolved ? t('bad_item_sync_message') : t('error_message')}
        </Typography>
        {itemsToBeSolved?.bridgeItemId ? (
          <>
            <BankButton
              styleType="tertiary"
              onClick={() => setIsResyncModalOpen(true)}
            >
              {t('solve_connection_button_label')}
            </BankButton>
            <AccountResyncModal
              isOpen={isResyncModalOpen}
              closeHandler={() => setIsResyncModalOpen(false)}
              accountStatusDescription={
                itemsToBeSolved?.accountStatusDescription
              }
              currentBridgeItemId={itemsToBeSolved?.bridgeItemId}
              currentAccountId={itemsToBeSolved?.id}
            />
          </>
        ) : (
          <BankButton
            styleType="tertiary"
            onClick={() => {
              window.location.reload();
            }}
          >
            <Typography
              tag="p3"
              lineHeight={{ default: '2rem' }}
              textAlign="center"
            >
              {t('reload_button_label')}
            </Typography>
          </BankButton>
        )}
      </FlexColumnCenterFullWidth>
    </BankSynchronizationContainer>
  );
}
