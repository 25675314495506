import {
  FlexAlignCenterJustifySpaceBetween,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { CloseButton } from './Toast.style';

export function Toast({
  message,
  closeToast,
}: {
  message: string;
  closeToast?: () => void;
}): JSX.Element {
  return (
    <FlexAlignCenterJustifySpaceBetween>
      <Typography tag="p3">{message}</Typography>
      <CloseButton onClick={closeToast}>
        <StreamLineIcon icon={StreamLineIconList.close} />
      </CloseButton>
    </FlexAlignCenterJustifySpaceBetween>
  );
}
