import type {
  AxiosInterceptorManager,
  AxiosInterceptorOptions,
  AxiosRequestConfig,
} from 'axios';

interface InterceptorHandler<V, T = V> {
  fulfilled: (value: V) => T | Promise<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rejected: (error: any) => any;
  synchronous?: boolean;
  runWhen?: ((config: AxiosRequestConfig) => boolean) | null;
}

/**
 * Interceptor Manager class that has the same behavior as the axios one but can be used with other http client
 */
export class InterceptorManager<V> implements AxiosInterceptorManager<V> {
  handlers: InterceptorHandler<V, V>[];

  constructor() {
    this.handlers = [];
  }

  /**
   * function to register interceptor
   * mimic axios behavior
   */
  use<T = V>(
    onFulfilled: (value: V) => T | Promise<T>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRejected: (error: any) => any,
    options?: AxiosInterceptorOptions
  ): number {
    const handler: InterceptorHandler<V, V> = {
      // Cast here to support same interface as axios
      fulfilled: onFulfilled as unknown as (value: V) => V | Promise<V>,
      rejected: onRejected,
      synchronous: options ? options.synchronous : false,
      runWhen: options ? options.runWhen : null,
    };
    this.handlers.push(handler);
    return this.handlers.length - 1;
  }

  /**
   * Necessary to match axios interface
   */
  eject(id: number): void {
    if (this.handlers[id]) {
      this.handlers.splice(id, 1);
    }
  }

  /**
   * Necessary to match axios interface
   */
  clear(): void {
    if (this.handlers) {
      this.handlers = [];
    }
  }

  /**
   * Necessary to match axios interface
   */
  getHandlers(): (InterceptorHandler<V, unknown> | null)[] {
    return this.handlers;
  }

  /**
   * Fonction to compose all declared success interceptor
   * Only handle case where in the end we expect an AxiosReponse like result
   */
  getComposedFulfilledHandler(): (response: V) => Promise<V> {
    return async (response: V | Promise<V>) =>
      this.handlers.reduce(
        (acc, handler) => handler.fulfilled(acc as V),
        response
      );
  }

  /**
   * Fonction to compose all declared error interceptor
   * have to keep the any in the interface as axios use it as well
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getComposedRejectedHandler(): (error: any) => Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (error: any) =>
      this.handlers.reduce((acc, handler) => handler.rejected(acc), error);
  }

  /**
   * Necessary to match axios interface
   */
  forEach(fn: (handler: InterceptorHandler<V, unknown> | null) => void): void {
    this.handlers.forEach((handler) => {
      if (handler !== null) {
        fn(handler);
      }
    });
  }
}
