import { type Dispatch, type SetStateAction } from 'react';

import { SubscriptionPeriodicity } from '@legalplace/shared';
import { Button, Typography } from '@legalplace/storybook';

import { ToggleBackground, ToggleContainer } from './PricingToggle.style';

export function PricingToggle({
  subscriptionPeriodicity,
  setSubscriptionPeriodicity,
  t,
}: {
  subscriptionPeriodicity: SubscriptionPeriodicity;
  setSubscriptionPeriodicity: Dispatch<SetStateAction<SubscriptionPeriodicity>>;
  t: (x: string) => string;
}): JSX.Element {
  return (
    <ToggleContainer>
      <ToggleBackground
        isToggleChecked={
          subscriptionPeriodicity === SubscriptionPeriodicity.YEARLY
        }
      >
        <Button
          onClick={() => {
            setSubscriptionPeriodicity(SubscriptionPeriodicity.YEARLY);
          }}
          internalSpacing={{ default: '0.4rem 0.8rem' }}
        >
          <Typography
            tag="p4"
            color={
              subscriptionPeriodicity === SubscriptionPeriodicity.YEARLY
                ? 'white'
                : 'grey700'
            }
            fontWeight="500"
          >
            {t('annual_subscription_label')}
          </Typography>
        </Button>
      </ToggleBackground>
      <ToggleBackground
        isToggleChecked={
          subscriptionPeriodicity === SubscriptionPeriodicity.MONTHLY
        }
      >
        <Button
          onClick={() => {
            setSubscriptionPeriodicity(SubscriptionPeriodicity.MONTHLY);
          }}
          internalSpacing={{ default: '0.4rem 0.8rem' }}
        >
          <Typography
            tag="p4"
            color={
              subscriptionPeriodicity === SubscriptionPeriodicity.MONTHLY
                ? 'white'
                : 'grey700'
            }
            fontWeight="500"
          >
            {t('monthly_subscription_label')}
          </Typography>
        </Button>
      </ToggleBackground>
    </ToggleContainer>
  );
}
