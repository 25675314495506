import { Suspense, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Capacitor } from '@capacitor/core';
import { CompanySetupStatus } from '@legalplace/shared';
import {
  BankIconLoader,
  FlexFullWidthAlignCenter,
} from '@legalplace/storybook';

import { ampli } from '../ampli/api';
import { ForceAppUpdateDrawer } from '../components/forceAppUpdateDrawer/ForceAppUpdateDrawer';
import { NavigationWrapper } from '../components/NavigationWrapper/NavigationWrapper';
import {
  ONBOARDING_COMPANY_SETUP_STATUSES,
  Status,
  V2_ONBOARDINGS_STATUSES,
} from '../constants';
import { shouldForceOnboardingCompletion } from '../hooks/onboardingv2/useShouldForceOnboardingCompletion';
import { useAppSelector } from '../hooks/store';
import {
  selectActiveCompany,
  selectCompaniesById,
} from '../store/companies/selectors';
import { selectPageVariant } from '../store/layout/selectors';
import {
  selectLoggedInState,
  selectUserInformations,
} from '../store/user/selector';

export function ProtectedRoute(): JSX.Element {
  const { pathname } = useLocation();
  const userInformation = useAppSelector(selectUserInformations);
  const companiesById = useAppSelector(selectCompaniesById);
  const currentCompany = useAppSelector(selectActiveCompany);
  const userLoggedInState = useAppSelector(selectLoggedInState);

  const pageVariant = useAppSelector(selectPageVariant);

  useEffect(() => {
    ampli.pageViewed({
      path_name: pathname,
      referrer: document.referrer,
      location: window.location.href,
      platform: Capacitor.getPlatform(),
    });
  }, [pathname]);

  if (userLoggedInState === Status.IDLE) {
    return <BankIconLoader spacing={{ default: '2.4rem auto' }} width="100%" />;
  }

  if (userLoggedInState === Status.FAILED) {
    const redirectUrl = pathname === '/' ? '' : `?redirectUrl=${pathname}`;
    return <Navigate to={`/connexion${redirectUrl}`} replace />;
  }
  if (!companiesById || Object.keys(companiesById).length === 0) {
    return <BankIconLoader spacing={{ default: '2.4rem auto' }} width="100%" />;
  }

  const [, pathCompanyId] = pathname.split('/');

  if (
    pathCompanyId &&
    !companiesById[pathCompanyId] &&
    !pathname.includes('redirection-onboarding')
  ) {
    return <Navigate to="/" replace />;
  }

  if (
    shouldForceOnboardingCompletion(currentCompany) &&
    !pathname.includes('onboarding-v2')
  ) {
    return <Navigate to={`/${currentCompany?.id}/onboarding-v2`} replace />;
  }

  const hasActiveFeatures = Object.values(
    currentCompany?.featureMap ?? {}
  ).some((feature) => feature?.isActive);
  if (
    currentCompany &&
    !hasActiveFeatures &&
    !shouldForceOnboardingCompletion(currentCompany) &&
    currentCompany.setupStatus !== CompanySetupStatus.NOT_READY &&
    !pathname.includes('inactif')
  ) {
    return <Navigate to={`/${currentCompany.id}/inactif`} replace />;
  }

  if (
    currentCompany &&
    currentCompany.setupStatus &&
    [
      ...ONBOARDING_COMPANY_SETUP_STATUSES,
      CompanySetupStatus.NOT_READY,
    ].includes(currentCompany.setupStatus) &&
    !pathname.includes('bienvenue')
  ) {
    return <Navigate to={`/${currentCompany.id}/bienvenue`} replace />;
  }

  if (
    currentCompany?.setupStatus &&
    V2_ONBOARDINGS_STATUSES.has(currentCompany.setupStatus) &&
    !pathname.includes('onboarding-v2') &&
    userInformation?.isFirstLogin
  ) {
    return <Navigate to={`/${currentCompany.id}/onboarding-v2`} replace />;
  }

  return (
    <>
      <NavigationWrapper isProtectedRoute pageVariant={pageVariant} />
      <Suspense
        fallback={
          <FlexFullWidthAlignCenter
            internalspacing={{ default: '10rem', large: '0rem' }}
          >
            <BankIconLoader spacing={{ default: '2.4rem auto' }} />
          </FlexFullWidthAlignCenter>
        }
      >
        <Outlet />
        {Capacitor.isNativePlatform() && <ForceAppUpdateDrawer />}
      </Suspense>
    </>
  );
}
