import { call, put, select, takeEvery } from 'typed-redux-saga';

import { getCompanyVatBalanceRequest } from '../../../services/api/company.api';
import {
  fetchActiveCompanyVatBalanceFailed,
  fetchActiveCompanyVatBalanceSuccess,
  startFetchActiveCompanyVatBalance,
  startSilentFetchActiveCompanyVatBalance,
} from '../actions';
import { selectActiveCompanyId } from '../selectors';

function* handleFetchVatBalance(): Generator {
  try {
    const activeCompanyId = yield* select(selectActiveCompanyId);
    if (!activeCompanyId) {
      throw new Error('cannot fetch VAT balance: no active company ID found');
    }
    const {
      data: {
        totalVatBalance,
        notJustifiedTransactionsCount,
        nonCategorizedTransactionsCount,
        transactionsMissingProofCount,
        collectedVat,
        deductableVat,
        paymentVat,
        creditedVat,
        vatBalanceAtStart,
        vatReferenceDate,
      },
    } = yield* call(getCompanyVatBalanceRequest, activeCompanyId);

    yield put(
      fetchActiveCompanyVatBalanceSuccess({
        vatBalance: totalVatBalance,
        nonJustifiedTransactionsCount: notJustifiedTransactionsCount,
        nonCategorizedTransactionsCount,
        transactionsMissingProofCount,
        collectedVat,
        deductableVat,
        paymentVat,
        creditedVat,
        vatBalanceAtStart,
        vatReferenceDate,
      })
    );
  } catch (error: unknown) {
    console.error(
      `[handleFetchVatBalance] - Error trying to fetch company VAT balance`,
      error
    );
    yield put(fetchActiveCompanyVatBalanceFailed());
  }
}

function* fetchVatBalanceSaga(): Generator {
  yield takeEvery(
    [
      startFetchActiveCompanyVatBalance,
      startSilentFetchActiveCompanyVatBalance,
    ],
    handleFetchVatBalance
  );
}

export default fetchVatBalanceSaga;
