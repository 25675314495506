import type { GetOperationDto } from '@legalplace/bankroot-api/modules/operation/dto';
import type { PageDto } from '@legalplace/bankroot-api/modules/pagination/dto';
import type {
  GetTransactionDto,
  ProofDto,
} from '@legalplace/bankroot-api/modules/transaction/dto';
import type {
  AccountingStatus,
  OperationsStatus,
  ProofStatus,
} from '@legalplace/shared';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../constants';
import type { IActiveFilters, IFilters } from '../../interfaces';

import type { PaginationOptions, TransactionState } from './types';

const initialState: TransactionState = {
  byId: {},
  allIds: [],
  status: Status.LOADING,
  pageStatus: Status.IDLE,
  filterParameters: {
    activeFilters: {},
    defaultFilters: {},
  },
  lastPaginationResult: undefined,
  editingExpenseId: undefined,
  duplicatingExpenseId: undefined,
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    startFetchTransactions: (
      state,
      action: PayloadAction<
        Partial<PaginationOptions> & {
          additionalTransaction?: GetTransactionDto;
        }
      >
    ) => {
      if (!action.payload.page && !action.payload.take) {
        state.status = Status.LOADING;
        state.byId = {};
        state.allIds = [];
      } else {
        state.pageStatus = Status.LOADING;
      }
    },
    setFilterParameters: (
      state,
      action: PayloadAction<{
        activeFilters: IActiveFilters;
        defaultFilters: IFilters;
        categorizableItemId?: string;
      }>
    ) => {
      state.filterParameters = action.payload;
    },
    fetchTransactionSuccess: (
      state,
      action: PayloadAction<PageDto<GetTransactionDto>>
    ) => {
      state.lastPaginationResult = action.payload.meta;
      let newStateById: Record<string, GetTransactionDto> = {};
      let newStateAllIds: string[] = [];
      if (action.payload.meta.page !== 1) {
        newStateById = state.byId;
        newStateAllIds = state.allIds;
      }
      state.allIds = [
        ...newStateAllIds,
        ...action.payload.data
          .filter((transaction) => !newStateAllIds.includes(transaction.id))
          .map((transaction) => transaction.id),
      ];
      state.byId = action.payload.data.reduce(
        (accumulator, transaction: GetTransactionDto) => ({
          ...accumulator,
          [transaction.id]: transaction,
        }),
        newStateById
      );
      state.status = Status.IDLE;
      state.pageStatus = Status.IDLE;
    },
    fetchTransactionsFailed: (state) => {
      state.status = Status.FAILED;
    },
    resetTransactions: () => initialState,
    updateTransaction: (
      state,
      { payload: transaction }: PayloadAction<GetTransactionDto>
    ) => {
      state.byId[transaction.id] = transaction;
    },
    partialUpdateTransaction: (
      state,
      {
        payload: {
          transactionId,
          accountingStatus,
          operationsStatus,
          proofStatus,
          proofs,
          operations,
          comment,
        },
      }: PayloadAction<{
        transactionId: string;
        accountingStatus?: AccountingStatus;
        operationsStatus?: OperationsStatus;
        proofStatus?: ProofStatus;
        proofs?: ProofDto[];
        operations?: GetOperationDto[];
        comment?: string;
      }>
    ) => {
      const currentTransaction = state.byId[transactionId];
      state.byId[transactionId] = {
        ...currentTransaction,
        ...(accountingStatus && { accountingStatus }),
        ...(proofStatus && { proofStatus }),
        ...(operationsStatus && { operationsStatus }),
        ...(proofs && { proofs }),
        ...(operations && { operations }),
        ...(comment !== undefined && { comment }),
      };
    },
    updateCurrentOperation: (
      state,
      { payload: operation }: PayloadAction<GetOperationDto | undefined>
    ) => {
      state.currentOperation = operation;
    },
    setEditingExpenseId: (
      state,
      { payload: expenseId }: PayloadAction<string | undefined>
    ) => {
      state.editingExpenseId = expenseId;
    },
    setDuplicatingExpenseId: (
      state,
      { payload: expenseId }: PayloadAction<string | undefined>
    ) => {
      state.duplicatingExpenseId = expenseId;
    },
  },
});

export default transactionsSlice.reducer;
