import { AccountingPlanName, OnboardingStep } from '@legalplace/shared';

export const onboarding = {
  page_title: 'Bienvenue',
  start: {
    title: 'Votre business commence ici',
    subtitle: 'Bienvenue sur LegalPlace Pro !',
    text: 'Nous sommes heureux de vous accompagner dans cette aventure. <br> Nous allons maintenant configurer votre compte ensemble.',
    info_message: "Assurez-vous d'avoir votre smartphone à portée de main.",
    footer: 'Durée : 2 min',
    button_label: 'Commencer',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
  },
  not_ready: {
    title: 'Vous êtes en avance',
    subtitle: 'Bienvenue sur LegalPlace Pro !',
    text: "Malheureusement, votre compte n'est pas encore disponible. <br> Nous vous avertirons par e-mail dès qu'il sera prêt à être utilisé.",
    button_label: 'Revenir sur LegalPlace',
  },
  card_delivery: {
    title: 'Votre carte est prête à partir !',
    subtitle: "Confirmez-nous simplement l'adresse de livraison.",
    info_message: 'Livraison estimée',
    info_message_emphasized: '{{deliveryDay}} prochain',
    order_card_button_label: 'Confirmer et continuer',
    skip_button_label: "Je n'ai pas besoin de carte",
    card_feature_1:
      'Carte Mastercard <strong> incluse dans votre offre </strong>',
    card_feature_2:
      "Paiements et retraits <strong> jusqu'à 20 000 € par mois </strong>",
    card_feature_3:
      'Compatible <strong> Apple Pay </strong> et <strong> Google Pay </strong>',
    card_feature_4:
      "Entièrement paramétrable <strong> depuis l'application </strong>",
    card_feature_5: '<strong> Assurance </strong> contre la fraude',
    update_address_button_label: "Modifier l'adresse",
    card_price: '0 €',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    form: {
      delivery_form_title: 'Adresse de livraison',
      address_1_label: 'Numéro et nom de rue',
      address_2_label: "Complément d'adresse (optionnel)",
      zip_code_label: 'Code postal',
      city_label: 'Ville',
      country_label: 'Pays',
      address_error_message:
        "Renseignez une adresse de réception qui n'est pas celle de votre domiciliation.",
    },
    country: {
      FRA: 'FRANCE',
    },
    monday: 'lundi',
    tuesday: 'mardi',
    wednesday: 'mecredi',
    thursday: 'jeudi',
    friday: 'vendredi',
    saturday: 'samedi',
    sunday: 'dimanche',
  },
  bank_information: {
    title: 'Voici vos coordonnées bancaires',
    text: 'Vous pouvez dès maintenant approvisionner votre compte et <br> transmettre votre IBAN à vos clients et partenaires.',
    button_label: 'Continuer',
    successful_copy_to_clipboard_message: 'Copié !',
    information_iban_title: 'IBAN',
    rib_download_button: 'Télécharger le RIB',
    unexpected_error: 'Une erreur est survenue.',
    export: {
      title: "Relevé d'identité bancaire",
      bank: 'Banque',
      iban: 'IBAN',
      bic: 'BIC',
      agency: 'Agence',
      key: 'Clé',
      account: 'Compte',
      owner: 'Titulaire',
    },
    download_started: 'Téléchargement démarré !',
    generating_rib: 'Chargement en cours...',
  },
  bank_synchronization: {
    title: 'Dernière étape : connectez votre compte professionnel',
    message_line_1:
      "Connecter votre compte professionnel est une étape importante, cela vous permettra d'avoir accès à l'ensemble des fonctionnalités de l'application.",
    message_line_2:
      'Sans cette connexion, impossible pour LegalPlace Pro de gérer votre comptabilité.',
    synchronize_button_label: 'Connecter mon compte',
    skip_button_label: 'Passer cette étape',
    skip_message:
      "Passez cette étape si votre compte bancaire n'est pas disponible actuellement.",
    unexpected_error: 'Une erreur est survenue.',
  },
  calendly_bank: {
    unexpected_error: 'Une erreur est survenue.',
  },
  accounting_trial_offer: {
    title: 'Compléter votre offre avec notre service comptabilité !',
    text: 'Testez gratuitement pendant 1 mois les fonctionnalités <br /> de comptabilité de LegalPlace Pro. <br /> <br /> <strong>Sans engagement</strong>, résiliable à tout moment <strong>sans aucun frais</strong>.',
    optin_button_label: 'Commencer mon essai gratuit de 30 jours',
    optin_button_mobile_label: 'Commencer mon essai gratuit',
    optin_information_label:
      '30 jours gratuit puis à partir de 85 € HT/mois. Désabonnement gratuit à tout moment.',
    sci_optin_information_label:
      '30 jours gratuit puis à partir de 65 € HT/mois. Désabonnement gratuit à tout moment.',
    optin_information_mobile_label:
      '30 jours gratuit puis à partir de 85 € HT/mois. <br> Désabonnement gratuit à tout moment.',
    sci_optin_information_mobile_label:
      '30 jours gratuit puis à partir de 65 € HT/mois. <br> Désabonnement gratuit à tout moment.',
    optout_button_label: `Je gère la comptabilité de mon côté`,
    unexpected_error: 'Une erreur est survenue.',
    accounting_feature_1:
      '<strong>Conseil illimité</strong> avec votre comptable',
    accounting_feature_2: 'Déclaration de <strong>TVA automatisée</strong>',
    accounting_feature_3: '<strong>Bilan</strong> & liasse fiscale',
    accounting_feature_4:
      'Gestion complète de votre compta : <strong>Facturation</strong>, <strong>Tableaux de bord</strong>, catégorisation <strong>via IA</strong>',
  },
  welcomed: {
    title: "C'est terminé !",
    text: "Tout est prêt pour utiliser votre compte LegalPlace Pro. <br /> N'hésitez pas à nous contacter pour toute remarque ou question, <br /> nous restons à votre disposition.",
    text_mobile:
      "Vous êtes prêt à utiliser votre compte LegalPlace Pro. <br /> N'hésitez pas à nous contacter pour toute remarque ou questions, nous restons à votre disposition.",
    button_label: 'Accéder à mon compte',
    footer_title: "Téléchargez l'application LegalPlace Pro",
    footer_text:
      'Disponible dès maintenant sur Android et iOS, <br /> elle vous sera nécessaire pour valider certaines opérations.',
    footer_text_mobile:
      'Disponible dès maintenant sur Android et iOS, elle vous sera nécessaire pour valider certaines opérations.',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
  },
  onboarding_v2: {
    page_title: 'Bienvenue',
    onboarding_title: 'Bienvenue sur LegalPlace Pro !',
    finalize_onboarding_title: 'Activez votre suivi comptable !',
    onboarding_subtitle: 'Finalisez votre inscription.',
    finalize_onboarding_subtitle:
      'Gérez vos finances en toute liberté avec un comptable en ligne disponible à tout moment, où que vous soyez !',
    steps: {
      [OnboardingStep.CompanyInformation]: 'Votre entreprise',
      [OnboardingStep.CredentialsCreation]: 'Création de compte',
      [OnboardingStep.BankAccountsSynchronization]: 'Synchronisation bancaire',
      [OnboardingStep.OnboardingCall]: 'Rendez-vous {{callInterlocutor}}',
      [OnboardingStep.ContractSignature]: 'Votre offre',
      [OnboardingStep.TaxesCredentials]: 'Identifiants impots.gouv',
      [OnboardingStep.CardDelivery]: 'Commander votre carte',
    },
    onboarding_stepper: {
      finalize_registration_button_label: 'Finalisez votre inscription',
      nb_steps_left: 'et on s’occupe de votre compta !',
      remaining_trial_days: `{{ remainingTrialDays }} jours d'essai restants`,
      not_interested_button_label: 'Je ne suis pas intéressé',
    },
    contract_signature: {
      contract_not_ready_line_1:
        'Votre {{callInterlocutor}} prépare votre contrat.',
      contract_not_ready_line_2:
        "Un email vous sera envoyé lorsqu'il sera prêt pour signature.",
      confirm_plan_title: 'Confirmez votre offre !',
      confirm_plan_subtitle:
        'Suite à votre rendez-vous, votre {{callInterlocutor}} vous recommande cette offre :',
      billed_annually: 'Paiement annuel',
      excluding_taxes: `\u00a0€ HT /mois`,
      go_to_signature_button_label: 'Signer mon contrat',
      edit_plan_button_label: 'Modifier mon offre',
      plan_name: {
        [AccountingPlanName.ELITE]: 'Elite',
        [AccountingPlanName.STARTER]: 'Starter',
        [AccountingPlanName.ESSENTIAL]: 'Essentiel',
      },
      plan_features: {
        invoicing_module: 'Module de facturation',
        vat_declaration: 'Déclaration de TVA',
        accounting_support: 'Conseil comptable par tchat',
        starter_offer_included: 'Offre starter incluse',
        balance_sheet: 'Bilan comptable et liasse fiscale',
        dedicated_accountant: 'Comptable dédié',
        phone_video_support: 'Accompagnement par téléphone ou visio',
        essential_offer_included: 'Offre Essentiel incluse',
        priority_balance_sheet: 'Bilan comptable prioritaire',
        multi_user_access: 'Accès multi-users',
        mandatory_legal_obligations: 'Obligations juridiques annuelles',
        tax_audit_support: 'Accompagné en cas de contrôle fiscal',
      },
      plan_description: {
        [AccountingPlanName.STARTER]: 'Le minimum pour lancer votre activité',
        [AccountingPlanName.ESSENTIAL]:
          'Toutes vos obligations avec un comptable dédié',
        [AccountingPlanName.ELITE]:
          'Ne pensez plus à la comptabilité, laissez-nous faire',
      },
      call_interlocutor: {
        advisor: 'conseiller',
        accountant: 'comptable',
      },
    },
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    bank_synchronization: {
      bad_item_sync_message:
        'Une erreur est survenue lors de la synchronisation de vos comptes bancaires.',
      solve_connection_button_label: 'Résoudre',
      error_message: 'Une erreur est survenue.',
      reload_button_label: 'Relancer la page',
      loading_message: 'Votre banque est en cours de synchronisation.',
      message_title: 'Sélectionnez le(s) compte(s) à synchroniser',
      message_subtitle:
        'La sélection de vos comptes professionnels est nécessaire à la synchronisation de votre espace',
      sync_accounts_button_label: 'Connecter mes comptes',
      data_disclaimer_title:
        "Legalplace Pro n'aura accès qu'en lecture aux opérations bancaires",
      data_disclaimer_button_label: 'En savoir plus',
      disclaimer_message_1:
        'Connectez votre compte(s) bancaire(s) professionnel(s) grâce à notre partenaire pour une catégorisation et une comptabilisation automatiques grâce à notre intelligence artificielle.',
      disclaimer_message_2:
        'Votre comptable ne peut pas effectuer de virements. Vos données restent confidentielles sur des serveurs en Union européene.',
      start_sync_button_label: 'Synchroniser votre banque',
      start_qonto_sync_button_label: 'Synchroniser votre compte Qonto',
      skip_to_onboarding_call_button_label: "Passer à l'étape suivante",
    },
    call_interlocutor: {
      advisor: 'conseiller',
      accountant: 'comptable',
    },
  },
};
