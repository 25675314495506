import { userSlice } from './reducer';

export const {
  setLoggedIn,
  setUserInformations,
  startLogoutUser,
  fetchCompanyUsersFailed,
  fetchCompanyUsersSuccess,
  startFetchCompanyUsers,
  updateCompanyUser,
} = userSlice.actions;
