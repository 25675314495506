import type { AxiosResponse } from 'axios';

import type {
  AccountCreationDateDto,
  AccountRibInformationDTO,
  FindAccountDto,
  GenerateStatementDto,
  IbanValidationDto,
} from '@legalplace/bankroot-api/modules/account/dto';
import type { ConsentUrlDto } from '@legalplace/bankroot-api/modules/consent/dto';

import { api } from './api';

export const fetchRibInformation = (
  accountId: string
): Promise<AxiosResponse<AccountRibInformationDTO>> =>
  api.get(`/account/${accountId}/rib`);

export const getAccountsRequest = (
  companyId: string
): Promise<AxiosResponse<FindAccountDto[]>> =>
  api.get(`/account?companyId=${companyId}`);

export const callIbanValidationEndpoint = (
  iban: string
): Promise<AxiosResponse<IbanValidationDto>> =>
  api.get(`/account/iban/validation?iban=${iban}`);

export const getAccountCreationDate = (
  accountId: string
): Promise<AxiosResponse<AccountCreationDateDto>> =>
  api.get(`/account/${accountId}/creation-date`);

export const generateAccountStatement = (
  accountId: string,
  generateStatementDto: GenerateStatementDto
): Promise<AxiosResponse<Blob>> => {
  const { month, year } = generateStatementDto;
  const queryParams = new URLSearchParams({
    month: month.toString(),
    year: year.toString(),
  });
  return api.get(`/account/${accountId}/statement?${queryParams.toString()}`, {
    responseType: 'blob',
  });
};

export const closeAccountRequest = (
  accountId: string
): Promise<AxiosResponse<ConsentUrlDto>> =>
  api.post(`/account/${accountId}/close`);

export const validateIbanForAccountId = (
  accountId: string
): Promise<AxiosResponse<IbanValidationDto>> =>
  api.get(`/account/iban/validate?accountId=${accountId}`);
