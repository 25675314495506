import { call, put, select, takeEvery } from 'typed-redux-saga';

import { getCompanyUserRightsRequest } from '../../../services/api/company.api';
import {
  setActiveCompanyUserRights,
  startFetchActiveCompanyUserRights,
} from '../actions';
import { selectActiveCompanyId } from '../selectors';

function* handleFetchUserRights(): Generator {
  try {
    const activeCompanyId = yield* select(selectActiveCompanyId);
    if (!activeCompanyId) {
      return;
    }
    const {
      data: { rights },
    } = yield* call(getCompanyUserRightsRequest, activeCompanyId);

    yield put(setActiveCompanyUserRights(rights));
  } catch (error: unknown) {
    console.error(
      `[handleFetchUserRights] - Error trying to fetch user company rights`,
      error
    );
    yield put(setActiveCompanyUserRights([]));
  }
}

function* fetchUserRightsSaga(): Generator {
  yield takeEvery(startFetchActiveCompanyUserRights, handleFetchUserRights);
}

export default fetchUserRightsSaga;
