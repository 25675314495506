import { VehicleType } from '@legalplace/shared';

export enum EngineOptions {
  COMBUSTION = 'combustion',
  ELECTRIC = 'electric',
}

export const ENGINE_OPTIONS = [
  {
    id: 'combustion-engine',
    value: EngineOptions.COMBUSTION,
    label: 'Thermique ou hybride',
  },
  {
    id: 'electric-engine',
    value: EngineOptions.ELECTRIC,
    label: 'Électrique',
  },
];

export const VEHICLE_TYPE_OPTIONS = [
  { id: 'car', value: VehicleType.CAR, label: 'Voiture' },
  {
    id: 'motorcycle',
    value: VehicleType.MOTORCYCLE,
    label: 'Deux-roues',
  },
];

export const ENGINE_CAPACITY_OPTIONS = [
  {
    id: 'less-than-50cc',
    value: VehicleType.MOTORCYCLE50CC,
    label: `Inférieure à 50 cm\u00b3`,
  },
  {
    id: 'more-than-50cc',
    value: VehicleType.MOTORCYCLE,
    label: 'Supérieure ou égale à 50 cm\u00b3',
  },
];

export enum AddPersonalVehicleSteps {
  VEHICLE_TYPE = 'vehicle-type',
  VEHICLE_IDENTITY = 'vehicle-identity',
  VEHICLE_INFORMATION_SUMMARY = 'vehicle-information-summary',
}
