import type { GetAcademyDto } from '@legalplace/bankroot-api/modules/academy/dto';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../services/api/api';

export const academyApi = createApi({
  reducerPath: 'academyApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['Academy'],
  endpoints: (build) => ({
    getAcademy: build.query<GetAcademyDto, string>({
      query: (companyId) => ({ url: `/company/${companyId}/academy` }),
      providesTags: ['Academy'],
    }),
    updateAcademyStatusToSkipped: build.mutation<void, string>({
      query: (companyId) => ({
        url: `/company/${companyId}/academy/skip`,
        method: 'POST',
      }),
      async onQueryStarted(companyId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          academyApi.util.updateQueryData('getAcademy', companyId, (draft) => {
            Object.assign(draft, { sections: [] });
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Academy'],
    }),
    updateReadArticle: build.mutation<
      void,
      { companyId: string; articleId: string }
    >({
      query: ({ companyId, articleId }) => ({
        url: `/company/${companyId}/academy/read/${articleId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Academy'],
    }),
  }),
});

export const {
  useGetAcademyQuery,
  useUpdateAcademyStatusToSkippedMutation,
  useUpdateReadArticleMutation,
} = academyApi;
