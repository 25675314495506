export enum ConsentAction {
  Redirect = 'redirect',
}

export interface ConsentMessage {
  action: ConsentAction;
  payload: string;
}

export const WINDOW_LOADED_MESSAGE = 'loaded';
export const WINDOW_CLOSED_MESSAGE = 'closed';
