import { PartnerEntityActions } from '../../../constants/partner.constants';

export const billingClient = {
  page_title: 'Clients',
  new_partner: 'Ajouter un client',
  number_of_partners_zero: 'Aucun client',
  number_of_partners_one: '1 client',
  number_of_partners_other: '{{ count }} clients',
  searchbar_placeholder: 'Nom du client',
  reset_button: 'Réinitialiser',
  billing_client_modal: {
    [`${PartnerEntityActions.ADD}_modal_title`]: 'Ajouter un client',
    [`${PartnerEntityActions.EDIT}_modal_title`]: 'Modifier un client',
    [`${PartnerEntityActions.DELETE}_modal_title`]: 'Supprimer un client',
    cancel_button: 'Annuler',
    show_delete_tab_button: 'Supprimer le client',
    [`${PartnerEntityActions.ADD}_button`]: 'Ajouter',
    [`${PartnerEntityActions.EDIT}_button`]: 'Enregistrer',
    [`${PartnerEntityActions.DELETE}_button`]: 'Supprimer',
    individual_tab_entity_title: 'Particulier',
    business_tab_entity_title: 'Entreprise',
    business_vat_number_form: 'N°TVA Intracommunautaire (optionnel)',
    individual_first_name_form: 'Prénom',
    individual_last_name_form: 'Nom',
    business_company_name_form: 'Raison sociale',
    business_company_name_form_search_placeholder: 'Rechercher une entreprise',
    business_company_name_form_no_result: 'Aucun résultat trouvé.',
    business_siret_form: 'SIREN / SIRET',
    client_email_form: 'E-mail (optionnel)',
    address_1_label: 'Numéro et nom de rue',
    address_2_label: "Complément d'adresse (optionnel)",
    zip_code_label: 'Code postal',
    city_label: 'Ville',
    country_label: 'Pays',
    delete_subtitle: 'Supprimer "{{billingClientName}}" ?',
    delete_warning_message:
      "Cette action est irréversible. Les factures en brouillon à l'attention de ce client seront également supprimées mais cela n'affectera pas ses factures et devis déjà créés.",
    [`${PartnerEntityActions.ADD}_success_message`]: 'Client ajouté !',
    [`${PartnerEntityActions.EDIT}_success_message`]: 'Client modifié !',
    [`${PartnerEntityActions.DELETE}_success_message`]: 'Client supprimé !',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    address_error_message: 'Veuillez remplir le champ manquant.',
  },
};
