import type { AxiosResponse } from 'axios';

import type { CancelPhysicalCardReason } from '@legalplace/bankroot-api/generated/api-graphql';
import type {
  ActivateCardDto,
  BareCardDto,
  CreateCardRequestDto,
  CreateCardResponseDto,
  GetCardDto,
  UpdateCardDto,
  UpdateCardStatusDto,
} from '@legalplace/bankroot-api/modules/card/dto';
import type { ConsentUrlDto } from '@legalplace/bankroot-api/modules/consent/dto/consentUrl.dto';

import { api } from './api';

export const getCardsRequest = (
  accountId: string
): Promise<AxiosResponse<BareCardDto[]>> =>
  api.get(`/card?accountId=${accountId}`);

export const getCardByIdRequest = (
  cardId: string
): Promise<AxiosResponse<GetCardDto>> => api.get(`/card/${cardId}`);

export const addCardRequest = async (
  createCardDto: CreateCardRequestDto
): Promise<CreateCardResponseDto> => {
  const {
    data: { consentUrl, cardId },
  } = await api.post('/card', createCardDto);
  return { consentUrl, cardId };
};

export const updateCardRequest = (
  updateCardDto: UpdateCardDto
): Promise<AxiosResponse<ConsentUrlDto>> =>
  api.put(`/card/${updateCardDto.cardId}`, updateCardDto);

export const updateCardStatusRequest = (
  updateCardStatusDto: UpdateCardStatusDto
): Promise<AxiosResponse<ConsentUrlDto | void>> =>
  api.patch(`/card/${updateCardStatusDto.cardId}`, updateCardStatusDto);

export const activateCardRequest = (
  activeCardDto: ActivateCardDto,
  cardId: string
): Promise<AxiosResponse<ConsentUrlDto>> =>
  api.post(`/card/${cardId}/activate`, activeCardDto);

export const cancelCardRequest = ({
  reason,
  cardId,
}: {
  reason: CancelPhysicalCardReason;
  cardId: string;
}): Promise<void> => api.delete(`/card/${cardId}?reason=${reason}`);

export const getCardPinRequest = (
  cardId: string
): Promise<AxiosResponse<ConsentUrlDto>> => api.get(`/card/${cardId}/pinCode`);

export const confirmConsentRequest = (
  cardId: string
): Promise<AxiosResponse<void>> =>
  api.post(`/card/${cardId}/confirm-consent`, {});
