import { Capacitor } from '@capacitor/core';
import { UserOrigin } from '@legalplace/shared';

export const getUserOrigin = (): UserOrigin => {
  if (!Capacitor.isNativePlatform()) {
    return UserOrigin.WEB;
  }

  return Capacitor.getPlatform() === 'ios'
    ? UserOrigin.IOS
    : UserOrigin.ANDROID;
};
