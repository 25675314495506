export default {
  rib_upload: {
    title: 'Transmettez-nous votre RIB professionnel',
    subtitle:
      'Votre RIB professionnel servira pour la facturation mais aussi pour toutes les démarches administratives de votre entreprise.',
    upload_file: 'Déposer votre RIB',
    allowed_types: 'PDF, PNG, JPEG',
    max_file_size: 'max 5Mo',
    confirm_delete: 'Oui, supprimer',
    cancel_delete: 'Annuler',
    invalid_file:
      'Votre fichier n’a pas pu être téléchargé, vérifiez la taille et le format de celui-ci.',
    confirm_rib: 'Confirmer mon RIB',
    unavailable_in_mobile: {
      title: 'Indisponible sur l’application',
      message:
        'Cette fonctionnalité est pour l’instant uniquement disponible depuis un navigateur. Rendez-vous sur <a href="https://pro.legalplace.fr/{{companyId}}/onboarding-v2">https://pro.legalplace.fr</a> pour poursuivre.',
    },
  },
  rib_selection: {
    no_account: {
      title: 'Synchronisez vos informations de facturation',
      message:
        'Ces informations seront utilisées pour toutes vos déclarations impots.gouv et vous pourrez décider d’utiliser les mêmes informations pour le prélèvement de votre abonnement LegalPlace Pro.',
      button_label: 'Synchroniser mon compte',
    },
    account_selection: {
      title: 'Sélectionnez vos informations de facturation',
      message:
        'Ce compte sera utilisé pour toutes vos déclarations, vous pouvez décider d’avoir le même compte pour le prélèvement de votre abonnement LegalPlace Pro.',
      account_tax_dropdown_label:
        'Sélectionnez le compte à utiliser pour vos démarches impots.gouv',
      account_subscription_dropdown_label:
        'Sélectionnez le compte pour le prélèvement de votre abonnement',
      bic_field_label: 'Complétez le BIC du compte selectionné',
      checkbox_label:
        'Je souhaite utiliser un autre compte pour le prélèvement de mon abonnement',
      other_account_dropdown_label: 'Utilisez un autre compte',
      other_account_iban_field_label: 'Complétez votre IBAN',
      other_account_bic_field_label: 'Complétez votre BIC',
      button_label: 'Valider mon choix',
      synchronize_disclaimer: {
        title: 'Pensez à synchroniser votre compte !',
        message:
          'Tous vos comptes doivent être connectés à LegalPlace Pro pour que votre comptabilité soit exacte. Votre comptable ne peut pas effectuer de virements. Vos données restent confidentielles sur des serveurs en union européenne.',
        know_more: 'En savoir plus',
      },
      error: {
        empty_field: 'Veuillez remplir ce champ',
        iban_length: 'Ce champ doit contenir 27 caractères',
        fr_iban_only: 'Seuls les IBAN français sont acceptés',
        iban_invalid: 'Veuillez saisir un IBAN valide',
        dropdown_fr_iban_only:
          'Veuillez sélectionner ou saisir un IBAN français',
        dropdown_invalid_account: 'Veuillez sélectionner un autre compte',
      },
    },
  },
};
