import { flexCenterAllColumn, styled, Typography } from '@legalplace/storybook';

export const DeliveryAddressRecapContainer = styled.div`
  ${flexCenterAllColumn}
  background-color: ${({ theme }) => theme.backgroundColors.grey25};
  width: 100%;
  heigth: 100%;
  border-radius: ${({ theme }) => theme.borders.radiusSm};
  border: ${({ theme }) =>
    `${theme.borders.widthRegular} ${theme.borders.styleRegular} ${theme.backgroundColors.grey100}`};
  padding: ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.small};
`;

export const DeliveryAddressRecapTypography = styled(Typography).attrs(
  (props) => ({
    ...props,
    tag: 'p',
    fontWeight: '500',
    color: 'primary900',
    textAlign: 'center',
  })
)``;

export const AddressContainer = styled.div``;
