import type { Dispatch } from 'react';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import type { AppDispatch, RootState } from '../store';
import type { CompaniesState } from '../store/companies/types';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): ThunkDispatch<
  {
    companies: CompaniesState;
  },
  undefined,
  AnyAction
> &
  Dispatch<AnyAction> => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
