import { css, flexBaseColumn, styled } from '@legalplace/storybook';

export const disabledNavigationCss = css`
  button,
  a {
    pointer-events: none;
    cursor: not-allowed;
    p,
    svg {
      color: ${({ theme }) => theme.typographyColors.greytainted300};
    }
  }
`;

export const CollapsableNavigationListContainer = styled.div<{
  isNavigationDisabled?: boolean;
}>`
  ${flexBaseColumn};
  width: 100%;
  ${({ isNavigationDisabled }) =>
    isNavigationDisabled && disabledNavigationCss};
`;

export const CollapsableGroupItemsContainer = styled.div`
  ${flexBaseColumn}
  gap: ${({ theme }) => theme.spacing.xxxxxsmall};
`;
