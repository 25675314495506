import type { BillingCatalogItem } from '@legalplace/bankroot-api/entities';
import type {
  CreateBillingCatalogItemsDto,
  GetBillingCatalogItemResponseDto,
  UpdateBillingCatalogItemDto,
} from '@legalplace/bankroot-api/modules/billing/dto';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../../services/api/api';

export const billingCatalogItemsApi = createApi({
  reducerPath: 'billingCatalogItemsApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['BillingCatalogItems'],
  endpoints: (build) => ({
    getBillingCatalogItems: build.query<
      GetBillingCatalogItemResponseDto[],
      string
    >({
      query: (companyId) => ({
        url: `/billing/catalog-item?companyId=${companyId}`,
      }),
      providesTags: ['BillingCatalogItems'],
    }),
    createBillingCatalogItems: build.mutation<
      BillingCatalogItem[],
      CreateBillingCatalogItemsDto
    >({
      query: (createBillingCatalogItemsDto) => ({
        url: `/billing/catalog-item`,
        method: 'POST',
        body: createBillingCatalogItemsDto,
      }),
      invalidatesTags: ['BillingCatalogItems'],
    }),
    updateBillingCatalogItems: build.mutation<
      BillingCatalogItem,
      UpdateBillingCatalogItemDto & { billingCatalogItemId: string }
    >({
      query: ({ billingCatalogItemId, ...updateBillingCatalogItemsDto }) => ({
        url: `/billing/catalog-item/${billingCatalogItemId}`,
        method: 'PATCH',
        body: updateBillingCatalogItemsDto,
      }),
      invalidatesTags: ['BillingCatalogItems'],
    }),
    deleteBillingCatalogItems: build.mutation<void, string>({
      query: (billingCatalogItemId) => ({
        url: `/billing/catalog-item/${billingCatalogItemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BillingCatalogItems'],
    }),
  }),
});

export const {
  useGetBillingCatalogItemsQuery,
  useCreateBillingCatalogItemsMutation,
  useUpdateBillingCatalogItemsMutation,
  useDeleteBillingCatalogItemsMutation,
} = billingCatalogItemsApi;
