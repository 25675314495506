import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { NavigationList } from '@legalplace/storybook';

import { Academy } from '../../components/academy/Academy';
import { Status } from '../../constants';
import { PageVariant } from '../../constants/pageWrapper.constants';
import { useAppSelector } from '../../hooks/store';
import { useCurrentDeviceBreakpoint } from '../../hooks/useCurrentDeviceBreakpoint';
import { buildNavigationItemList } from '../../services/router/navigationitemList';
import {
  selectAccountsAllIds,
  selectAccountStatus,
} from '../../store/accounts/selectors';
import { selectActiveCompany } from '../../store/companies/selectors';
import { useGetFeatureFlagsQuery } from '../../store/featureFlags/reducer';
import { PageWrapperWithSubheader } from '../pageWrapperWithSubheader/PageWrapperWithSubheader';

export function Menu(): JSX.Element {
  const { t } = useTranslation('menu');
  const activeCompany = useAppSelector(selectActiveCompany);
  const { data: featureFlags } = useGetFeatureFlagsQuery(activeCompany?.id, {
    skip: !activeCompany?.id,
  });
  const [isAcademyOpen, setIsAcademyOpen] = useState(false);
  const accountsAllIds = useAppSelector(selectAccountsAllIds);
  const fetchAccountsStatus = useAppSelector(selectAccountStatus);
  const hasNoAccounts =
    fetchAccountsStatus === Status.IDLE && !accountsAllIds?.length;

  const navigate = useNavigate();

  const { isDesktop, isMobile, isMobileOrTablet } =
    useCurrentDeviceBreakpoint();

  useEffect(() => {
    if (isDesktop) {
      navigate('/');
    }
  });

  const navigationItemList = buildNavigationItemList({
    t,
    companyId: activeCompany?.id,
    featureMap: activeCompany?.featureMap,
    isDesktop: !isMobileOrTablet,
    hasNoAccounts,
    shouldShowBankUpsell: featureFlags?.DISPLAY_BANKING_UPSELL_IN_MENU_FLAG,
    shouldShowAccountingUpsell:
      featureFlags?.DISPLAY_ACCOUNTING_UPSELL_IN_MENU_FLAG,
    shouldShowMyAccountantPage:
      featureFlags?.DISPLAY_MY_ACCOUNTANT_PAGE &&
      activeCompany?.hasSupportProfile,
    shouldShowImmobilisationsPage: activeCompany?.hasImmobilisations,
  });

  const itemList = navigationItemList.slice(isMobile ? 3 : 5).map((item) => {
    if (item.uri) {
      return { ...item, uri: `${item.uri}?from=menu` };
    }
    return item;
  });

  return (
    <PageWrapperWithSubheader
      pageTitle={activeCompany?.name || t('page_title')}
      pageVariant={PageVariant.NAVBAR}
      gap={{ default: '1.6rem' }}
    >
      <Academy isOpen={isAcademyOpen} setIsOpen={setIsAcademyOpen} />
      <NavigationList itemList={itemList} linkComponent={Link} />
    </PageWrapperWithSubheader>
  );
}

export default Menu;
