import { useTranslation } from 'react-i18next';

import { PERIODICITY_TO_MONTHLY_FACTOR } from '@legalplace/bankroot-api/constants';
import { SubscriptionPeriodicity } from '@legalplace/shared';
import {
  FlexAlignCenterJustifyCenter,
  Typography,
} from '@legalplace/storybook';

import { useAppSelector } from '../../../../../../hooks/store';
import { selectAccountingOnboardingInformation } from '../../../../../../store/onboardingSteps/selector';

import { BaselineAlignContainer } from './PricingLabel.style';

export function PricingLabel({
  subscriptionPeriodicity,
  price,
}: {
  subscriptionPeriodicity: SubscriptionPeriodicity;
  price: number;
}): JSX.Element {
  const accountingInformation = useAppSelector(
    selectAccountingOnboardingInformation
  );
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'onboarding_call.pricing_table',
  });

  const { plan, monthlyPrice } = accountingInformation || {};

  const isBilledAnnually =
    subscriptionPeriodicity === SubscriptionPeriodicity.YEARLY;

  const isUpdatingPlan = !!plan && !!monthlyPrice;

  if (subscriptionPeriodicity === SubscriptionPeriodicity.BIYEARLY) {
    throw new Error('Biyearly subscription is not supported');
  }

  const monthlyDisplayedPrice = Math.ceil(
    price / 100 / PERIODICITY_TO_MONTHLY_FACTOR[subscriptionPeriodicity]
  );

  return (
    <>
      <FlexAlignCenterJustifyCenter gap={{ default: '0.4rem' }}>
        <BaselineAlignContainer>
          {!isUpdatingPlan && (
            <Typography tag="p" color="primary900" fontWeight="500">
              {t('from')}
            </Typography>
          )}
          <Typography tag="h5" color="primary500" fontWeight="700">
            {monthlyDisplayedPrice}
          </Typography>
        </BaselineAlignContainer>
        <Typography tag="h6" color="primary900" fontWeight="500">
          <sup>{t('currency')}</sup>
        </Typography>
        <Typography tag="h6" color="primary900">
          <sup>{t('excluding_taxes')}</sup>
        </Typography>
      </FlexAlignCenterJustifyCenter>
      {isBilledAnnually && (
        <Typography
          tag="p4"
          color="grey700"
          spacing={{ default: '0.4rem auto' }}
        >
          {t('billed_annually')}
        </Typography>
      )}
    </>
  );
}
