import { StreamLineIconList } from '@legalplace/storybook';

import { useAppSelector } from '../../../hooks/store';
import { useUpdateReadArticleMutation } from '../../../store/academy/reducer';
import { selectActiveCompany } from '../../../store/companies/selectors';
import { Checkbox } from '../../common/forms/Checkbox/Checkbox';

import {
  AcademyCheckboxButton,
  AcademyCheckboxLabel,
  AnimatedRightArrow,
} from './AcademyCheckbox.style';

interface IAcademyCheckboxProps {
  label?: string;
  href?: string;
  isChecked: boolean;
  articleId: string;
  openNextSection: (articleId: string) => void;
}

export function AcademyCheckbox({
  label,
  href,
  isChecked,
  articleId,
  openNextSection,
}: IAcademyCheckboxProps): JSX.Element {
  const activeCompany = useAppSelector(selectActiveCompany);
  const [updateReadArticle] = useUpdateReadArticleMutation();

  const handleOnClick = async (): Promise<void> => {
    window.open(href, '_blank');
    if (isChecked) {
      return;
    }
    try {
      await updateReadArticle({
        companyId: activeCompany?.id || '',
        articleId,
      });
      openNextSection(articleId);
    } catch (error: unknown) {
      console.error(
        `[AcademyCheckbox] - Error while updating article to read for company ${activeCompany?.id}`,
        error
      );
    }
  };

  return (
    <AcademyCheckboxButton onClick={handleOnClick} href={href}>
      <Checkbox checked={isChecked} />
      <AcademyCheckboxLabel tag="p3" fontWeight="400" textAlign="left">
        {label}
      </AcademyCheckboxLabel>
      <AnimatedRightArrow
        icon={StreamLineIconList.arrowright1}
        height="2rem"
        width="2rem"
        spacing={{ default: '0 1rem' }}
        fillColor="primary500"
      />
    </AcademyCheckboxButton>
  );
}
