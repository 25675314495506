import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionFeatureStatus } from '@legalplace/shared';
import {
  Button,
  FlexContainerBasicColumn,
  Progress,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { shouldForceOnboardingCompletion } from '../../hooks/onboardingv2/useShouldForceOnboardingCompletion';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { selectActiveCompany } from '../../store/companies/selectors';
import { setIsOnboardingDrawerOpen } from '../../store/onboardingSteps/actions';
import { selectOnboardingSteps } from '../../store/onboardingSteps/selector';
import { OnboardingStepContent } from '../onboardingV2Steps/OnboardingStepContent';
import { OnboardingV2Drawer } from '../onboardingV2Steps/OnboardingV2Drawer';

import { AccountingTrialEndModal } from './AccountingTrialEndModal';

import { UnderlinedTypography } from '../UI/UnderlinedTypography.style';
import {
  FinalizeRegistrationButton,
  MenuOnboardingStepperContainer,
  MobileButtonContentContainer,
  SubMenuOnboardingStepperContainer,
} from './MenuOnboardingStepper.style';

export function MobileMenuOnboardingStepper(): JSX.Element | null {
  const { t } = useTranslation('onboarding', {
    keyPrefix: 'onboarding_v2',
  });
  const dispatch = useAppDispatch();

  const {
    progressPercentage,
    currentStep,
    stepsLeftCount,
    accountingOnboardingInformation,
  } = useAppSelector(selectOnboardingSteps);

  const activeCompany = useAppSelector(selectActiveCompany);

  const [isAccountingTrialEndModalOpen, setIsAccountingTrialEndModalOpen] =
    useState(shouldForceOnboardingCompletion(activeCompany));

  const [isModalOpen, setIsModalOpen] = useState(false);

  const shouldDisplayNotInterestedButton =
    activeCompany?.featureMap?.ComptaBase?.status ===
      SubscriptionFeatureStatus.TrialExpired &&
    !accountingOnboardingInformation?.isContractSigned &&
    activeCompany?.featureMap?.BankBase?.isActive;

  useEffect(() => {
    if (stepsLeftCount === 0) {
      setIsAccountingTrialEndModalOpen(false);
      dispatch(setIsOnboardingDrawerOpen(false));
    }
  }, [dispatch, stepsLeftCount]);

  if (!progressPercentage || !currentStep || !activeCompany) {
    return null;
  }

  const closeDrawer = (): void => {
    setIsAccountingTrialEndModalOpen(false);
    dispatch(setIsOnboardingDrawerOpen(false));
  };

  return (
    <>
      <Progress
        value={progressPercentage}
        color="pink200"
        progressBarHeight={{ default: '0.4rem' }}
        backgroundColor="white"
        borderRadius="2rem"
      />
      <MenuOnboardingStepperContainer>
        <SubMenuOnboardingStepperContainer>
          <FinalizeRegistrationButton
            onClick={() => {
              setIsAccountingTrialEndModalOpen(true);
            }}
            width={{ default: '100%' }}
          >
            <MobileButtonContentContainer>
              <FlexContainerBasicColumn gap={{ default: '0.8rem' }}>
                <FlexContainerBasicColumn>
                  <Typography tag="p4" fontWeight="600" textAlign="left">
                    {t('onboarding_stepper.finalize_registration_button_label')}
                  </Typography>
                  <Typography tag="p4" textAlign="left">
                    {t('onboarding_stepper.nb_steps_left')}
                  </Typography>
                </FlexContainerBasicColumn>
                {shouldDisplayNotInterestedButton && (
                  <Button
                    styleType="tertiary"
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsModalOpen(true);
                    }}
                  >
                    <UnderlinedTypography tag="p4" color="white">
                      {t('onboarding_stepper.not_interested_button_label')}
                    </UnderlinedTypography>
                  </Button>
                )}
              </FlexContainerBasicColumn>
              <StreamLineIcon icon={StreamLineIconList.arrowdown1} />
            </MobileButtonContentContainer>
          </FinalizeRegistrationButton>
        </SubMenuOnboardingStepperContainer>
      </MenuOnboardingStepperContainer>
      <OnboardingV2Drawer
        isOpen={isAccountingTrialEndModalOpen}
        onClose={closeDrawer}
      >
        <OnboardingStepContent />
      </OnboardingV2Drawer>
      <AccountingTrialEndModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        activeCompanyId={activeCompany?.id}
      />
    </>
  );
}
