import {
  Button,
  flexBaseColumn,
  flexFullAlignCenterSpaceBetween,
  styled,
} from '@legalplace/storybook';

export const MenuOnboardingStepperContainer = styled.div<{
  hasBorderRadius?: boolean;
}>`
  ${flexBaseColumn};
  background: ${({ theme }) => theme.backgroundColors.primary900};
  ${({ hasBorderRadius, theme }) =>
    hasBorderRadius && `border-radius: ${theme.borders.radiusMd}`};
  color: ${({ theme }) => theme.typographyColors.white};
`;

export const SubMenuOnboardingStepperContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.xsmall}`};
  gap: ${({ theme }) => theme.spacing.xxxxsmall};
  ${flexBaseColumn};
`;

export const FinalizeRegistrationButton = styled(Button)`
  justify-content: space-between;
`;

export const MobileButtonContentContainer = styled.div`
  ${flexFullAlignCenterSpaceBetween}
`;
