import type { FormEvent } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EMAIL_REGEX,
  FlexContainerBasicAlignCenter,
  FlexContainerBasicColumn,
  ShowDesktopOnly,
  ShowMobileTabletOnly,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { useAppSelector } from '../../../../hooks/store';
import { updateTaxesCredentialsRequest } from '../../../../services/api/onboardingFlow.api';
import { showToastError } from '../../../../services/notifications/toast';
import { selectActiveCompany } from '../../../../store/companies/selectors';
import { InputWrapper } from '../../../common/forms/inputWrapper/InputWrapper';
import { OnboardingMobileFooter } from '../../../CompanyAccountingOnboardingSteps/steps/OnboardingMobileFooter';

import { InputContainer } from '../../../common/forms/inputWrapper/InputWrapper.style';
import { PrimaryButton } from '../../../UI/PrimaryButton.style';
import { OnboardingStepBodyContainer } from '../../OnboardingV2Steps.style';
import {
  OnboardingForm,
  StyledPasswordField,
  SubmitButtonContainer,
} from '../CredentialsCreation/CredentialsCreation.style';
import { StyledEmailField } from './TaxesCredentials.style';

export function TaxesCredentials({
  notifySuccess,
}: {
  notifySuccess: () => void;
}): JSX.Element {
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'taxes_credentials',
  });

  const activeCompany = useAppSelector(selectActiveCompany);
  const { VITE_APP_URL } = import.meta.env;

  const [errorMessage, setErrorMessage] = useState<{
    email?: string;
    password?: string;
  }>({});

  const [passwordValue, setPasswordValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const updateTaxesCredentials = useCallback(
    async (event?: FormEvent<HTMLFormElement>): Promise<void> => {
      event?.preventDefault();
      const newErrors: Record<string, string> = {};
      try {
        setIsLoading(true);

        if (!EMAIL_REGEX.test(emailValue)) {
          newErrors.email = t('email_error');
        }

        setErrorMessage(newErrors);
        if (Object.values(newErrors).length) {
          return;
        }

        if (!activeCompany?.id) {
          return;
        }

        await updateTaxesCredentialsRequest(activeCompany?.id, {
          username: emailValue,
          password: passwordValue,
        });

        notifySuccess();
      } catch (error: unknown) {
        console.error(
          `[taxesCredentials] - Error trying to create taxes credentials`,
          error
        );
        showToastError(t('unexpected_error'));
      } finally {
        setIsLoading(false);
      }
    },
    [
      passwordValue,
      emailValue,
      activeCompany,
      t,
      setIsLoading,
      setErrorMessage,
      notifySuccess,
    ]
  );

  return (
    <OnboardingForm onSubmit={updateTaxesCredentials}>
      <OnboardingStepBodyContainer internalspacing={{ default: '2.4rem' }}>
        <FlexContainerBasicColumn gap={{ default: '0.8rem' }}>
          <Typography
            tag="p"
            fontWeight="600"
            color="primary900"
            textAlign="left"
          >
            {t('title')}
          </Typography>
          <Typography tag="p3" color="primary900" textAlign="left">
            {t('subtitle')}
          </Typography>
          <Typography tag="p3" color="primary500" fontWeight="500">
            <a
              href={`${VITE_APP_URL}/Création_de_votre_compte_impots.gouv.pdf`}
              download
              target="_blank"
              rel="noreferrer"
            >
              {t('download_guide_label')}
            </a>
          </Typography>
        </FlexContainerBasicColumn>
        <FlexContainerBasicColumn>
          <InputWrapper
            name="emailField"
            label={t('email_field')}
            hasError={!!errorMessage.email}
            errorMessage={errorMessage.email}
          >
            <InputContainer
              hasError={!!errorMessage.email}
              placeholderColor="grey300"
            >
              <StyledEmailField
                name="email"
                type="email"
                placeholder={t('email_placeholder')}
                onChange={(e) => setEmailValue(e.target.value)}
              />
            </InputContainer>
          </InputWrapper>
          <InputWrapper name="passwordField" label={t('password_field')}>
            <StyledPasswordField
              name="newPasswordConfirmation"
              placeholder="**********"
              onChange={(e) => setPasswordValue(e.target.value)}
              placeholderColor="grey300"
              color="grey700"
              hasError={false}
            />
          </InputWrapper>
          <FlexContainerBasicAlignCenter gap={{ default: '0.8rem' }}>
            <StreamLineIcon
              icon={StreamLineIconList.lock}
              fillColor="grey400"
            />
            <Typography
              tag="p5"
              fontWeight="500"
              color="greytainted400"
              textAlign="left"
            >
              {t('encrypted_informations')}
            </Typography>
          </FlexContainerBasicAlignCenter>
        </FlexContainerBasicColumn>
        <ShowDesktopOnly>
          <SubmitButtonContainer>
            <PrimaryButton
              width={{ default: 'fit-content' }}
              isLoading={isLoading}
              type="submit"
            >
              {t('validate_credentials')}
            </PrimaryButton>
          </SubmitButtonContainer>
        </ShowDesktopOnly>
      </OnboardingStepBodyContainer>
      <ShowMobileTabletOnly width={{ default: '100%' }}>
        <OnboardingMobileFooter
          onClick={updateTaxesCredentials}
          primaryButtonLabel={t('validate_credentials')}
          isLoading={isLoading}
        />
      </ShowMobileTabletOnly>
    </OnboardingForm>
  );
}
