export const MAX_ACCOUNTING_OVERLAY_WIDTH = '52rem';

export const CATEGORY_MODAL_MAX_WIDTH = '118rem';
export const CATEGORY_MODAL_LEFT_SIDEBAR_MIN_WIDTH = `calc(${CATEGORY_MODAL_MAX_WIDTH} / 4)`;
export const CATEGORY_MODAL_LEFT_SIDEBAR_MAX_WIDTH = `calc(${CATEGORY_MODAL_MAX_WIDTH} * 0.3)`;

export const BOTTOM_MENU_HEIGHT = '6.7rem';

export const AUTO_COMPLETE_DROPDOWN_MAX_HEIGHT = '20rem';

export const ONBOARDING_ASSOCIATE_MODAL_HEIGHT = '37rem';
export const ONBOARDING_PAPPERS_OPTIONS_HEIGHT = '16rem';

export const ARTICLE_CARD_HEIGHT = '12rem';
