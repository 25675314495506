import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { Capacitor } from '@capacitor/core';
import { bankTheme, GlobalStyles, ThemeProvider } from '@legalplace/storybook';

import { ampli } from './ampli/api';
import { AppUrlListener } from './components/AppUrlListener/AppUrlListener';
import { SelectSynchronizedAccountsModal } from './components/selectSynchronizedAccountsModal/SelectSynchronizedAccountsModal';
import { useAppDispatch, useAppSelector } from './hooks/store';
import { useReloadContext } from './hooks/useReloadContext';
import { getUserInformations } from './services/api/auth.api';
import { initApp } from './services/capacitor/appSetup';
import { startFetchCompanies } from './store/companies/actions';
import {
  selectActiveCompany,
  selectActiveCompanyId,
} from './store/companies/selectors';
import { setLoggedIn, setUserInformations } from './store/user/actions';
import {
  selectLoggedInState,
  selectUserInformations,
} from './store/user/selector';
import { ReloadContext } from './app.context';
import { Status } from './constants';
import { Router } from './router';

import '@legalplace/storybook/assets/inter.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/ReactToastify.css';
import '@legalplace/storybook/assets/dateRangeFilterCustomStyle/styles.css';

export function App(): JSX.Element {
  const dispatch = useAppDispatch();

  const userLoggedInState = useAppSelector(selectLoggedInState);
  const activeCompanyId = useAppSelector(selectActiveCompanyId);
  const activeCompany = useAppSelector(selectActiveCompany);
  const userStoreInformations = useAppSelector(selectUserInformations);
  const reloadContext = useReloadContext();

  useEffect(() => {
    if (userLoggedInState === Status.SUCCESS) {
      dispatch(startFetchCompanies());
    }
  }, [dispatch, userLoggedInState]);

  useEffect(() => {
    const getUserInfo = async (): Promise<void> => {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: import.meta.env.VITE_APP_INTERCOM_ID,
        custom_launcher_selector: 'button.btn-toggle-help',
      });
      try {
        const { data: userInformation } = await getUserInformations();
        await ampli.identify(userInformation.userId).promise;
        dispatch(setLoggedIn());
        dispatch(setUserInformations({ ...userInformation }));
      } catch (error: unknown) {
        if (Capacitor.isNativePlatform()) {
          await initApp();
        }
        console.error(
          `[getUserInfo] - Error trying to get user info, user may not be logged in yet`,
          error
        );
      }
    };
    getUserInfo();
  }, [dispatch]);

  useEffect(() => {
    const { userId, firstName, lastName, email, phoneNumber } =
      userStoreInformations;
    window.dataLayer?.push({
      event: 'userData',
      user_id: userId,
      user_firstname: firstName,
      user_lastname: lastName,
      user_email: email,
      user_phonenumber: phoneNumber,
      company_id: activeCompanyId,
      company_name: activeCompany?.name,
    });

    if (!userId) return;

    window.Intercom?.('update', {
      user_id: userId,
      name: `${firstName} ${lastName}`,
      email,
      phone: phoneNumber,
      'Company ID': activeCompanyId,
      'Company Name': activeCompany?.name,
    });
  }, [activeCompanyId, activeCompany?.name, userStoreInformations]);

  return (
    <ThemeProvider theme={bankTheme}>
      <GlobalStyles />
      <ReloadContext.Provider value={reloadContext}>
        <Router />
        {Capacitor.isNativePlatform() && <AppUrlListener />}
        <ToastContainer
          theme="colored"
          position="top-right"
          hideProgressBar
          icon={false}
          closeButton={false}
        />
        <SelectSynchronizedAccountsModal />
      </ReloadContext.Provider>
    </ThemeProvider>
  );
}
