import { Fragment, useState } from 'react';

import { DEFAULT_GROUP_ID, Separator } from '@legalplace/storybook';

import type { INavigationList } from '../../../interfaces';
import { CollapsableSection } from '../../CollapsableSection/CollapsableSection';

import { CollapsableNavigationListItem } from './CollapsableNavigationListItem/CollapsableNavigationListItem';
import { groupItemsByGroup } from './CollapsableNavigationList.helper';

import {
  CollapsableGroupItemsContainer,
  CollapsableNavigationListContainer,
} from './CollapsableNavigationList.style';

export function CollapsableNavigationList({
  itemList,
  activeItemId = '',
  linkComponent,
  isNavigationDisabled,
}: INavigationList): JSX.Element {
  const grouppedItems = groupItemsByGroup(itemList);
  const activeGroupId = itemList.find(
    (item) => item.id === activeItemId
  )?.groupId;

  const [triggerCollapseSectionsSymbol, setTriggerCollapseSectionsSymbol] =
    useState<Record<string, symbol>>();

  const collapseOtherSections = (groupIdToOpen?: string): void => {
    const newSectionsSymbol = grouppedItems.reduce((acc, { groupId }) => {
      if (groupId === groupIdToOpen) {
        return acc;
      }
      return {
        ...acc,
        [groupId]: Symbol(groupId),
      };
    }, {});
    setTriggerCollapseSectionsSymbol(newSectionsSymbol);
  };

  return (
    <CollapsableNavigationListContainer
      isNavigationDisabled={isNavigationDisabled}
    >
      {grouppedItems.map(
        ({ groupId, groupLabel, groupIcon, items, groupShowSeparator }) =>
          grouppedItems.length === 1 || groupId.includes(DEFAULT_GROUP_ID) ? (
            items.map((item) => (
              <Fragment key={item.id}>
                <CollapsableNavigationListItem
                  item={item}
                  selectedItemId={activeItemId}
                  isGrouped={false}
                  linkComponent={linkComponent}
                  isNavigationDisabled={isNavigationDisabled}
                />
                {!!item.showSeparator && (
                  <Separator
                    backgroundColor="grey125"
                    spacing={{ default: '0.8rem 0' }}
                  />
                )}
              </Fragment>
            ))
          ) : (
            <Fragment key={groupId}>
              <CollapsableSection
                title={groupLabel || ''}
                icon={groupIcon}
                collapseOtherSections={collapseOtherSections}
                triggerCloseSymbol={triggerCollapseSectionsSymbol?.[groupId]}
                groupId={groupId}
                defaultCollapsedState={groupId !== activeGroupId}
                isActive={activeGroupId === groupId}
              >
                <CollapsableGroupItemsContainer>
                  {items.map((item) => (
                    <CollapsableNavigationListItem
                      item={item}
                      selectedItemId={activeItemId}
                      isGrouped
                      linkComponent={linkComponent}
                      key={item.id}
                      isNavigationDisabled={isNavigationDisabled}
                    />
                  ))}
                </CollapsableGroupItemsContainer>
              </CollapsableSection>
              {!!groupShowSeparator && (
                <Separator
                  backgroundColor="grey125"
                  spacing={{ default: '0.8rem 0' }}
                />
              )}
            </Fragment>
          )
      )}
    </CollapsableNavigationListContainer>
  );
}
