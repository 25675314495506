import { CompanySetupStatus, OnboardingStep } from '@legalplace/shared';

export const ONBOARDING_COMPANY_SETUP_STATUSES = [
  CompanySetupStatus.START,
  CompanySetupStatus.ORDER_CARD,
  CompanySetupStatus.BANK_INFORMATION,
  CompanySetupStatus.ACCOUNTING_TRIAL_OFFER,
  CompanySetupStatus.WELCOMED,
  CompanySetupStatus.CALENDLY_BANK,
  CompanySetupStatus.BANK_SYNCHRONIZATION,
  CompanySetupStatus.CALENDLY_COMPTA,
  CompanySetupStatus.CALENDLY_NO_SHOW,
  CompanySetupStatus.START_COMPTA,
];

export const NO_ONBOARDING_NEEDED_COMPANY_SETUP_STATUSES = [
  CompanySetupStatus.COMPLETE,
  CompanySetupStatus.WAITING_FOR_NOTARY_TRANSFER,
  CompanySetupStatus.NONBOARDING,
];

export const ONBOARDING_STEPS_COEFFICIENTS: Record<OnboardingStep, number> = {
  [OnboardingStep.CompanyInformation]: 3,
  [OnboardingStep.BankAccountsSynchronization]: 2,
  [OnboardingStep.ContractSignature]: 1,
  [OnboardingStep.CredentialsCreation]: 1,
  [OnboardingStep.OnboardingCall]: 2,
  [OnboardingStep.TaxesCredentials]: 1,
  [OnboardingStep.CardDelivery]: 2,
};

export const V2_ONBOARDINGS_STATUSES = new Set<CompanySetupStatus>([
  CompanySetupStatus.START_COMPTA_V2,
]);

export const QONTO_STANDALONE_ONBOARDING_SOURCE_QUERY_PARAM = 'qonto';
