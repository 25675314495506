import type { ElementType } from 'react';
import { Fragment, useEffect, useState } from 'react';

import type {
  IHeaderAppLogo,
  ILeftMenuButton,
  INavigationListItem,
  INavUserMenuV2,
  StreamLineIconList,
} from '@legalplace/storybook';
import {
  ImageButton,
  NavUserMenuV2,
  Separator,
  StreamLineIcon,
  Typography,
  useComponentVisibility,
} from '@legalplace/storybook';

import { shouldForceOnboardingCompletion } from '../../hooks/onboardingv2/useShouldForceOnboardingCompletion';
import { useAppSelector } from '../../hooks/store';
import { selectActiveCompany } from '../../store/companies/selectors';
import { Academy } from '../academy/Academy';

import { CollapsableNavigationList } from './CollapsableNavigationList/CollapsableNavigationList';
import { MenuOnboardingStepper } from './MenuOnboardingStepper';

import {
  ButtonsGroupContainer,
  FooterActionContainer,
  FooterContainer,
  LeftMenuButton,
  LeftMenuContainer,
  MainContainer,
  NavigationListButton,
  NavigationListContainer,
  VerticalDivider,
} from './LeftMenu.style';

export interface ILeftMenu {
  headerData: IHeaderAppLogo;
  items: INavigationListItem[];
  activeItemId?: string;
  buttons: ILeftMenuButton[];
  navUserMenuData: INavUserMenuV2;
  linkComponent: ElementType;
  footerActionLabel?: string;
  footerActionIcon?: StreamLineIconList;
  onFooterActionClick?: () => void;
}

export function LeftMenu({
  headerData,
  items,
  activeItemId,
  buttons,
  navUserMenuData,
  linkComponent,
  footerActionLabel,
  footerActionIcon,
  onFooterActionClick,
}: ILeftMenu): JSX.Element {
  const {
    ref,
    isComponentVisible: isNavMenuOpen,
    setIsComponentVisible: setIsNavMenuOpen,
  } = useComponentVisibility(false);

  const activeCompany = useAppSelector(selectActiveCompany);
  const [isAcademyOpen, setIsAcademyOpen] = useState(false);
  useEffect(() => {
    setIsAcademyOpen(false);
  }, [activeCompany, activeItemId]);

  return (
    <LeftMenuContainer>
      <MainContainer>
        <ImageButton
          image={headerData.image}
          linkComponent={linkComponent}
          uri={headerData.redirectUri}
          imgWidth="15.5rem"
          imgHeight="2.4rem"
          buttonHeight="8rem"
          padding="0 1.6rem"
        />
        <NavigationListContainer>
          <Academy isOpen={isAcademyOpen} setIsOpen={setIsAcademyOpen} />
          <CollapsableNavigationList
            itemList={items}
            activeItemId={activeItemId}
            linkComponent={linkComponent}
            isNavigationDisabled={shouldForceOnboardingCompletion(
              activeCompany
            )}
          />
        </NavigationListContainer>
      </MainContainer>
      <FooterContainer>
        <MenuOnboardingStepper />
        {onFooterActionClick && footerActionLabel && footerActionIcon && (
          <NavigationListButton
            onClick={onFooterActionClick}
            $isSelected={false}
            height={{ default: '4.8rem' }}
          >
            <FooterActionContainer>
              <StreamLineIcon
                icon={footerActionIcon}
                fillColor="primary900"
                width="2rem"
                height="2rem"
              />
              <Typography
                tag="p3"
                color="primary900"
                lineHeight={{ default: '2.4rem' }}
                fontWeight="400"
              >
                {footerActionLabel}
              </Typography>
            </FooterActionContainer>
          </NavigationListButton>
        )}
        <Separator
          spacing={{ default: '0.8rem 0' }}
          backgroundColor="grey125"
        />
        <ButtonsGroupContainer>
          {buttons.map((button, index: number) => (
            <Fragment key={`fragment_${button.id}`}>
              {index > 0 && <VerticalDivider />}
              <LeftMenuButton
                styleType="tertiary"
                onClick={button.onClickHandler}
                key={button.id}
                className={button.id === 'help' ? 'btn-toggle-help' : undefined}
                dataTestId={button.id}
              >
                <StreamLineIcon
                  icon={button.icon}
                  fillColor="primary900"
                  height="2rem"
                  width="2rem"
                />
              </LeftMenuButton>
            </Fragment>
          ))}
        </ButtonsGroupContainer>
        <Separator spacing={{ default: '0.8rem 0' }} />
        <NavUserMenuV2
          buttonText={navUserMenuData.buttonText}
          menuItemList={navUserMenuData.menuItemList}
          selectMenuItemHandler={navUserMenuData.selectMenuItemHandler}
          userFullName={navUserMenuData.userFullName}
          logoutHandler={navUserMenuData.logoutHandler}
          helpHandler={navUserMenuData.helpHandler}
          settingsHandler={navUserMenuData.settingsHandler}
          t={navUserMenuData.t}
          shouldDisplayDropdownAboveRight
          buttonTextMaxWidth="15.7rem"
          isNavMenuOpen={isNavMenuOpen}
          setIsNavMenuOpen={setIsNavMenuOpen}
          ref={ref}
        />
      </FooterContainer>
    </LeftMenuContainer>
  );
}
