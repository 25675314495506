import { BillingItemType } from '@legalplace/shared';

import { PartnerEntityActions } from '../../../constants/partner.constants';

export const billingItem = {
  page_title: 'Prestations',
  new_partner: 'Ajouter une prestation',
  number_of_partners_zero: 'Aucune prestation',
  number_of_partners_one: '1 prestation',
  number_of_partners_other: '{{ count }} prestations',
  searchbar_placeholder: 'Nom de la prestation',
  reset_button: 'Réinitialiser',
  subtext_tax_included: '{{unitPrice}} € TTC | TVA {{ vat }}%',
  subtext_tax_excluded: '{{unitPrice}} € HT | TVA {{ vat }}%',
  delete_title: 'Supprimer la prestation',
  delete_subtitle: 'Supprimer "{{catalogItemName}}" ?',
  delete_warning_message: 'Cette suppression est définitive',
  delete_button: 'Supprimer',
  cancel_button: 'Annuler',
  details_item_modal: {
    lines: 'Prestations',
    label: 'Nom de la prestation',
    label_placeholder: 'Outillage, 1 jour',
    missing_value_error_message: 'Veuillez remplir le champ manquant.',
    item_type_dropdown_label: 'Nature de la prestation',
    item_type_dropdown_placeholder: 'Services',
    [`${BillingItemType.Good}_label`]: 'Marchandises',
    [`${BillingItemType.Service}_label`]: 'Services',
    count_in_centiles: 'Quantité',
    tax_free_unit_price: 'Prix unitaire (€ HT)',
    tax_included_unit_price: 'Prix unitaire (€ TTC)',
    switch_to_tax_free_label: 'passer en HT',
    switch_to_tax_included_label: 'passer en TTC',
    save_amount: 'Valider',
    vat_label: 'TVA',
    vat_dropdown_placeholder: 'TVA',
    save_catalog_item_toggle_label: 'Enregistrer comme prestation récurrente',
    description: 'Description',
    save: 'Valider',
    save_text: 'Valider',
    missing_field_error_message: 'Veuillez remplir les champs manquants',
    dropdown_placeholder: 'Sélectionner une prestation existante',
    dropdown_search_placeholder: 'Rechercher une prestation',
    dropdown_empty_search_title: 'Aucun résultat',
    subtext_tax_included: '{{unitPrice}} € TTC | TVA {{ vat }}%',
    subtext_tax_excluded: '{{unitPrice}} € HT | TVA {{ vat }}%',
    missing_item_error_message:
      'Veuillez sélectionner ou ajouter une prestation',
  },
  billing_item_entity_modal: {
    [`${PartnerEntityActions.ADD}_modal_title`]: 'Ajouter une prestation',
    [`${PartnerEntityActions.EDIT}_modal_title`]: 'Modifier une prestation',
    [`${PartnerEntityActions.ADD}_button`]: 'Ajouter',
    [`${PartnerEntityActions.EDIT}_button`]: 'Enregistrer',
    cancel_button: 'Annuler',
    [`${PartnerEntityActions.ADD}_success_message`]: 'Prestation ajoutée !',
    [`${PartnerEntityActions.EDIT}_success_message`]: 'Prestation modifiée !',
  },
};
