import type { AxiosResponse } from 'axios';

import type {
  CreateAuthConfirmationResponseDto,
  GenerateTokensRequestDto,
  GetAccountingLoginUrlRequestDto,
  GetAccountingLoginUrlResponseDto,
  LoginResponseDto,
  UserInformationDto,
} from '@legalplace/bankroot-api/modules/auth/dto';
import type { InitPasswordDto } from '@legalplace/bankroot-api/modules/onboardingAuth/dto/initPassword.dto';

import { api } from './api';

export const getUserInformations = (): Promise<
  AxiosResponse<UserInformationDto>
> => api.get(`/auth/informations`);

export const loginUserV2 = (
  email: string,
  password: string,
  deviceId?: string
): Promise<
  AxiosResponse<LoginResponseDto | CreateAuthConfirmationResponseDto>
> =>
  api.post(`/auth/v2/login`, {
    username: email,
    password,
    deviceId,
  });

export const generateTokensForRequest = (
  generateTokensRequestDto: GenerateTokensRequestDto
): Promise<AxiosResponse<LoginResponseDto>> =>
  api.post(`/auth/v2/login/confirm`, generateTokensRequestDto);

export const postLogoutUser = async (
  deviceId?: string
): Promise<AxiosResponse<void>> =>
  api.post(`/auth/logout${deviceId ? `?deviceId=${encodeURI(deviceId)}` : ''}`);

export const resetPasswordRequest = async (
  resetToken: string,
  newPassword: string
): Promise<AxiosResponse<void>> =>
  api.post(`/auth/reset`, { newPassword, resetToken });

export const forgotPassword = async (
  email: string
): Promise<AxiosResponse<void>> => api.post(`/auth/forgot`, { email });

export const createSwanAuthUser = async (
  code: string,
  onboardingId?: string,
  userId?: string
): Promise<AxiosResponse<void>> =>
  api.patch(`/auth/swan`, { onboardingId, userId, code });

export const checkResetTokenValidityRequest = async (
  resetToken: string
): Promise<AxiosResponse<void>> =>
  api.get(
    `/auth/reset-token/active?resetToken=${encodeURIComponent(resetToken)}`
  );

export const getAccountingLoginUrl = async (
  getAccountingLoginUrlRequestDto: GetAccountingLoginUrlRequestDto
): Promise<AxiosResponse<GetAccountingLoginUrlResponseDto>> =>
  api.post('/auth/accounting-login-url', getAccountingLoginUrlRequestDto);

export const firstTimeLoginRequest = async (
  resetToken: string
): Promise<AxiosResponse<LoginResponseDto>> =>
  api.post('/auth/onboarding/first-time-login', {
    resetToken,
  });

export const initPasswordRequest = async (
  initPasswordDto: InitPasswordDto
): Promise<AxiosResponse<void>> =>
  api.post('/auth/onboarding/init-password', initPasswordDto);
