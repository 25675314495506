export enum AddUserSteps {
  INFO = 'info',
  FORM = 'form',
  PENDING_CONSENT = 'pendingConsent',
  FAILED_CONSENT = 'failedConsent',
  SUCCESS_CONSENT = 'successConsent',
  SUCCESS = 'success',
}

export enum UserPosition {
  EXECUTIVE = 'executive',
  EMPLOYEE = 'employee',
  ASSOCIATE = 'associate',
  ACCOUNTANT = 'accountant',
  OTHER = 'other',
}

export const newUserEmptyState = {
  userId: '',
  userCompanyId: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  position: '',
};

export enum CompanyUserActions {
  REACTIVATE = 'reactivate',
  DEACTIVATE = 'deactivate',
  IDLE = 'idle',
}

export enum CompanyUserReactivateStep {
  CONFIRMATION = 'confirmation',
  PENDING_CONSENT = 'pendingConsent',
  SUCCESS_CONSENT = 'successConsent',
  FAILED_CONSENT = 'failedConsent',
}
