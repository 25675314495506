import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexContainerBasicColumn, Typography } from '@legalplace/storybook';

import { useAppDispatch, useAppSelector } from '../../../../hooks/store';
import { finalizeBridgeItemRequest } from '../../../../services/api/bridgeItem.api';
import { finalizeExternalAccountConnectionRequest } from '../../../../services/api/externalAccount.api';
import { startFetchAccounts } from '../../../../store/accounts/actions';
import { selectPendingAccounts } from '../../../../store/accounts/selectors';
import { selectActiveCompanyId } from '../../../../store/companies/selectors';
import {
  getInitialNewAccountsToSynchronize,
  getNewAccountsToSynchronizeValues,
} from '../../../selectSynchronizedAccountsModal/selectSynchronizedAccountsModal.helper';
import { SelectSynchronizedAccountsToggleList } from '../../../selectSynchronizedAccountsModal/SelectSynchronizedAccountsToggleList';

import { PrimaryButton } from '../../../UI/PrimaryButton.style';

export function BankSynchronizationAccountSelection({
  notifySuccess,
}: {
  notifySuccess: () => void;
}): JSX.Element {
  const { t } = useTranslation('onboarding', {
    keyPrefix: 'onboarding_v2.bank_synchronization',
  });
  const dispatch = useAppDispatch();
  const pendingAccounts = useAppSelector(selectPendingAccounts);
  const [isLoading, setIsLoading] = useState(false);
  const companyId = useAppSelector(selectActiveCompanyId);

  const [newAccountsToSynchronize, setNewAccountsToSynchronize] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    if (pendingAccounts.length) {
      setNewAccountsToSynchronize(
        getInitialNewAccountsToSynchronize(pendingAccounts)
      );
    }
  }, [pendingAccounts]);

  const finalizeBridgeItem = async (
    pendingBridgeItemId: string,
    selectedAccountIds: string[] = []
  ): Promise<void> => {
    try {
      setIsLoading(true);
      await finalizeBridgeItemRequest(pendingBridgeItemId, selectedAccountIds);
      dispatch(startFetchAccounts());
      notifySuccess();
    } catch (error: unknown) {
      console.error(
        `[finalizeBridgeItem] - Error finalizing bridge item ${pendingBridgeItemId}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const finalizeExternalAccountConnection = async (
    pendingExternalAuthId: string,
    selectedAccountIds: string[] = []
  ): Promise<void> => {
    try {
      setIsLoading(true);
      await finalizeExternalAccountConnectionRequest(
        pendingExternalAuthId,
        selectedAccountIds,
        companyId
      );
      dispatch(startFetchAccounts());
      notifySuccess();
    } catch (error: unknown) {
      console.error(
        `[finalizeExternalAuth] - Error finalizing external auth ${pendingExternalAuthId}`
      );
    } finally {
      setIsLoading(false);
    }
  };
  const finalizeAccounts = async (): Promise<void> => {
    if (
      !pendingAccounts?.length ||
      (!pendingAccounts[0].bridgeItemId &&
        !pendingAccounts[0].externalAccountConnectionId)
    ) {
      return;
    }

    const {
      bridgeItemId: pendingBridgeItemId,
      externalAccountConnectionId: pendingExternalAccountConnectionId,
    } = pendingAccounts[0];

    const accountsToSynchronize = getNewAccountsToSynchronizeValues(
      newAccountsToSynchronize
    );

    if (pendingBridgeItemId) {
      finalizeBridgeItem(pendingBridgeItemId, accountsToSynchronize);
    } else if (pendingExternalAccountConnectionId) {
      finalizeExternalAccountConnection(
        pendingExternalAccountConnectionId,
        accountsToSynchronize
      );
    }
  };

  return (
    <FlexContainerBasicColumn gap={{ default: '4rem' }}>
      <FlexContainerBasicColumn gap={{ default: '0.4rem' }}>
        <Typography tag="p" color="primary900" fontWeight="500">
          {t('message_title')}
        </Typography>
        <Typography tag="p3" color="primary900">
          {t('message_subtitle')}
        </Typography>
      </FlexContainerBasicColumn>
      <SelectSynchronizedAccountsToggleList
        newAccountsToSynchronize={newAccountsToSynchronize}
        setNewAccountsToSynchronize={setNewAccountsToSynchronize}
      />
      <PrimaryButton
        spacing={{ default: '1rem 0 1rem auto' }}
        width={{ default: 'fit-content' }}
        isLoading={isLoading}
        onClick={finalizeAccounts}
      >
        {t('sync_accounts_button_label')}
      </PrimaryButton>
    </FlexContainerBasicColumn>
  );
}
