import type { ThemeColorsType } from '@legalplace/storybook';
import {
  Button,
  css,
  FlexAlignCenterJustifyCenter,
  FlexContainerBasicColumn,
  keyframes,
  StreamLineIcon,
  styled,
} from '@legalplace/storybook';

export const ProgressCollapseContainer = styled(FlexContainerBasicColumn)`
  padding: ${({ theme }) => theme.spacing.small};
  border-radius: ${({ theme }) => theme.borders.radiusMd};
  :hover {
    background-color: ${({ theme }) => theme.backgroundColors.grey50};
  }
  transition: background-color 0.2s ease-out;
`;

export const ProgressCollapseButton = styled(Button)`
  padding: 0;
  width: 100%;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.small};
`;

export const TitleContainer = styled(FlexContainerBasicColumn)`
  gap: ${({ theme }) => theme.spacing.xxxxsmall};
  align-items: flex-start;
  flex-grow: 1;
`;

export const IconContainer = styled.div<{
  $backgroudColor: keyof ThemeColorsType;
}>`
  background-color: ${({ theme, $backgroudColor }) => theme[$backgroudColor]};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: grid;
  justify-items: center;
  align-items: center;
  > * {
    grid-column: 1;
    grid-row: 1;
  }
`;

export const CollapseArrow = styled(StreamLineIcon)<{ isOpened: boolean }>`
  transform: ${({ isOpened }) => (isOpened ? 'rotate(-180deg)' : '')};
  transition: transform 0.2s ease-out;
`;

const calculateClipPath = ($progress: number, $size: number): string => {
  if ($progress >= 1) {
    return 'none';
  }
  const halfSize = $size / 2;
  const radius = halfSize + 10;
  const radiansAngle = $progress * 2 * Math.PI;
  const largeArc = radiansAngle > Math.PI ? 1 : 0;
  return `path('M ${halfSize} ${halfSize} L ${halfSize} -10 A ${radius} ${radius} 0 ${largeArc} 0 ${
    halfSize - radius * Math.sin(radiansAngle)
  } ${halfSize - radius * Math.cos(radiansAngle)} Z')`;
};

export const ProgressCircle = styled(FlexAlignCenterJustifyCenter)<{
  $progress: number;
  $fillColor: keyof ThemeColorsType;
  $size: number;
}>`
  top: inherit;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 50%;
  border: 2px solid ${({ theme, $fillColor }) => theme[$fillColor]};
  clip-path: ${({ $progress, $size }) => calculateClipPath($progress, $size)};
`;

const collapsableOpen = keyframes`
  0% {
    overflow: hidden;
  }
  100% {
    overflow: hidden;
  }
`;

export const CollapsableContainer = styled.div<{
  isOpen?: boolean;
  maxHeight: number;
}>`
  max-height: ${({ isOpen, maxHeight }) => (isOpen ? `${maxHeight}px` : '0')};
  overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  ${({ isOpen }) =>
    isOpen &&
    css`
      animation: 0.2s ${collapsableOpen};
    `};
  transition: max-height 0.2s ease-out;
`;

export const CollapsableBodyContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing.medium} 0 0 ${theme.spacing.xxsmall}`};
`;
