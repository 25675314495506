import type { AxiosResponse } from 'axios';

import type { JustificationWrapper } from '@legalplace/bankroot-api/dto';
import type { ConsentUrlDto } from '@legalplace/bankroot-api/modules/consent/dto';
import type { GetOperationDto } from '@legalplace/bankroot-api/modules/operation/dto';
import type { PageDto } from '@legalplace/bankroot-api/modules/pagination/dto';
import type {
  CategorizeSimilarTransactionsDto,
  GetTransactionDto,
  GetTransactionQueryDto,
  PutTransactionDto,
} from '@legalplace/bankroot-api/modules/transaction/dto';

import type { IExecuteCreditTransfer } from '../../components/executeCreditTransfer/ExecuteCreditTransfer.interface';
import type { PaginationOptions } from '../../store/transactions/types';
import { getTransactionsRequestParams } from '../transactions/requestParams';

import { api } from './api';

export const getTransactionsRequest = (
  companyId: string,
  filters?: Partial<GetTransactionQueryDto>,
  paginationOptions?: PaginationOptions
): Promise<AxiosResponse<PageDto<GetTransactionDto>>> =>
  api.get(
    `/transaction?${getTransactionsRequestParams(
      companyId,
      filters,
      paginationOptions
    )}`
  );

export const exportTransactionsRequest = (
  companyId: string,
  filters?: Partial<GetTransactionQueryDto>
): Promise<AxiosResponse<Blob>> =>
  api.get(
    `/transaction/export?${getTransactionsRequestParams(companyId, filters)}`,
    { responseType: 'blob' }
  );

export const executeCreditTransfertRequest = (
  executeCreditTranferValue: IExecuteCreditTransfer
): Promise<AxiosResponse<ConsentUrlDto>> =>
  api.post(`/transaction`, executeCreditTranferValue);

export const updateTransactionRequest = (
  transactionId: string,
  putTransactionDto: PutTransactionDto
): Promise<
  AxiosResponse<
    JustificationWrapper<GetTransactionDto & { operations: GetOperationDto[] }>
  >
> => api.put(`/transaction/${transactionId}`, putTransactionDto);

export const getPcaTransactionsRequest = (
  companyId: string,
  filters?: Partial<GetTransactionQueryDto>,
  paginationOptions?: PaginationOptions,
  categorizableItemId?: string
): Promise<AxiosResponse<PageDto<GetTransactionDto>>> =>
  api.get(
    `/transaction/pca?${getTransactionsRequestParams(
      companyId,
      filters,
      paginationOptions,
      categorizableItemId
    )}`
  );

export const getSimilarTransactionsRequest = (
  transactionId: string,
  page: number,
  take: number
): Promise<AxiosResponse<PageDto<GetTransactionDto>>> =>
  api.get(
    `/transaction/similar?transactionId=${transactionId}&page=${page}&take=${take}`
  );

export const categorizeSimilarTransactionsRequest = async (
  transactionId: string,
  categorizeSimilarTransactionDto: CategorizeSimilarTransactionsDto
): Promise<void> =>
  api.post(
    `/transaction/${transactionId}/categorize-similar`,
    categorizeSimilarTransactionDto
  );
