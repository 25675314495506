import {
  FlexAlignCenterJustifyCenter,
  flexColumnAlignCenter,
  styled,
} from '@legalplace/storybook';

export const BankSynchronizationContainer = styled.div<{ isMobile?: boolean }>`
  ${flexColumnAlignCenter};
  height: 100%;
  gap: ${({ theme }) => theme.spacing.small};
  max-width: 60rem;
  padding: ${({ theme }) => theme.spacing.large};
  overflow: ${({ isMobile }) => (isMobile ? 'scroll' : 'visible')};
`;

export const SkipButtonContainer = styled(FlexAlignCenterJustifyCenter)`
  margin: ${({ theme }) => theme.spacing.medium};
`;

export const BankDisclaimerContainer = styled.div`
  ${flexColumnAlignCenter};
  gap: ${({ theme }) => theme.spacing.medium};
`;
