import type { GetOperationCategoryDto } from '@legalplace/bankroot-api/modules/operation/dto';
import { BalanceType } from '@legalplace/shared';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { ALL_CATEGORIES_OPERATION_DTO, Status } from '../../constants';
import { getAllLeafCategories } from '../../services/utils';

import type { CategoriesState } from './types';

const initialState: CategoriesState = {
  byId: {},
  allIds: [],
  status: Status.IDLE,
  currentCategoryPath: [],
  cachedCategories: {
    [BalanceType.Credit]: [],
    [BalanceType.Debit]: [],
  },
  byExternalId: {},
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    startFetchCategories: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        balanceType: BalanceType;
        isTabletDesktop: boolean;
      }>
    ) => {
      const { balanceType } = action.payload;

      if (state.cachedCategories[balanceType]?.length) {
        return;
      }

      state.byId = {};
      state.allIds = [];
      state.status = Status.LOADING;
      state.currentCategoryPath = [];
    },
    fetchCategoriesSuccess: (
      state,
      {
        payload: { categories, isTabletDesktop, balanceType, isFromCache },
      }: PayloadAction<{
        categories: GetOperationCategoryDto[];
        isTabletDesktop?: boolean;
        balanceType: BalanceType;
        isFromCache?: boolean;
      }>
    ) => {
      const leafCategories = getAllLeafCategories(categories);

      if (!isFromCache) {
        categories.unshift({
          ...ALL_CATEGORIES_OPERATION_DTO,
          childCategories: leafCategories,
        });
      }

      state.byId = categories.reduce(
        (accumulator, category: GetOperationCategoryDto) => ({
          ...accumulator,
          [category.id]: category,
        }),
        {} as Record<string, GetOperationCategoryDto>
      );
      const leafCategoriesByExternalId = leafCategories.reduce(
        (accumulator, category: GetOperationCategoryDto) => {
          if (!category.externalId) {
            return accumulator;
          }
          return {
            ...accumulator,
            [category.externalId]: category,
          };
        },
        {} as Record<string, GetOperationCategoryDto>
      );
      state.byExternalId = {
        ...state.byExternalId,
        ...leafCategoriesByExternalId,
      };
      state.allIds = categories.map((category) => category.id);
      state.status = Status.IDLE;

      if (!state.cachedCategories[balanceType]?.length) {
        state.cachedCategories[balanceType] = categories;
      }

      if (categories.length > 0 && isTabletDesktop) {
        state.currentCategoryPath = [categories[0]];
      }
    },
    fetchCategoriesFailed: (state) => {
      state.status = Status.FAILED;
    },
    updateCurrentCategoryPath: (
      state,
      { payload: currentCategoryPath }: PayloadAction<GetOperationCategoryDto[]>
    ) => {
      state.currentCategoryPath = currentCategoryPath;
    },
    resetCategories: () => initialState,
  },
});

export default categoriesSlice.reducer;
