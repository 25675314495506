import { styled } from '@legalplace/storybook';

import { addStyleInDesktopAndTablet } from '../UI/Responsive.style';

export const AccountingTrialEndContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundColors.primary900};
  padding: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  ${({ theme }) =>
    addStyleInDesktopAndTablet(
      theme,
      `margin-top:${theme.spacing.small}; 
       border-radius:${theme.borders.radiusMd};
       width:calc(100% - ${theme.spacing.large});
       margin-bottom:${theme.spacing.xlarge};`
    )};
`;
