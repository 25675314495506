import { type ReactNode, useEffect, useRef, useState } from 'react';

import type { ThemeColorsType } from '@legalplace/storybook';
import {
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import {
  CollapsableBodyContainer,
  CollapsableContainer,
  CollapseArrow,
  IconContainer,
  ProgressCircle,
  ProgressCollapseButton,
  ProgressCollapseContainer,
  TitleContainer,
} from './ProgressCollapse.style';

export interface ProgressCollapseProps {
  icon: StreamLineIconList;
  title: string;
  subtitle: string;
  progress: number;
  fillColor: keyof ThemeColorsType;
  fontColor: keyof ThemeColorsType;
  arrowColor: keyof ThemeColorsType;
  iconBackgroundColor: keyof ThemeColorsType;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  children?: ReactNode[] | ReactNode;
}

export function ProgressCollapse({
  icon,
  title,
  subtitle,
  progress,
  fillColor,
  fontColor,
  iconBackgroundColor,
  arrowColor,
  isOpen,
  setIsOpen,
  children,
}: ProgressCollapseProps): JSX.Element {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const isControlled = isOpen !== undefined && setIsOpen !== undefined;
  const [selectIsOpen, setSelectIsOpen] = isControlled
    ? [isOpen, setIsOpen]
    : [internalIsOpen, setInternalIsOpen];

  const bodyRef = useRef<HTMLDivElement | null>(null);
  const [bodyContentHeight, setBodyContentHeight] = useState(0);

  useEffect(() => {
    setBodyContentHeight(bodyRef.current?.offsetHeight || 0);
  }, [selectIsOpen, bodyRef, children]);

  const handleOnClick = (): void => {
    setSelectIsOpen(!selectIsOpen);
  };

  return (
    <ProgressCollapseContainer>
      <ProgressCollapseButton onClick={handleOnClick}>
        <IconContainer $backgroudColor={iconBackgroundColor}>
          <ProgressCircle
            $progress={progress}
            $fillColor={fillColor}
            $size={40}
          />
          <StreamLineIcon icon={icon} fillColor={fillColor} />
        </IconContainer>
        <TitleContainer>
          <Typography
            tag="p3"
            color={fontColor}
            lineHeight={{ default: '1.6rem' }}
            fontWeight="500"
          >
            {title}
          </Typography>
          <Typography
            tag="p5"
            color={fontColor}
            lineHeight={{ default: '1.6rem' }}
            fontWeight="400"
          >
            {subtitle}
          </Typography>
        </TitleContainer>
        <CollapseArrow
          icon={StreamLineIconList.arrowdown1}
          fillColor={arrowColor}
          isOpened={selectIsOpen}
        />
      </ProgressCollapseButton>
      <CollapsableContainer isOpen={selectIsOpen} maxHeight={bodyContentHeight}>
        <CollapsableBodyContainer ref={bodyRef}>
          {children}
        </CollapsableBodyContainer>
      </CollapsableContainer>
    </ProgressCollapseContainer>
  );
}
