import type { IBillingDocumentFilters } from '@legalplace/bankroot-api/interfaces';

import type { PaginationOptions } from '../../store/transactions/types';

export const getDocumentRequestParams = (
  companyId: string,
  filters?: IBillingDocumentFilters,
  paginationOptions?: PaginationOptions
): string => {
  const invoiceQueryParams = new URLSearchParams({ companyId });

  if (
    filters?.billingInvoiceStatuses &&
    Array.isArray(filters?.billingInvoiceStatuses)
  ) {
    filters.billingInvoiceStatuses.forEach((billingInvoiceStatuses) =>
      invoiceQueryParams.append(
        'billingInvoiceStatuses[]',
        billingInvoiceStatuses
      )
    );
  } else if (filters?.billingInvoiceStatuses) {
    invoiceQueryParams.append(
      'billingInvoiceStatuses[]',
      filters.billingInvoiceStatuses.toString()
    );
  }
  if (
    filters?.billingQuoteStatuses &&
    Array.isArray(filters?.billingQuoteStatuses)
  ) {
    filters.billingQuoteStatuses.forEach((billingQuoteStatuses) =>
      invoiceQueryParams.append('billingQuoteStatuses[]', billingQuoteStatuses)
    );
  } else if (filters?.billingQuoteStatuses) {
    invoiceQueryParams.append(
      'billingQuoteStatuses[]',
      filters.billingQuoteStatuses.toString()
    );
  }

  if (filters?.search) {
    invoiceQueryParams.append('search', filters.search);
  }
  if (filters?.isBeforeUpdatedAt) {
    invoiceQueryParams.append('isBeforeUpdatedAt', filters.isBeforeUpdatedAt);
  }
  if (filters?.isAfterUpdatedAt) {
    invoiceQueryParams.append('isAfterUpdatedAt', filters.isAfterUpdatedAt);
  }
  if (paginationOptions) {
    const { page, take } = paginationOptions;
    invoiceQueryParams.append('page', page.toString());
    invoiceQueryParams.append('take', take.toString());
  }

  return invoiceQueryParams.toString();
};
